import { Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertOptions, AlertService, OrderBy, NotificationOptions } from 'desy-angular';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { ProcedimientoAdministrativo } from 'src/app/interfaces/procedimiento-administrativo';
import { Protocolo } from 'src/app/interfaces/protocolo';
import { Rpt } from 'src/app/interfaces/rpt';
import { Workflow } from 'src/app/interfaces/workflow';
import { AdhesionService } from 'src/app/services/adhesiones/adhesion.service';
import { LoginService } from 'src/app/services/login/login.service';
import { ProtocolosService } from 'src/app/services/protocolos/protocolos.service';
import { RptService } from 'src/app/services/rpt/rpt.service';

@Component({
    selector: 'app-ver-adhesion-enviada',
    templateUrl: './ver-adhesion-enviada.component.html',
    styleUrls: ['./ver-adhesion-enviada.component.css'],
})
export class VerAdhesionEnviadaComponent implements OnInit {
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    @ViewChild('alertSuccess', { read: ViewContainerRef }) alertSuccess: ViewContainerRef;
    @Output() cerrarModalEvent = new EventEmitter<boolean>();
    controlaErrores: boolean;
    activeConfirmarRechazar: boolean;
    error: string;
    role: string;
    idAdhesion: string;
    openModal: boolean = false;
    constructor(
        private protocoloService: ProtocolosService,
        private adhesionService: AdhesionService,
        private rptService: RptService,
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private alertService: AlertService
    ) {}

    async ngOnInit() {
        this.buscando = true;
        this.route.params.subscribe((params) => {
            this.idAdhesion = params.idAdhesion;
        });
        await this.getDatos();
        this.buscando = false;
    }

    activeFinalizar: boolean = false;
    protocolo: Protocolo;
    procedimiento: ProcedimientoAdministrativo;
    adhesion: Adhesion;
    numRPTs: number = 0;
    buscando: boolean = false;
    rpts: Rpt[] = [];
    orderBy: OrderBy;
    type: any;
    workflows: Workflow[];
    workflowCreacion: any;
    motivoRechazo: string;
    title: string;
    subtitle: string | null;
    taskDpd: any;
    taskDsf: any;
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;

    async getDatos() {
        const idAdhesion = this.route.snapshot.paramMap.get('idAdhesion');

        this.adhesion = await this.adhesionService.getAdhesionById(idAdhesion!);
        this.role = this.loginService.getCurrentRole();
        this.rpts = [];

        this.rpts = this.adhesion.RPTs!;
        this.protocolo = await this.getProtocolo();
        this.procedimiento = this.getProcedimiento();
        this.workflows = await this.adhesionService.getWorkflowsByName({
            name: this.adhesion.nombreCompleto!,
            last: true,
            workflowType: ['CREATE'],
        });
        this.workflowCreacion = await this.adhesionService.getWorkFlow(this.workflows[0].idWorkflowExecution);
        this.taskDpd = this.workflowCreacion.workflow.taskList.find((task: any) => task.name == 'DPD Validation');
        this.taskDsf = this.workflowCreacion.workflow.taskList.find(
            (task: any) => task.name == 'DS_F Validation' || task.name == 'Data Steward FC Validation'
        );
        this.getTitle();
    }

    async getTitle() {
        const userOrganizationalUnit = JSON.parse(localStorage.getItem('operacion')!).organizationalUnit;
        const substepDpd = this.taskDpd?.subSteps.find(
            (substep: any) => substep.organizationalUnit !== userOrganizationalUnit
        );
        const substepDsf = this.taskDsf?.subSteps.find(
            (substep: any) => substep.organizationalUnit !== userOrganizationalUnit
        );
        const userDpd = await this.rptService.getUserByJobCode(substepDpd?.user);
        const userDsf = await this.rptService.getUserByJobCode(substepDsf?.user);
        const hasDpd = this.protocolo.SI === 'true';
        switch (this.adhesion.estado) {
            case EstadosAdhesionEnum.PENDIENTE:
                this.title = hasDpd
                    ? 'Esta adhesión necesita la aprobación del delegado de protección de datos y de la persona responsable funcionalmente del conjunto de datos de cesión.'
                    : 'Esta adhesión necesita la aprobación de la persona responsable funcionalmente del conjunto de datos de cesión.';
                this.subtitle = null;
                break;
            case EstadosAdhesionEnum.APROBADA:
                this.title = hasDpd
                    ? `Aprobada el ${this.formatDate(substepDpd.endDate)} por ${userDpd!.nombre} y el ${this.formatDate(
                          substepDsf.endDate
                      )} por ${substepDsf.user}`
                    : `Aprobada el ${this.formatDate(substepDsf.endDate)} por ${userDsf!.nombre}`;
                this.subtitle = null;
                break;
            case EstadosAdhesionEnum.RECHAZADA:
                this.title =
                    this.taskDpd?.state === EstadosAdhesionEnum.RECHAZADA
                        ? `Rechazada el ${this.formatDate(substepDpd.endDate)} por ${userDpd!.nombre}`
                        : `Rechazada el ${this.formatDate(substepDsf.endDate)} por ${userDsf!.nombre}`;
                this.subtitle =
                    this.taskDpd.state === EstadosAdhesionEnum.RECHAZADA
                        ? `El motivo del rechazo es: ${substepDpd.comment}`
                        : `El motivo del rechazo es: ${substepDsf.comment}`;
                break;
            case EstadosAdhesionEnum.BLOQUEADA:
                const workflowsBloqueo = await this.adhesionService.getWorkflowsByName({
                    name: this.adhesion.nombreCompleto!,
                    last: true,
                    workflowType: ['DEACTIVATE'],
                });
                const lastWorkflow = await this.adhesionService.getWorkFlow(workflowsBloqueo[0].idWorkflowExecution);
                const taskBloqueo = lastWorkflow.workflow.taskList[0];
                const usuarioBloqueo = await this.rptService.getUserByJobCode(
                    taskBloqueo?.subSteps[taskBloqueo.subSteps.length - 1].user
                );
                this.title =
                    this.taskDpd?.state === EstadosAdhesionEnum.BLOQUEADA
                        ? `Bloqueada el ${this.formatDate(
                              taskBloqueo?.subSteps[taskBloqueo.subSteps.length - 1].endDate
                          )} por ${usuarioBloqueo!.nombre}`
                        : `Bloqueada el ${this.formatDate(
                              taskBloqueo?.subSteps[taskBloqueo.subSteps.length - 1].endDate
                          )} por ${usuarioBloqueo!.nombre}`;
                //Cuando se añada la funcionalidad de añadir comentarios al bloqueo
                break;
            case EstadosAdhesionEnum.FINALIZADA:
                this.title = 'Esta adhesión está finalizada';
                this.subtitle = null;
                break;
        }
    }
    formatDate(endDate: Date) {
        const date = new Date(endDate);
        const month = date.toLocaleDateString('Es-es', { month: 'long' });
        return `${date.getDate()} de ${month} a las ${date.getHours()}:${date.getMinutes()}`;
    }

    async getProtocolo() {
        const resp: any = await this.protocoloService.getDatosProtocolo(this.adhesion?.idProtocolo);
        const protocolo: Protocolo = this.protocoloService.mapProtocolo(
            resp,
            this.adhesion?.idProtocolo!,
            this.adhesion.protocoloAri!,
            this.adhesion.estado!
        );

        return protocolo;
    }

    getProcedimiento() {
        let procedimiento: ProcedimientoAdministrativo;
        procedimiento = { nombre: this.adhesion.nombreServicio };
        return procedimiento;
    }

    limpiaNombreOrganismo(nombreCompleto: string) {
        return nombreCompleto?.substring(3);
    }

    goToListadoAdhesion() {
        this.router.navigate(['listado-adhesiones/enviadas']);
    }

    abreTabNormativa() {
        window.open(this.adhesion.baseLegal?.enlace, '_blank');
    }

    getOrganizacionDepartamento(nombreOrigin: string): string[] {
        let nombreArray = nombreOrigin.split('/');
        let nombreFinal: string[] = [];
        nombreFinal[0] = nombreArray[1];
        nombreFinal[1] = nombreArray[2];

        return nombreFinal;
    }
    openDialog() {
        this.activeFinalizar = true;
    }
    closeDialog() {
        this.activeFinalizar = false;
    }
    async finalizarAdhesion() {
        const resp: any = await this.adhesionService.finalizarAdhesion(
            this.adhesion.idAdhesion!,
            this.adhesion.estado!
        );
        if ((resp.code == '0000' || resp.code === '99997' || resp.code === '99996') && !resp.data?.error?.length) {
            this.buscando = true;
            this.closeDialog();
            await this.getDatos();
        } else {
            this.closeDialog();
            this.error = resp.data?.error.length ? resp.data.error.join('\n') : resp.description;
            this.openError();
        }
    }
    openError() {
        const alertOptions: AlertOptions = {
            id: 'alert',
            place: this.alertPlace,
            role: 'alert',
            ariaLive: 'assertLive',
            classes: 'mt-5',
        };

        let notificationOptions: NotificationOptions = {
            title: 'Ha ocurrido un error: ' + this.error,
            type: 'alert',
            classes: 'c-notification c-notification--alert',
            isDismissible: false,
        };
        this.alertService.openAlert(notificationOptions, alertOptions);
    }
    openSuccess() {
        const alertOptions: AlertOptions = {
            id: 'success',
            place: this.alertSuccess,
            role: 'success',
            ariaLive: 'assertLive',
            classes: 'mt-5',
        };

        let notificationOptions: NotificationOptions = {
            title: 'Adhesión modificada correctamente',
            description: { text: 'Las modificaciones en los RPTs se ha realizado correctamente.' },
            type: 'success',
            classes: 'c-notification c-notification--success',
            isDismissible: true,
        };
        this.alertService.openAlert(notificationOptions, alertOptions);
    }

    onModificarRpt() {
        this.openModal = true;
    }
    responseDataSon(data: boolean) {
        setTimeout(async () => {
            this.ngOnInit();
            this.openModal = data;
            this.openSuccess();
        }, 2000);
    }
    closeModalSon(data: boolean) {
        this.openModal = data;
    }
}

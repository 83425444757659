export class ChartModel {
    type;
    data;
    options;
    aplicacionesDiccionario;

    constructor(chartData, aplications) {
        this.aplications = aplications?.data;

        // Crear el diccionario de aplicaciones
        this.aplicacionesDiccionario = crearDiccionario(this.aplications, Colors);

        this.type = chartData.type ?? 'line';
        this.data = {
            labels: formattedData(
                chartData.map((x) => x.semana_dia_mes),
                chartData.map((x) => x.tipo_vista),
                chartData.map((x) => x.anyo)
            ),
            datasets: Object.keys(this.aplicacionesDiccionario).map(
                (x) => new Dataset(chartData, x, this.aplicacionesDiccionario)
            ),
        };

        this.options = {
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 14,
                        },
                    },
                },
                y: {
                    ticks: {
                        font: {
                            size: 14,
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    labels: {
                        font: {
                            size: 14,
                        },
                    },
                    position: 'bottom',
                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                },
                tooltip: {
                    mode: 'nearest',
                    intersect: false,
                    bodyFontSize: 40,
                    callbacks: {
                        label: function (data) {
                            const label = 'Numero de consultas realizadas: ' + data.formattedValue || '';
                            return label;
                        },
                    },
                },
            },
        };
    }
}

function formattedData(data, type, anyo) {
    return data.map((num) => {
        const numString = num.toString();
        if (numString.length === 8) {
            const dataformatString = `${numString.substring(0, 4)}-${numString.substring(4, 6)}-${numString.substring(
                6,
                8
            )}`;

            const fecha = new Date(dataformatString);
            const dia = fecha.getDate();
            const mes = fecha.toLocaleString('default', { month: 'short' });

            const fechaFormateada = dia + ' de ' + mes;

            return fechaFormateada;
        }
        if (type[0] === 'SEMANAL') {
            const fechasSemanales = anyo.map((x) => {
                const date = new Date(x, 0, 1 + (num - 1) * 7);
                const startDate = new Date(date.setDate(date.getDate() - date.getDay()));
                const endDate = new Date(date.setDate(date.getDate() + 6));

                const startMonth = ('0' + (startDate.getMonth() + 1)).slice(-2);
                const startDay = ('0' + startDate.getDate()).slice(-2);
                const endMonth = ('0' + (endDate.getMonth() + 1)).slice(-2);
                const endDay = ('0' + endDate.getDate()).slice(-2);

                const startYear = startDate.getFullYear();
                const endYear = endDate.getFullYear();

                return startDay + '/' + startMonth + ' al ' + endDay + '/' + endMonth + ' ' + startYear;
            });

            return Array.from(new Set(fechasSemanales));
        }
        if (type[0] === 'MENSUAL') {
            const mesesCortos = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
            return mesesCortos[num - 1];
        }
        if (type[0] === 'ANUAL') {
            return Array.from(new Set(anyo));
        }
    });
}

const Colors = Object.freeze({
    1: '#4d1f2e',
    2: '#aa3c2b',
    3: '#739ba5',
    4: '#00607a',
    5: '#013c53',
    6: '#bc8a01',
    7: '#ed6c02',
    8: '#ed020d',
    9: '#646464',
    10: '#92949b',
    11: '#7b3a49',
    12: '#d95744',
    13: '#89b2bd',
    14: '#0092b5',
    15: '#024c63',
    16: '#e2b500',
    17: '#ff8a33',
    18: '#ff4d4f',
    19: '#7a7a7a',
    20: '#bfc1c9',
});

function crearDiccionario(aplications, colors) {
    const diccionario = {};

    aplications.forEach((app, index) => {
        const alias = app.alias.toUpperCase() || 'SINAPP';
        const color = colors[index + 1] || '#000000'; // Color negro por defecto si no hay coincidencia

        diccionario[alias] = {
            siglas: alias,
            nombre: app.descripcion,
            propiedad: `total_${alias.toLowerCase()}`,
            color: color,
        };
    });

    return Object.freeze(diccionario);
}

class Dataset {
    label;
    data;
    fill;
    borderColor;
    tension;

    constructor(dataSet, type, aplicacionesDiccionario) {
        this.label = aplicacionesDiccionario[type].nombre;
        this.data = dataSet.map((x) => x[aplicacionesDiccionario[type].propiedad]);
        this.fill = false;
        this.borderColor = aplicacionesDiccionario[type].color;
        this.backgroundColor = aplicacionesDiccionario[type].color;
        this.tension = 0.3;
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filtroOrganismo',
})
export class FiltroOrganismoPipe implements PipeTransform {
    organizacionNombreOrdenado: String;
    transform(value: string | undefined): any {
        this.organizacionNombreOrdenado = '' + value;

        this.organizacionNombreOrdenado = this.organizacionNombreOrdenado.trim().replace(/_/g, ' ');
        // lowercase all except the first letter
        this.organizacionNombreOrdenado =
            this.organizacionNombreOrdenado[0].toUpperCase() + this.organizacionNombreOrdenado.slice(1).toLowerCase();
        // delete all ; characters
        this.organizacionNombreOrdenado = this.organizacionNombreOrdenado.replace(/;/g, ' ');
        if (this.organizacionNombreOrdenado.includes('Dpto')) {
            this.organizacionNombreOrdenado = this.organizacionNombreOrdenado.replace('Dpto', 'Departamento');
        }

        if (this.organizacionNombreOrdenado.includes('Dpto ciudadania')) {
            this.organizacionNombreOrdenado = 'Departamento de Ciudadanía y Derechos Sociales';
        }
        if (this.organizacionNombreOrdenado.includes('Iass')) {
            this.organizacionNombreOrdenado = 'Instituto Aragonés de Servicios Sociales (IASS)';
        }
        if (this.organizacionNombreOrdenado.includes('Dpto hacienda administración publica')) {
            this.organizacionNombreOrdenado = 'Departamento de Hacienda y Administración Pública';
        }
        if (this.organizacionNombreOrdenado.includes('Iaap')) {
            this.organizacionNombreOrdenado = 'Instituto Aragonés de Administración Pública (IAAP)';
        }
        if (this.organizacionNombreOrdenado.includes('Intervencion general')) {
            this.organizacionNombreOrdenado = 'Intervención general';
        }

        return this.organizacionNombreOrdenado;
    }
}

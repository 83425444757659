import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Output, EventEmitter } from '@angular/core';
import { Usuario } from 'src/app/interfaces/usuario';
import { environment } from '../../../environments/environment';
import { Token } from '../../interfaces/token';
import { DOCUMENT } from '@angular/common';
import { operacion } from 'src/app/interfaces/operacion';

const url = environment.url;
const urlToken = environment.urlGetToken;
@Injectable({
    providedIn: 'root',
})
export class LoginService {
    @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
    @Output() getIsLoggedIn: EventEmitter<any> = new EventEmitter();
    @Output() getToken: EventEmitter<any> = new EventEmitter();
    constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) {}

    async validarToken(body: any) {
        console.log('[HAL] Validando en servicio');
        return new Promise((resolve) => {         
            this.http.post(
            `${urlToken}/api/get_token`, 
            body
            ).subscribe((resp: any) => {
                resolve(resp);
            });
        });
            }

    async getRoles() {
        return new Promise((resolve) => {
            this.http.get(`${url}/gateway/api/v2/auth/user`).subscribe((resp: any) => {
                resolve(resp);
            });
        });
    }

    setCurrentRole(role: string[]) {
        localStorage.setItem('role', JSON.stringify(role));
    }

    getCurrentRole() {
        let token = JSON.parse(localStorage.getItem('role')!);
        return token
    }

    setCurrentToken(token: Token) {
        localStorage.setItem('token', JSON.stringify(token));
    }

    getCurrentToken(): Token {
        return JSON.parse(localStorage.getItem('token')!);
    }

    getOperacion(): operacion {
        return JSON.parse(localStorage.getItem('operacion')!);
    }

    setOperacion(operacion: operacion) {
        localStorage.setItem('operacion', JSON.stringify(operacion));
    }

    setTokenAnjana(tokenAnjana: string) {
        localStorage.setItem('tokenAnjana', tokenAnjana);
        this.getToken.emit(tokenAnjana);
    }

    getTokenAnjana(): string {
        return localStorage.getItem('tokenAnjana')!;
    }

    setUsuarioActual(usuario: Usuario) {
        localStorage.setItem('usuario', JSON.stringify(usuario));
        this.getLoggedInName.emit(usuario);
        this.getIsLoggedIn.emit(true);
    }

    getUsuarioActual(): Usuario {
        return JSON.parse(localStorage.getItem('usuario')!);
    }

    logOut() {
        const currentUser: Usuario = this.getUsuarioActual();
        localStorage.clear();
        //redirect the user to a page that does not require authentication
        this.document.location.href = `https://${environment.url_serv}/rest/identification/HAL/${btoa(
            environment.home_url
        )}/${currentUser.metodoIdentificacion}/logout`;
        //`https://preaplicaciones.aragon.es/mfe_core/rest/identification/HAL/aHR0cHM6Ly9kMjdqZ3Jyb2VqdXBtbS5jbG91ZGZyb250Lm5ldC8=/${currentUser.metodoIdentificacion}/logout`;
    }

    public isAuthenticated(): boolean {
        const usuario: Usuario = this.getUsuarioActual();
        const anjanaToken = this.getTokenAnjana();
        if (usuario != null && anjanaToken != null && usuario != undefined && anjanaToken != undefined) {
            if (Date.now() >= usuario.expiracion! * 1000) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}

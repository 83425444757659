import { Injectable, Pipe } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Query } from '../../interfaces/query';
import { Protocolo } from '../../interfaces/protocolo';
import { environment } from 'src/environments/environment';

const url = environment.url;
@Injectable({
    providedIn: 'root',
})
export class ProtocolosService {
    constructor(private http: HttpClient) {}

    async getProtocolos(query: Query) {
        return new Promise((resolve) => {
            this.http.post(`${url}/gateway/api/v1/indexer/kerno/search`, query).subscribe((resp: any) => {
                resolve(resp.data);
            });
        });
    }

    async getDatosProtocolo(protocoloId: string | undefined): Promise<any> {
        return new Promise((resolve) => {
            this.http.get(`${url}/gateway/api/admin/kerno/entity/DSA/${protocoloId}`).subscribe((resp: any) => {
                resolve(resp);
            });
        }); /*11*/
    }

    mapProtocolo({ data }: any, id: string, ari: string, state: string): Protocolo {
        const sections = data.menus.find((menu: any) => menu.name === 'Detalles').sections;
        const generalInfo = sections.find((section: any) => section.name === 'Información General');
        const gobierno = sections.find((section: any) => section.name === 'Gobierno');
        const terminosLicencia = sections.find((section: any) => section.name === 'Términos y Licencia');

        const protocolo = {
            ari: ari,
            nombre: generalInfo.sectionFields.find((sectionField: any) => sectionField.componentFront.name === 'name')
                .selectedValue?.value,
            descripcion: generalInfo.sectionFields.find(
                (sectionField: any) => sectionField.componentFront.name === 'description'
            ).selectedValue?.value,
            nombreDepartamento: ' nombreDepartamento',
            nombreOrganismo: 'nombreOrganismo',
            condiciones: terminosLicencia.sectionFields.find(
                (sectionField: any) => sectionField.componentFront.name === 'condiciones'
            ).selectedValue?.value,
            organismoExtendido: '',
            id,
            PI: gobierno.sectionFields.find((sectionField: any) => sectionField.componentFront.name === 'pi')
                .selectedValue?.value,
            SI: gobierno.sectionFields.find((sectionField: any) => sectionField.componentFront.name === 'si')
                .selectedValue?.value,
            state,
        };
        if (protocolo.nombreOrganismo === 'Dpto_Ciudadania') {
            protocolo.organismoExtendido = ' Departamento de Ciudadania y Derechos Sociales';
        } else {
            protocolo.organismoExtendido = protocolo.nombre;
        }
        return protocolo;
    }
    async getDatosProtocolo2(protocoloId: string | undefined): Promise<string[]> {
        return new Promise((resolve) => {
            this.http.get(`${url}/gateway/api/v2/entity/DSA/${protocoloId}`).subscribe((resp: any) => {
                resolve(resp);
            });
        }); /*11*/
    }

    getDatosProtocoloOrdenados(res: any) {
        const sections = res!.data.menus.find((menu: any) => menu.name === 'Detalles').sections;
        const sectionFields = sections.find((section: any) => section.name === 'Términos y Licencia').sectionFields;
        let datosDesordenados = sectionFields.find(
            (sectionField: any) => sectionField.componentFront.name === 'datafieldsaincluir'
        ).selectedValue?.value;
        let datosOrdenados: string[] = [];
        datosDesordenados.forEach((dato: any) => {
            let datoOrdenado = dato.ari.split(':');
            datosOrdenados.push(datoOrdenado[5]);
        });

        return datosOrdenados;
    }

    setProtocoloActual(protocolo: Protocolo) {
        localStorage.setItem('protocoloAdhesion', JSON.stringify(protocolo));
    }

    getProtocoloActual(): Protocolo {
        return JSON.parse(localStorage.getItem('protocoloAdhesion')!);
    }

    organizaDatosProtocolo(datos: string[]): string[] {
        let datosFinales: string[] = [];
        for (let dato of datos) {
            let datoTratado = dato.split(':');
            datosFinales.push(datoTratado[5]);
        }
        return datosFinales;
    }
}

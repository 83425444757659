<main id="adhesionContent" class="container mx-auto px-base">
    <div class="pb-sm"></div>
    <a (click)="goToSeleccionServicio()" role="button" draggable="false" class="c-button c-button--transparent">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 140 140"
            class="self-center mr-2"
            aria-hidden="true"
            focusable="false"
            width="1em"
            height="1em"
        >
            <path
                d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                fill="currentColor"
            ></path>
        </svg>
        Volver
    </a>
    <div class="pb-sm"></div>
    <p class="c-paragraph-sm text-neutral-dark">Paso 3 de 5</p>
    <h1 class="c-h1">Nueva adhesión</h1>

    <h2 class="c-h2">3. Base legal</h2>
    <p class="c-paragraph-base text-neutral-dark">
        Añade la normativa general que regula este servicio y especifica el tiempo de vigencia de la adhesión.
    </p>

    <p class="text-sm mb-2">Todos los campos marcados con asterisco son obligatorios</p>
    <p class="c-paragraph-base font-semibold mb-0">Código de la normativa</p>
    <form #formularioBases="ngForm">
        <div class="container w-full mx-auto flex justify-between" id="contenedorCodigo">
            <desy-select
                id="tipo"
                name="valorTipo"
                class="pr-base w-1/2"
                classes="w-full"
                [items]="items"
                [(ngModel)]="valorTipo"
                required
                #tipoControl="ngModel"
                ngDefaultControl
            >
                <desy-error-message
                    *ngIf="!valorTipo && controlaErrores"
                ></desy-error-message>
                <desy-label>Tipo*</desy-label></desy-select
            >
            <desy-input
                id="valorRango"
                class="pr-base year"
                classes="w-full"
                name="valorRango"
                labelText="Rango/Año*"
                placeholder="Ej. 38/2004"
                required="true"
                [(ngModel)]="valorRango"
                required
                #rangoControl="ngModel"
                ngDefaultControl
            >
                <desy-error-message
                    *ngIf="(!valorRango &&  controlaErrores) || controlaFechaNormativa"
                ></desy-error-message>
            </desy-input>

            <desy-input
                id="inputDia"
                name="inputDia"
                class="pr-base"
                classes="w-14"
                labelText="Día*"
                [(ngModel)]="valorDiaNorma"
                required
                #rangoControl="ngModel"
                [maxlength]="2"
                inputmode="numeric"
                ngDefaultControl
                ><desy-error-message
                    *ngIf="(!valorDiaNorma && controlaErrores) ||  controlaFechaNormativa"
                ></desy-error-message>
            </desy-input>
            <desy-select
                name="valorMesNorma"
                labelText="Mes*"
                class="month"
                classes="w-full"
                [items]="meses"
                [(ngModel)]="valorMesNorma"
                required
                ngDefaultControl
            >
                <desy-error-message
                    *ngIf="(!valorMesNorma && controlaErrores) || controlaFechaNormativa"
                ></desy-error-message>
            </desy-select>
        </div>

        <p class="c-paragraph-base font-semibold mb-0">Título de la normativa*</p>
        <desy-textarea
            name="valorTituloNorma"
            hintText="Incluye el título de la normativa sin escribir su rango ni su fecha."
            id="tituloNormativa"
            placeholder="Ej. General de subvenciones..."
            classes="w-full"
            class="w-full"
            [(ngModel)]="valorTituloNorma"
            required
            #tituloNormaControl="ngModel"
            ngDefaultControl
        >
            <desy-tooltip [id]="'tooltip'" [complex]="true">
                <desy-icon>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 140 140"
                        width="1em"
                        height="1em"
                        class="w-4 h-4 text-neutral-dark"
                        role="img"
                        aria-label="Ayuda"
                    >
                        <path
                            d="M140 15a15 15 0 00-15-15H15A15 15 0 000 15v110a15 15 0 0015 15h110a15 15 0 0015-15zM70 117.51a10 10 0 1110-10 10 10 0 01-10 10zm9.17-39.08a2.5 2.5 0 00-1.67 2.36v1.71a7.5 7.5 0 01-15 0v-10A7.5 7.5 0 0170 65a12.5 12.5 0 10-12.5-12.5 7.5 7.5 0 01-15 0 27.5 27.5 0 1136.67 25.93z"
                            fill="currentColor"
                        />
                    </svg>
                </desy-icon>
                <desy-tooltip-content>
                    <p>TODO</p>
                </desy-tooltip-content>
            </desy-tooltip>
            <desy-error-message
                *ngIf="(!valorTituloNorma || valorTituloNorma.length === 0) && controlaErrores"
                text="Este campo es obligatorio"
            ></desy-error-message
        ></desy-textarea>

        <p class="c-paragraph-base font-semibold mb-0">Enlace de la normativa*</p>
        <desy-input
            name="enlaceNormativa"
            id="enlaceNormativa"
            classes="w-full"
            class="w-full"
            placeholder="Ej. https://www.boe.es/pdf/2003/BOE-A2003-20977-consolidado.pdf"
            [(ngModel)]="valorEnlaceNorma"
            required
            #enlaceNormativaControl="ngModel"
            ngDefaultControl
        >
            <desy-error-message *ngIf="(!valorEnlaceNorma || valorEnlaceNorma.length === 0) && controlaErrores"
                >Debes seleccionar al menos una opción</desy-error-message
            >
        </desy-input>

        <div class="pb-md"></div>

        <p class="c-paragraph-base font-semibold mb-0">Tiempo de vigencia de la adhesión al protocolo de consulta*</p>
        <desy-radios
            [name]="'vigencia'"
            [(ngModel)]="valorVigencia"
            required
            #vigenciaControl="ngModel"
            ngDefaultControl
        >
            <desy-error-message *ngIf="(!valorVigencia || valorVigencia.length === 0) && controlaErrores"
                >Debes seleccionar al menos una opción</desy-error-message
            >
            <desy-radio-item value="indefinido" id="indefinido">
                <desy-label>Indefinido</desy-label>
                <desy-hint>La adhesión al protoclo de consulta quedará vigente por un periodo ilimitado.</desy-hint>
            </desy-radio-item>

            <desy-radio-item [conditional]="true" value="fechaDefinida">
                <desy-label>Hasta una fecha concreta</desy-label>
                <desy-hint>
                    La adhesión al protocolo de consulta estará vigente solo hasta una fecha determinada.
                </desy-hint>
                <desy-content>
                    <div class="pb-sm"></div>

                    <form class="form" [formGroup]="form">
                        <desy-date-input
                            formGroupName="formFecha"
                            [hintData]="hintData"
                            [errorMessageData]="errorMessageData"
                            [(items)]="itemsFecha"
                            [formGroupClasses]="formGroupClasses"
                            [classes]="classes"
                            [role]="role"
                            [ariaLabel]="ariaLabel"
                            [ariaDescribedBy]="ariaDescribedBy"
                            [ariaLabelledBy]="ariaLabelledBy"
                            [ariaHidden]="ariaHidden"
                            [ariaDisabled]="ariaDisabled"
                            [ariaControls]="ariaControls"
                            [ariaCurrent]="ariaCurrent"
                            [ariaLive]="ariaLive"
                            [ariaExpanded]="ariaExpanded"
                            [ariaErrorMessage]="ariaErrorMessage"
                            [ariaHasPopup]="ariaHasPopup"
                            [tabindex]="tabindex"
                        >
                            <desy-error-message
                                *ngIf="
                                    controlaFechaVigencia
                                "
                                >Debes introducir una fecha correcta</desy-error-message
                            >
                        </desy-date-input>
                    </form>
                </desy-content>
            </desy-radio-item>
            >
        </desy-radios>

        <desy-error-message *ngIf="controlaErrores">*Debes rellenar todos los campos para continuar</desy-error-message>
        <desy-error-message *ngIf="controlaFechaNormativa && !controlaErrores"
            >*El día del código de la normativa es incorrecto</desy-error-message
        >
        <desy-error-message *ngIf="controlaFechaVigencia && !controlaFechaNormativa && !controlaErrores">*La fecha de vigencia es incorrecta</desy-error-message>
        <div class="pb-sm"></div>
        <desy-button
            type="button"
            (clickEvent)="onSubmit()"
            classes="c-button-loader--primary"
            id="botonFinal"
            [preventDoubleClick]="true"
        >
            Continuar</desy-button
        >
        <div class="pb-md"></div>
    </form>

    <div class="pb-md"></div>
</main>

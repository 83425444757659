<div class="card w-full h-40 border border-neutral-base box flex items-center justify-center">
  <desy-spinner class="h-72" classes="pt-36" *ngIf="buscando"></desy-spinner>
    <div *ngIf="!buscando" class="w-full">
        <div class="flex items-end mt-5 ml-5 mb-4">
            <div class="cuadrado" [ngClass]="state"></div>
            <p class="ml-2 font-semibold">{{ title }}</p>
        </div>
        <div class="flex mx-5 border-t border-neutral-base">
            <h1 class="text-5xl mt-6">{{ amount }}</h1>
            <div class="flex flex-col ml-4 mt-6">
                <p class="c-paragraph-base mb-1">{{subtitle}}</p>
                <a
                    [routerLink]="[url]"
                    [queryParams]="{ estado: state }"
                    class="c-paragraph-base text-primary-base underline font-semibold"
                    >{{link}}</a
                >
            </div>
        </div>
    </div>
</div>

import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Protocolo } from '../../../interfaces/protocolo';
import { ProcedimientoAdministrativo } from '../../../interfaces/procedimiento-administrativo';
import { ProtocolosService } from '../../../services/protocolos/protocolos.service';
import { ProcedimientoAdminsitrativoService } from '../../../services/procedimiento-administrativo/procedimiento-adminsitrativo.service';
import { RptService } from '../../../services/rpt/rpt.service';
import { Rpt } from '../../../interfaces/rpt';
import { Bases } from '../../../interfaces/bases';
import { EnvioAdhesion } from 'src/app/interfaces/envio-adhesion';
import { EnvioAdhesionService } from '../../../services/envio-adhesion/envio-adhesion.service';
import { ConfirmacionAdhesion } from '../../../interfaces/confirmacion-adhesion';
import { Router } from '@angular/router';
import { AlertOptions, AlertService, NotificationOptions } from 'desy-angular';
import { LoginService } from 'src/app/services/login/login.service';
import { Query } from 'src/app/interfaces/query';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';
import { RolesEnum } from 'src/app/enum/roles';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-paso-5',
    templateUrl: './paso5.component.html',
    styleUrls: ['./paso5.component.css'],
})
export class Paso5Component implements OnInit {
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    autoButtonHint: string;
    autoButtonState: string;
    autoButtonClass: string = 'c-button-loader--primary ml-sm';
    autoButtonDisabled: boolean;
    observaciones: string | null = '';
    protocolo: Protocolo;
    procedimiento: ProcedimientoAdministrativo;
    rpts: Rpt[] = [];
    baseLegal: Bases;
    datosProtocolo: any;
    meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ];
    baseMes: string;
    vigenciaAdhesion: string;
    errorAdhesion: boolean = false;
    buscando = false;
    error: string;
    jobCode: string;
    flagTooltip: boolean = true;
    queryGetAri: Query = {
        facetPrefix: '',
        fq: {
            LAST_MODIFIED_OBJECT: [true],
            OBJ_SUB_TYPE: ['PUESTO'],
            OBJ_TYPE: ['ENTITY'],
        },
        groupBy: '',
        orderBy: 'OBJ_NAME',
        q: '',
        rows: 0,
        simpleSearch: false,
        sortingType: 'ASC',
        start: 0,
        zeroRows: false,
    };
    isCollapsed: boolean = true;

    constructor(
        private alertService: AlertService,
        private envioAdhesionService: EnvioAdhesionService,
        private loginService: LoginService,
        private nuevaAdhesionService: NuevaAdhesionService,
        private procedimientoService: ProcedimientoAdminsitrativoService,
        private protocoloService: ProtocolosService,
        private router: Router,
        private rptService: RptService
    ) {}

    async ngOnInit() {
        this.buscando = true;
        if (!this.nuevaAdhesionService.getRPTsActuales()) {
            this.router.navigate(['/nueva-adhesion/RPTs']);
            return;
        }
        await this.getDatos();
        this.buscando = false;
        this.jobCode = this.loginService.getUsuarioActual().jobCode!;
    }

    async getDatos() {
        this.protocolo = this.nuevaAdhesionService.getProtocoloActual()!;
        this.procedimiento = this.nuevaAdhesionService.getProcedimientoActual()!;
        this.rpts = this.nuevaAdhesionService.getRPTsActuales()!;
        this.baseLegal = this.nuevaAdhesionService.getIncorporacionBases()!;
        this.baseMes = this.meses[parseInt(this.baseLegal?.mes) - 1];
        let res = await this.protocoloService.getDatosProtocolo(this.protocolo.id!);
        let datosOrdenados = this.protocoloService.getDatosProtocoloOrdenados(res);
        this.datosProtocolo = this.getNivelesDatos(datosOrdenados);
        let fechaVigenciaAdhesion = this.baseLegal?.tiempoVigencia?.split('/');
        let fechaDia: string = '';
        if (Number.parseInt(fechaVigenciaAdhesion![0]) - 1 < 10) {
            fechaDia = '0' + Number.parseInt(fechaVigenciaAdhesion![0]);
        } else {
            fechaDia = Number.parseInt(fechaVigenciaAdhesion![0]) - 1 + '';
        }
        this.vigenciaAdhesion =
            fechaVigenciaAdhesion![2] + '-' + fechaVigenciaAdhesion![1] + '-' + fechaDia + 'T' + '22:00:00.000Z';
    }

    getNivelesDatos(datos: string[]) {
        let datosADevolver: string[] = [];
        datos.forEach((dato: string) => {
            let partes = dato.split('-');
            let nuevoTexto = partes[0].trim();

            datosADevolver.push(nuevoTexto);
        });
        return datosADevolver;
    }
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    groupBy(list: any, prop: any) {
        return list.reduce((groupped: any, item: any) => {
            var key = item[prop];
            delete item[prop];
            if (groupped.hasOwnProperty(key)) {
                groupped[key].push(item);
            } else {
                groupped[key] = [item];
            }
            return groupped;
        }, {});
    }
    groupSubKeys(obj: any, properties: any, propIndex: any) {
        var grouppedObj = this.groupBy(obj, properties[propIndex]);
        Object.keys(grouppedObj).forEach((key) => {
            if (propIndex < properties.length - 2) {
                grouppedObj[key] = this.groupSubKeys(grouppedObj[key], properties, propIndex + 1);
            } else {
                grouppedObj[key] = grouppedObj[key].map((item: any) => item[properties[propIndex + 1]]);
            }
        });
        return grouppedObj;
    }

    groupByProperties(list: any, properties: any) {
        return this.groupSubKeys(list, properties, 0);
    }

    async solicitaAdhesion() {
        this.flagTooltip = false;
        this.autoButtonState = 'is-loading';
        this.autoButtonClass = 'c-button-loader--is-loading c-button-loader--primary ml-sm';
        this.autoButtonDisabled = true;
        this.autoButtonHint = 'Carga de 3 segundos...';

        let tipoDato: EnvioAdhesion = {
            id: 615,
            name: 'name',
            label: 'Nombre',
            description: '',
            isCustom: false,
            type: 'TEXT_AREA',
            value: this.protocolo?.nombre + '_' + this.procedimiento?.codigo + '_' + this.jobCode + '_' + Date.now(),
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let dsa: EnvioAdhesion = {
            id: 611,
            name: 'source',
            label: 'fuente',
            description: '',
            isCustom: false,
            type: 'ENTITY_SEARCH',
            value: {
                ari: this.protocolo?.ari,
                state: this.protocolo?.state,
                version: 0,
                metadata: {
                    link: `/entity/dsa/form/${this.protocolo.id}`,
                },
            },
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        const queryDestination: Query = { ...this.queryGetAri, q: this.jobCode, rows: 1 };
        const respDestination: any = await this.rptService.getRPTs(queryDestination);
        const ariUser: string = respDestination.results.BASIC[0].solrDocument.ARI;
        const idObj: string = respDestination.results.BASIC[0].solrDocument.ID_OBJ;
        const state: string = respDestination.results.BASIC[0].solrDocument.STATE;
        let destinatario: EnvioAdhesion = {
            id: 612,
            name: 'destination',
            label: 'Destino',
            description: '',
            isCustom: false,
            type: 'ENTITY_SEARCH',
            value: {
                ari: ariUser,
                state: state,
                metadata: {
                    link: `/entity/puesto/form/${idObj}`,
                },
            },
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let procedimiento: EnvioAdhesion = {
            id: 613,
            name: 'procedimiento',
            label: 'Procedimiento',
            description: 'Procedimiento',
            isCustom: false,
            type: 'TEXT_AREA',
            value: this.procedimiento?.codigo! + ' - ' + this.procedimiento?.nombre!,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let zona: EnvioAdhesion = {
            id: 662,
            name: 'zone',
            label: 'Zona',
            description: '',
            isCustom: false,
            type: 'SELECT',
            value: environment.zone,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };

        const q = this.rpts.map((rpt) => rpt.puesto);
        const queryPuestos: Query = { ...this.queryGetAri, q: q.join(' , '), rows: this.rpts.length };
        const resp: any = await this.rptService.getRPTs(queryPuestos);

        const arisPuestos: string[] = resp.results.BASIC.map((item: any) => ({
            ari: item.solrDocument.ARI,
            state: item.solrDocument.STATE,
            metadata: {
                link: `/entity/puesto/form/${item.solrDocument.ID_OBJ}`,
            },
        }));
        let rpts: EnvioAdhesion = {
            id: 659,
            name: 'job_list',
            label: 'Listado de puestos',
            description: 'Listado de puestos',
            isCustom: false,
            type: 'ARRAY_ENTITY',
            value: arisPuestos,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let fechaLimite: EnvioAdhesion = {
            id: 618,
            name: 'fecha_limite',
            label: 'Fecha Límite',
            description: 'Fecha limite de adhesión al protocolo',
            isCustom: false,
            type: 'INPUT_DATE',
            value: this.vigenciaAdhesion,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let observaciones: EnvioAdhesion = {
            id: 620,
            name: 'observaciones',
            label: 'Observaciones',
            description: 'Ingrese las observaciones que considere.',
            isCustom: false,
            type: 'TEXT_AREA',
            value: this.observaciones == '' ? null : this.observaciones,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let tipoNormativa: EnvioAdhesion = {
            id: 625,
            name: 'tipo_normativa',
            label: 'Tipo Normativa',
            description: 'Tipo de normativa',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.baseLegal?.tipo,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let rangoAnyo: EnvioAdhesion = {
            id: 626,
            name: 'rango_ano',
            label: 'Rango/Año',
            description: 'Rango/Año',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.baseLegal?.rango,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let dia: EnvioAdhesion = {
            id: 627,
            name: 'dia',
            label: 'Día',
            description: 'Día',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.baseLegal?.dia,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let mes: EnvioAdhesion = {
            id: 628,
            name: 'mes',
            label: 'Mes',
            description: 'Mes',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.baseLegal?.mes,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let tituloNormativa: EnvioAdhesion = {
            id: 629,
            name: 'titulo_normativa',
            label: 'Título de la Normativa',
            description: 'Título de la Normativa',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.baseLegal?.titulo,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let enlaceNormativa: EnvioAdhesion = {
            id: 630,
            name: 'enlace_normativa',
            label: 'Enlace de la Normativa',
            description: 'Enlace de la Normativa',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.baseLegal?.enlace,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let PI: EnvioAdhesion = {
            id: 632,
            name: 'pi',
            label: 'PI',
            description: 'PI',
            isCustom: false,
            type: 'INPUT_CHECKBOX',
            value: this.protocolo.PI!,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let SI: EnvioAdhesion = {
            id: 644,
            name: 'si',
            label: 'SI',
            description: 'Información sensible',
            isCustom: false,
            type: 'INPUT_CHECKBOX',
            value: this.protocolo.SI ?? 'false',
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        const finishDate: EnvioAdhesion = {
            id: 661,
            name: 'finish_date',
            label: 'Fecha de finalización',
            description: 'Fecha de finalización',
            isCustom: false,
            type: 'INPUT_DATE',
            value: null,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };

        let envioAdhesion: EnvioAdhesion[] = [
            tipoDato,
            dsa,
            destinatario,
            procedimiento,
            rpts,
            fechaLimite,
            observaciones,
            tipoNormativa,
            rangoAnyo,
            dia,
            mes,
            tituloNormativa,
            enlaceNormativa,
            PI,
            SI,
            finishDate,
            zona,
        ];
        interface Peticion {
            entityAttributes: EnvioAdhesion[];
        }
        let peticion: Peticion = {
            entityAttributes: envioAdhesion,
        };

        let idAdhesion: any = await this.envioAdhesionService.enviarAdhesion(peticion);
        if (idAdhesion.code == '0000') {
            let confirmacionAdhesion: ConfirmacionAdhesion = {
                // TODO: rol hardcodeado
                role: RolesEnum.JEFE_SERVICIO,
                requestReason: null,
            };
            let respuestaConfirmacion: any = await this.envioAdhesionService.confirmarAdhesion(
                confirmacionAdhesion,
                idAdhesion.data.id
            );
            this.autoButtonState = 'is-success';
            this.autoButtonClass = 'c-button-loader--is-success c-button-loader--primary ml-sm';
            this.autoButtonHint = 'Reiniciando en 3 segundos...';
            this.autoButtonDisabled = false;
            this.router.navigate(['/adhesion-enviada', idAdhesion!.data?.id]);
        } else {
            this.error = idAdhesion.code + ' ' + idAdhesion.description;
            this.openError();
            console.error('Ha habido un error ', idAdhesion.code, ' la causa es: ', idAdhesion.description);
            this.autoButtonState = 'is-success';
            this.autoButtonClass = 'c-button-loader--is-success c-button-loader--primary ml-sm';
            this.autoButtonHint = 'Reiniciando en 3 segundos...';
            this.errorAdhesion = true;
        }
    }

    openError() {
        const alertOptions: AlertOptions = {
            id: 'alert',
            place: this.alertPlace,
            role: 'alert',
            ariaLive: 'assertLive',
            classes: 'mt-5',
            focusFirst: true,
        };

        var notificationOptions: NotificationOptions = {
            title: {
                text: 'Ha ocurrido un error: ' + this.error,
            },
            type: 'alert',
            classes: 'c-notification c-notification--alert',
            isDismissible: false,
        };
        this.alertService.openAlert(notificationOptions, alertOptions);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConfirmacionAdhesion } from 'src/app/interfaces/confirmacion-adhesion';

const url = environment.url;
@Injectable({
    providedIn: 'root',
})
export class EnvioAdhesionService {
    constructor(private http: HttpClient) {}

    async enviarAdhesion(envioAdhesion: any) {
        return new Promise((resolve) => {
            this.http.post(`${url}/gateway/api/v2/relationship/save/ADHESION`, envioAdhesion).subscribe((resp: any) => {
                resolve(resp);
            });
        });
    }

    async confirmarAdhesion(confirmacionAdhesion: ConfirmacionAdhesion, id: any) {
        return new Promise((resolve) => {
            this.http
                .post(`${url}/gateway/api/v2/relationship/submit/ADHESION/${id}`, confirmacionAdhesion)
                .subscribe((resp: any) => {
                    resolve(resp);
                });
        });
    }

    async getRPTsAnjana2(id: any) {
        return new Promise((resolve) => {
            this.http.get(`${url}/gateway/api/v2/relationship/16876`).subscribe((resp: any) => {
                resolve(resp.data);
            });
        });
    }
}

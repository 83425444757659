let aplicationsRef = {};
export default function transformarDatos(response, aplications) {
    // Verificar si 'response' es un array antes de usar 'flatMap'
    if (!Array.isArray(response)) {
        console.error('El response no es un array:', response);
        return [];
    }

    // Definir el array de meses fuera del switch para evitar el error
    const meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
    ];
    if (!aplications) {
        aplicationsRef = aplicationsRef;
    } else {
        aplicationsRef = aplications;
    }
    // Crear un mapa de alias a descripciones para facilitar la búsqueda
    const aliasMap = aplicationsRef?.data.reduce((acc, app) => {
        acc[app.alias.toLowerCase()] = app.descripcion; // Convertir alias a minúsculas
        return acc;
    }, {});
    console.log('[ALIASMAP]: ', aliasMap);

    const data = response.flatMap((item) => {
        const { tipo_vista, semana_dia_mes: periodo, anyo, ...totals } = item;

        const periodoStr = periodo.toString();
        let formattedPeriodo;

        // Manejo del formato basado en el tipo de vista
        switch (tipo_vista) {
            case 'DIARIO':
                if (periodoStr.length !== 8) {
                    console.error('El formato de periodo es inválido para vista DIARIA:', periodo);
                    return [];
                }
                const anio = periodoStr.substring(0, 4);
                const mes = periodoStr.substring(4, 6);
                const dia = periodoStr.substring(6, 8);
                const mesNombre = meses[Number(mes) - 1];
                formattedPeriodo = `${Number(dia)} de ${mesNombre} de ${anio}`;
                break;

            case 'SEMANAL':
                if (periodoStr.length !== 2) {
                    console.error('El formato de periodo es inválido para vista SEMANAL:', periodo);
                    return [];
                }
                const semana = Number(periodoStr);
                const primerDia = obtenerPrimerDiaSemana(anyo, semana);
                const ultimoDia = obtenerUltimoDiaSemana(anyo, semana);
                formattedPeriodo = `Del ${formatearFecha(primerDia)} al ${formatearFecha(ultimoDia)}`;
                break;

            case 'MENSUAL':
                if (periodoStr.length < 1 || periodoStr.length > 2) {
                    console.error('El formato de periodo es inválido para vista MENSUAL:', periodo);
                    return [];
                }
                const mesMensual = Number(periodoStr);
                const mesNombreMensual = meses[mesMensual - 1];
                formattedPeriodo = `${mesNombreMensual}`;
                break;

            case 'ANUAL':
                if (periodoStr.length < 1 || periodoStr.length > 4) {
                    console.error('El formato de periodo es inválido para vista ANUAL:', periodo);
                    return [];
                }
                formattedPeriodo = periodoStr === '0' ? `${anyo}` : `${periodoStr}`;
                break;

            default:
                console.error('Tipo de vista no reconocido:', tipo_vista);
                return [];
        }

        // Filtrar y preparar los totales
        const totalsArray = Object.entries(totals)
            .filter(([key]) => key.startsWith('total') && key !== 'total')
            .map(([key, value]) => {
                const nombreSinPrefijo = key.replace('total_', ''); // Eliminar 'total_'

                // Verificar si el alias es vacío

                if (nombreSinPrefijo === 'sinapp') {
                    return { nombre: 'Sin aplicacion solicitante', valor: value }; // Usar 'Sin aplicación' para alias vacío
                }

                const descripcion = aliasMap[nombreSinPrefijo.toLowerCase()] || nombreSinPrefijo; // Ignorar mayúsculas y minúsculas
                return { nombre: descripcion, valor: value };
            });

        return [{ periodo: formattedPeriodo, totales: totalsArray }];
    });

    return data;
}

// Función para obtener el primer día de una semana en un año dado
function obtenerPrimerDiaSemana(anyo, semana) {
    const fecha = new Date(anyo, 0, 1 + (semana - 1) * 7); // Primer día del año + semanas
    const diaSemana = fecha.getDay(); // Obtener día de la semana (0 = Domingo, 6 = Sábado)
    const diferencia = (diaSemana <= 1 ? 1 : 8) - diaSemana; // Diferencia para llegar al Lunes
    fecha.setDate(fecha.getDate() + diferencia); // Ajustar al Lunes
    return fecha;
}

// Función para obtener el último día de una semana en un año dado
function obtenerUltimoDiaSemana(anyo, semana) {
    const primerDia = obtenerPrimerDiaSemana(anyo, semana);
    const ultimoDia = new Date(primerDia);
    ultimoDia.setDate(primerDia.getDate() + 6); // Agregar 6 días para obtener el Domingo
    return ultimoDia;
}

// Función para formatear fechas
function formatearFecha(fecha) {
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Meses de 0-11, por eso sumamos 1
    const anio = fecha.getFullYear();
    return `${dia}/${mes}/${anio}`;
}

<main id="content" class="container mx-auto px-base my-12">
    <ng-template #alertPlace></ng-template>
    <div *ngIf="!buscando">
        <iframe title="mfe_iframe" width="100%" height="450" id="mfe_iframe" src=""> </iframe>
    </div>
    <desy-spinner *ngIf="buscando" id="spinner" text="Cargando..." classes="text-2xl py-24"></desy-spinner>
    <hr class="border-t border-neutral-base my-xl" />
    <h2 class="font-bold mb-base">Preguntas frecuentes</h2>
    <ul>
        <li>
            <desy-details summaryText="¿Qué es Cl@ve?">
                <div class="prose ml-6">
                    <p>
                        Cl&#64;ve es una plataforma de verificación de identidades electrónicas para la identificación y
                        autenticación de la ciudadanía. Nos permite identificarnos ante las Administraciones Públicas
                        con plenas garantías de seguridad.
                    </p>

                    <p>
                        Más información en el
                        <a href="https://clave.gob.es/clave_Home/clave/queEs.html" class="c-link"
                            >sitio web de <span aria-label="Clave">Cl&#64;ve</span></a
                        >
                    </p>
                </div>
            </desy-details>
            <desy-details summaryText="Para acceder con Cl@ve necesito estar registrado. ¿Cómo registrarme?">
                <div class="prose ml-6">
                    <p>Hay 3 vías para registrarse en <span aria-label="Clave">Cl&#64;ve</span>:</p>
                    <ul>
                        <li>A través de Internet sin certificado electrónico.</li>
                        <li>A través de Internet con certificado electrónico o DNIe.</li>
                        <li>Presencialmente en una Oficina de Registro.</li>
                    </ul>
                    <p>
                        Lee la explicación completa paso a paso en el
                        <a href="https://clave.gob.es/clave_Home/registro/Como-puedo-registrarme.html" class="c-link"
                            >sitio web de <span aria-label="Clave">Cl&#64;ve</span></a
                        >.
                    </p>
                </div>
            </desy-details>
            <desy-details summaryText="¿Cómo accedo a la aplicación con Cl@ve?">
                <div class="prose ml-6">
                    <p>
                        En la primera pantalla podrás elegir tu método de identificación: DNIe o certificado
                        electrónico, <span aria-label="Clave">Cl&#64;ve</span> PIN o
                        <span aria-label="Clave">Cl&#64;ve</span> Permanente. Recuerda que para acceder con
                        <span aria-label="Clave">Cl&#64;ve</span> necesitas estar
                        <a href="https://clave.gob.es/clave_Home/registro/Como-puedo-registrarme.html" class="c-link"
                            >registrado</a
                        >, y que <span aria-label="Clave">Cl&#64;ve</span> PIN sólo permite identificarse, no firmar
                        electrónicamente.
                    </p>
                    <p>Una vez seleccionado tu método de identificación, deberás:</p>
                    <ul>
                        <li>
                            Si usas DNIe o certificado, pulsa en Acceder, selecciona tu certificado y accede a la
                            aplicación.
                        </li>
                        <li>
                            Si usas <span aria-label="Clave">Cl&#64;ve</span> PIN, pulsa en Acceder, introduce el DNI o NIE
                            y selecciona la opción para generar el PIN.
                        </li>
                        <li>
                            Si usas <span aria-label="Clave">Cl&#64;ve</span> Permanente, deberás introducir tu usuario y
                            contraseña para acceder a la aplicación.
                        </li>
                    </ul>
                    <p>
                        Si la identificación ha sido correcta, entrarás en la aplicación. Si la identificación no es
                        válida, deberás salir y comenzar de nuevo.
                    </p>
                    <p>
                        El vídeo
                        <a href="https://youtu.be/Cz0y75Bg-sE?t=46" class="c-link"
                            >Ciudadanos: métodos de identificación y firma para trámites electrónicos</a
                        >
                        te muestra los métodos más utilizados para acceder con <span aria-label="Clave">Cl&#64;ve</span>:
                        DNI electrónico o certificado electrónico y con
                        <span aria-label="Clave">Cl&#64;ve</span> permanente.
                    </p>
                </div>
            </desy-details>
            <desy-details summaryText="Ambas opciones permiten certificado electrónico ¿Qué opción he de elegir?">
                <div class="prose ml-6">
                    <p>
                        La opción recomendada es <span aria-label="Clave">Cl&#64;ve</span>. El sistema unificado
                        <span aria-label="Clave">Cl&#64;ve</span> permite el acceso con certificado electrónico y es el
                        método de acceso estándar. En caso de que el sistema <span aria-label="Clave">Cl&#64;ve</span> no
                        esté disponible, ofrecemos un segundo método alternativo
                        <em>"Con certificado electrónico"</em> para acceder sin necesidad de usar el sistema
                        <span aria-label="Clave">Cl&#64;ve</span>.
                    </p>
                </div>
            </desy-details>
            <desy-details summaryText="¿Cómo saber que certificado tengo instalado?"
                ><div class="prose ml-6">
                    <p>
                        Encontrarás una explicación breve y clara en el vídeo
                        <a href="https://youtu.be/FAzV53Actlw" class="c-link"
                            >Ciudadanos: cómo saber qué certificado tengo instalado</a
                        >. En el
                        <a href="https://www.youtube.com/channel/UCUAhYzc8VKOfDVZZTFghOXw" class="c-link"
                            >canal de Youtube de SDA Servicios Digitales de Aragón</a
                        >
                        encontrarás más videos útiles de ayuda.
                    </p>
                </div>
            </desy-details>
            <desy-details summaryText="¿Cómo obtener Cl@ve permanente?">
                <div class="prose ml-6">
                    <p>
                        Encontrarás una explicación breve y clara en los vídeos:
                        <a href="https://youtu.be/PmNKu3hZ8S8" class="c-link"
                            >Ciudadanos: cómo obtener <span aria-label="Clave">Cl&#64;ve</span> permanente. Parte 1</a
                        >
                        y
                        <a href="https://youtu.be/gdPM7RsRSM4" class="c-link"
                            >Ciudadanos: cómo obtener <span aria-label="Clave">Cl&#64;ve</span> permanente. Parte 2</a
                        >. En el
                        <a href="https://www.youtube.com/channel/UCUAhYzc8VKOfDVZZTFghOXw" class="c-link"
                            >canal de Youtube de SDA Servicios Digitales de Aragón</a
                        >
                        encontrarás más videos útiles de ayuda.
                    </p>
                </div>
            </desy-details>
            <desy-details summaryText="Soy ciudadano/a y no puedo acceder. ¿Dónde encuentro ayuda?">
                <div class="prose ml-6">
                    <p>
                        Puedes ponerte en contacto con el <em>Soporte Técnico de Servicios Digitales de Aragón</em> de
                        varias formas:
                    </p>
                    <ul>
                        <li>
                            Llamar al número de teléfono de Soporte Técnico de Servicios Digitales de Aragón:
                            <a href="tel:+34976289414" class="c-link">976 289 414</a>. <br />
                            Horario de lunes a jueves de 7:30 a 18:30, y los viernes de 7:30 a 16:00.
                        </li>
                        <li>
                            Escribir un email a:
                            <a href="mailto:soportesae@aragon.es" class="c-link">soportesae&#64;aragon.es</a>
                        </li>
                        <li>
                            Rellenar el
                            <a href="https://aplicaciones.aragon.es/saco_core/formConsulta.action" class="c-link"
                                >Formulario de Solicitud de Soporte Técnico</a
                            >
                        </li>
                    </ul>
                    <p>
                        También puedes consultar los
                        <a href="https://www.youtube.com/channel/UCUAhYzc8VKOfDVZZTFghOXw/featured"
                            >vídeos de ayuda de SDA - Servicios Digitales de Aragón en Youtube</a
                        >
                    </p>
                </div>
            </desy-details>
            <desy-details summaryText="Trabajo para el Gobierno de Aragón y no puedo acceder. ¿Dónde encuentro ayuda?"
                ><div class="prose ml-6">
                    <p>
                        Puedes ponerte en contacto con el <em>Soporte Técnico de Servicios Digitales de Aragón</em> de
                        varias formas:
                    </p>
                    <ul>
                        <li>
                            Llamar al número interno de teléfono de Soporte Técnico de Servicios Digitales de Aragón:
                            <a href="tel:+34976289421" class="c-link">976 289 421</a>. <br />
                            Horario de lunes a jueves de 7:30 a 18:30, y los viernes de 7:30 a 16:00.
                        </li>
                        <li>
                            Escribir un email a:
                            <a href="mailto:soportesae@aragon.es" class="c-link">soportesae&#64;aragon.es</a>
                        </li>
                        <li>
                            Rellenar el
                            <a href="https://aplicaciones.aragon.es/saco_core/formConsulta.action?e=y" class="c-link"
                                >Formulario de Solicitud de Soporte Técnico si trabajas para el Gobierno de Aragón</a
                            >
                        </li>
                    </ul>
                    <p>
                        También puedes consultar los
                        <a href="https://www.youtube.com/channel/UCUAhYzc8VKOfDVZZTFghOXw/featured"
                            >vídeos de ayuda de SDA - Servicios Digitales de Aragón en Youtube</a
                        >
                    </p>
                </div>
            </desy-details>
            <desy-details
                summaryText="Tengo un problema relativo a trámites, plazos y otros temas no técnicos. ¿Dónde encuentro ayuda?"
            >
                <div class="prose ml-6">
                    <p>
                        Si tus dudas o problemas son relativos a temas no técnicos sino de la administración, puedes
                        contactar con nosotros:
                    </p>
                    <ul>
                        <li>
                            Llamar al número de teléfono de Información:
                            <a href="tel:+34976714111" class="c-link">976 71 41 11</a>. <br />Horario de lunes a viernes
                            de 9:00 a 14:00
                        </li>
                        <li>
                            Escribir un email a:
                            <a href="mailto:infoyregistro@aragon.es" class="c-link">infoyregistro&#64;aragon.es</a>
                        </li>
                    </ul>
                </div>
            </desy-details>
            <desy-details summaryText="¿Qué inconvenientes tiene el acceder sin certificado electrónico">
                <div class="prose ml-6">
                    <p>Si accedes sin certificado electrónico, tienes los siguientes inconvenientes:</p>
                    <ul>
                        <li>
                            No se guardará la información que hayas introducido en la aplicación para futuras sesiones.
                        </li>
                        <li>No podrás firmar ni presentar electrónicamente.</li>
                        <li>No podrás actuar en nombre de otras personas físicas o jurídicas.</li>
                    </ul>
                </div>
            </desy-details>
        </li>
    </ul>
</main>

import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { Rpt } from 'src/app/interfaces/rpt';
import { AdhesionService } from '../../services/adhesiones/adhesion.service';
import { RptService } from '../../services/rpt/rpt.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Protocolo } from 'src/app/interfaces/protocolo';
import { ProcedimientoAdministrativo } from 'src/app/interfaces/procedimiento-administrativo';
import { ProtocolosService } from '../../services/protocolos/protocolos.service';
import { OrderBy, AlertOptions, AlertService, NotificationOptions } from 'desy-angular';
import { ValidarAdhesion } from '../../interfaces/validar-adhesion';
import { LoginService } from '../../services/login/login.service';
import { checkIsDpd, checkIsDSFuncional, checkIsDSAdhesion } from 'src/app/utils/checkRol';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';
import { Workflow } from 'src/app/interfaces/workflow';
@Component({
    selector: 'app-ver-adhesion-recibida',
    templateUrl: './ver-adhesion-recibida.component.html',
    styleUrls: ['./ver-adhesion-recibida.component.css'],
})
export class VerAdhesionRecibidaComponent implements OnInit {
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    controlaErrores: boolean;
    activeConfirmarRechazar: boolean;
    error: string;
    role: string[];
    isDpd: boolean = checkIsDpd();
    isDSFuncional: boolean = checkIsDSFuncional();
    isDSAdhesion: boolean = checkIsDSAdhesion();
    bloqueadoPorMi: boolean = false;
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;
    iconBloqueo = {
        html: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 491.858 491.858" ><path d="M245.929,0C110.106,0,0,110.106,0,245.929s110.106,245.929,245.929,245.929c135.822,0,245.929-110.106,245.929-245.929S381.751,0,245.929,0z M43.721,245.929c0-111.677,90.531-202.208,202.208-202.208c46.144,0,88.668,15.467,122.696,41.485L85.204,368.625C59.187,334.597,43.721,292.072,43.721,245.929z M245.929,448.137c-45.828,0-88.087-15.255-122-40.95L407.186,123.93c25.694,33.911,40.949,76.171,40.949,121.999C448.136,357.605,357.605,448.137,245.929,448.137z" fill="#5E616B"/></svg>',
        type: '',
    };
    isEnabled: boolean;
    constructor(
        private protocoloService: ProtocolosService,
        private adhesionService: AdhesionService,
        private rptService: RptService,
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private alertService: AlertService
    ) {}

    async ngOnInit() {
        this.buscando = true;
        await this.getDatos();
        this.buscando = false;
    }

    activeAprobar = false;
    activeRechazar = false;
    protocolo: Protocolo;
    procedimiento: ProcedimientoAdministrativo;
    adhesion: Adhesion;
    numRPTs: number = 0;
    buscando: boolean = false;
    rpts: Rpt[] = [];
    orderBy: OrderBy;
    type: any;
    queryValidarAdhesion: ValidarAdhesion;
    workflows: Workflow[];
    workflowCreacion: any;
    workflowBloqueo: any;
    motivoRechazo: string;
    datosJefeSupervisor = {
        estado: '',
        nombre: '',
        jobCode: '',
        fecha: '',
        motivo: '',
    };
    datosDpd = {
        estado: '',
        nombre: '',
        jobCode: '',
        fecha: '',
        motivo: '',
    };
    taskDpd: any;
    taskDsf: any;
    activeBloquear: boolean = false;
    activeDesbloquear: boolean = false;
    activeFinalizar: boolean = false;
    usuarioRechazo: Rpt;
    tieneDatosSensibles: boolean;

    async getDatos() {
        this.buscando = true;
        const idAdhesion = this.route.snapshot.paramMap.get('idAdhesion');
        this.adhesion = await this.adhesionService.getAdhesionById(idAdhesion!);
        const usuarioBloqueo = this.adhesionService.usuarioBloqueo;
        this.isEnabled = usuarioBloqueo[0]?.userName === JSON.parse(localStorage.getItem('usuario')!).jobCode;
        this.rpts = [];
        this.rpts = this.adhesion.RPTs!;
        this.protocolo = await this.getProtocolo();
        this.tieneDatosSensibles = this.protocolo.SI === 'true';
        this.procedimiento = await this.getProcedimiento();
        if (this.adhesion.estado === EstadosAdhesionEnum.FINALIZADA) {
            this.buscando = false;
            return;
        }
        await this.getDatosWorkflows();
        this.buscando = false;
    }
    async getDatosWorkflows() {
        this.workflows = await this.adhesionService.getWorkflowsByName({
            name: this.adhesion.nombreCompleto!,
            last: true,
            workflowType: ['CREATE'],
        });
        this.workflowCreacion = await this.adhesionService.getWorkFlow(this.workflows[0].idWorkflowExecution);
        const taskDpdCreacion = this.workflowCreacion.workflow.taskList.find(
            (task: any) => task.name == 'DPD Validation'
        );
        const taskDsfCreacion = this.workflowCreacion.workflow.taskList.find(
            (task: any) => task.name == 'DS_F Validation' || task.name == 'Data Steward FC Validation'
        );
        const dsf = await this.rptService.getUserByJobCode(taskDsfCreacion?.subSteps[0].user);

        this.datosJefeSupervisor.estado = taskDsfCreacion?.state;
        this.datosJefeSupervisor.nombre = dsf?.nombre ?? '';
        this.datosJefeSupervisor.jobCode = environment.entorno + dsf?.puesto ? environment.entorno + dsf?.puesto : '';
        this.datosJefeSupervisor.fecha = taskDsfCreacion?.subSteps[0].endDate;
        this.datosJefeSupervisor.motivo = taskDsfCreacion?.subSteps[0].comment;
        if (this.tieneDatosSensibles) {
            const dpd = await this.rptService.getUserByJobCode(
                taskDpdCreacion?.subSteps[taskDpdCreacion.subSteps.length - 1].user
            );
            this.datosDpd.estado = taskDpdCreacion?.state;
            this.datosDpd.nombre = dpd?.nombre ?? '';
            this.datosDpd.jobCode = environment.entorno + dpd?.puesto ? environment.entorno + dpd?.puesto : '';
            this.datosDpd.fecha = taskDpdCreacion?.subSteps[0].endDate;
            this.datosDpd.motivo = taskDpdCreacion?.subSteps[0].comment;
        }

        if (this.adhesion.estado === EstadosAdhesionEnum.BLOQUEADA) {
            // const workflowsBloqueo = await this.adhesionService.getWorkflowsByName({
            //     name: this.adhesion.nombreCompleto!,
            //     last: true,
            //     workflowType: ['DEACTIVATE'],
            // });

            // const lastWorkflow = await this.adhesionService.getWorkFlow(workflowsBloqueo[0].idWorkflowExecution);
            // const taskBloqueo = lastWorkflow.workflow.taskList[0];
            const usuarioBloqueo = await this.rptService.getUserByJobCode(
                this.adhesionService.usuarioBloqueo?.userName
            );
            if (usuarioBloqueo) {
                usuarioBloqueo.puesto = environment.entorno + usuarioBloqueo?.puesto;
                usuarioBloqueo.organismo = environment.entorno + usuarioBloqueo?.organismo;
            }
            this.bloqueadoPorMi = usuarioBloqueo?.puesto === this.loginService.getUsuarioActual().jobCode;
            if (usuarioBloqueo?.puesto === this.datosJefeSupervisor.jobCode) {
                this.datosJefeSupervisor.estado = EstadosAdhesionEnum.BLOQUEADA;
                this.datosJefeSupervisor.nombre = usuarioBloqueo?.nombre || '';
                this.datosJefeSupervisor.jobCode = usuarioBloqueo?.puesto || '';
                this.datosJefeSupervisor.fecha = this.adhesionService.usuarioBloqueo.endtime;
                this.datosJefeSupervisor.motivo = this.adhesionService.usuarioBloqueo.actionDescription;
            } else {
                this.datosDpd.estado = EstadosAdhesionEnum.BLOQUEADA;
                this.datosDpd.nombre = usuarioBloqueo?.nombre || '';
                this.datosDpd.jobCode = usuarioBloqueo?.puesto || '';
                this.datosDpd.fecha = this.adhesionService.usuarioBloqueo.endtime;
                this.datosDpd.motivo = this.adhesionService.usuarioBloqueo.actionDescription;
            }
        }
    }

    async getProtocolo() {
        const resp: any = await this.protocoloService.getDatosProtocolo(this.adhesion?.idProtocolo);
        const protocolo: Protocolo = this.protocoloService.mapProtocolo(
            resp,
            this.adhesion?.idProtocolo!,
            this.adhesion.protocoloAri!,
            this.adhesion.estado!
        );
        return protocolo;
    }

    getProcedimiento() {
        let procedimiento: ProcedimientoAdministrativo;
        procedimiento = { nombre: this.adhesion.nombreServicio };
        return procedimiento;
    }

    limpiaNombreOrganismo(nombreCompleto: string) {
        return nombreCompleto?.substring(3);
    }

    goToListadoAdhesion() {
        this.router.navigate(['listado-adhesiones/recibidas']);
    }

    getOrganizacionDepartamento(nombreOrigin: string): string[] {
        let nombreArray = nombreOrigin.split('/');
        let nombreFinal: string[] = [];
        nombreFinal[0] = nombreArray[1];
        nombreFinal[1] = nombreArray[2];

        return nombreFinal;
    }

    openDialogAprobar(): void {
        this.activeAprobar = true;
    }

    openDialogRechazar(): void {
        this.activeRechazar = true;
    }
    openDialogConfirmarRechazar() {
        this.controlaErrores = false;
        if (this.motivoRechazo.length > 0) {
            this.activeConfirmarRechazar = true;
        } else {
            this.controlaErrores = true;
        }
    }

    closeDialog() {
        this.activeAprobar = false;
        this.activeRechazar = false;
        this.activeBloquear = false;
        this.activeDesbloquear = false;
        this.activeFinalizar = false;
        this.buscando = true;
        this.buscando = false;
    }

    async aprobarAdhesion() {
        this.queryValidarAdhesion = {
            executionList: [this.workflows[0].idWorkflowExecution],
            validate: true,
            comment: { 'en-US': ' ', 'es-ES': '' },
        };
        let resp: any = await this.adhesionService.resolverAdhesion(this.queryValidarAdhesion);
        if ((resp.code == '0000' || resp.code === '99997' || resp.code === '99996') && !resp.data?.error?.length) {
            this.closeDialog();
            await this.getDatos();
            this.openAprobado();
        } else {
            this.closeDialog();
            this.error = resp.data?.error.length ? resp.data.error.join('\n') : resp.description;
            this.openError();
        }
    }

    async rechazarAdhesion() {
        this.queryValidarAdhesion = {
            executionList: [this.workflows[0].idWorkflowExecution],
            validate: false,
            comment: { 'en-US': this.motivoRechazo, 'es-ES': '' },
        };
        let resp: any = await this.adhesionService.resolverAdhesion(this.queryValidarAdhesion);

        if ((resp.code == '0000' || resp.code === '99997' || resp.code === '99996') && !resp.data?.error?.length) {
            this.closeDialogConfirmar();
            this.closeDialog();
            await this.getDatos();
            this.openRechazado();
        } else {
            this.closeDialogConfirmar();
            this.closeDialog();
            this.error = resp.data?.error.length ? resp.data.error.join('\n') : resp.description;
            this.openError();
        }
    }

    openAprobado(): void {
        const alertOptions: AlertOptions = {
            id: 'alert',
            place: this.alertPlace,
            role: 'alert',
            ariaLive: 'assertLive',
            classes: 'mt-5',
        };

        if (this.protocolo.SI == 'true' && !this.isDpd) {
            var notificationOptions: NotificationOptions = {
                title: 'Adhesión aprobada parcialmente',
                type: 'success',
                icon: {
                    html: `
                    <svg id="interface-validation-check" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <rect id="Rectángulo_38" data-name="Rectángulo 38" width="24" height="24" fill="none"/>
                        <path id="Trazado_182" data-name="Trazado 182" d="M5.141,18.2a2.48,2.48,0,0,1-1.994-1.109L.33,13.546a1.529,1.529,0,0,1,.152-1.957,1.2,1.2,0,0,1,1.775.082L4.9,14.993A.3.3,0,0,0,5.368,15l10.4-11.992a1.2,1.2,0,0,1,1.816-.051,1.529,1.529,0,0,1,.046,2.009L7.1,17.234a2.4,2.4,0,0,1-1.959.963Z" transform="translate(2.954 1.429)" fill="#158930"/>
                    </svg>`,
                },
                classes: 'c-notification--success',
                isDismissible: true,
            };
        } else {
            var notificationOptions: NotificationOptions = {
                title: 'Adhesión aprobada',
                type: 'success',
                icon: {
                    html: `
                    <svg id="interface-validation-check" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <rect id="Rectángulo_38" data-name="Rectángulo 38" width="24" height="24" fill="none"/>
                        <path id="Trazado_182" data-name="Trazado 182" d="M5.141,18.2a2.48,2.48,0,0,1-1.994-1.109L.33,13.546a1.529,1.529,0,0,1,.152-1.957,1.2,1.2,0,0,1,1.775.082L4.9,14.993A.3.3,0,0,0,5.368,15l10.4-11.992a1.2,1.2,0,0,1,1.816-.051,1.529,1.529,0,0,1,.046,2.009L7.1,17.234a2.4,2.4,0,0,1-1.959.963Z" transform="translate(2.954 1.429)" fill="#158930"/>
                    </svg>`,
                },
                classes: 'c-notification--success',
                isDismissible: true,
            };
        }

        this.alertService.openAlert(notificationOptions, alertOptions);
    }

    openRechazado() {
        const alertOptions: AlertOptions = {
            id: 'alert',
            place: this.alertPlace,
            role: 'alert',
            ariaLive: 'assertLive',
            classes: 'mt-5',
        };

        var notificationOptions: NotificationOptions = {
            title: 'Adhesión rechazada correctamente',
            type: 'success',
            classes: 'c-notification--success',
            isDismissible: true,
        };

        this.alertService.openAlert(notificationOptions, alertOptions);
    }

    openError() {
        const alertOptions: AlertOptions = {
            id: 'alert',
            place: this.alertPlace,
            role: 'alert',
            ariaLive: 'assertLive',
            classes: 'mt-5',
        };

        var notificationOptions: NotificationOptions = {
            title: 'Ha ocurrido un error: ' + this.error,
            type: 'alert',
            classes: 'c-notification c-notification--alert',
            isDismissible: false,
        };
        if (this.error) {
            this.alertService.openAlert(notificationOptions, alertOptions);
        }
    }

    async closeDialogConfirmar() {
        this.activeConfirmarRechazar = false;
    }

    onBloquearAdhesion() {
        this.activeBloquear = true;
    }

    onDesbloquearAdhesion() {
        this.activeDesbloquear = true;
    }
    onFinalizarAdhesion() {
        this.activeFinalizar = true;
    }

    async bloquearAdhesion() {
        const resp: any = await this.adhesionService.bloquearAdhesion(this.adhesion.idAdhesion!);
        if ((resp.code == '0000' || resp.code === '99997' || resp.code === '99996') && !resp.data?.error?.length) {
            this.buscando = true;
            this.closeDialog();

            setTimeout(() => {
                this.getDatos();
            }, 1500);
        } else {
            this.closeDialog();
            this.error = resp.data?.error.length ? resp.data.error.join('\n') : resp.description;
            this.openError();
        }
    }
    async desbloquearAdhesion() {
        const resp: any = await this.adhesionService.desbloquearAdhesion(this.adhesion.idAdhesion!);
        if ((resp.code == '0000' || resp.code === '99997' || resp.code === '99996') && !resp.data?.error?.length) {
            this.buscando = true;
            this.closeDialog();
            setTimeout(() => {
                this.getDatos();
            }, 1000);
        } else {
            this.closeDialog();
            this.error = resp.data?.error.length ? resp.data.error.join('\n') : resp.description;
            this.openError();
        }
    }

    async finalizarAdhesion() {
        const resp: any = await this.adhesionService.finalizarAdhesion(
            this.adhesion.idAdhesion!,
            this.adhesion.estado!
        );
        if ((resp.code == '0000' || resp.code === '99997' || resp.code === '99996') && !resp.data?.error?.length) {
            this.buscando = true;
            this.closeDialog();
            setTimeout(() => {
                this.getDatos();
            }, 1000);
        } else {
            this.closeDialog();
            this.error = resp.data?.error.length ? resp.data.error.join('\n') : resp.description;
            this.openError();
        }
    }

    modificarRpts() {}
}

<body class="dialog">
    <button (click)="onCerrar()" class="close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00607A"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
    </button>
    <div class="pb-md"></div>
    <desy-tree
        [name]="'exampleNameContent'"
        [type]="'checkbox'"
        [expandedFirstLevel]="false"
        [decoupleChildFromParent]="false"
        [(searchMatchValues)]="searchMatchValues"
        [(ngModel)]="organizacionesSeleccionadas"
    >
        <desy-fieldset>
            <desy-legend>Organismo responsable</desy-legend>
        </desy-fieldset>
        <desy-hint [id]="'hint-gral'">Tu selección se usará para filtrar el listado</desy-hint>
        <desy-search-bar
            placeholder="Buscar..."
            labelText="Búsqueda Organismos"
            name="filterDatasets"
            [(ngModel)]="organizacionQuery.q"
            class="w-xl"
        >
        </desy-search-bar>
        <div class="pb-sm"></div>
        <div class="pb-md"></div>
        <div *ngIf="!buscando">
            <div *ngFor="let organizacion of organizaciones; let i = index">
                <desy-tree-item [value]="organizacion.nombre" [(checked)]="organizacion.check">
                    <desy-label [ngClass]="{'font-normal': !organizacionQuery.q}">{{ organizacion.nombre}}</desy-label>
                </desy-tree-item>
            </div>
        </div>
        <desy-spinner class="h-72" classes="pt-36" *ngIf="buscando"></desy-spinner>
    </desy-tree>
    <div class="pb-md"></div>

    <div class="flex flex-wrap gap-sm w-full justify-between">
        <div class="mt-sm">
            <desy-button
                text="Seleccionar"
                classes="c-button--primary ml-sm"
                (clickEvent)="onPutOrganizaciones()"
            ></desy-button>
        </div>
        <div class="mt-sm">
            <desy-button text="Cerrar" class="pr-4" (clickEvent)="onCerrar()"></desy-button>
        </div>
    </div>
    <div class="pb-sm"></div>
    <div class="pb-sm"></div>
</body>

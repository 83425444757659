<main id="adhesionContent" class="container mx-auto px-base">
    <div class="pb-sm"></div>
    <a (click)="goToNuevaAdhesion()" role="button" draggable="false" class="c-button c-button--transparent">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 140 140"
            class="self-center mr-2"
            aria-hidden="true"
            focusable="false"
            width="1em"
            height="1em"
        >
            <path
                d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                fill="currentColor"
            ></path>
        </svg>
        Volver
    </a>
    <div class="pb-sm"></div>
    <p class="c-paragraph-sm text-neutral-dark">Paso 2 de 5</p>
    <h1 class="c-h1">Nueva adhesión</h1>

    <h2 class="c-h2">2. Selecciona un servicio</h2>
    <p class="c-paragraph-base text-neutral-dark">
        Elige el servicio administrativo para el cual necesitas consultar el protocolo de consulta "{{
            nombreProtocolo
        }}".
    </p>
    <div class="flex" id="searchbar">
        <desy-search-bar
            id="identificadorAdhesiones"
            placeholder="Buscar por código, nombre, departamento u organismo..."
            labelText="Búsqueda Procedimientos"
            name="filterDatasets"
            [(ngModel)]="search"
            (keydown.enter)="onSearch()"
            style="flex-grow: 1"
        >
            <desy-button text="Buscar" classes="c-button--primary ml-5 mr-0" (clickEvent)="onSearch()"></desy-button>
        </desy-search-bar>
    </div>

    <div class="pb-md"></div>
    <desy-spinner *ngIf="buscando" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
    <div class="pb-md"></div>
    <div *ngIf="!buscando">
        <desy-label>
            <h3 class="block ng-start-inserted" style="font-size: 18px">
                <label for="">
                    <strong> {{ procedimientosAdministrativos.length }} servicios</strong></label
                >
            </h3>
        </desy-label>
        <div class="pb-sm"></div>

        <div *ngFor="let servicio of procedimientosPaginacion">
            <desy-item class="item">
                <desy-title [classes]="'font-bold'">{{ servicio?.codigo }} - {{ servicio?.nombre }}</desy-title>

                <desy-item-item
                    *ngIf="
                        servicio?.nombreOrganismoProductor != null && servicio?.nombreOrganismoProductor != undefined
                    "
                    >{{ servicio?.nombreOrganismoProductor }}</desy-item-item
                >
                <desy-item-item
                    *ngIf="
                        servicio?.nombreOrganismoProductor == null || servicio?.nombreOrganismoProductor == undefined
                    "
                    >Este servicio no cuenta con Organismo Productor</desy-item-item
                >

                <desy-item-content-right>
                    <desy-button classes="c-button mr-6 c-button--sm c-button--transparent ng-star-inserted">
                        <a
                            *ngIf="servicio.codigoPadre == ''"
                            target="_blank"
                            href="{{ verDetalles }}/{{ servicio?.codigo }}/data"
                        >
                            Ver detalles</a
                        >
                        <a
                            *ngIf="servicio.codigoPadre !== ''"
                            target="_blank"
                            href="{{ verDetalles }}/{{ servicio?.codigo }}/child/{{ servicio?.codigoPadre }}/data"
                        >
                            Ver detalles</a
                        >
                        <svg
                            class="iconoDetalles"
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="20px"
                            fill="#00607A"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                            />
                        </svg>
                    </desy-button>
                    <desy-button classes="c-button--sm" (click)="onSeleccionar(servicio)" class="pr-4"
                        >Seleccionar
                    </desy-button>
                </desy-item-content-right>
            </desy-item>
            <div class="pb-sm"></div>
        </div>
        <div class="pb-md"></div>
        <desy-pagination
            *ngIf="procedimientosAdministrativos.length"
            [itemsPerPage]="rows"
            [totalItems]="procedimientosAdministrativos.length"
            [hasSelect]="true"
            [hasNext]="true"
            [hasPrevious]="true"
            [idPrefix]="'selector'"
            previousText="Anterior"
            nextText="Siguiente"
            (click)="onButtonClick()"
            [(currentPage)]="selectedPageDefault"
        >
        </desy-pagination>
    </div>
</main>
<div class="pb-lg"></div>

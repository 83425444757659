import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(public auth: LoginService, public router: Router) {}
    canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
          console.log('No está identificado')
            this.router.navigate(['']);
            return false;
        }
        return true;
    }
}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ListadoAdhesionesService {
    tipoListado: any;
    filtroOrganizacionesSeleccionadas: any;
    filtroEstado: any;
    filtroCreacion: any;
    filtroModificacion: any;
    ordenacion: any;
    search: any;

    constructor(private router: Router) {
        // Elimina el estado de los filtros cuando sale del listado o detalle
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const url = event.url;
                if (
                    !url.includes('listado-adhesiones') &&
                    !url.includes('detalle-adhesion-recibida') &&
                    !url.includes('detalle-adhesion-enviada')
                ) {
                    this.clearFiltros();
                }
            }
        });
    }

    getTipoListado() {
        return this.tipoListado;
    }
    setTipoListado(value: any) {
        this.tipoListado = value;
    }
    getFiltroOrganizacionesSeleccionadas() {
        return this.filtroOrganizacionesSeleccionadas;
    }
    setFiltroOrganizacionesSeleccionadas(value: any) {
        this.filtroOrganizacionesSeleccionadas = value;
    }
    getFiltroEstado() {
        return this.filtroEstado;
    }
    setFiltroEstado(value: any) {
        this.filtroEstado = value;
    }
    getFiltroCreacion() {
        return this.filtroCreacion;
    }
    setFiltroCreacion(value: any) {
        this.filtroCreacion = value;
    }
    getFiltroModificacion() {
        return this.filtroModificacion;
    }
    setFiltroModificacion(value: any) {
        this.filtroModificacion = value;
    }
    getOrdenacion() {
        return this.ordenacion;
    }
    setOrdenacion(value: any) {
        this.ordenacion = value;
    }
    getSearch() {
        return this.search;
    }
    setSearch(value: any) {
        this.search = value;
    }

    clearFiltros() {
        this.filtroOrganizacionesSeleccionadas = null;
        this.filtroEstado = null;
        this.filtroCreacion = null;
        this.filtroModificacion = null;
        this.ordenacion = null;
        this.search = null;
    }
}

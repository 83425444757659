import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Usuario } from 'src/app/interfaces/usuario';
import { LoginService } from '../../services/login/login.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    buscando = false;
    isLogged = false;
    usuarioLogeado: Usuario;
    routerSubscription: Subscription;

    // Estados de navegación
    isActiveInicio = false;
    isActiveNuevaAdhesion = false;
    isActiveEnviada = false;
    isActiveRecibida = false;
    isActiveCedidos = false;
    isActiveAdheridos = false;

    constructor(private loginService: LoginService, private router: Router) {
        loginService.getLoggedInName.subscribe(() => {
            this.getDatosUsuario();
        });

        // Suscribirse a los cambios de navegación
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.updateActiveState(event.url);
            }
        });
    }

    async ngOnInit() {
        this.buscando = true;
        this.getDatosUsuario();
        this.updateActiveState(this.router.url); // Inicializar estado
        this.buscando = false;
    }

    getDatosUsuario() {
        this.usuarioLogeado = this.loginService.getUsuarioActual();
        if (this.usuarioLogeado && this.loginService.isAuthenticated()) {
            this.isLogged = true;
        }
    }

    limpiarAdhesion() {
        localStorage.removeItem('protocolo');
        localStorage.removeItem('procedimientoAdhesion');
        localStorage.removeItem('baseAdhesion');
        localStorage.removeItem('rptsAdhesion');
    }

    onLogOut() {
        this.loginService.logOut();
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        localStorage.removeItem('tokenAnjana');
        localStorage.removeItem('role');
    }

    /**
     * 🔄 Actualiza los estados `isActive*` según la URL actual.
     */
    updateActiveState(currentUrl: string) {
        this.isActiveInicio = currentUrl === '/' || currentUrl === '/home';
        this.isActiveNuevaAdhesion = currentUrl === '/nueva-adhesion/protocolos';
        this.isActiveEnviada = currentUrl === '/listado-adhesiones/enviadas';
        this.isActiveRecibida = currentUrl === '/listado-adhesiones/recibidas';
        this.isActiveCedidos = currentUrl === '/uso-datos/cedidos';
        this.isActiveAdheridos = currentUrl === '/uso-datos/adheridos';
    }

    ngOnDestroy() {
        // Evitar fugas de memoria al destruir el componente
        this.routerSubscription.unsubscribe();
    }
}

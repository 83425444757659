import { ComponentRef, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Query } from '../../interfaces/query';
import { DialogComponent } from 'desy-angular';
import { Organizacion } from '../../interfaces/organizacion';
import { environment } from 'src/environments/environment';
const url = environment.url;
@Injectable({
    providedIn: 'root',
})
export class OrganizacionService {
    nombresQueryOrganizaciones: string[] = [];
    activaBusqueda: boolean = false;
    dialogOrganizaciones: ComponentRef<DialogComponent>;
    organizaciones: Organizacion[] = [];
    organizacionesFiltro: any = [];
    orgDep: [];
    organizacionesDiccionario: any = {
        'Dpto ciudadania': 'Departamento de Ciudadanía y Derechos Sociales',
        'Dpto Deporte': 'Departamento de Educación, Cultura y Deporte',
        Iass: 'Instituto Aragonés de Servicios Sociales (IASS)',
        'Dpto hacienda administracion publica': 'Departamento de Hacienda y Administración Pública',
        Iaap: 'Instituto Aragonés de Administración Pública (IAAP)',
        'Intervencion general': 'Intervención general',
        'Dpto agricultura': 'Departamento de Agricultura',
    };
    nuevaOrganizacion: Organizacion;

    constructor(private http: HttpClient) {}

    async getOrganizaciones(query: Query): Promise<Organizacion[]> {
        return new Promise((resolve) => {
            this.http.post(`${url}/gateway/api/v1/indexer/kerno/search`, query).subscribe((resp: any) => {
                const organizaciones = this.mapOrganizacionesActuales(resp.data.facetFields[2].values);
                this.organizaciones = organizaciones;
                resolve(organizaciones);
            });
        });
    }

    postNombresQueryOrganizaciones(nombres: string[]) {
        this.nombresQueryOrganizaciones = nombres;
        this.activaBusqueda = true;
    }

    getNombresQueryOrganizaciones(): string[] {
        this.activaBusqueda = false;
        return this.nombresQueryOrganizaciones;
    }

    getActivaBusqueda(): boolean {
        return this.activaBusqueda;
    }

    postDialog(dialog: ComponentRef<DialogComponent>) {
        this.dialogOrganizaciones = dialog;
    }

    getDialog() {
        return this.dialogOrganizaciones;
    }

    getOrganizacionDepartamento(nombreOrigin: string): string[] {
        let nombreArray = nombreOrigin.split('/');
        let nombreFinal: string[] = [];
        if (nombreArray.length > 1) {
            nombreFinal[0] = nombreArray[1];
            nombreFinal[1] = nombreArray[2];
        } else {
            nombreFinal[0] = nombreArray[0];
        }

        return nombreFinal;
    }

    async setOrganizacionesActuales(organizaciones: Organizacion[]) {
        this.organizaciones = organizaciones;
    }

    mapOrganizacionesActuales(data: any): Organizacion[] {
        const orgDep = JSON.parse(localStorage.getItem('organismos') || '[]'); // Maneja el caso donde localStorage esté vacío
        this.organizacionesFiltro = [];
        const organizacionesExistentes: Map<string, Organizacion[]> = new Map();

        data.forEach((dato: any) => {
            const datoFiltrado = orgDep.filter((x: any) => x.codigo_organismo === dato.name);

            if (datoFiltrado.length > 0) {
                const nuevaOrganizacion: Organizacion = {
                    nombreGobierno: this.getNombreGobierno(datoFiltrado[0]?.nombre_organismo),
                    nombre: this.getNombreOrganizacion(datoFiltrado[0]?.nombre_departamento),
                    code: dato.name,
                    check: false,
                };

                // Añadir al mapa optimizando el acceso a la clave
                if (!organizacionesExistentes.has(nuevaOrganizacion.nombre)) {
                    organizacionesExistentes.set(nuevaOrganizacion.nombre, []);
                }
                organizacionesExistentes.get(nuevaOrganizacion.nombre)!.push(nuevaOrganizacion);
            }
        });

        // Convertir el mapa en el formato requerido
        organizacionesExistentes.forEach((organismos, nombre) => {
            this.organizacionesFiltro.push({
                nombre,
                code: organismos[0].code, // Tomamos el code del primer elemento
                check: false,
                organismos,
            });
        });

        return this.organizacionesFiltro;
    }

    async getOrganizacionesActuales() {
        return this.organizacionesFiltro;
    }

    getNombresOrdenados(array: any[]) {
        array.map((org) => {
            org.nombreOrdenado = org.nombre;
            org.nombreOrdenado = org.nombreOrdenado.toLocaleLowerCase();
            org.nombreOrdenado = org.nombreOrdenado![0].toUpperCase() + org.nombreOrdenado!.slice(1).toLowerCase();
            for (let key of Object.keys(this.organizacionesDiccionario)) {
                if (org.nombreOrdenado === key) {
                    org.nombreOrdenado = this.organizacionesDiccionario[key];
                    break;
                }
            }
            if (org.subOrganizacion) {
                this.getNombresOrdenados(org.subOrganizacion);
            }
        });
    }

    getNombreOrganizacion(nombreCompleto: string) {
        let nombre = nombreCompleto;
        return nombre;
    }

    getNombreGobierno(nombreCompleto: string) {
        let nombre = nombreCompleto;
        return nombre;
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'datafields',
})
export class DatafieldsPipe implements PipeTransform {
    transform(value: string, separator: string): string {
        let splits = value.split(separator);
        if (splits.length > 1) {
            let x: string = '';
            splits.forEach((split) => {
                x += split + ' ';
            });
            return x;
        } else {
            return splits[0];
        }
    }
}

import { Component, Injectable, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Token } from 'src/app/interfaces/token';
import { Usuario } from 'src/app/interfaces/usuario';
import { LoginService } from '../../services/login/login.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
    constructor(private loginService: LoginService) {
        loginService.getLoggedInName.subscribe((user) => {
            //this.usuarioLogeado = user;
            this.getDatosUsuario();
        });
        /* loginService.getIsLoggedIn.subscribe((logged) => {
            this.isLogged = logged;
        });*/
    }
    buscando = false;
    isLogged = false;
    usuarioLogeado: Usuario;
    async ngOnInit() {
        this.buscando = true;
        this.getDatosUsuario();
        this.buscando = false;
    }
    token: Token;

    getDatosUsuario() {
        this.usuarioLogeado = this.loginService.getUsuarioActual();
        if (this.usuarioLogeado != null && this.usuarioLogeado != undefined && this.loginService.isAuthenticated()) {
            this.isLogged = true;
        }
    }

    limpiarAdhesion() {
        localStorage.removeItem('protocolo');
        localStorage.removeItem('procedimientoAdhesion');
        localStorage.removeItem('baseAdhesion');
        localStorage.removeItem('rptsAdhesion');
    }
    onLogOut() {
        this.loginService.logOut();
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        localStorage.removeItem('tokenAnjana');
        localStorage.removeItem('role');
    }
}

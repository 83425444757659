<main id="adhesionContent" class="container mx-auto px-base">
    <ng-template #alertPlace></ng-template>
    <div class="pb-sm"></div>
    <a routerLink="/home" role="button" draggable="false" class="c-button c-button--transparent">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 140 140"
            class="self-center mr-2"
            aria-hidden="true"
            focusable="false"
            width="1em"
            height="1em"
        >
            <path
                d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                fill="currentColor"
            ></path>
        </svg>
        Volver
    </a>
     <div class="pb-sm"></div>
    <p class="c-paragraph-sm text-neutral-dark">Paso 1 de 5</p>
    <h1 class="c-h1">Nueva adhesión</h1>

    <h2 class="c-h2">1.¿Qué datos necesitas?</h2>
    <p class="c-paragraph-base text-neutral-dark">
        Selecciona el protocolo de consulta que agrupe los datos a los que quieras adherirte
    </p>
    <div class="flex" id="searchbar">
        <desy-search-bar
            id="identificadorAdhesiones"
            placeholder="Buscar por nombre, departamento, organismo, descripción (Ej. Protocolo de discapacidad limitado)..."
            labelText="Búsqueda Nueva Adhesión"
            name="filterDatasets"
            [(ngModel)]="protocoloQuery.q"
            (keyDown.enter)="filterProtocolos(protocoloQuery.q)"
            style="flex-grow: 1"
        >
            <desy-button
                text="Buscar"
                classes="c-button--primary ml-5 mr-0"
                (clickEvent)="filterProtocolos(protocoloQuery.q)"
            ></desy-button>
        </desy-search-bar>
    </div>

    <div class="pb-md"></div>
    <desy-spinner *ngIf="buscando" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
    <div *ngIf="!buscando">
        <h3 class="block ng-start-inserted" style="font-size: 18px;">
            <strong> {{ numResultados }} protocolos de consulta</strong>
        </h3>
        <div class="pb-sm"></div>
        <div class="flex">
            <desy-button
                text="Organismo responsable"
                [ngClass]="{ active: organizacionesBusqueda.length }"
                classes="c-button--sm"
                (clickEvent)="openDialog()"
                id="botonOrganismos"
            ></desy-button>
            <desy-button classes="c-button--sm c-button--transparent" (click)="onEliminaFiltros()"
                >Eliminar Filtros</desy-button
            >
        </div>

        <div class="pb-sm"></div>

        <div *ngFor="let protocolo of protocolosPaginados">
            <desy-item class="item">
                <desy-title [classes]="'font-bold'">{{ protocolo.nombre }}</desy-title>

                <desy-item-item id="organismo">{{ protocolo.nombreDepartamento | filtroOrganismo }}</desy-item-item>
                <desy-item-item *ngIf="protocolo.nombreDepartamento != 'IASS'">
                {{
                    protocolo.organismoExtendido | filtroOrganismo
                }}
                
            </desy-item-item>
                <desy-item-item *ngIf="protocolo.nombreDepartamento == 'IASS'">{{
                    protocolo.organismoExtendido | filtroOrganismo
                }}</desy-item-item>
                <desy-item-content-bottom>
                    <desy-details summaryText="Ver descripción">{{ protocolo.descripcion | filtroOrganismo }}</desy-details>
                </desy-item-content-bottom>
                <desy-item-content-right>
                    <desy-button
                        classes="c-button mr-6 c-button--sm c-button--transparent ng-star-inserted"
                        (clickEvent)="goToDetalle(protocolo)"
                    >
                        Ver detalles
                    </desy-button>
                    <desy-button classes="c-button--sm" class="pr-4" (clickEvent)="goToServicio(protocolo)"
                        >Seleccionar
                    </desy-button>
                </desy-item-content-right>
                <button class="c-button c-button--sm c-button--transparent ng-star-inserted" routerLink="/detalle">
                    Descripcion
                </button>
            </desy-item>
            <div class="pb-sm"></div>
        </div>
        <div class="pb-md"></div>
        <desy-pagination
            #paginator
            [itemsPerPage]="rows"
            [totalItems]="numResultados"
            [hasSelect]="true"
            [hasNext]="true"
            [hasPrevious]="true"
            [(currentPage)]="selectedPageDefault"
            [idPrefix]="'selector'"
            previousText="Anterior"
            nextText="Siguiente"
            (click)="onButtonClick($event)"
        >
        </desy-pagination>
    </div>
</main>

<div class="pb-lg"></div>

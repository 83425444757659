import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-detalle-protocolo-adhesion',
    templateUrl: './detalle-protocolo-adhesion.component.html',
    styleUrls: ['./detalle-protocolo-adhesion.component.css'],
})
export class DetalleProtocoloAdhesionComponent implements OnInit {
    idAdhesion: Number;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.idAdhesion = Number(this.route.snapshot.queryParamMap.get('idAdhesion'));
    }

    goToVerAdhesion(){
        this.router.navigate([`/detalle-adhesion-enviada/${this.idAdhesion}`]);
    }
}


<desy-item classes="mb-5 card-recibidas">
    <desy-title class="c-paragraph-base w-full">
        <div class="flex mt-1">
            <b class="c-h3 mr-4"> {{ adhesion.nombreProtocolo }} </b>
            <p class="font-semibold">{{ adhesion.nombreAdhesion }} </p>
        </div>
    </desy-title>
    >
    <desy-item-item classes="flex c-paragraph-base">
        <div class="flex mb-5">
            <div class="cuadrado pendiente mr-2" ngClass="{{ adhesion.estado }}"></div>
            <p class="mr-10" *ngIf="adhesion.estado != undefined">{{ adhesion.estado | estado }}</p>
            <p>
                Creada el {{ adhesion.fechaCreacion | date: 'd' }} de {{adhesion.fechaCreacion | date: 'MMMM'}} de {{adhesion.fechaCreacion | date: 'YYYY'}}.
                Solicitada por {{ adhesion.RPTSolicitante?.nombre }}
            </p>
        </div>
    </desy-item-item>
    >
    <desy-item-content-right class="flex ml-0">
        <div class="flex pb-8">
            <ng-container [ngSwitch]="true">
                <desy-menubar [id]="'mas'"
                  *ngSwitchCase="adhesion.estado === EstadosAdhesionEnum.PENDIENTE"
                  [idPrefix]="'mas'"
                  classes=""
                  [items]="masItemsPending"
                  (activeSubItemChange)="openDialog($event)">
                </desy-menubar>
                <desy-menubar [id]="'mas'"
                  *ngSwitchCase="isDSAdhesion && adhesion.estado === EstadosAdhesionEnum.APROBADA "
                  [idPrefix]="'mas'"
                  classes=""
                  [items]="masItemsApproved"
                  (activeSubItemChange)="openDialog($event)">
                </desy-menubar>
                <desy-menubar [id]="'mas'"
                  *ngSwitchCase="isDSAdhesion && adhesion.estado === EstadosAdhesionEnum.BLOQUEADA && bloqueadaPorMi"
                  [idPrefix]="'mas'"
                  classes=""
                  [items]="masItemsBlocked"
                  (activeSubItemChange)="openDialog($event)">
                </desy-menubar>
                <desy-menubar [id]="'mas'"
                    *ngSwitchCase="adhesion.estado === EstadosAdhesionEnum.BLOQUEADA &&  !bloqueadaPorMi"
                    [idPrefix]="'mas'"
                    classes=""
                    [items]="masItemsFinalizar"
                    (activeSubItemChange)="openDialog($event)">
                </desy-menubar>
            </ng-container>

            <desy-button (clickEvent)="onVerAdhesion(adhesion)">Ver adhesión</desy-button>
        </div>
    </desy-item-content-right>
    <desy-item-content-bottom classes="w-full ">
        <div class="flex place-content-between w-full mt-5">
            <desy-label classes="text-base text-neutral-base"  style="width: 40%"
                >Solicitante <br />
                <label for="" class="text-black text-sm w-full" *ngIf="adhesion.RPTSolicitante">
                    {{ adhesion.RPTSolicitante.cargo }}
                </label>
            </desy-label>
            <desy-label classes="text-base text-neutral-base" style="width: 30%"
                >Servicio
                <br />
                <label for="" class="text-black text-sm w-full" *ngIf="adhesion.nombreServicio">
                    {{ adhesion.nombreServicio | slice: 40 }}
                </label>
            </desy-label>
            <desy-label classes="text-base text-neutral-base"  style="width: 15%"
                >RPTs
                <br />
                <label for="" class="text-black text-sm w-full"
                    ><desy-tooltip [icon]="{ type: 'info' }">
                        <desy-content *ngIf="adhesion.RPTs != undefined"
                            >{{ adhesion.RPTs.length }} RPTs</desy-content
                        >

                        <desy-tooltip-content>
                            <div *ngFor="let rpt of adhesion.RPTs">{{ rpt.cargo }} - {{ rpt.nombre }}</div>
                        </desy-tooltip-content>
                    </desy-tooltip>
                </label>
            </desy-label>
            <desy-label classes="text-base text-neutral-base" style="width: 15%"
                >Tiempo de vigencia
                <br />
                <label
                    for=""
                    class="text-black text-sm w-full"
                    *ngIf="adhesion.fechaVigencia != '2999-12-31' && adhesion.fechaVigencia != '2999-12-30'"
                >
                    {{ adhesion.fechaVigencia | date: 'medium' }}</label
                >
                <label
                    for=""
                    class="text-black"
                    *ngIf="
                        adhesion.fechaVigencia === '2999-12-31' ||
                        adhesion.fechaVigencia === '2999-12-30' ||
                        adhesion.fechaVigencia == null
                    "
                >
                    Indefinido
                </label>
            </desy-label>
        </div>
    </desy-item-content-bottom>
</desy-item>
<desy-dialog
    *ngIf="activeAprobar"
    [(active)]="activeAprobar"
    [id]="'dialog'"
    [caller]="aprobar"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="true"
>
    <ng-template #aprobar>
        <desy-modal
            [isDismissible]="true"
            (closeModal)="closeDialog()"
            classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
        >
            <desy-icon
                ><svg
                    id="Grupo_1141"
                    data-name="Grupo 1141"
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                >
                    <path
                        id="Trazado_992"
                        data-name="Trazado 992"
                        d="M40.8,7.2h-3V3a3,3,0,0,0-6,0V7.2H16.2V3a3,3,0,0,0-6,0V7.2h-3A7.2,7.2,0,0,0,0,14.4V40.8A7.2,7.2,0,0,0,7.2,48H40.8A7.2,7.2,0,0,0,48,40.8V14.4A7.2,7.2,0,0,0,40.8,7.2ZM34.522,24.922l-12,12a3,3,0,0,1-4.243,0l-4.8-4.8a3,3,0,0,1,4.243-4.243L20.4,30.557l9.878-9.878a3,3,0,1,1,4.243,4.243Z"
                        fill="#d6eaf0"
                    />
                </svg>
            </desy-icon>
            <desy-title>¿Quieres aprobar la {{ adhesion.nombreAdhesion }}? </desy-title>
            <desy-modal-button-secondary
                [classes]="'c-button--primary c-button--sm'"
                (clickEvent)="aprobarAdhesion()"
                >Sí, aprobar adhesión</desy-modal-button-secondary
            >
            <desy-modal-button-primary
                [classes]="'c-button--secondary c-button--sm'"
                (clickEvent)="closeDialog()"
                >Cancelar</desy-modal-button-primary
            >
        </desy-modal>
    </ng-template>
</desy-dialog>
<desy-dialog
    *ngIf="activeConfirmarRechazar"
    [(active)]="activeConfirmarRechazar"
    [id]="'dialog'"
    [caller]="confirmarRechazar"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="true"
    class="z-50 absolute"
>
    <ng-template #confirmarRechazar>
        <desy-modal
            [isDismissible]="true"
            (closeModal)="closeDialogConfirmar()"
            classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white mt-2xl"
        >
            <desy-icon
                ><svg
                    id="Grupo_1141"
                    data-name="Grupo 1141"
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                >
                    <path
                        id="Trazado_992"
                        data-name="Trazado 992"
                        d="M40.8,7.2h-3V3a3,3,0,0,0-6,0V7.2H16.2V3a3,3,0,0,0-6,0V7.2h-3A7.2,7.2,0,0,0,0,14.4V40.8A7.2,7.2,0,0,0,7.2,48H40.8A7.2,7.2,0,0,0,48,40.8V14.4A7.2,7.2,0,0,0,40.8,7.2ZM34.522,24.922l-12,12a3,3,0,0,1-4.243,0l-4.8-4.8a3,3,0,0,1,4.243-4.243L20.4,30.557l9.878-9.878a3,3,0,1,1,4.243,4.243Z"
                        fill="#d6eaf0"
                    />
                </svg>
            </desy-icon>
            <desy-title>¿Quieres rechazar la {{ adhesion.nombreAdhesion }}? </desy-title>
            <desy-modal-button-primary
                [classes]="'c-button--secondary c-button--sm'"
                (clickEvent)="closeDialogConfirmar()"
                >Cancelar</desy-modal-button-primary
            >
            <desy-modal-button-secondary
                [classes]="'c-button--primary c-button--sm'"
                (clickEvent)="rechazarAdhesion()"
                >Sí, rechazar adhesión</desy-modal-button-secondary
            >
        </desy-modal>
    </ng-template>
</desy-dialog>
<desy-dialog
    *ngIf="activeRechazar"
    [(active)]="activeRechazar"
    [id]="'dialog-'"
    [caller]="rechazar"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="false"
    classes="m-0 w-full h-full"
    class="z-40 absolute"
>
    <ng-template #rechazar class="w-full h-full m-0">
        <div class="relative w-full mx-auto h-full m-0 p-0 border border-neutral-base bg-white">
            <desy-item classes="w-full m-0 px-80">
                <desy-icon>
                    <svg
                        id="Grupo_1148"
                        data-name="Grupo 1148"
                        xmlns="http://www.w3.org/2000/svg"
                        width="35.5"
                        height="35.5"
                        viewBox="0 0 35.5 35.5"
                    >
                        <path
                            id="Trazado_1020"
                            data-name="Trazado 1020"
                            d="M30.175,5.325H27.956V2.219a2.219,2.219,0,0,0-4.437,0V5.325H11.981V2.219a2.219,2.219,0,1,0-4.437,0V5.325H5.325A5.325,5.325,0,0,0,0,10.65V30.175A5.325,5.325,0,0,0,5.325,35.5h24.85A5.325,5.325,0,0,0,35.5,30.175V10.65A5.325,5.325,0,0,0,30.175,5.325ZM24.644,25.056a2.219,2.219,0,0,1-3.138,3.138L17.75,24.438l-3.756,3.756a2.219,2.219,0,0,1-3.138-3.138L14.612,21.3l-3.756-3.756a2.219,2.219,0,1,1,3.138-3.138l3.756,3.756,3.756-3.756a2.219,2.219,0,1,1,3.138,3.138L20.888,21.3Z"
                            fill="#5e616b"
                        />
                    </svg>
                </desy-icon>
                <desy-title classes="c-h3">{{ 'Rechazar adhesión' | uppercase }}</desy-title>
                <desy-item-item class="flex">
                    <div class="flex">
                        <p class="c-paragraph-sm font-bold text-neutral-base mr-4">
                            {{ adhesion.nombreServicio }}
                        </p>
                        <p class="c-paragraph-sm font-bold text-neutral-base">{{ adhesion.nombreAdhesion }}</p>
                    </div>
                </desy-item-item>
                <desy-item-content-right id="right-rechazar">
                    <div class="flex my-auto mt-2">
                        <desy-button classes="c-button--sm c-button--secondary" (clickEvent)="closeDialog()"
                            >Cancelar</desy-button
                        >

                        <desy-button
                            classes="ml-4 c-button--sm c-button--primary"
                            (clickEvent)="openDialogConfirmarRechazar()"
                            >Rechazar</desy-button
                        >
                    </div>
                </desy-item-content-right>
            </desy-item>
            <div class="container mx-auto px-base my-12">
                <h2 class="c-h2">Indica el motivo del rechazo</h2>
                <p class="c-paragraph-base black font-semibold">Motivo del rechazo</p>
                <desy-textarea
                    required="true"
                    classes="w-3/5 h-80"
                    [(ngModel)]="motivoRechazo"
                    placeholder="Indica el motivo del rechazo de la adhesión (ej. Lorem ipsum)"
                >
                    <desy-error-message
                        [text]="'Debes introducir un motivo para rechazar la adhesión'"
                        *ngIf="controlaErrores"
                    ></desy-error-message>
                </desy-textarea>
            </div>
        </div>
    </ng-template>
</desy-dialog>
<desy-dialog
    *ngIf="activeBloquear"
    [(active)]="activeBloquear"
    [id]="'dialog'"
    [caller]="bloquear"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="true"
>
    <ng-template #bloquear>
        <desy-modal
            [isDismissible]="true"
            (closeModal)="closeDialog()"
            classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
        >
            <desy-icon
                ><svg
                    id="Grupo_1141"
                    data-name="Grupo 1141"
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                >
                    <path
                        id="Trazado_992"
                        data-name="Trazado 992"
                        d="M40.8,7.2h-3V3a3,3,0,0,0-6,0V7.2H16.2V3a3,3,0,0,0-6,0V7.2h-3A7.2,7.2,0,0,0,0,14.4V40.8A7.2,7.2,0,0,0,7.2,48H40.8A7.2,7.2,0,0,0,48,40.8V14.4A7.2,7.2,0,0,0,40.8,7.2ZM34.522,24.922l-12,12a3,3,0,0,1-4.243,0l-4.8-4.8a3,3,0,0,1,4.243-4.243L20.4,30.557l9.878-9.878a3,3,0,1,1,4.243,4.243Z"
                        fill="#d6eaf0"
                    />
                </svg>
            </desy-icon>
            <desy-title>
                ¿Quieres bloquear el uso de la adhesión? 
                <div class="c-paragraph-sm font-normal mt-base mb-10">
                    Los RPTs vinculados ya no podrán acceder a la información proporcionada en esta adhesión.
                </div>
            </desy-title>
            <desy-modal-button-secondary
                [classes]="'c-button--primary c-button--sm'"
                (clickEvent)="bloquearAdhesion()"
                >Sí, bloquear adhesión</desy-modal-button-secondary
            >
            <desy-modal-button-primary
                [classes]="'c-button--secondary c-button--sm'"
                (clickEvent)="closeDialog()"
                >Cancelar</desy-modal-button-primary
            >
        </desy-modal>
    </ng-template>
</desy-dialog>
<desy-dialog
    *ngIf="activeDesbloquear"
    [(active)]="activeDesbloquear"
    [id]="'dialog'"
    [caller]="desbloquear"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="true"
>
    <ng-template #desbloquear>
        <desy-modal
            [isDismissible]="true"
            (closeModal)="closeDialog()"
            classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
        >
            <desy-icon
                ><svg
                    id="Grupo_1141"
                    data-name="Grupo 1141"
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                >
                    <path
                        id="Trazado_992"
                        data-name="Trazado 992"
                        d="M40.8,7.2h-3V3a3,3,0,0,0-6,0V7.2H16.2V3a3,3,0,0,0-6,0V7.2h-3A7.2,7.2,0,0,0,0,14.4V40.8A7.2,7.2,0,0,0,7.2,48H40.8A7.2,7.2,0,0,0,48,40.8V14.4A7.2,7.2,0,0,0,40.8,7.2ZM34.522,24.922l-12,12a3,3,0,0,1-4.243,0l-4.8-4.8a3,3,0,0,1,4.243-4.243L20.4,30.557l9.878-9.878a3,3,0,1,1,4.243,4.243Z"
                        fill="#d6eaf0"
                    />
                </svg>
            </desy-icon>
            <desy-title>
                ¿Quieres activar el uso de la adhesión? 
                <div class="c-paragraph-sm font-normal mt-base mb-10">
                    Los RPTs vinculados podrán acceder a la información proporcionada en esta adhesión.
                </div>
            </desy-title>
            <desy-modal-button-secondary
                [classes]="'c-button--primary c-button--sm'"
                (clickEvent)="desbloquearAdhesion()"
                >Sí, activar la adhesión</desy-modal-button-secondary
            >
            <desy-modal-button-primary
                [classes]="'c-button--secondary c-button--sm'"
                (clickEvent)="closeDialog()"
                >Cancelar</desy-modal-button-primary
            >
        </desy-modal>
    </ng-template>
</desy-dialog>
<desy-dialog
    *ngIf="activeFinalizar"
    [(active)]="activeFinalizar"
    [id]="'dialog'"
    [caller]="finalizar"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="true"
>
    <ng-template #finalizar>
        <desy-modal
            [isDismissible]="true"
            (closeModal)="closeDialog()"
            classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
        >
            <desy-title>
                Finalizar uso de la adhesion
                <div class="text-base font-normal mt-base">
                    Al finalizar la adhesión, el organismo consumidor no podrá realizar consultas sobre los datos proporcionados en esta adhesión. 
                    Este proceso no es reversible. ¿Estás seguro?
                </div>
            </desy-title>
            <desy-modal-button-primary
                [classes]="'c-button--secondary c-button--sm button-cancelar'"
                (clickEvent)="closeDialog()"
                >Cancelar</desy-modal-button-primary
            >
            <desy-modal-button-secondary
                [classes]="'c-button--primary c-button--sm button-finalizar'"
                (clickEvent)="finalizarAdhesion()"
                >Sí, finalizar</desy-modal-button-secondary
            >
        </desy-modal>
    </ng-template>
</desy-dialog>

export const environment = {
    appId: 'HAL',
    appKey: '08/dT.e_234(~uR:H1!',
    dynamoUrl: 'https://pre.hal.aragon.es',
    entorno: 'PRE_',
    home_url: 'https://pre-hal.aragon.es',
    procedimiento: '99999',
    production: true,
    url_serv: 'preaplicaciones.aragon.es/mfe_core',
    url: 'https://pro.hal.aragon.es',
    urlGetToken: 'https://pre.hal.aragon.es',
    urlHistory: 'https://pre.hal.aragon.es',
    zone: 'pre',
    urlDetalles: 'https://preaplicaciones.aragon.es/seda/service/detail',
};

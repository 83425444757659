import { Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { Router } from '@angular/router';
import { AdhesionService } from 'src/app/services/adhesiones/adhesion.service';
import { ProcedimientoAdministrativo } from 'src/app/interfaces/procedimiento-administrativo';
import { Protocolo } from 'src/app/interfaces/protocolo';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';
import { checkIsDSAdhesion } from 'src/app/utils/checkRol';
import { Workflow } from 'src/app/interfaces/workflow';

@Component({
    selector: 'app-recibidas',
    templateUrl: './recibidas.component.html',
    styleUrls: ['./recibidas.component.css'],
})
export class RecibidasComponent{
    @Input() adhesion: Adhesion;
    @Input() bloqueadaPorMi: boolean;
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    @Output() update = new EventEmitter();
    @Output() error = new EventEmitter<string>();
    options = {
        aprobar: 'Aprobar',
        rechazar: 'Rechazar',
        bloquear: 'Bloquear uso de adhesión',
        desbloquear: 'Activar uso adhesión',
        finalizar: 'Finalizar',
    }
    masItemsPending: any = [
        {
            text: 'Más',
            classes: 'mr-2 mas-button',
            sub: {
                items: [
                    { role: 'menuitem', text: this.options.aprobar },
                    { role: 'menuitem', text: this.options.rechazar },
                ],
            },
        },
    ];
    masItemsApproved: any = [
        {
            text: 'Más',
            classes: 'mr-2 mas-button',
            sub: {
                items: [
                    { role: 'menuitem', text: this.options.bloquear },
                    { role: 'menuitem', text: this.options.finalizar },
                ],
            },
        },
    ];
    masItemsBlocked: any = [
        {
            text: 'Más',
            classes: 'mr-2 mas-button',
            sub: {
                items: [
                    { role: 'menuitem', text: this.options.desbloquear },
                    { role: 'menuitem', text: this.options.finalizar },
                ],
            },
        },
    ];
    masItemsFinalizar: any = [
        {
            text: 'Más',
            classes: 'mr-2 mas-button',
            sub: {
                items: [
                    { role: 'menuitem', text: this.options.finalizar },
                ],
            },
        },
    ];
    activeAprobar = false;
    activeRechazar = false;
    activeBloquear = false;
    activeDesbloquear = false;
    activeFinalizar = false;
    controlaErrores: boolean;
    activeConfirmarRechazar: boolean;
    motivoRechazo: string;
    procedimiento: ProcedimientoAdministrativo;
    protocolo: Protocolo;
    isDSAdhesion: boolean = checkIsDSAdhesion();
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;

    constructor(
        private adhesionService: AdhesionService,
        private router: Router,
    ) {}
    
    onVerAdhesion(adhesion: Adhesion) {
        this.router.navigate(['/detalle-adhesion-recibida', adhesion.idAdhesion]);
    }
    openDialog($event: any){
        switch ($event.text) {
            case this.options.aprobar:
                this.activeAprobar = true;
                break;
            case this.options.rechazar:
                this.activeRechazar = true;
                break;
            case this.options.bloquear:
                this.activeBloquear = true;
                break;
            case this.options.desbloquear:
                this.activeDesbloquear = true;
                break;
            case this.options.finalizar:
                this.activeFinalizar = true;
                break;
        }
    }
   
    openDialogConfirmarRechazar() {
        this.controlaErrores = false;
        if (this.motivoRechazo.length > 0) {
            this.activeConfirmarRechazar = true;
        } else {
            this.controlaErrores = true;
        }
    }
    async closeDialog() {
        this.activeAprobar = false;
        this.activeRechazar = false;
        this.activeBloquear = false;
        this.activeDesbloquear = false;
        this.activeFinalizar = false;
    }
    async aprobarAdhesion() {
        const workflows: Workflow[] = await this.adhesionService.getWorkflowsByName({
            name: this.adhesion.nombreCompleto!,
            last: true,
            workflowType: ['CREATE'],
        });

        const queryValidarAdhesion = {
            executionList: [workflows[0].idWorkflowExecution],
            validate: true,
            comment: { 'en-US': '', 'es-ES': '' },
        };
        let resp: any = await this.adhesionService.resolverAdhesion(queryValidarAdhesion);
        if (resp.code == '0000' && !resp.data?.error?.length) {
            this.closeDialog();
            this.update.emit();
        } else {
            this.closeDialog();
            this.error.emit(resp.data?.error?.length ? resp.data.error.join('\n') : resp.description);
        }
    }

    async rechazarAdhesion() {
        const workflows: Workflow[] = await this.adhesionService.getWorkflowsByName({
            name: this.adhesion.nombreCompleto!, 
            last: true,
            workflowType: ['CREATE'],
        });

        const queryValidarAdhesion = {
            executionList: [workflows[0].idWorkflowExecution],
            validate: false,
            comment: { 'en-US': '', 'es-ES': '' },
        };
        let resp: any = await this.adhesionService.resolverAdhesion(queryValidarAdhesion);

        if (resp.code == '0000'&& !resp.data?.error?.length) {
            this.closeDialogConfirmar();
            this.closeDialog();
            this.update.emit();
        } else {
            this.closeDialogConfirmar();
            this.closeDialog();
            this.error.emit(resp.data?.error?.length ? resp.data.error.join('\n') : resp.description);
        }
    }

    async bloquearAdhesion() {
        const resp: any = await this.adhesionService.bloquearAdhesion(this.adhesion.idAdhesion!);
        if (resp.code == '0000'&& !resp.data?.error?.length) {
            this.closeDialog();
            this.update.emit();
        } else {
            this.closeDialog();
            this.error.emit(resp.data?.error?.length ? resp.data.error.join('\n') : resp.description);
        }
    }
    async desbloquearAdhesion() {
        const resp: any = await this.adhesionService.desbloquearAdhesion(this.adhesion.idAdhesion!);
        if (resp.code == '0000' && !resp.data?.error?.length) {
            this.closeDialog();
            this.update.emit();
        } else {
            this.closeDialog();
            this.error.emit(resp.data?.error?.length ? resp.data.error.join('\n') : resp.description);
        }
    }
    async finalizarAdhesion() {
        const resp: any = await this.adhesionService.finalizarAdhesion(this.adhesion.idAdhesion!, this.adhesion.estado!);
        if (resp.code == '0000' && !resp.data?.error?.length) {
            this.closeDialog();
            this.update.emit();
        } else {
            this.closeDialog();
            this.error.emit(resp.data?.error?.length ? resp.data.error.join('\n') : resp.description);
        }
    }
    async closeDialogConfirmar() {
        this.activeConfirmarRechazar = false;
    }
}

import { ComponentRef, Injectable } from '@angular/core';
import { Adhesion } from '../../interfaces/adhesion';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DialogComponent } from 'desy-angular';
import { ValidarAdhesion } from '../../interfaces/validar-adhesion';
import { WorkflowQuery } from 'src/app/interfaces/workflow-query';
import { LoginService } from '../login/login.service';
import { Usuario } from 'src/app/interfaces/usuario';
import { RptService } from '../rpt/rpt.service';
import { Rpt } from 'src/app/interfaces/rpt';
import { Query } from 'src/app/interfaces/query';
import { ProtocolosService } from '../protocolos/protocolos.service';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';
import { checkIsDpd, checkIsDSFuncional } from 'src/app/utils/checkRol';
import { Workflow } from 'src/app/interfaces/workflow';

const url = environment.url;
const urlDynamo = environment.dynamoUrl + '/api/data/invocar_lambdas_adhesion';

@Injectable({
    providedIn: 'root',
})
export class AdhesionService {
    dialogAdhesion: ComponentRef<DialogComponent>;
    constructor(
        private http: HttpClient,
        private rptService: RptService,
        private protocolosService: ProtocolosService,
        private loginService: LoginService
    ) {}

    async getWorkflowsByName(params: { name: string; last?: boolean; workflowType?: string[] }): Promise<Workflow[]> {
        const query: WorkflowQuery = {
            objectName: params.name,
            size: 500,
        };
        if (params.workflowType) query.workflowType = params.workflowType;
        if (params.last) {
            query.order = 'DESC';
            query.sortBy = ['requestDate'];
            query.size = 1;
        }
        return new Promise((resolve) => {
            this.http.post(`${url}/gateway/api/v2/workflow`, query).subscribe((resp: any) => {
                resolve(resp.data.results);
            });
        });
    }

    async getAllWorkflows(query: WorkflowQuery) {
        return new Promise((resolve) => {
            this.http.post(`${url}/gateway/api/v2/workflow`, query).subscribe((resp: any) => {
                resolve(resp.data);
            });
        });
    }

    async getWorkFlow(idWorkflow: any): Promise<any> {
        return new Promise((resolve) => {
            this.http.get(`${url}/gateway/api/v1/workflow/execution/${idWorkflow}`).subscribe((resp: any) => {
                resolve(resp.data);
            });
        });
    }

    async resolverAdhesion(query: ValidarAdhesion) {
        return new Promise((resolve) => {
            //llamada validate
            this.http.put(`${url}/gateway/api/v1/task/validate`, query).subscribe((resp: any) => {
                resolve(resp);
            });
            //llamada a invocar_lamda
            this.http.post(urlDynamo, {}).subscribe((resp: any) => {});
        });
    }

    //TODO
    async bloquearAdhesion(id: number): Promise<any> {
        const completeUrl = `${url}/gateway/api/v2/relationship/change-state/ADHESION/APPROVED/DISABLED/${id}`;
        // const payload = { role: this.loginService.getCurrentRole().join(', '), requestReason: null };
        const payload = { role: 'datasteward_adhesion', requestReason: null };
        return new Promise((resolve) => {
            this.http.post(completeUrl, payload).subscribe((resp: any) => {
                resolve(resp);
            });

            this.http.post(urlDynamo, {}).subscribe((resp: any) => {});
        });
    }
    async desbloquearAdhesion(id: number): Promise<any> {
        const completeUrl = `${url}/gateway/api/v2/relationship/change-state/ADHESION/DISABLED/APPROVED/${id}`;
        // const payload = { role: this.loginService.getCurrentRole().join(', '), requestReason: null };
        const payload = { role: 'datasteward_adhesion', requestReason: null };
        return new Promise((resolve) => {
            this.http.post(completeUrl, payload).subscribe((resp: any) => {
                resolve(resp);
            });
            this.http.post(urlDynamo, {}).subscribe((resp: any) => {});
        });
    }
    async finalizarAdhesion(id: number, estado: string): Promise<any> {
        const payload = {
            objectList: [id + ''],
            attributes: [
                {
                    attributeName: 'finish_date',
                    attributeValue: new Date().toISOString(),
                },
            ],
        };
        const completeUrl = `${url}/gateway/api/admin/kerno/attribute/update/relationship/adhesion`;
        return new Promise((resolve) => {
            this.http.put(completeUrl, payload).subscribe(async (resp: any) => {
                if (estado === EstadosAdhesionEnum.BLOQUEADA || resp?.code !== '0000') {
                    resolve(resp);
                    this.http.post(urlDynamo, {}).subscribe((resp: any) => {});
                } else {
                    const response = await this.bloquearAdhesion(id);
                    resolve(response);
                    this.http.post(urlDynamo, {}).subscribe((resp: any) => {});
                }
            });
        });
    }
    //TODO
    async getAdhesionById(idAdhesion: string): Promise<Adhesion> {
        let query: Query = {
            q: '',
            fq: {
                OBJ_SUB_TYPE: ['ADHESION'],
                // SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
                // STATE: [
                //     EstadosAdhesionEnum.APROBADA,
                //     EstadosAdhesionEnum.PENDIENTE,
                //     EstadosAdhesionEnum.RECHAZADA,
                //     EstadosAdhesionEnum.BLOQUEADA,
                // ],
                ZONE: [environment.zone],
                ID_OBJ: [idAdhesion],
            },
            start: 0,
            rows: 1,
            orderBy: 'START_DATE',
            sortingType: 'DESC',
        };
        const data: any = await this.protocolosService.getProtocolos(query);
        if (!data.results?.BASIC?.length) return Promise.reject('No se ha encontrado la adhesión');
        return await this.mapAdhesion(data.results?.BASIC[0]?.solrDocument);
    }

    async getAdhesionesEnviadas(query: Query): Promise<Adhesion[]> {
        const adhesiones = await this.getAdhesionesEnviadasRaw({ query });
        const mappedAdhesiones = [];
        for (const adhesion of adhesiones) {
            if (adhesion.solrDocument.DESTINATION_TYPE !== 'APP') {
                const mappedAdhesion = await this.mapAdhesion(adhesion.solrDocument);
                mappedAdhesiones.push(mappedAdhesion);
            }
        }
        return mappedAdhesiones;
    }

    async getAdhesionesEnviadasRaw(params: { estados?: EstadosAdhesionEnum[]; query?: Query }): Promise<any> {
        const incluyeFinalizadas = params.query?.fq?.STATE?.includes(EstadosAdhesionEnum.FINALIZADA);
        const incluyeBloqueadas = params.query?.fq?.STATE?.includes(EstadosAdhesionEnum.BLOQUEADA);
        // Las adhesiones finalizadas son bloqueadas. Si no está el estado de bloqueado se añade y se elimina el de finalizado que no existe
        if (incluyeFinalizadas) {
            params.query!.fq!.STATE = params.query!.fq!.STATE!.filter(
                (estado: string) => estado !== EstadosAdhesionEnum.FINALIZADA
            );
            if (!incluyeBloqueadas) {
                params.query!.fq!.STATE.push(EstadosAdhesionEnum.BLOQUEADA);
            }
        }
        let adhesionQuery: Query = params.query
            ? {
                  ...params.query,
                  fq: {
                      ...params.query.fq,
                      CREATED_USER: [this.loginService.getUsuarioActual().jobCode!],
                  },
              }
            : {
                  q: '',
                  fq: {
                      OBJ_SUB_TYPE: ['ADHESION'],
                      //   ZONE: [environment.zone],
                      STATE: params.estados,
                      CREATED_USER: [this.loginService.getUsuarioActual().jobCode!],
                  },
                  start: 0,
                  orderBy: 'START_DATE',
                  sortingType: 'DESC',
              };
        if (params.query) delete adhesionQuery.fq?.ID_OBJ;
        const response: any = await this.protocolosService.getProtocolos(adhesionQuery);
        if (incluyeFinalizadas && !incluyeBloqueadas) {
            return response.results.BASIC.filter((adhesion: any) => {
                return (
                    adhesion.solrDocument.STATE !== EstadosAdhesionEnum.BLOQUEADA ||
                    (adhesion.solrDocument.STATE === EstadosAdhesionEnum.BLOQUEADA && adhesion.solrDocument.FINISH_DATE)
                );
            });
        }
        if (!incluyeFinalizadas && incluyeBloqueadas) {
            return response.results.BASIC.filter((adhesion: any) => {
                return (
                    adhesion.solrDocument.STATE !== EstadosAdhesionEnum.BLOQUEADA ||
                    (adhesion.solrDocument.STATE === EstadosAdhesionEnum.BLOQUEADA &&
                        !adhesion.solrDocument.FINISH_DATE)
                );
            });
        }
        return response.results?.BASIC;
    }

    async getAdhesionesRecibidas(query: Query): Promise<Adhesion[]> {
        const adhesiones = await this.getAdhesionesRecibidasRaw({ query });
        const mappedAdhesiones = [];
        for (const adhesion of adhesiones) {
            if (adhesion.solrDocument.DESTINATION_TYPE !== 'APP') {
                const mappedAdhesion = await this.mapAdhesion(adhesion.solrDocument);
                mappedAdhesiones.push(mappedAdhesion);
            }
        }
        return mappedAdhesiones;
    }

    async getAdhesionesRecibidasRaw(params: { estados?: EstadosAdhesionEnum[]; query?: Query }): Promise<any[]> {
        const incluyeFinalizadas = params.query?.fq?.STATE?.includes(EstadosAdhesionEnum.FINALIZADA);
        const incluyeBloqueadas = params.query?.fq?.STATE?.includes(EstadosAdhesionEnum.BLOQUEADA);
        const incluyeAprobadas = params.query?.fq?.STATE?.includes(EstadosAdhesionEnum.APROBADA);
        const incluyeRechazadas = params.query?.fq?.STATE?.includes(EstadosAdhesionEnum.RECHAZADA);
        // Obtiene los workflows aprobados y rechazados para los que es validador(las bloqueadas tienen workflows aprobados)
        let states = [];
        if (incluyeBloqueadas || incluyeFinalizadas || incluyeAprobadas) {
            states.push(EstadosAdhesionEnum.APROBADA);
        }
        if (incluyeRechazadas) {
            states.push(EstadosAdhesionEnum.RECHAZADA);
        }
        const workflowQuery = {
            objectSubType: ['ADHESION'],
            state: states,
            workflowType: ['CREATE'],
            size: 500,
            validator: [this.loginService.getUsuarioActual().jobCode!],
        };
        const resp: any = await this.getAllWorkflows(workflowQuery);
        const workflows = resp?.results?.filter(
            (workflow: any) => workflow.requester !== this.loginService.getUsuarioActual().jobCode!
        );
        const ids = workflows.map((result: any) => result.idObject);

        // Las adhesiones finalizadas son bloqueadas. Si no está el estado de bloqueado se añade y se elimina el de finalizado que no existe
        if (incluyeFinalizadas) {
            params.query!.fq!.STATE = params.query!.fq!.STATE!.filter(
                (estado: string) => estado !== EstadosAdhesionEnum.FINALIZADA
            );
            if (!incluyeBloqueadas) {
                params.query!.fq!.STATE.push(EstadosAdhesionEnum.BLOQUEADA);
            }
        }
        // Obtiene las adhesiones aprobadas, rechazadas y bloqueadas de los workflows obtenidos
        //         const adhesionQuery: Query = params.query
        //             ? {
        //                   ...params.query,
        //                   fq: {
        //                       ...params.query.fq,
        //                       //   ID_OBJ: ids,
        //                       STATE: params.query.fq?.STATE?.filter(
        //                           (estado: string) => estado !== EstadosAdhesionEnum.PENDIENTE
        //                       ),
        //                       SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
        //                   },
        //               }
        //             : ids
        //             ? {
        //             q: '',
        //             fq: {
        //                 OBJ_SUB_TYPE: ['ADHESION'],
        //                 ZONE: [environment.zone],
        //                 STATE: params.estados,
        // // ID_OBJ: ids,
        //                 SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
        //             },
        //             start: 0,
        //             orderBy: 'START_DATE',
        //             sortingType: 'DESC',
        //         }
        //             : {
        //                   q: '',
        //             fq: {
        //                 OBJ_SUB_TYPE: ['ADHESION'],
        //                       ZONE: [environment.zone],
        //                       STATE: params.estados,
        //                 SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
        //                   },
        //                   start: 0,
        //                   orderBy: 'START_DATE',
        //                   sortingType: 'DESC',
        //                   };
        const createBaseQuery = () => ({
            q: '',
            fq: {
                OBJ_SUB_TYPE: ['ADHESION'],
                // ZONE: [environment.zone],
                STATE: params.estados,
                SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
            },
            start: 0,
            orderBy: 'START_DATE',
            sortingType: 'DESC',
        });

        const createModifiedQuery = (query: Query) => ({
            ...query,
            fq: {
                ...query.fq,
                ID_OBJ: ids,
                STATE: query.fq?.STATE?.filter((estado: string) => estado !== EstadosAdhesionEnum.PENDIENTE),
                SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
            },
        });

        const adhesionQuery: Query = params.query
            ? createModifiedQuery(params.query)
            : ids
            ? {
                  ...createBaseQuery(),
                  fq: {
                      ...createBaseQuery().fq,
                      ID_OBJ: ids,
                  },
              }
            : createBaseQuery();
        if (params.query) {
            adhesionQuery.fq!.STATE = params.query.fq!.STATE!.filter(
                (estado: string) => estado !== EstadosAdhesionEnum.PENDIENTE
            );
        }
        const response: any = await this.protocolosService.getProtocolos(adhesionQuery);
        if (incluyeFinalizadas && !incluyeBloqueadas) {
            return response.results.BASIC.filter((adhesion: any) => {
                return (
                    adhesion.solrDocument.STATE !== EstadosAdhesionEnum.BLOQUEADA ||
                    (adhesion.solrDocument.STATE === EstadosAdhesionEnum.BLOQUEADA && adhesion.solrDocument.FINISH_DATE)
                );
            });
        }
        if (!incluyeFinalizadas && incluyeBloqueadas) {
            return response.results.BASIC.filter((adhesion: any) => {
                return (
                    adhesion.solrDocument.STATE !== EstadosAdhesionEnum.BLOQUEADA ||
                    (adhesion.solrDocument.STATE === EstadosAdhesionEnum.BLOQUEADA &&
                        !adhesion.solrDocument.FINISH_DATE)
                );
            });
        }
        return response.results?.BASIC;
    }

    async getAdhesionesRecibidasPendientes(query: Query): Promise<any[]> {
        const adhesiones = await this.getAdhesionesRecibidasPendientesRaw(query);
        const mappedAdhesiones = [];
        for (const adhesion of adhesiones) {
            if (adhesion.solrDocument.DESTINATION_TYPE !== 'APP') {
                const mappedAdhesion = await this.mapAdhesion(adhesion.solrDocument);
                mappedAdhesiones.push(mappedAdhesion);
            }
        }
        return mappedAdhesiones;
    }

    async getAdhesionesRecibidasPendientesRaw(query?: Query) {
        const workflowQuery = {
            objectSubType: ['ADHESION'],
            state: [EstadosAdhesionEnum.PENDIENTE],
            workflowType: ['CREATE'],
            size: 500,
        };
        const resp: any = await this.getAllWorkflows(workflowQuery);
        const workflows = resp.results.filter(
            (workflow: any) => workflow.requester !== this.loginService.getUsuarioActual().jobCode!
        );
        const ids: string[] = [];
        switch (true) {
            case checkIsDpd():
                workflows.forEach((workflow: any) => {
                    const step7 = workflow.progress.find((item: any) => item.step === 7);
                    const step58 = workflow.progress.find((item: any) => item.step === 58);
                    if (!step58 || !step7) return;
                    if (
                        step7.state === EstadosAdhesionEnum.APROBADA &&
                        step58.state === EstadosAdhesionEnum.PENDIENTE
                    ) {
                        ids.push(workflow.idObject);
                    }
                });
                break;
            case checkIsDSFuncional():
                workflows.forEach((workflow: any) => {
                    const step7 = workflow.progress.find((item: any) => item.step === 7);
                    const step58 = workflow.progress.find((item: any) => item.step === 58);
                    if (!step58 || !step7) return;
                    if (step7.state === EstadosAdhesionEnum.PENDIENTE && step58.state === EstadosAdhesionEnum.WAITING) {
                        ids.push(workflow.idObject);
                    }
                });
                break;
            default:
                break;
        }
        // Obtiene las adhesiones recibidas pendientes de los workflows filtrados
        const adhesionQuery: Query = ids
            ? query
                ? {
                      ...query,
                      fq: {
                          ...query.fq,
                          ID_OBJ: ids,
                          STATE: [EstadosAdhesionEnum.PENDIENTE],
                          SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
                      },
                  }
                : {
                      q: '',
                      fq: {
                          OBJ_SUB_TYPE: ['ADHESION'],
                          //   ZONE: [environment.zone],
                          STATE: [EstadosAdhesionEnum.PENDIENTE],
                          ID_OBJ: ids,
                          SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
                      },
                      start: 0,
                      orderBy: 'START_DATE',
                      sortingType: 'DESC',
                  }
            : query
            ? {
                  ...query,
                  fq: {
                      ...query.fq,
                      STATE: [EstadosAdhesionEnum.PENDIENTE],
                      SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
                  },
              }
            : {
                  q: '',
                  fq: {
                      OBJ_SUB_TYPE: ['ADHESION'],
                      //   ZONE: [environment.zone],
                      STATE: [EstadosAdhesionEnum.PENDIENTE],
                      ID_OBJ: ids,
                      SOURCE_ORGANIZATIONAL_UNIT: [JSON.parse(localStorage.getItem('usuario')!).orgCode],
                  },
                  start: 0,
                  orderBy: 'START_DATE',
                  sortingType: 'DESC',
              };

        const response: any = await this.protocolosService.getProtocolos(adhesionQuery);
        return response.results?.BASIC;
    }

    async mapAdhesion(solrDocument: any): Promise<Adhesion> {
        const puestos: Rpt[] = await this.rptService.getRPTsDynamoAragon();
        const rptAri: string[] = solrDocument.JOB_LIST;
        const jobCodes = rptAri?.map((puesto) => puesto.split(':').find((code: string) => code.includes('JOB')));
        //TODO - prueba codigo
        const jobCodeFilter = jobCodes?.map((x) => (x?.length === 12 ? x.substring(4) : x)) || [];
        const rpts = puestos.filter((rpt) => {
            return jobCodeFilter.includes(rpt.puesto);
        });
        const solicitanteJobCode = solrDocument.DESTINATION?.split(':').find((code: string) => code.includes('JOB'));
        const solicitanteJobCodeSinPrefijo =
            solicitanteJobCode.length == 12 ? solicitanteJobCode.slice(4) : solicitanteJobCode;
        const puestoSolicitante = puestos.find((rpt) => rpt.puesto === solicitanteJobCodeSinPrefijo);
        return {
            idAdhesion: solrDocument.ID_OBJ,
            nombreAdhesion: 'Adhesión ' + solrDocument.ID_OBJ,
            idProtocolo: solrDocument.SOURCE_ID,
            protocoloAri: solrDocument.SOURCE,
            nombreProtocolo: solrDocument.SOURCE.split(':')[5],
            estado:
                solrDocument.STATE === EstadosAdhesionEnum.BLOQUEADA && solrDocument.FINISH_DATE
                    ? EstadosAdhesionEnum.FINALIZADA
                    : solrDocument.STATE,
            fechaCreacion: solrDocument.START_DATE,
            SOURCE_ORGANIZATIONAL_UNIT: solrDocument.SOURCE_ORGANIZATIONAL_UNIT,
            RPTSolicitante: puestoSolicitante,
            idRPTSolicitante: solicitanteJobCodeSinPrefijo,
            organismoSolicitante: solrDocument.DESTINATION.split(':')[6],
            nombreServicio: solrDocument.PROCEDIMIENTO?.split(':')[5] ?? solrDocument.PROCEDIMIENTO,
            idServicio: solrDocument.PROCEDIMIENTO?.split(':')[4] ?? solrDocument.PROCEDIMIENTO?.split('-')[0],
            RPTs: rpts,
            RPTAri: rptAri,
            observaciones: solrDocument.OBSERVACIONES,
            fechaVigencia: solrDocument.FECHA_LIMITE?.split('T')[0],
            baseLegal: {
                dia: solrDocument.DIA,
                enlace: solrDocument.ENLACE_NORMATIVA,
                mes: solrDocument.MES,
                rango: solrDocument.RANGO_ANO,
                tipo: solrDocument.TIPO_NORMATIVA,
                titulo: solrDocument.TITULO_NORMATIVA,
                tiempoVigencia: solrDocument.VALIDITY_DATE,
            },
            nombreCompleto: solrDocument.OBJ_NAME,
            FINISH_DATE: solrDocument.FINISH_DATE,
        };
    }

    async getAdhesionesBloqueadasPorMi() {
        // TODO: Esto no escala. Cuando un mismo usuario haya bloqueado y desbloqueado mas de 50000 veces ya no funciona... Buscar solución
        let query = {
            objectSubType: ['ADHESION'],
            state: [EstadosAdhesionEnum.APROBADA],
            workflowType: ['DEACTIVATE', 'ACTIVATE'],
            size: 50000,
            validator: [this.loginService.getUsuarioActual().jobCode!],
        };
        let resp: any = await this.getAllWorkflows(query);
        const workflowsDesactivados = resp.results.filter((workflow: any) => workflow.workflowType === 'DEACTIVATE');
        const workflowsActivados = resp.results.filter((workflow: any) => workflow.workflowType === 'ACTIVATE');
        workflowsActivados.forEach((workflow: any) => {
            const index = workflowsDesactivados.findIndex((item: any) => item.idObject === workflow.idObject);
            if (index === -1) return;
            workflowsDesactivados.splice(index, 1);
        });
        return workflowsDesactivados.map((workflow: any) => workflow.idObject);
    }
}

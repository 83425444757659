<div class="border-b border-neutral-dark bg-white">
    <div class="container mx-auto mt-base -mb-px px-base">
        <div class="lg:flex lg:flex-wrap lg:w-full">
            <div class="lg:w-full mb-base lg:mb-lg">
                <!-- subheaderTitle -->
                <h2 class="c-h2 mb-xs mt-12">Mis Adhesiones</h2>
                <!-- /subheaderTitle -->
            </div>
            <div class="order-4 w-full">
                <desy-menu-horizontal [ariaLabelledBy]="'cabecera-menu'" [classes]="'c-menu-horizontal--tabs'">
                    <desy-menu-horizontal-item
                        [routerLink]="['/listado-adhesiones/enviadas']"
                        routerLinkActive="active"
                        [active]="tab === 'enviadas'"
                        >Enviadas</desy-menu-horizontal-item
                    >
                    <desy-menu-horizontal-item
                        [routerLink]="['/listado-adhesiones/recibidas']"
                        routerLinkActive="active"
                        [active]="tab === 'recibidas'"
                        >Recibidas</desy-menu-horizontal-item
                    >
                </desy-menu-horizontal>
                <!-- /subheaderMenu -->
            </div>
        </div>
    </div>
</div>

<main id="content" class="container mx-auto px-base my-12">
    <desy-spinner *ngIf="buscando" classes="mb-2"></desy-spinner>
    <app-listado *ngIf="!buscando"></app-listado>
</main>

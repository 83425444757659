import { RolesEnum } from "../enum/roles";

// Check if the current user has any of the roles passed as parameters or all of them if the and parameter is true
function checkRoles(roles: string[], and = false) {
    if (and) {
        return roles.every((r) => JSON.parse(localStorage.getItem('role')!).includes(r));
    }
    return roles.some((r) =>JSON.parse(localStorage.getItem('role')!).includes(r));
}

function checkIsDpd(){
    return JSON.parse(localStorage.getItem('role')!).includes(RolesEnum.DPD);
}
function checkIsJefeServicio(){
    return JSON.parse(localStorage.getItem('role')!).includes(RolesEnum.JEFE_SERVICIO);
}
function checkIsDSFuncional(){
    return JSON.parse(localStorage.getItem('role')!).includes(RolesEnum.DS_FUNCIONAL);
}
function checkIsDSAdhesion(){
    return JSON.parse(localStorage.getItem('role')!).includes(RolesEnum.DS_ADHESION);
}

export { checkRoles, checkIsDpd, checkIsJefeServicio, checkIsDSFuncional, checkIsDSAdhesion };
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConfirmacionAdhesion } from 'src/app/interfaces/confirmacion-adhesion';

const url = environment.url;
const urlDynamo = environment.dynamoUrl+'/api/data/invocar_lambdas_adhesion'
@Injectable({
    providedIn: 'root',
})
export class EditarAdhesionService {
    constructor(private http: HttpClient) {}

    async editarAdhesion(editarAdhesion: {}, id: String) {
        return new Promise((resolve) => {
            this.http.post(
                `${url}/gateway/api/v2/relationship/save/ADHESION/${id}`, editarAdhesion)
                .subscribe(
                    (resp: any) => {
                resolve(resp);
            });
        });
    }

    async confirmarAdhesion(confirmacionAdhesion: ConfirmacionAdhesion, id: String) {
        return new Promise((resolve) => {
            this.http
                .post(`${url}/gateway/api/v2/relationship/submit/ADHESION/${id}`, confirmacionAdhesion)
                .subscribe((resp: any) => {
                    resolve(resp);
                });
            this.http.post(urlDynamo, {}).subscribe((resp: any) => {
                    resolve(resp);
                });
        });
    }
}

<desy-item classes="mb-5 card-enviadas">
    <desy-title class="c-paragraph-base w-full">
        <div class="flex mt-1">
            <b class="c-h3 mr-4"> {{ adhesion.nombreProtocolo }} </b>
            <p class="font-semibold">{{ adhesion.nombreAdhesion }}</p>
        </div>
    </desy-title>
    <desy-item-item classes="flex c-paragraph-base">
        <div class="flex mb-5">
            <div class="cuadrado pendiente mr-2" ngClass="{{ adhesion.estado }}"></div>
            <p class="mr-10" *ngIf="adhesion.estado != undefined">{{ adhesion.estado | estado }}</p>
            <p>
                Creada el {{ adhesion.fechaCreacion | date : 'd' }} de {{ adhesion.fechaCreacion | date : 'MMMM' }} de
                {{ adhesion.fechaCreacion | date : 'YYYY' }}. Solicitada por {{ adhesion.RPTSolicitante?.nombre }}
            </p>
        </div>
    </desy-item-item>
    <desy-item-content-right class="flex ml-0">
        <div
            class="mt-2 ml-0 fixed-height-container"
            style="
                position: relative;
                display: flex;
                justify-content: space-evenly;
                max-width: 21rem;
                min-width: 21rem;
                width: 21rem;
                margin-left: -9rem;
            "
        >
            <desy-menubar
                [id]="'mas'"
                *ngIf="
                    adhesion.estado === EstadosAdhesionEnum.APROBADA ||
                    adhesion.estado === EstadosAdhesionEnum.BLOQUEADA
                "
                [idPrefix]="'mas'"
                classes=""
                [items]="masItems"
                (activeSubItemChange)="openDialog()"
            >
            </desy-menubar>
            <desy-button (clickEvent)="onVerAdhesion()" style="position: absolute; right: 0rem" class="mr-0"
                >Ver adhesión</desy-button
            >
        </div>
    </desy-item-content-right>
    <desy-item-content-bottom classes="w-full ">
        <div class="flex place-content-between w-full mt-5">
            <desy-label classes="text-base text-neutral-base" style="width: 60%"
                >Servicio
                <br />
                <label for="" class="text-black text-sm w-full" *ngIf="adhesion.nombreServicio">
                    {{ adhesion.nombreServicio | slice : 80 }}
                </label>
            </desy-label>
            <desy-label classes="text-base text-neutral-base" style="width: 20%"
                >RPTs
                <br />
                <label for="" class="text-black text-sm w-full"
                    ><desy-tooltip [icon]="{ type: 'info' }">
                        <desy-content *ngIf="adhesion.RPTs != undefined">{{ adhesion.RPTs.length }} RPTs</desy-content>

                        <desy-tooltip-content>
                            <div *ngFor="let rpt of adhesion.RPTs">{{ rpt.cargo }} - {{ rpt.nombre }}</div>
                        </desy-tooltip-content>
                    </desy-tooltip>
                </label>
            </desy-label>
            <desy-label classes="text-base text-neutral-base" style="width: 20%"
                >Tiempo de vigencia
                <br />
                <label
                    for=""
                    class="text-black text-sm w-full"
                    *ngIf="adhesion.fechaVigencia != '2999-12-31' && adhesion.fechaVigencia != '2999-12-30'"
                >
                    {{ adhesion.fechaVigencia | date : 'medium' }}</label
                >
                <label
                    for=""
                    class="text-black"
                    *ngIf="
                        adhesion.fechaVigencia === '2999-12-31' ||
                        adhesion.fechaVigencia === '2999-12-30' ||
                        adhesion.fechaVigencia == null
                    "
                >
                    Indefinido
                </label>
            </desy-label>
        </div>
    </desy-item-content-bottom>
</desy-item>
<desy-dialog
    *ngIf="activeFinalizar"
    [(active)]="activeFinalizar"
    [id]="'dialog'"
    [caller]="finalizar"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="true"
>
    <ng-template #finalizar>
        <desy-modal
            [isDismissible]="true"
            (closeModal)="closeDialog()"
            classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
        >
            <desy-title>
                Finalizar uso de la adhesion
                <div class="text-base font-normal mt-base">
                    Los RPTs vinculados ya no podrán acceder a la información proporcionada en esta adhesion. ¿Estás
                    seguro?
                </div>
            </desy-title>
            <desy-modal-button-primary
                [classes]="'c-button--secondary c-button--sm button-cancelar'"
                (clickEvent)="closeDialog()"
                >Cancelar</desy-modal-button-primary
            >
            <desy-modal-button-secondary
                [classes]="'c-button--primary c-button--sm button-finalizar'"
                (clickEvent)="finalizarAdhesion()"
                >Sí, finalizar</desy-modal-button-secondary
            >
        </desy-modal>
    </ng-template>
</desy-dialog>

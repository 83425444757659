import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    DesyAngularModule,
    DesyButtonsModule,
    DesyCommonsModule,
    DesyModalsModule,
    DesyPaginationModule,
    DesyViewsModule,
    DesyFormsModule,
    DesyTablesModule,
    DesyNavModule,
} from 'desy-angular';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEsAr from '@angular/common/locales/es-AR';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { HeaderComponent } from './shared/header/header.component';
import { FiltroOrganismosComponent } from './shared/filtro-organismos/filtro-organismos.component';
import { DetallesProtocoloComponent } from './componentes/nueva-adhesion/paso1/detalles-protocolo/detalles-protocolo.component';
import { ListadoAdhesionesComponent } from './componentes/listado-adhesiones/listado-adhesiones.component';
import { AdhesionEnviadaComponent } from './componentes/adhesion-enviada/adhesion-enviada.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SlicePipe } from './pipes/slice/slice.pipe';
import { OrganizacionesPipe } from './pipes/organizaciones/organizaciones.pipe';
import { EstadoPipe } from './pipes/estados/estado.pipe';
import { VerAdhesionRecibidaComponent } from './componentes/ver-adhesion-recibida/ver-adhesion-recibida.component';
import { LoginComponent } from './componentes/login/login.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './componentes/home/home.component';
import { CardComponent } from './componentes/home/card/card.component';
import { DatafieldsPipe } from './pipes/datafields.pipe';
import { FiltroOrganismoPipe } from './pipes/filtro-organismo.pipe';
import { VerAdhesionEnviadaComponent } from './componentes/ver-adhesion-enviada/ver-adhesion-enviada.component';
import { RptListadoPipe } from './pipes/rpt-listado.pipe';
import { DetalleProtocoloAdhesionComponent } from './componentes/detalle-protocolo-adhesion/detalle-protocolo-adhesion.component';
import { EnviadasComponent } from './componentes/listado-adhesiones/enviadas/enviadas.component';
import { RecibidasComponent } from './componentes/listado-adhesiones/recibidas/recibidas.component';
import { ListadoComponent } from './componentes/listado-adhesiones/listado/listado.component';
import { NuevaAdhesionComponent } from './componentes/nueva-adhesion/nueva-adhesion.component';
import { Paso1Component } from './componentes/nueva-adhesion/paso1/paso1.component';
import { Paso2Component } from './componentes/nueva-adhesion/paso2/paso2.component';
import { Paso3Component } from './componentes/nueva-adhesion/paso3/paso3.component';
import { Paso4Component } from './componentes/nueva-adhesion/paso4/paso4.component';
import { Paso5Component } from './componentes/nueva-adhesion/paso5/paso5.component';
import { DetalleProtocoloComponent } from './shared/detalle-protocolo/detalle-protocolo.component';
import { ModificarRptComponent } from './componentes/modificar-rpt/modificar-rpt.component';
import { AlertComponent } from './componentes/modificar-rpt/alert/alert.component';
import { HistoricosComponent } from './componentes/historico/historico.component';
import { ModalTableComponent } from './componentes/historico/modal-table/modal-table.component';
registerLocaleData(localeEsAr, 'es-Ar');
@NgModule({ declarations: [
        AdhesionEnviadaComponent,
        AlertComponent,
        AppComponent,
        CardComponent,
        DatafieldsPipe,
        DetalleProtocoloAdhesionComponent,
        DetalleProtocoloComponent,
        DetallesProtocoloComponent,
        EnviadasComponent,
        EstadoPipe,
        FiltroOrganismoPipe,
        FiltroOrganismosComponent,
        FooterComponent,
        HeaderComponent,
        HomeComponent,
        ListadoAdhesionesComponent,
        ListadoComponent,
        LoginComponent,
        ModificarRptComponent,
        NuevaAdhesionComponent,
        OrganizacionesPipe,
        Paso1Component,
        Paso2Component,
        Paso3Component,
        Paso4Component,
        Paso5Component,
        RecibidasComponent,
        RptListadoPipe,
        SlicePipe,
        VerAdhesionEnviadaComponent,
        VerAdhesionRecibidaComponent,
        HistoricosComponent,
        ModalTableComponent
    ],
    bootstrap: [AppComponent],
    exports: [RptListadoPipe], imports: [AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        DesyAngularModule,
        DesyButtonsModule,
        DesyCommonsModule,
        DesyFormsModule,
        DesyModalsModule,
        DesyNavModule,
        DesyPaginationModule,
        DesyTablesModule,
        DesyViewsModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule], providers: [
        { provide: LOCALE_ID, useValue: 'es-Ar' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

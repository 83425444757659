<desy-header *ngIf="isLogged" [expandedLogo]="false" homepageRouterLink="/home">
    <desy-header-subnav> Plataforma de gobernanza </desy-header-subnav>

    <desy-header-navigation>
        <desy-header-navigation-item routerLink="/home">Inicio</desy-header-navigation-item>
        <desy-header-navigation-item routerLink="/nueva-adhesion/protocolos">Nueva adhesión</desy-header-navigation-item>
    </desy-header-navigation>
    <desy-header-dropdown
    id="mis-adhesiones"
        >Mis adhesiones
        <desy-nav >
            <desy-nav-item routerLink="../listado-adhesiones/enviadas">
                Enviadas
            </desy-nav-item>
            <desy-nav-item routerLink="../listado-adhesiones/recibidas">
                Recibidas
            </desy-nav-item>
        </desy-nav>
    </desy-header-dropdown>
    <desy-header-dropdown
    id="mis-adhesiones"
        >Uso de datos
        <desy-nav >
            <desy-nav-item routerLink="../uso-datos/cedidos">
                Protocolos cedidos
            </desy-nav-item>
            <desy-nav-item routerLink="../uso-datos/adheridos">
                Protocolos adheridos
            </desy-nav-item>
        </desy-nav>
    </desy-header-dropdown>
    <desy-header-dropdown
    style="margin-left:auto;"
        >Hola, {{ usuarioLogeado.nombre | titlecase }}
        <desy-nav>
            <desy-nav-item (clickEvent)="onLogOut()">
                Cerrar Sesion
            </desy-nav-item>
        </desy-nav>
    </desy-header-dropdown>
    
    <desy-header-offcanvas>
        <desy-header-offcanvas-button>Menu</desy-header-offcanvas-button>
        <desy-header-offcanvas-close-button>Cerrar</desy-header-offcanvas-close-button>
        <desy-content>
            <ul>
                <li>
                    <desy-nav 
                        [idPrefix]="'nav1'" 
                        classes="bg-primary-light/20 menu-responsive" 
                        [items]="[
                            { text: 'Inicio', routerLink: '/home' },
                        ]"
                    ></desy-nav>
                </li>
                <li>
                    <desy-nav 
                        [idPrefix]="'nav1'" 
                        classes="bg-primary-light/20 menu-responsive" 
                        [items]="[
                            { text: 'Nueva Adhesión', routerLink: '/nueva-adhesion/protocolos' },
                        ]"
                    ></desy-nav>
                </li>
                <li>
                    <desy-nav
                        [idPrefix]="'nav2'"
                        classes="bg-primary-light/20 menu-responsive"
                        [items]="[
                            { text: 'Mis adheshiones enviadas', routerLink: '/listado-adhesiones/enviadas' },
                        ]"
                    ></desy-nav>
                </li>
                <li>
                    <desy-nav
                        [idPrefix]="'nav2'"
                        classes="bg-primary-light/20 menu-responsive"
                        [items]="[
                            { text: 'Mis adheshiones recibidas', routerLink: '/listado-adhesiones/recibidas' }
                        ]"
                    ></desy-nav>
                </li>
                <li>
                    <desy-nav classes="bg-primary-light/20 menu-responsive">
                        <desy-nav-item (clickEvent)="onLogOut()">
                            Cerrar Sesion
                        </desy-nav-item>
                    </desy-nav>
                </li>
            </ul>
        </desy-content>
    </desy-header-offcanvas>
</desy-header>

<desy-header *ngIf="!isLogged" [expandedLogo]="false"
    ><desy-header-subnav>Plataforma de gobernanza </desy-header-subnav></desy-header
>

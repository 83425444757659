import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { Query } from 'src/app/interfaces/query';
import { AdhesionService } from 'src/app/services/adhesiones/adhesion.service';
import { ProtocolosService } from 'src/app/services/protocolos/protocolos.service';

@Component({
    selector: 'app-adhesion-enviada',
    templateUrl: './adhesion-enviada.component.html',
    styleUrls: ['./adhesion-enviada.component.css'],
})
export class AdhesionEnviadaComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {}
    idAdhesion: any;

    async ngOnInit(): Promise<void> {
        this.idAdhesion = this.route.snapshot.paramMap.get('idAdhesion')
    }

    goToDetalleAdhesion() {
        this.router.navigate(['/detalle-adhesion-enviada', this.idAdhesion]);
    }
}

<main id="adhesionContent" class="container mx-auto px-base" >
    <div class="pb-sm"></div>

    <div class="pb-sm"></div>
    <p class="c-paragraph-sm text-neutral-dark">Paso 1 de 5</p>
    <h1 class="c-h1">Nueva adhesión</h1>
    <div class="flex flex-wrap gap-sm w-full justify-between">
        <div class="mt-sm">
            <a (click)="goToNuevaAdhesion()" role="button" draggable="false" class="c-button c-button--transparent">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 140 140"
                    class="self-center mr-2"
                    aria-hidden="true"
                    focusable="false"
                    width="1em"
                    height="1em"
                >
                    <path
                        d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                        fill="currentColor"
                    ></path>
                </svg>
                Volver al listado
            </a>
        </div>
        <div class="mt-sm">
            <desy-button
                text="Seleccionar"
                classes="c-button--primary ml-sm"
                (clickEvent)="goToServicio()"
            ></desy-button>
        </div>
    </div>

    <div class="pb-sm"></div>

    <app-detalle-protocolo></app-detalle-protocolo>
</main>
<div class="pb-lg"></div>

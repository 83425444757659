import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-listado-adhesiones',
    templateUrl: './listado-adhesiones.component.html',
    styleUrls: ['./listado-adhesiones.component.css'],
})
export class ListadoAdhesionesComponent implements OnInit {
    tab: string | undefined;
    buscando: boolean = false;

    constructor(private router: Router, private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef) {}
    async ngOnInit() {
        this.route.params.subscribe((param) => {
            this.buscando = true;
            this.tab = param.tipoListado;
            this.changeDetectorRef.detectChanges();
            this.buscando = false;
        });
    }
}

export class TableModel {
    data

    constructor(dataListados) {
        this.data = listar(dataListados)
    }

}
function listar(dataListados) {
    let arrayValor = []
    const objReduce = dataListados.reduce((agrupado, elemento) => {
        const { id_solicitud, ...resto } = elemento;
        if (!agrupado[id_solicitud]) {
            agrupado[id_solicitud] = {};
            arrayValor = []
        }
        Object.entries(resto).forEach(([clave, valor]) => {
            if(!Array.isArray(valor)){
                agrupado[id_solicitud][clave] = [];
            }
            if (clave === 'dsa') {
                arrayValor.push(valor)
                agrupado[id_solicitud][clave] = [...new Set(arrayValor)];
            } else {
                if (Array.isArray(valor) && valor.length === 1) {
                    agrupado[id_solicitud][clave] = valor[0];
                } else {
                    const setValue = new Set(agrupado[id_solicitud][clave].concat(valor));
                    agrupado[id_solicitud][clave] = [...setValue];
                }
            }
            if (clave == 'atributos_solicitud') {
                agrupado[id_solicitud].atributos_solicitud =
                    agrupado[id_solicitud].atributos_solicitud[0].replace(/^\{|\}$/g, '').substring(0, 30)

            }
            if(clave == 'inicio_timestamp'){
                agrupado[id_solicitud].inicio_timestamp 

                const meses = [
                    "enero", "febrero", "marzo", "abril", "mayo", "junio",
                    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
                ];
            
                const fecha = new Date(agrupado[id_solicitud].inicio_timestamp);
                const dia = fecha.getDate();
                const mes = meses[fecha.getMonth()];
                const año = fecha.getFullYear();
                let horas = fecha.getHours();
                let minutos = fecha.getMinutes();
                let segundos = fecha.getSeconds();

                const agregarCero = (valor) => {
                    return valor < 10 ? '0' + valor : valor;
                };
            
                // Aplicar la función para minutos, segundos y horas
                horas = agregarCero(horas);
                minutos = agregarCero(minutos);
                segundos = agregarCero(segundos);
            
                agrupado[id_solicitud].inicio_timestamp = `${dia} de ${mes} de ${año} ${horas}:${minutos}:${segundos}`;
            }
        });
        return agrupado;
    }, {})
    return Object.values(objReduce)
}
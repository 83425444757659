<main class="container mx-auto px-base">
    <ng-template #alertSuccess></ng-template>
    <div *ngIf="!adhesion">La adhesión solicitada no existe</div>
    <div *ngIf="!buscando && adhesion">
        <ng-template #alertPlace></ng-template>
        <div class="pb-sm"></div>
        <a (click)="goToListadoAdhesion()" role="button" draggable="false" class="c-button c-button--transparent">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 140 140"
                class="self-center mr-2"
                aria-hidden="true"
                focusable="false"
                width="1em"
                height="1em"
            >
                <path
                    d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                    fill="currentColor"
                ></path>
            </svg>
            Volver
        </a>

        <div class="pb-sm"></div>
        <div class="title flex justify-between mb-4">
            <div class="w-full">
                <div class="flex mt-1 c- paragraph-base mb-2 w-full">
                    <h1 class="c-h3 mr-4 title-h1" *ngIf="adhesion.nombreAdhesion != null">
                        {{ adhesion.nombreAdhesion }}
                    </h1>
                    <h2 class="font-semibold subtitle-h2">{{ adhesion.nombreProtocolo }}</h2>
                    <div class="buttons">
                        <desy-button
                            *ngIf="
                                adhesion.estado == EstadosAdhesionEnum.APROBADA ||
                                adhesion.estado == EstadosAdhesionEnum.BLOQUEADA
                            "
                            classes="c-button--secondary mr-3"
                            (clickEvent)="openDialog()"
                            >Finalizar uso de la adhesión</desy-button
                        >

                        <div
                            style="display: flex; align-items: center"
                            *ngIf="adhesion.estado === 'APPROVED' || adhesion.estado === 'BLOQUEADA'"
                        >
                            <div>
                                <desy-button
                                    [disabled]="true"
                                    classes="c-button--primary"
                                    (clickEvent)="onModificarRpt()"
                                    >Añadir o eliminar RPTs</desy-button
                                >
                            </div>
                            <desy-tooltip id="tooltip1">
                                <desy-content></desy-content>
                                <desy-icon type="alert"></desy-icon>
                                <desy-tooltip-content>
                                    <p>
                                        Estamos trabajando para poder activar nuevamente el botón "Añadir o eliminar
                                        RPTs".
                                    </p>
                                </desy-tooltip-content>
                            </desy-tooltip>
                        </div>
                    </div>
                </div>
                <div class="flex mt-1 c- paragraph-base mb-2 w-full">
                    <div class="flex mb-5">
                        <div class="cuadrado pendiente mr-2" ngClass="{{ adhesion.estado }}"></div>
                        <p class="mr-10 c- paragraph-base mb-2 semi-bold" *ngIf="adhesion.estado">
                            {{ adhesion.estado | estado }}
                        </p>
                        <p class="mr-10">
                            Creada el {{ adhesion.fechaCreacion | date : 'd' }} de
                            {{ adhesion.fechaCreacion | date : 'MMMM' }} de
                            {{ adhesion.fechaCreacion | date : 'YYYY' }} . Solicitada por
                            {{ adhesion.RPTSolicitante?.nombre }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-12">
            <p class="c-h3 bold black">
                {{ title }}
            </p>
            <p *ngIf="subtitle">{{ subtitle }}</p>
        </div>
        <div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Protocolo de consulta</strong></h3>
                <p class="c- paragraph-base mb-2 text-neutral-dark mb-2">
                    {{ protocolo.nombre }}
                </p>
                <a
                    id="menu-item"
                    [routerLink]="['/protocolo/detalle/', protocolo.id]"
                    [queryParams]="{ idAdhesion: adhesion.idAdhesion }"
                    class="c-menu-horizontal__link relative hover:text-primary-base underline truncate focus:outline-none"
                >
                    <span class="c-link text-mb"> Ver detalles </span>
                </a>
            </div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Servicio</strong></h3>
                <p *ngIf="procedimiento.nombre != undefined" class="c- paragraph-base mb-2 text-neutral-dark">
                    {{ procedimiento.nombre }}
                </p>
            </div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Base Legal</strong></h3>
                <p class="c- paragraph-base mb-2 text-neutral-dark" *ngIf="adhesion.baseLegal != undefined">
                    {{ adhesion.baseLegal.tipo }} {{ adhesion.baseLegal.rango }}, del {{ adhesion.baseLegal.dia }} de
                    {{ adhesion.baseLegal.mes }}, {{ adhesion.baseLegal.titulo }}
                </p>
                <a
                    id="menu-item"
                    target="_blank"
                    [href]="adhesion.baseLegal?.enlace"
                    class="c-menu-horizontal__link relative hover:text-primary-base underline truncate focus:outline-none"
                    style="cursor: pointer"
                >
                    <span class="c-link text-mb flex">
                        Ver detalles
                        <svg
                            class="iconoDetalles"
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="20px"
                            fill="currentColor"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                            />
                        </svg>
                    </span>
                </a>
            </div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Tiempo de vigencia</strong></h3>
                <p
                    class="c- paragraph-base mb-2 text-neutral-dark"
                    *ngIf="adhesion.fechaVigencia != '2999-12-31' && adhesion.fechaVigencia != '2999-12-30'"
                >
                    {{ adhesion.fechaVigencia | date : 'medium' }}
                </p>
                <p
                    class="c- paragraph-base mb-2 text-neutral-dark"
                    *ngIf="adhesion.fechaVigencia === '2999-12-31' || adhesion.fechaVigencia === '2999-12-30'"
                >
                    Indefinido
                </p>
                <p class="c- paragraph-base mb-2 text-neutral-dark" *ngIf="adhesion.fechaVigencia == null">
                    Indefinido
                </p>
            </div>
        </div>

        <div class="mb-2 font-bold">{{ rpts.length }} RPTs</div>
        <div class="mb-12">
            <desy-table-advanced [classes]="'min-w-full'" [idPrefix]="'table-with-all'">
                <desy-table-advanced-header>
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Nombre</desy-content></desy-table-advanced-header-cell
                    >
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Cargo</desy-content></desy-table-advanced-header-cell
                    >
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Organismo</desy-content></desy-table-advanced-header-cell
                    >
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Email</desy-content></desy-table-advanced-header-cell
                    >
                </desy-table-advanced-header>

                <desy-table-advanced-row *ngFor="let rpt of rpts">
                    <desy-table-advanced-row-cell>{{ rpt.nombre }}</desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>{{ rpt.cargo }}</desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>{{ rpt.organismo }}</desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>{{ rpt.email }}</desy-table-advanced-row-cell>
                </desy-table-advanced-row>
            </desy-table-advanced>
        </div>
        <div class="mb-36" *ngIf="adhesion.observaciones != undefined && adhesion.observaciones.length > 0">
            <h3 class="c-h3">Observaciones</h3>
            <p>{{ adhesion.observaciones }}</p>
        </div>
        <div class="mb-36" *ngIf="adhesion.observaciones == undefined">
            <h3 class="c-h3">Observaciones</h3>
            <p class="c-paragraph-base text-neutral-dark italic">No se han dejado observaciones para esta adhesión</p>
        </div>
    </div>
    <desy-spinner *ngIf="buscando" classes="my-10 mb-12 p-base"></desy-spinner>
</main>
<desy-dialog
    *ngIf="activeFinalizar"
    [(active)]="activeFinalizar"
    [id]="'dialog'"
    [caller]="finalizar"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="true"
    [classes]="'margin-auto'"
>
    <ng-template #finalizar>
        <desy-modal
            [isDismissible]="true"
            (closeModal)="closeDialog()"
            classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
        >
            <desy-title>
                Finalizar uso de la adhesion
                <div class="text-base font-normal mt-base">
                    Los RPTs vinculados ya no podrán acceder a la información proporcionada en esta adhesion. ¿Estás
                    seguro?
                </div>
            </desy-title>
            <desy-modal-button-primary
                [classes]="'c-button--secondary c-button--sm button-cancelar'"
                (clickEvent)="closeDialog()"
                >Cancelar</desy-modal-button-primary
            >
            <desy-modal-button-secondary
                [classes]="'c-button--primary c-button--sm button-finalizar'"
                (clickEvent)="finalizarAdhesion()"
                >Sí, finalizar</desy-modal-button-secondary
            >
        </desy-modal>
    </ng-template>
</desy-dialog>
<desy-dialog
    *ngIf="openModal"
    [(active)]="openModal"
    [id]="'dialog'"
    [caller]="modificarRPT"
    focusOnClose="dialog-btn"
    [dismissOnBackdrop]="false"
    [classes]="'bg-white min-w-full h-full  margin-auto'"
>
    <ng-template #modificarRPT>
        <app-modificar-rpt
            [adhesion]="adhesion"
            (datosAlPadreEvent)="responseDataSon($event)"
            (cerrarModalEvent)="closeModalSon($event)"
        ></app-modificar-rpt>
    </ng-template>
</desy-dialog>

import { Component } from '@angular/core';
import {Router } from '@angular/router';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';

@Component({
    selector: 'app-detalles-protocolo',
    templateUrl: './detalles-protocolo.component.html',
    styleUrls: ['./detalles-protocolo.component.css'],
})
export class DetallesProtocoloComponent {
    constructor(
        private router: Router,
        private nuevaAdhesionService: NuevaAdhesionService,
    ) {}
    goToServicio() {
        this.router.navigate(['/nueva-adhesion/seleccion-servicio']);
    }

    goToNuevaAdhesion() {
        this.nuevaAdhesionService.setProtocoloActual(null),
        this.router.navigate(['/nueva-adhesion/protocolos']);
    }
}

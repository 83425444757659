<div *ngIf="!waiting">
    <h2 class="c-h2">{{ protocolo.nombre | filtroOrganismo }}</h2>
    <h3 class="c-h3"><strong>Resumen</strong></h3>
    <p class="c-paragraph-base text-neutral-dark">
        {{ protocolo.descripcion }}
    </p>
    <div class="pb-sm"></div>
    <div>
        <div class="mx-auto">
            <div class="datos-disponibles">
                <h3 class="c-h3 "><strong>Datos disponibles para la consulta</strong> </h3>
                <div class="boton-mostrar">
                    <p class="cursor-pointer px-4 py-2 underline" (click)="toggleCollapse()" *ngIf="isCollapsed">
                        Mostrar todo
                    </p>
                    <p class="cursor-pointer px-4 py-2 underline" (click)="toggleCollapse()" *ngIf="!isCollapsed">
                        Ocultar todo
                    </p>
                </div>
            </div>

            <div [ngClass]="{'hidden': isCollapsed}" class="px-4 py-2">
                <div class="grid">
                    <ul>
                        <li *ngFor="let dato of datosProtocolo">{{ dato }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="pb-sm"></div>
    <div class="pb-sm"></div>
    <div style="margin-bottom: 2rem; margin-top: 1rem;" *ngIf="protocolo.organismoExtendido">
        <h3 class="c-h3"><strong>Organismo responsable</strong></h3>
        <p class="c-paragraph-base text-neutral-dark">
            {{ protocolo.organismoExtendido | filtroOrganismo }}.
        </p>
    </div>
    <div style="margin-bottom: 2rem; margin-top: 1rem;" *ngIf="protocolo.nombreDepartamento">
        <h3 class="c-h3"><strong>Departamento</strong></h3>
        <p class="c-paragraph-base text-neutral-dark">
            {{ protocolo.nombreDepartamento | filtroOrganismo }}.
        </p>
    </div>
    <div style="margin-bottom: 2rem; margin-top: 1rem;">
        <h3 class="c-h3"><strong>Condiciones</strong></h3>
        <p class="c-paragraph-base text-neutral-dark" *ngIf="
                protocolo.condiciones != undefined &&
                protocolo.condiciones.length > 0 &&
                protocolo.condiciones != 'Condiciones'
            ">
            No hay condiciones especiales en la adhesión.
        </p>
        <p class="c-paragraph-base text-neutral-dark italic"
            *ngIf="protocolo.condiciones == undefined || protocolo.condiciones === 'Condiciones'">
            No hay condiciones especiales en la adhesión.
        </p>
    </div>
</div>
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ProtocolosService } from '../../../services/protocolos/protocolos.service';
import { Query } from '../../../interfaces/query';
import { Protocolo } from '../../../interfaces/protocolo';
import { AlertService, DialogOptions, DialogService } from 'desy-angular';
import { OrganizacionService } from '../../../services/organizaciones/organizaciones.service';
import { FiltroOrganismosComponent } from '../../../shared/filtro-organismos/filtro-organismos.component';
import { Router } from '@angular/router';
import { Organizacion } from '../../../interfaces/organizacion';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';
import { RptService } from 'src/app/services/rpt/rpt.service';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';
import { Organismos } from 'src/app/interfaces/organismos';
import { query } from '@angular/animations';
@Component({
    selector: 'app-paso1',
    templateUrl: './paso1.component.html',
    styleUrls: ['./paso1.component.css'],
})
export class Paso1Component implements OnInit {
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    @ViewChild('paginator') el: ElementRef | undefined;
    rows: number = 10;
    organismos: Organismos[] = [];
    protocolos: Protocolo[] = [];
    protocolosPaginados: Protocolo[] = [];
    protocoloQuery: Query = {
        q: '',
        fq: {
            OBJ_SUB_TYPE: ['DSA'],
            STATE: [EstadosAdhesionEnum.APROBADA],
            ZONE: [environment.zone],
        },
        start: 0,
        orderBy: 'OBJ_NAME',
        sortingType: 'ASC',
    };
    selectedPageDefault = 1;
    organizaciones: Organizacion[] = [];
    organizacionesSeleccionadas: string[] = [];
    organizacionQuery: Query = {
        q: '',
        fq: {
            OBJ_SUB_TYPE: ['DSA'],
            STATE: [EstadosAdhesionEnum.APROBADA],
            // ZONE: [environment.zone],
        },
        start: 0,
        orderBy: 'OBJ_NAME',
    };
    numResultados: number = 0;
    buscando: boolean = false;
    organizacionesBusqueda: string[] = [];
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;

    resultadoDelMatch = [
        {
            codigo_organismo: '',
            nombre_organismo: '',
            nombre_departamento: '',
        },
    ];

    dataProtocolos: any = null;
    Params: string = '';

    resultados: any[] = [];
    dataFilterWithEnviroment: any;

    constructor(
        private alertService: AlertService,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: DialogService,
        private nuevaAdhesionService: NuevaAdhesionService,
        private organizacionService: OrganizacionService,
        private protocolosService: ProtocolosService,
        private router: Router,
        private rptService: RptService
    ) {}

    async ngOnInit() {
        this.buscando = true;
        //Precarga la informacion de los rpts de aragon
        this.rptService.getRPTsDynamoAragon();

        await this.onGetProtocolos(this.protocoloQuery);
        await this.onGetOrganizaciones(this.organizacionQuery);
        this.buscando = false;
    }

    async onButtonClick(event: any) {
        if (event.target?.id === 'selector-next') {
            await this.onNext();
        } else if (event.target?.id === 'selector-previous') {
            await this.onPrevious();
        } else if (event.target?.id.includes('selector')) {
            if (event.target?.id === 'selector') {
                this.onPageSelect(1);
            } else {
                let paginaInicial = +event.target?.id?.substring(9);
                await this.onPageSelect(paginaInicial + 1);
            }
        }
    }

    async onNext() {
        this.changePage();
    }

    async onPrevious() {
        this.changePage();
    }

    async onPageSelect(numPagina: number) {
        this.selectedPageDefault = numPagina;
        this.changePage();
    }

    async onGetProtocolos(query: Query) {
        try {
            this.buscando = true;
            this.protocolos = [];
            this.protocolosPaginados = [];

            // Obtener datos iniciales si es necesario
            if (this.dataProtocolos === null || this.dataProtocolos?.results?.BASIC.length <= 1) {
                this.dataProtocolos = await this.protocolosService.getProtocolos(query);
            }

            // Manejar errores al cargar los protocolos
            if (!this.dataProtocolos) {
                this.alertService.openAlert(
                    {
                        title: 'Se ha producido un error al cargar la página',
                        description: { text: 'Por favor, inténtelo de nuevo más tarde' },
                        type: 'alert',
                    },
                    {
                        id: 'alert',
                        place: this.alertPlace,
                        role: 'alert',
                        ariaLive: 'assertLive',
                        classes: 'mt-5',
                    }
                );
                return;
            }

            // Filtrar por organizaciones si corresponde
            const dataJson = JSON.parse(localStorage.getItem('organismos')!);
            const matchedOrganisms = this.organizacionesBusqueda.length
                ? dataJson.filter((org: any) => this.organizacionesBusqueda.includes(org.nombre_departamento))
                : [];
            this.resultadoDelMatch = matchedOrganisms;
            this.organismos = dataJson;

            // Filtrar códigos por entorno
            const validCodes = this.organismos.map((org) => org.codigo_organismo);
            const filteredProtocols = this.dataProtocolos.results.BASIC.filter((proto: any) => {
                const units = proto.solrDocument.ORGANIZATIONAL_UNIT;
                return units?.some((unit: any) => validCodes.includes(unit));
            });

            // Filtrar protocolos por coincidencias en organismos
            const matchedCodes = new Set(this.resultadoDelMatch.map((match) => match.codigo_organismo));
            const finalProtocols = (
                this.resultadoDelMatch.length > 1
                    ? filteredProtocols.filter((proto: any) =>
                          matchedCodes.has(proto.solrDocument.ORGANIZATIONAL_UNIT[0])
                      )
                    : filteredProtocols
            ).map((proto: any) => this.mapToProtocolo(proto));

            this.protocolos = finalProtocols;
            this.numResultados = this.protocolos.length;

            // Paginación
            const start = (this.selectedPageDefault - 1) * this.rows;
            this.protocolosPaginados = this.protocolos.slice(start, start + this.rows);
        } catch (error) {
            console.error('Error al cargar protocolos:', error);
            this.alertService.openAlert(
                {
                    title: 'Se ha producido un error',
                    description: { text: 'Por favor, inténtelo de nuevo más tarde.' },
                    type: 'alert',
                },
                {
                    id: 'alert',
                    place: this.alertPlace,
                    role: 'alert',
                    ariaLive: 'assertLive',
                    classes: 'mt-5',
                }
            );
        } finally {
            this.buscando = false;
        }
    }

    private mapToProtocolo(proto: any): Protocolo {
        const organismo = this.organismos.find(
            (org) => org.codigo_organismo === proto.solrDocument.ORGANIZATIONAL_UNIT[0]
        );

        const descripcion = proto.solrDocument.DESCRIPTION.endsWith('.')
            ? proto.solrDocument.DESCRIPTION
            : proto.solrDocument.DESCRIPTION + '.';

        const organismoExtendido =
            organismo?.nombre_organismo === 'Dpto_Ciudadania'
                ? ' Departamento de Ciudadania y Derechos Sociales'
                : organismo?.nombre_organismo || '';

        const organismoMaps = {
            nombre: proto.solrDocument.OBJ_NAME,
            nombreOrganismo: organismo?.nombre_organismo || '',
            nombreDepartamento: organismo?.nombre_departamento || '',
            descripcion,
            id: proto.solrDocument?.ID_OBJ,
            ari: proto.solrDocument.ARI,
            condiciones: proto.solrDocument.CONDICIONES ?? null,
            PI: proto.solrDocument.PI,
            state: proto.solrDocument.STATE,
            organismoExtendido,
        };
        return organismoMaps;
    }
    normalizarCadena(cadena: any) {
        // Convertir a minúsculas y eliminar tildes
        return cadena
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
    }

    async filterProtocolos(params: string, currentPage: number = 1) {
        this.buscando = true; // Inicia el estado de búsqueda
        this.dataProtocolos = []; // Limpia la lista de protocolos antes de realizar la consulta
        this.protocolosPaginados = [];
        this.numResultados = 0; // Limpia los resultados anteriores

        // Realiza la solicitud al servicio para obtener los protocolos
        if (params !== '') {
            const originalParams = params;
            this.protocoloQuery.q = '*' + params + '*';
            this.dataProtocolos = await this.protocolosService.getProtocolos(this.protocoloQuery);
            this.protocoloQuery.q = originalParams;
        } else {
            this.dataProtocolos = await this.protocolosService.getProtocolos(this.protocoloQuery);
        }

        // Filtrar protocolos basados en el entorno
        this.dataProtocolos = this.dataProtocolos.results.BASIC;

        // Mapear los protocolos coincidentes
        const protocolosCoincidentes = this.dataProtocolos.map((proto: any) => this.mapToProtocolo(proto));
        // const protocolosCoincidentes = this.dataProtocolos.map((proto: any) => this.mapToProtocolo(proto));

        // Configurar paginación
        const itemsPerPage = 10; // Elementos por página
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        this.protocolosPaginados = protocolosCoincidentes.slice(startIndex, endIndex);
        this.numResultados = protocolosCoincidentes.length; // Total de resultados

        this.buscando = false;
    }
    async onGetOrganizaciones(organizacionQuery: Query) {
        this.buscando = true;
        this.organizaciones = [];
        this.organizacionesSeleccionadas = [];
        this.organizaciones = await this.organizacionService.getOrganizaciones(organizacionQuery);
        this.buscando = false;
    }

    openDialog(): void {
        const dialogOptions: DialogOptions = {
            id: 'dialog',
            dismissOnBackdrop: true,
            focusOnClose: 'dialog-btn',
        };
        this.dialogService.openDialog(FiltroOrganismosComponent, dialogOptions).then(async (result) => {
            this.organizacionService.postDialog(result.dialog);
            this.dialogService.onCloseDialog(result.dialog).then(async () => {
                if (this.organizacionService.activaBusqueda) {
                    this.organizacionesBusqueda = this.organizacionService.getNombresQueryOrganizaciones();
                    this.buscando = true;
                    await this.onGetProtocolos(this.protocoloQuery);

                    this.buscando = false;
                }
            });
        });
    }

    async onEliminaFiltros() {
        this.buscando = true;
        this.organizacionesBusqueda = [];
        this.organizaciones.map((x) => (x.check = false));
        this.resultadoDelMatch = [];
        this.dataProtocolos = null;
        this.protocoloQuery.q = '';
        await this.onGetProtocolos(this.protocoloQuery);
        this.buscando = false;
    }

    async goToServicio(protocolo: Protocolo) {
        const selectedProtocolo = await this.protocolosService.getDatosProtocolo(protocolo.id);

        const mappedProtocolo = this.protocolosService.mapProtocolo(
            selectedProtocolo,
            protocolo.id!,
            protocolo.ari,
            protocolo.state!
        );
        this.nuevaAdhesionService.setProtocoloActual(mappedProtocolo);
        this.router.navigate(['nueva-adhesion/seleccion-servicio']);
    }

    async goToDetalle(protocolo: Protocolo) {
        const selectedProtocolo = await this.protocolosService.getDatosProtocolo(protocolo.id);
        const mappedProtocolo = this.protocolosService.mapProtocolo(
            selectedProtocolo,
            protocolo.id!,
            protocolo.ari,
            protocolo.state!
        );
        this.nuevaAdhesionService.setProtocoloActual(mappedProtocolo);
        this.router.navigate(['/nueva-adhesion/protocolos', protocolo.id]);
    }

    async onSeleccionarPagina() {
        this.changePage();
    }
    async changePage() {
        this.buscando = true;
        this.protocolosPaginados = this.protocolos.slice(
            (this.selectedPageDefault - 1) * this.rows,
            (this.selectedPageDefault - 1) * this.rows + this.rows
        );
        this.changeDetectorRef.detectChanges();
        this.buscando = false;
        window.scroll(0, 0);
    }
}

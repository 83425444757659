import { Component, OnInit } from '@angular/core';
import { DialogService } from 'desy-angular';
import { Query } from 'src/app/interfaces/query';
import { OrganizacionService } from 'src/app/services/organizaciones/organizaciones.service';
import { Organizacion } from '../../interfaces/organizacion';
import { SubOrganizacion } from '../../interfaces/suborganizacion';

@Component({
    selector: 'app-filtro-organismos',
    templateUrl: './filtro-organismos.component.html',
    styleUrls: ['./filtro-organismos.component.css'],
})
export class FiltroOrganismosComponent implements OnInit {
    organizaciones: Organizacion[] = [];
    organizacionesSeleccionadas: string[] = [];
    organizacionQuery: Query = {
        q: '',
        start: 0,
        orderBy: 'OBJ_NAME',
    };
    buscando: boolean = false;
    searchMatchValues: string[] = [];

    constructor(private organizacionService: OrganizacionService, private dialogService: DialogService) {}

    async ngOnInit() {
        this.buscando = true;
        this.organizaciones = await this.organizacionService.getOrganizacionesActuales();
        this.buscando = false;
    }

    onOrganizacionCheck(organizacion: Organizacion) {
        organizacion.check = !organizacion.check;
        }
        
    onPutOrganizaciones() {
        this.organizacionService.postNombresQueryOrganizaciones(this.organizacionesSeleccionadas);
        this.organizacionService.setOrganizacionesActuales(this.organizaciones);
        this.dialogService.closeDialog(this.organizacionService.getDialog());
    }

    onCerrar() {
        this.dialogService.closeDialog(this.organizacionService.getDialog());
    }
}

import { Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    title: string;
    subtitle: string;
    @Input() idAdhesion: string;
    @Output() datosAlPadreEvent = new EventEmitter<boolean>();
    @Output() cerrarModalEvent = new EventEmitter<boolean>();

    constructor(private router: Router) {}
    ngOnInit() {
        this.title = 'Cancelar';
        this.subtitle = '¿Esta seguro que desea salir?';
    }
    cancel() {
        const modal = false;
        this.datosAlPadreEvent.emit(modal);
    }

    closeModal() {
        const modal = false;
        this.cerrarModalEvent.emit(modal);
    }
}

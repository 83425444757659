<div class="container">
    <desy-modal
        [isDismissible]="true"
        (closeModal)="closeModal()"
        classes="container__modal absolute max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
    >
        <desy-title>
            {{ title }}
            <div class="text-base font-normal mt-base">
                {{ subtitle }}
            </div>
        </desy-title>
        <desy-modal-button-primary
            [classes]="'c-button--secondary c-button--sm button-cancelar'"
            (clickEvent)="cancel()"
            >Cancelar</desy-modal-button-primary
        >
        <desy-modal-button-secondary
            [classes]="'c-button--primary c-button--sm button-finalizar'"
            (clickEvent)="closeModal()"
            >Aceptar</desy-modal-button-secondary
        >
    </desy-modal>
</div>

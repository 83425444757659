import { HistoricosService } from './../../services/historicos/historicos.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { ChartModel } from './model/chart.model';
import { TableModel } from './model/table.model';
import { FiltrosModel } from './model/filtros.model';
import transformarDatos from './model/modal.chart.model';
import { ModalTableComponent } from './modal-table/modal-table.component';
// import { FormControl, FormGroup } from '@angular/forms';

Chart.register(...registerables);
@Component({
    selector: 'app-historico',
    templateUrl: './historico.component.html',
    styleUrls: ['./historico.component.scss'],
})
export class HistoricosComponent implements OnInit {
    // #start-area-const
    @ViewChild('canvas') canvasRef: ElementRef;
    ctx: CanvasRenderingContext2D;
    myChart: Chart;
    solicitudesRealizadas: any;
    opcionesSelector = [{ periodo: 'SEMANAL' }, { periodo: 'DIARIO' }, { periodo: 'MENSUAL' }, { periodo: 'ANUAL' }];
    vistaSeleccionada: string = 'DIARIO';
    buscando: boolean = true;
    datos: any;
    // Obtener la URL actual
    url = window.location.href;
    // Dividir la URL en partes usando '/' como separador
    partesUrl = this.url.split('/');
    // Obtener la última parte de la URL, que debería ser "cedidos"
    peticion = this.partesUrl[this.partesUrl.length - 1];
    //paginacion
    currentPage = 0;
    page: number;
    consultas: any[] = [];
    consultasPaginacion: any[];
    selectedPageDefault: 1;
    totalPaginas: number;

    valoresFiltros: any[] = [null, null, null, null, null];
    isDisabled: boolean = false;

    fechaInicio: string = '';
    fechaFin: string = '';

    tab: string = this.peticion;
    params = {
        params: {
            peticion: this.tab ?? this.peticion, //boton
            fecha_inicio:
                this.fechaInicio !== ''
                    ? this.fechaInicio
                    : `${new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear()}-${(
                          '0' +
                          (new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() + 1)
                      ).slice(-2)}-${('0' + new Date(new Date().setMonth(new Date().getMonth() - 1)).getDate()).slice(
                          -2
                      )}`,
            fecha_fin:
                this.fechaFin !== ''
                    ? this.fechaFin
                    : `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(
                          new Date().getDate()
                      ).padStart(2, '0')}`,
            ORGCODE: 'ORG17487' ?? JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
            JOBCODE:
                'JOB21249' ?? JSON.parse(localStorage.getItem('usuario')!).jobCode.length == 12
                    ? JSON.parse(localStorage.getItem('usuario')!).jobCode.slice(4)
                    : JSON.parse(localStorage.getItem('usuario')!).jobCode, //getTOKEN
            num_pagina: this.currentPage.toString(),
            protocolos: this.valoresFiltros[0],
            organismos: this.valoresFiltros[1],
            procedimientos: this.valoresFiltros[2],
            aplicaciones: this.valoresFiltros[3],
            jobcode_solicitante: this.valoresFiltros[4],
        },
    };
    //filtros
    filters = {
        params: {
            ORGCODE: 'ORG17487' ?? JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
            JOBCODE:
                this.peticion === 'adheridos'
                    ? JSON.parse(localStorage.getItem('usuario')!).jobCode.length == 12
                        ? JSON.parse(localStorage.getItem('usuario')!).jobCode.slice(4)
                        : JSON.parse(localStorage.getItem('usuario')!).jobCode
                    : '',
        },
    };
    nombreFiltros = ['protocolos', 'organismo', 'procedimiento', 'aplicacion', 'jobcode'];
    filtros: any;
    pruebas: any;
    filtrosMap: any[];
    filterParamsNull: any;
    itemsFechas = [
        { labelText: 'Día', name: 'day', classes: 'w-14', maxlength: 2 },
        { labelText: 'Mes', name: 'month', classes: 'w-14', maxlength: 2 },
        { labelText: 'Año', name: 'year', classes: 'w-20', maxlength: 4 },
    ];
    buscandoPagina: boolean = false;
    form = new FormGroup({
        fechaDesde: new FormGroup({
            day: new FormControl(),
            month: new FormControl(),
            year: new FormControl(),
        }),
        fechaHasta: new FormGroup({
            day: new FormControl(),
            month: new FormControl(),
            year: new FormControl(),
        }),
    });
    errores: boolean;
    totalesSumados: number = 0;
    buscandoFiltros: boolean;
    show: boolean = false;
    modalData: any;
    fechaNoValida: string = '';
    anioNoValido: string = '';
    mesNoValido: string = '';
    diaNoValido: string = '';
    totalRegistros: any;
    tooltipData: any;
    tooltipPosition = { top: '0px', left: '0px' };
    aplication: unknown;
    modalChart: any;
    isTableVisible: boolean;
    isModalOpen: boolean;

    constructor(private historicosService: HistoricosService) {}
    @ViewChild(ModalTableComponent) modalTableComponent!: ModalTableComponent;

    async openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    async ngOnInit() {
        this.buscandoFiltros = true;
        this.filtros = await this.historicosService?.getFilters(this.filters);
        this.filtrosMap = this.nombreFiltros.map((x) => {
            const valor = this.filtros?.data[this.peticion][x];
            const nombre = x.toUpperCase() === 'JOBCODE' ? 'RPTs' : x.toUpperCase();
            return new FiltrosModel(nombre, valor);
        });
        await this.getDatos();
        this.buscandoFiltros = false;
    }
    async getDatos() {
        this.buscando = true;
        this.aplication = await this.historicosService.getAplication();
        console.log('[APLICACIONES]: ', this.aplication);
        const filterParamsNull = this.eliminarValoresNulos(this.params.params);
        const response: any = await this.historicosService?.getHistoricosData(filterParamsNull);
        this.datos = response;
        this.currentPage === 0 ? this.currentPage++ : null;
        const filterDate =
            response.data?.agg_protocolos?.filter((x: any) => x.tipo_vista === this.vistaSeleccionada) || [];
        const sortedFilterDate = filterDate.sort((a: any, b: any) => a.semana_dia_mes - b.semana_dia_mes);
        console.log('[sortedFilterDate]: ', sortedFilterDate);
        this.modalChart = transformarDatos(sortedFilterDate, this.aplication);
        console.log('[MOODELCHART]: ', this.modalChart);
        const chartConfig = new ChartModel(sortedFilterDate, this.aplication);
        this.ctx = this.canvasRef?.nativeElement.getContext('2d');
        this.myChart = new Chart(this.ctx, chartConfig);

        this.solicitudesRealizadas = new TableModel(response.data?.historico_consultas || []);
        const totales = filterDate.map((x: any) => x.total);
        this.totalesSumados = totales.reduce((suma: number, num: number) => suma + num, 0);
        this.totalPaginas = response.data?.total_paginas_historico || 0;
        this.totalRegistros = response.data?.total_registros_historico || 0;
        this.buscando = false;
    }
    onChangeSelector(vista: string) {
        // this.vistaSeleccionada = event.target.value;
        const filterDate: any = this.datos?.data.agg_protocolos?.filter((x: any) => x.tipo_vista === vista);
        const sortedFilterDate = filterDate?.sort((a: any, b: any) => a.semana_dia_mes - b.semana_dia_mes);
        const chartConfig: any = new ChartModel(sortedFilterDate, this.aplication);
        this.ctx = this.canvasRef?.nativeElement.getContext('2d');
        this.myChart.destroy();
        this.myChart = new Chart(this.ctx, chartConfig);
        this.modalChart = transformarDatos(sortedFilterDate);
        this.solicitudesRealizadas = new TableModel(this.datos?.data.historico_consultas);
    }
    seleccionarVista(vista: string) {
        this.vistaSeleccionada = vista;
        this.onChangeSelector(this.vistaSeleccionada);
    }
    toggleTable() {
        this.isTableVisible = !this.isTableVisible; // Cambia el estado de visibilidad
    }
    //#inicio nueva paginacion
    async getPaginacion() {
        this.params = {
            params: {
                peticion: this.peticion, //boton
                fecha_inicio: `${new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear()}-${(
                    '0' +
                    (new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() + 1)
                ).slice(-2)}-${('0' + new Date(new Date().setMonth(new Date().getMonth() - 1)).getDate()).slice(-2)}`,
                fecha_fin: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(
                    new Date().getDate()
                ).padStart(2, '0')}`,
                ORGCODE: 'ORG17487' ?? JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
                // "ORGCODE": JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
                JOBCODE:
                    JSON.parse(localStorage.getItem('usuario')!).jobCode.length == 12
                        ? JSON.parse(localStorage.getItem('usuario')!).jobCode.slice(4)
                        : JSON.parse(localStorage.getItem('usuario')!).jobCode, //getTOKEN
                num_pagina: this.currentPage.toString(),
                protocolos: this.valoresFiltros[0],
                organismos: this.valoresFiltros[1],
                procedimientos: this.valoresFiltros[2],
                aplicaciones: this.valoresFiltros[3],
                jobcode_solicitante: this.valoresFiltros[4],
            },
        };
        this.solicitudesRealizadas = null;
        this.filterParamsNull = this.eliminarValoresNulos(this.params.params);
        this.buscandoPagina = true;
        const response: any = await this.historicosService?.getHistoricosData(this.filterParamsNull);
        this.buscandoPagina = false;
        this.solicitudesRealizadas = new TableModel(response?.data.historico_consultas);
    }

    async onButtonClick(event: any) {
        if (event.target?.id === 'selector-next') {
            await this.onNext();
        } else if (event.target?.id === 'selector-previous') {
            await this.onPrevious();
        } else if (event.target?.id.includes('selector')) {
            if (event.target?.id === 'selector') {
                this.onPageSelect(1);
            } else {
                let paginaInicial = +event.target?.id?.substring(9);
                await this.onPageSelect(paginaInicial + 1);
            }
        }
    }

    async onNext() {
        this.changePage();
    }

    async onPrevious() {
        this.changePage();
    }

    async onPageSelect(numPagina: number) {
        this.currentPage = numPagina;
        this.changePage();
    }
    async changePage() {
        this.getPaginacion();
        window.scroll(0, 0);
    }
    //#fin nueva paginacion

    onChangeSelectorFilter(_valoresFiltros: any, _fechaInicio: any, _fechaFin: any) {
        this.params = {
            params: {
                peticion: this.peticion, //boton
                fecha_inicio:
                    this.fechaInicio !== ''
                        ? this.fechaInicio
                        : `${new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear()}-${(
                              '0' +
                              (new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() + 1)
                          ).slice(-2)}-${(
                              '0' + new Date(new Date().setMonth(new Date().getMonth() - 1)).getDate()
                          ).slice(-2)}`,
                fecha_fin:
                    this.fechaFin !== ''
                        ? this.fechaFin
                        : `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(
                              new Date().getDate()
                          ).padStart(2, '0')}`,
                // "ORGCODE": JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
                ORGCODE: 'ORG17487' ?? JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
                JOBCODE:
                    JSON.parse(localStorage.getItem('usuario')!).jobCode.length == 12
                        ? JSON.parse(localStorage.getItem('usuario')!).jobCode.slice(4)
                        : JSON.parse(localStorage.getItem('usuario')!).jobCode, //getTOKEN
                num_pagina: '0',
                protocolos: this.valoresFiltros[0],
                organismos: this.valoresFiltros[1],
                procedimientos: this.valoresFiltros[2],
                aplicaciones: this.valoresFiltros[3],
                jobcode_solicitante: this.valoresFiltros[4],
            },
        };
        this.isDisabled = this.valoresFiltros.some((valor) => valor !== null);

        this.Buscar();
    }

    eliminarValoresNulos(objeto: any): any {
        for (const clave in objeto) {
            if (objeto.hasOwnProperty(clave) && objeto[clave] === null) {
                delete objeto[clave];
            }
        }
        return { params: objeto };
    }
    async Buscar() {
        // this.buscando = true
        this.myChart.destroy();
        this.solicitudesRealizadas = null;
        this.getDatos();
        this.isDisabled = this.valoresFiltros.some((valor) => valor !== null);
        this.currentPage = 0;
        // this.buscando = false;
    }
    async limpiarFiltros() {
        // this.buscando = true
        this.params = {
            params: {
                peticion: this.peticion, //boton
                fecha_inicio: `${new Date(new Date().setMonth(new Date().getMonth() - 1)).getFullYear()}-${(
                    '0' +
                    (new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() + 1)
                ).slice(-2)}-${('0' + new Date(new Date().setMonth(new Date().getMonth() - 1)).getDate()).slice(-2)}`,
                fecha_fin: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(
                    new Date().getDate()
                ).padStart(2, '0')}`,
                ORGCODE: 'ORG17487' ?? JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
                // "ORGCODE": JSON.parse(localStorage.getItem('usuario')!).orgCode, //getTOKEN
                JOBCODE:
                    JSON.parse(localStorage.getItem('usuario')!).jobCode.length == 12
                        ? JSON.parse(localStorage.getItem('usuario')!).jobCode.slice(4)
                        : JSON.parse(localStorage.getItem('usuario')!).jobCode, //getTOKEN
                num_pagina: '0',
                protocolos: null,
                organismos: null,
                procedimientos: null,
                aplicaciones: null,
                jobcode_solicitante: null,
            },
        };
        this.filtrosMap.forEach((item: any) => {
            if (item.valor) {
                item.valor.forEach((valor: any) => {
                    if (valor.check !== undefined) {
                        valor.check = false;
                    }
                });
            }
        });
        this.valoresFiltros = [null, null, null, null, null];
        this.fechaFin = '';
        this.fechaInicio = '';
        this.myChart.destroy();
        this.solicitudesRealizadas = null;
        this.getDatos();
        this.isDisabled = this.valoresFiltros.some((valor) => valor !== null);
        // this.buscando = false
    }

    concatenarSeleccion(filtroNombre: any): void {
        // Obtener todos los elementos checkbox seleccionados
        const checkboxes = document.querySelectorAll('desy-tree-item input[type="checkbox"]:checked');
        // Array para almacenar los valores seleccionados
        const seleccionados: string[] = [];
        // Iterar sobre los checkboxes seleccionados y agregar sus valores al array
        checkboxes.forEach((checkbox: any) => {
            seleccionados.push(checkbox.value);
        });
        // Concatenar los valores separados por coma
        const resultado = seleccionados.join(',');

        switch (filtroNombre) {
            case 'Protocolos':
                this.valoresFiltros[0] = resultado;
                break;
            case 'Organismo':
                this.valoresFiltros[1] = resultado;
                break;
            case 'Procedimiento':
                this.valoresFiltros[2] = resultado;
                break;
            case 'Aplicacion':
                this.valoresFiltros[3] = resultado;
                break;
            case 'Rpts':
                this.valoresFiltros[4] = resultado;
                break;
            default:
                // Manejar caso predeterminado si es necesario
                break;
        }
        this.onChangeSelectorFilter(this.valoresFiltros, this.fechaInicio, this.fechaFin);
    }
    onCancelarSeleccion(filtroNombre: any, filtroValor: any) {
        filtroValor.map((x: any) => (x.check = false));
    }

    onFiltrosFecha(tipo: any) {
        this.anioNoValido = '';
        this.fechaNoValida = '';
        this.mesNoValido = '';
        this.diaNoValido = '';
        const fechaDesdeValue = this.form.controls['fechaDesde'].value;
        const fechaHastaValue = this.form.controls['fechaHasta'].value;

        const diaDesde = fechaDesdeValue.day;
        const mesDesde = fechaDesdeValue.month;
        const anyoDesde = fechaDesdeValue.year;

        const diaHasta = fechaHastaValue.day;
        const mesHasta = fechaHastaValue.month;
        const anyoHasta = fechaHastaValue.year;

        // Comprueba que los años tengan cuatro dígitos
        if (anyoDesde.toString().length !== 4 || anyoHasta.toString().length !== 4) {
            this.anioNoValido = 'Los años deben tener cuatro dígitos y deben ser validos.';
            return; // O maneja el error de otra manera
        }

        if (diaDesde.toString().length !== 2 || diaHasta.toString().length !== 2) {
            this.diaNoValido = 'Los dias deben ser dos numeros. ej: 03 .';
            return; // O maneja el error de otra manera
        }

        if (mesDesde < 1 || mesDesde > 12 || mesHasta < 1 || mesHasta > 12) {
            this.mesNoValido = 'Los meses deben estar en el rango de 1 a 12.';
            return;
        }

        // Crea las fechas y comprueba si el rango es correcto
        const fechaInicio = new Date(anyoDesde, mesDesde - 1, diaDesde);
        const fechaFin = new Date(anyoHasta, mesHasta - 1, diaHasta);

        fechaInicio.setHours(0, 0, 0, 0);
        fechaFin.setHours(0, 0, 0, 0);

        const fechaInicioStr = fechaInicio.toLocaleDateString();
        const fechaFinStr = fechaFin.toLocaleDateString();

        if (fechaInicio > fechaFin) {
            this.fechaNoValida = 'La fecha de inicio debe ser menor que la fecha de fin.';
            return; // O maneja el error de otra manera
        }

        // Dividir las cadenas en sus partes
        const partesFechaInicio = fechaInicioStr.split('/');
        const partesFechaFin = fechaFinStr.split('/');

        // Obtener las partes individuales de las fechas de inicio y fin
        const diaInicio = partesFechaInicio[0].padStart(2, '0'); // Asegúrate de que el día tenga al menos 2 dígitos
        const mesInicio = partesFechaInicio[1].padStart(2, '0'); // Asegúrate de que el mes tenga al menos 2 dígitos
        const añoInicio = partesFechaInicio[2];

        const diaFin = partesFechaFin[0].padStart(2, '0'); // Asegúrate de que el día tenga al menos 2 dígitos
        const mesFin = partesFechaFin[1].padStart(2, '0'); // Asegúrate de que el mes tenga al menos 2 dígitos
        const añoFin = partesFechaFin[2];

        // Formatear las fechas en el formato deseado ('YYYY-MM-DD')
        this.fechaInicio = `${añoInicio}-${mesInicio}-${diaInicio}`;
        this.fechaFin = `${añoFin}-${mesFin}-${diaFin}`;

        this.onChangeSelectorFilter(this.valoresFiltros, this.fechaInicio, this.fechaFin);
    }

    onCancelarFechas(tipo: any) {
        this.form.controls['fechaDesde'].reset();
        this.form.controls['fechaHasta'].reset();
    }
    openTooltip(event: MouseEvent, data: any) {
        this.tooltipPosition = {
            top: `${event.clientY + 10}px`,
            left: `${event.clientX + 10}px`,
        };
        this.tooltipData = data;
        this.show = true;
    }

    closeTooltip() {
        this.show = false;
    }
    // fin area modal
}

import { Component, OnDestroy } from '@angular/core';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';

@Component({
    selector: 'app-nueva-adhesion',
    templateUrl: './nueva-adhesion.component.html',
    styleUrls: ['./nueva-adhesion.component.css'],
})
export class NuevaAdhesionComponent implements OnDestroy{
    constructor(
      private nuevaAdhesionService: NuevaAdhesionService,
    ) {}

    ngOnDestroy(): void {
      this.nuevaAdhesionService.clear();
    }
    
}

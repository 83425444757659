import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const url = environment.urlHistory;
@Injectable({
    providedIn: 'root',
})
export class HistoricosService {

    constructor(private http: HttpClient) { }

    async getHistoricosData(params: any) {
        return new Promise((resolve) => {
            this.http.post(`${url}/api/audit/v3/protocolos`, params).subscribe((resp: any) => {
                resolve(resp);
            });
        });
    }

    async getFilters(params: any){
        return new Promise((resolve) =>{
            this.http.post(`${url}/api/audit/v3/filtros`, params).subscribe((resp: any)=>{
                resolve(resp)
            });
        });
    }
    async getAplication(){
        return new Promise((resolve)=>{
            this.http.get(`${url}/api/audit/v3/aplicaciones`).subscribe((resp : any) =>{
                resolve(resp)
            })
        })
    }
}
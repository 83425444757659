import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Query } from 'src/app/interfaces/query';
import { environment } from 'src/environments/environment';
import { Rpt } from '../../interfaces/rpt';
import { LoginService } from '../login/login.service';

const url = environment.url;
const urlDynamo = environment.dynamoUrl;
// const urlDynamo = environment.dynamoUrl;

@Injectable({
    providedIn: 'root',
})
export class RptService {
    puestosAragon: Rpt[] = [];
    constructor(private http: HttpClient, private loginService: LoginService) {}

    async getRPTs(query: Query) {
        return new Promise((resolve) => {
            this.http.post(`${url}/gateway/api/v1/indexer/kerno/search`, query).subscribe((resp: any) => {
                resolve(resp.data);
            });
        });
    }

    async getRPTsDynamo(): Promise<Rpt[]> {
        const solicitanteJobCodeSinPrefijo =
            this.loginService.getUsuarioActual().jobCode!.length == 12
                ? this.loginService.getUsuarioActual().jobCode!.slice(4)
                : this.loginService.getUsuarioActual().jobCode;

        const queryDynamoEquipo: any = {
            appId: environment.appId,
            userToken: '',
            appKey: environment.appKey,
            procedimiento: environment.procedimiento,
            tipo_identificador: 'puesto.siu.siu_job_code',
            identificador: solicitanteJobCodeSinPrefijo,
            atributos: ['puesto.es_supervisor.codigo_puesto_siu'],
        };
        return new Promise((resolve) => {
            this.http
                .post(`${urlDynamo}/api/data/v1/obtener_entidad`, queryDynamoEquipo)
                .subscribe(async (resp: any) => {
                    if (!resp.body.atributos.puesto?.es_supervisor_del_puesto) {
                        resolve([]);
                        return;
                    }
                    const data: any = Object.values(resp.body.atributos.puesto.es_supervisor_del_puesto);
                    const jobs = data.map((job: any) => job['puesto.es_supervisor.codigo_puesto_siu']);
                    const allRpts = await this.getRPTsDynamoAragon();
                    const rpts = allRpts.filter((rpt: Rpt) => jobs.includes(rpt.puesto));
                    resolve(rpts);
                });
        });
    }

    async getRPTsDynamoAragon(): Promise<Rpt[]> {
        let exk = null; // Variable para manejar el LastEvaluatedKey
        let allRpts: Rpt[] = []; // Aquí almacenaremos todos los RPTs recuperados

        const queryDynamoAragon: any = {
            appId: environment.appId,
            userToken: '',
            appKey: environment.appKey,
            procedimiento: environment.procedimiento,
            tipo_entidad: 'puesto.siu',
            tipo_identificador: '-',
            identificador: '-',
            atributos: [
                'puesto.codigo_puesto_siu',
                'puesto.descripcion',
                'puesto.codigo_unidad_gestion',
                'puesto.rpt',
                'puesto.nombre',
                'puesto.primer_apellido',
                'puesto.segundo_apellido',
                'puesto.correo_electronico',
            ],
            limit: 2000,
        };

        return new Promise((resolve) => {
            // Verificar si ya tenemos los datos en memoria o en localStorage
            if (this.puestosAragon.length || localStorage.getItem('puestosAragon')) {
                if (!this.puestosAragon.length) {
                    this.puestosAragon = JSON.parse(localStorage.getItem('puestosAragon')!);
                }
                resolve(this.puestosAragon);
                return;
            }

            // Función para realizar las llamadas paginadas
            const fetchData = () => {
                // Realizar la solicitud HTTP
                this.http.post(`${urlDynamo}/api/data/v1/obtener_tipo_entidad`, queryDynamoAragon).subscribe(
                    (resp: any) => {
                        // Verificar si hay datos en la respuesta
                        if (!resp.body.atributos['puesto.siu']) {
                            resolve(allRpts);
                            return;
                        }

                        // Obtener y mapear los datos
                        const data: any = resp.body.atributos['puesto.siu'];
                        const rpts = this.mapRptDynamo(Object.values(data));

                        // Añadir los datos recuperados al array general
                        allRpts = [...allRpts, ...rpts];

                        // Verificar si existe LastEvaluatedKey
                        exk = resp.body.atributos.LastEvaluatedKey;

                        if (exk) {
                            // Si existe LastEvaluatedKey, se agrega al query y se hace otra solicitud
                            queryDynamoAragon.exclusive_start_key = exk;
                            fetchData(); // Realiza otra llamada para la siguiente página de datos
                        } else {
                            // Si no hay más LastEvaluatedKey, guardar los datos en localStorage y resolver la promesa
                            localStorage.setItem('puestosAragon', JSON.stringify(allRpts));
                            resolve(allRpts);
                        }
                    },
                    (error) => {
                        // Manejo de errores (opcional)
                        resolve(allRpts); // En caso de error, se resuelve con los datos recopilados hasta el momento
                    }
                );
            };

            // Realizar la primera llamada
            fetchData();
        });
    }

    async getRPT(rptID: string | undefined) {
        return new Promise((resolve) => {
            this.http.get(`${url}/gateway/api/admin/kerno/entity/RPT/${rptID}`).subscribe((resp: any) => {
                resolve(resp);
            });
        });
    }

    getUserRpt(jobCode: string): Promise<number> {
        const query: any = {
            appId: environment.appId,
            userToken: '',
            appKey: environment.appKey,
            procedimiento: environment.procedimiento,
            tipo_identificador: 'puesto.siu.siu_job_code',
            identificador: jobCode,
            atributos: ['puesto.rpt'],
        };
        return new Promise((resolve) => {
            this.http.post(`${urlDynamo}/api/data/v1/obtener_atributos`, query).subscribe((resp: any) => {
                resolve(resp.body.atributos[0]['puesto.rpt']);
            });
        });
    }

    async getUserByJobCode(jobCode: string): Promise<Rpt | null> {
        if (!this.puestosAragon.length || !localStorage.getItem('puestosAragon')) {
            await this.getRPTsDynamoAragon();
        }
        this.puestosAragon = JSON.parse(localStorage.getItem('puestosAragon')!);
        return this.puestosAragon.find((rpt: Rpt) => environment.entorno + rpt.puesto === jobCode) ?? null;
    }

    mapRpt({ data }: any): Rpt {
        const sections = data.menus.find((menu: any) => menu.name === 'Detalles').sections;
        const sectionFields = sections.find((section: any) => section.name === 'Informacion General').sectionFields;
        const rpt: Rpt = {
            nombre:
                sectionFields.find((sectionField: any) => sectionField.componentFront.name === 'nombre_apellido')
                    ?.selectedValue?.value || 'RPT',
            cargo: sectionFields.find((sectionField: any) => sectionField.componentFront.name === 'name')?.selectedValue
                ?.value,
            email:
                sectionFields.find((sectionField: any) => sectionField.componentFront.name === 'mail')?.selectedValue
                    ?.value || 'mail',
            organismo: data.organizationalUnit || 'Organismo',
            id: sectionFields.find((sectionField: any) => sectionField.componentFront.name === 'code')?.selectedValue
                ?.value,
        };
        return rpt;
    }

    mapRptDynamo(data: any): Rpt[] {
        let rpts: Rpt[] = [];

        for (let row of data) {
            if (row['puesto.codigo_puesto_siu'] != '999' && row['puesto.descripcion'] != 'HAL_PRIV') {
                let rpt: Rpt = {
                    nombre: `${row['puesto.nombre']} ${row['puesto.primer_apellido']} ${row['puesto.segundo_apellido']}`,
                    descripcion: row['puesto.descripcion'],
                    cargo: row['puesto.rpt'] + ' - ' + row['puesto.descripcion'],
                    email: row['puesto.correo_electronico'],
                    organismo: row['puesto.codigo_unidad_gestion'],
                    puesto: row['puesto.codigo_puesto_siu'],
                    rpt: row['puesto.rpt'],
                    id: '-',
                    check: false,
                    ari: '-',
                };
                rpts.push(rpt);
            }
        }
        return rpts;
    }
}

<div class="flex items-center justify-center w-full items-center flex-col light-blue">
    <desy-spinner class="h-72" classes="pt-36" *ngIf="buscando"></desy-spinner>
    <div class="flex w-full items-center flex-col h-18 light-blue" classes="bg-primary-light/20" *ngIf="!buscando">
        <h1 class="c-h1 mb-2 mt-14 text-center title">Te damos la bienvenida a la Plataforma de gobernanza, {{ usuario.nombre | titlecase }}</h1>
        <div class="mt-8 mb-11 opacity-100 flex items-center">
            <!--    <desy-button classes="c-button--secondary mr-4 items-center"
                ><svg
                    class="mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.711"
                    height="17.409"
                    viewBox="0 0 17.711 17.409"
                >
                    <path
                        id="Unión_26"
                        data-name="Unión 26"
                        d="M830.3,3323.7a10,10,0,0,1,.913-4.122l-.8-.549a.964.964,0,0,1,.385-1.745l2.7-.469a.966.966,0,0,1,1.113.771l.506,2.674a.966.966,0,0,1-.422.987.95.95,0,0,1-.526.157.976.976,0,0,1-.546-.168l-.741-.515a7.994,7.994,0,0,0-.6,2.978,6.724,6.724,0,1,0,6.724-6.725.99.99,0,0,1,0-1.98,8.7,8.7,0,1,1-8.7,8.7Zm11.275,2.893a.962.962,0,0,1-.43-.1l-2.572-1.285a.965.965,0,0,1-.533-.863v-4.179a.964.964,0,0,1,1.928,0v3.584l2.039,1.018a.964.964,0,0,1-.43,1.827Z"
                        transform="translate(-830 -3315)"
                        fill="#00607a"
                    /></svg
                >Ver borradores</desy-button
            > -->
            <desy-button classes="c-button--primary items-center pl-0" routerLink="/nueva-adhesion/protocolos"
                ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#FFFFFF"
                    class="mx-2.5"
                >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                </svg>
                Nueva adhesión</desy-button
            >
        </div>
    </div>
</div>

<main id="content" class="container mx-auto px-base my-12 mb-28">
    <h2 class="c-h2">Mis adhesiones enviadas</h2>
    <p>Solicitudes realizadas como consumidor para la adhesión a protocolos de acceso de datos de otros cedentes.</p>
    <br>
    <div class="flex flex-wrap cards">
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'PENDIENTES'"
            [amount]="adhesionesEnviadas.pendientes"
            [subtitle]="'enviadas pendientes'"
            [url]="'/listado-adhesiones/enviadas'"
            [state]="EstadosAdhesionEnum.PENDIENTE"
            [link]="'Ver mis adhesiones pendientes'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'APROBADAS'"
            [amount]="adhesionesEnviadas.aprobadas"
            [subtitle]="'enviadas aprobadas'"
            [url]="'/listado-adhesiones/enviadas'"
            [state]="EstadosAdhesionEnum.APROBADA"
            [link]="'Ver mis adhesiones aprobadas'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'RECHAZADAS'"
            [amount]="adhesionesEnviadas.rechazadas"
            [subtitle]="'enviadas rechazadas'"
            [url]="'/listado-adhesiones/enviadas'"
            [state]="EstadosAdhesionEnum.RECHAZADA"
            [link]="'Ver mis adhesiones rechazadas'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'BLOQUEADAS'"
            [amount]="adhesionesEnviadas.bloqueadas"
            [subtitle]="'enviadas bloqueadas'"
            [url]="'/listado-adhesiones/enviadas'"
            [state]="EstadosAdhesionEnum.BLOQUEADA"
            [link]="'Ver mis adhesiones bloqueadas'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'FINALIZADAS'"
            [amount]="adhesionesEnviadas.finalizadas"
            [subtitle]="'enviadas finalizadas'"
            [url]="'/listado-adhesiones/enviadas'"
            [state]="EstadosAdhesionEnum.FINALIZADA"
            [link]="'Ver mis adhesiones finalizadas'"
        ></app-card>
    </div>
    <div class="flex justify-center mt-9">
        <a
            routerLink="/listado-adhesiones/enviadas"
            class="c-paragraph-base text-primary-base underline font-semibold mb-10"
            >Ver todas mis adhesiones enviadas</a
        >
    </div>
    <h2 class="c-h2 mt-2">Mis adhesiones recibidas</h2>
    <p>Peticiones recibidas desde otra unidad administrativa solicitando la adhesión a protocolos de acceso da datos ofrecidos como cedente.</p>
    <br>
    <div class="flex flex-wrap cards">
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'PENDIENTES'"
            [amount]="adhesionesRecibidas.pendientes"
            [subtitle]="'recibidas pendientes'"
            [url]="'/listado-adhesiones/recibidas'"
            [state]="EstadosAdhesionEnum.PENDIENTE"
            [link]="'Ver mis adhesiones pendientes'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'APROBADAS'"
            [amount]="adhesionesRecibidas.aprobadas"
            [subtitle]="'recibidas aprobadas'"
            [url]="'/listado-adhesiones/recibidas'"
            [state]="EstadosAdhesionEnum.APROBADA"
            [link]="'Ver mis adhesiones aprobadas'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'RECHAZADAS'"
            [amount]="adhesionesRecibidas.rechazadas"
            [subtitle]="'recibidas rechazadas'"
            [url]="'/listado-adhesiones/recibidas'"
            [state]="EstadosAdhesionEnum.RECHAZADA"
            [link]="'Ver mis adhesiones rechazadas'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'BLOQUEADAS'"
            [amount]="adhesionesRecibidas.bloqueadas"
            [subtitle]="'recibidas bloqueadas'"
            [url]="'/listado-adhesiones/recibidas'"
            [state]="EstadosAdhesionEnum.BLOQUEADA"
            [link]="'Ver mis adhesiones bloqueadas'"
        ></app-card>
        <app-card
            class="card"
            [buscando]="buscando"
            [title]="'FINALIZADAS'"
            [amount]="adhesionesRecibidas.finalizadas"
            [subtitle]="'recibidas finalizadas'"
            [url]="'/listado-adhesiones/recibidas'"
            [state]="EstadosAdhesionEnum.FINALIZADA"
            [link]="'Ver mis adhesiones finalizadas'"
        ></app-card>
    </div>
    <div class="flex justify-center mt-9">
        <a routerLink="/listado-adhesiones/recibidas" class="c-paragraph-base text-primary-base underline font-semibold"
            >Ver todas mis adhesiones recibidas</a
        >
    </div>
</main>

import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OrderBy } from 'desy-angular';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { Rpt } from 'src/app/interfaces/rpt';
import { RptService } from '../../services/rpt/rpt.service';
import { EditarAdhesionService as EditarAdhesionService } from 'src/app/services/editar-adhesion/editar-adhesion.service';
import { EditarAdhesion } from 'src/app/interfaces/editar-adhesion';
import { Query } from 'src/app/interfaces/query';
import { ConfirmacionAdhesion } from 'src/app/interfaces/confirmacion-adhesion';
import { RolesEnum } from 'src/app/enum/roles';
import { LoginService } from 'src/app/services/login/login.service';
import { Protocolo } from 'src/app/interfaces/protocolo';
import { ProtocolosService } from 'src/app/services/protocolos/protocolos.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-modificar-rpt',
    templateUrl: './modificar-rpt.component.html',
    styleUrls: ['./modificar-rpt.component.scss'],
})
export class ModificarRptComponent implements OnInit {
    actualizaTablaSeleccionados: boolean = false;
    allRpts: Rpt[] = [];
    allRptsSinSeleccionados: Rpt[] = [];
    autoButtonClass: string;
    autoButtonDisabled: boolean;
    autoButtonHint: string;
    autoButtonState: string;
    baseLegal: any;
    baseMes: any;
    botonDisabled: boolean = true;
    buscando: boolean;
    datosProtocolo: any;
    error: string;
    errorAdhesion: boolean;
    flagTooltip: boolean;
    idAdhesion: string;
    jobCode: any;
    loading: boolean;
    meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ];
    observaciones: any;
    openModalAddRPTS: boolean;
    orderBy: OrderBy = OrderBy.none;
    procedimiento: any;
    protocolo: Protocolo;
    queryGetAri: Query = {
        facetPrefix: '',
        fq: {
            LAST_MODIFIED_OBJECT: [true],
            OBJ_SUB_TYPE: ['PUESTO'],
            OBJ_TYPE: ['ENTITY'],
        },
        groupBy: '',
        orderBy: 'OBJ_NAME',
        q: '',
        rows: 0,
        simpleSearch: false,
        sortingType: 'ASC',
        start: 0,
        zeroRows: false,
    };
    rows: number = 10;
    rptPage: number;
    rpts: Rpt[] = [];
    rptFilter: Rpt[] = [];
    rptSeleccionados: Rpt[] = [];
    rptSeleccionadosPage = 1;
    rptSeleccionadosPaginados: Rpt[] = [];
    search: string = '';
    seleccionaTodos: boolean;
    tipoRPTSeleccionado: string = 'RPTs de mi equipo';
    vigenciaAdhesion: any;
    modalAlert: boolean = false;
    @Input() adhesion: Adhesion;
    @Output() datosAlPadreEvent = new EventEmitter<boolean>();
    @Output() cerrarModalEvent = new EventEmitter<boolean>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private editarAdhesionService: EditarAdhesionService,
        private loginService: LoginService,
        private protocoloService: ProtocolosService,
        private rptService: RptService,
        private router: Router
    ) {}
    async ngOnInit() {
        this.idAdhesion = '' + this.adhesion!.idAdhesion!;
        this.loading = true;
        this.loading = false;
        this.jobCode = this.loginService.getUsuarioActual().jobCode!;

        this.getDatos();
        this.botonDisabled = true;
    }

    guardarAdhesion() {
        this.solicitaAdhesion();
        const modal = false;
        this.datosAlPadreEvent.emit(modal);
    }
    cancel() {
        this.modalAlert = true;
    }
    responseDataSon(data: boolean) {
        this.modalAlert = data;
    }
    closeModalSon(data: boolean) {
        this.modalAlert = data;
        const modal = false;

        this.cerrarModalEvent.emit(modal);
    }
    async getDatos() {
        this.rpts = this.rpts;
        this.baseLegal = this.adhesion?.baseLegal;
        this.baseMes = this.meses[parseInt(this.baseLegal?.mes) - 1];
        let res = await this.protocoloService.getDatosProtocolo(this.adhesion?.idProtocolo);
        this.protocolo = this.protocoloService.mapProtocolo(
            res,
            this.adhesion?.idProtocolo!,
            this.adhesion?.protocoloAri!,
            this.adhesion?.estado!
        );
        let datosOrdenados = this.protocoloService.getDatosProtocoloOrdenados(res);
        this.datosProtocolo = this.getNivelesDatos(datosOrdenados);
        let fechaVigenciaAdhesion = this.adhesion?.fechaVigencia ?? this.baseLegal.tiempoVigencia.split('/');
        let fechaDia: string = '';
        if (Number.parseInt(fechaVigenciaAdhesion[0]) - 1 < 10) {
            fechaDia = '0' + Number.parseInt(fechaVigenciaAdhesion[0]);
        } else {
            fechaDia = Number.parseInt(fechaVigenciaAdhesion[0]) - 1 + '';
        }
        this.vigenciaAdhesion =
            fechaVigenciaAdhesion[2] + '-' + fechaVigenciaAdhesion[1] + '-' + fechaDia + 'T' + '22:00:00.000Z';
    }

    getNivelesDatos(datos: string[]) {
        let datosADevolver: string[] = [];
        datos.forEach((dato: string) => {
            let partes = dato.split('-');
            let nuevoTexto = partes[0].trim();

            datosADevolver.push(nuevoTexto);
        });
        return datosADevolver;
    }

    groupByProperties(list: any, properties: any) {
        return this.groupSubKeys(list, properties, 0);
    }
    groupSubKeys(obj: any, properties: any, propIndex: any) {
        var grouppedObj = this.groupBy(obj, properties[propIndex]);
        Object.keys(grouppedObj).forEach((key) => {
            if (propIndex < properties.length - 2) {
                grouppedObj[key] = this.groupSubKeys(grouppedObj[key], properties, propIndex + 1);
            } else {
                grouppedObj[key] = grouppedObj[key].map((item: any) => item[properties[propIndex + 1]]);
            }
        });
        return grouppedObj;
    }
    groupBy(list: any, prop: any) {
        return list.reduce((groupped: any, item: any) => {
            var key = item[prop];
            delete item[prop];
            if (groupped.hasOwnProperty(key)) {
                groupped[key].push(item);
            } else {
                groupped[key] = [item];
            }
            return groupped;
        }, {});
    }

    changeCheck(rpt: Rpt) {
        debugger;
        if (this.rpts.length) {
            this.rpts[this.rpts.indexOf(rpt)].check = !this.rpts[this.rpts.indexOf(rpt)].check;
            this.botonDisabled = !this.rpts[this.rpts.indexOf(rpt)].check;
            this.rptSeleccionados = [];
        } else {
            rpt.check === false ? (rpt.check = true) : (rpt.check = false);
            this.rptFilter.push(rpt);
            // Elimina elementos duplicados de this.rptFilter
            this.rptFilter = Array.from(new Set(this.rptFilter));
            this.adhesion!.RPTs! = this.adhesion!.RPTs!.filter((rpt) => !this.rptFilter.includes(rpt));
            this.rptSeleccionados = this.rptFilter.filter((x) => x.check === true);

            this.botonDisabled = this.rptSeleccionados.length === 0;
        }
    }
    async openAddRpts() {
        this.openModalAddRPTS = true;
        this.buscando = true;
        this.loading = true;
        this.allRpts = await this.getAllRPTs();
        this.rptPage = 1;
        this.updateRpts();
        this.buscando = false;
        this.loading = false;
    }
    closeAddRpts() {
        this.openModalAddRPTS = false;
    }
    AddRpts() {
        this.guardarRpts();
        this.openModalAddRPTS = false;
    }
    async guardarRpts() {
        this.loading = true;
        const uniqueRPTsSet = new Set(this.rpts.filter((x) => x.check === true).concat(this.adhesion!.RPTs!));
        const uniqueRPTsArray = Array.from(uniqueRPTsSet);
        this.adhesion!.RPTs = uniqueRPTsArray;
        this.changeDetectorRef.detectChanges();
        this.updateRpts();
        this.adhesion?.RPTs!.forEach((x) => (x.check = false));
        this.loading = false;
        this.rpts = [];
    }
    async deleteRPTS() {
        this.loading = true;
        this.adhesion!.RPTs! = this.adhesion!.RPTs!;
        this.changeDetectorRef.detectChanges();
        this.updateRpts;
        this.rptSeleccionados = [];
        this.adhesion?.RPTs!.forEach((x) => (x.check = false));
        this.loading = false;
    }
    async searchRpts() {
        this.loading = true;
        this.setTipoRPT(this.tipoRPTSeleccionado);
        this.updateRpts();
        this.loading = false;
        this.buscando = false;
    }

    async cambiaTipoRPT(tipo: string) {
        this.buscando = true;
        this.seleccionaTodos = false;
        this.setTipoRPT(tipo);
        this.allRpts = await this.getAllRPTs();
        this.rptPage = 1;
        this.updateRpts();
        this.buscando = false;
    }
    setTipoRPT(tipo: string) {
        this.tipoRPTSeleccionado = tipo;
    }
    updateRpts() {
        this.allRptsSinSeleccionados = this.allRpts.filter((item) => {
            return (
                !this.rptSeleccionados.some((rpt) => rpt.cargo === item.cargo) &&
                (this.search.length === 0 ||
                    item.cargo.toLowerCase().includes(this.search.toLowerCase().trim()) ||
                    item.nombre?.toLowerCase().includes(this.search.toLowerCase().trim()) ||
                    item.email?.toLowerCase().includes(this.search.toLowerCase().trim()) ||
                    item.organismo.toLowerCase().includes(this.search.toLowerCase().trim()))
            );
        });
        this.actualizaTablaSeleccionados = true;
        this.getPaginacionRpts();
        this.changeDetectorRef.detectChanges();
        this.actualizaTablaSeleccionados = false;
    }
    getPaginacionRpts() {
        if (!this.allRptsSinSeleccionados.length) {
            this.rpts = [];
            this.botonDisabled = true;
            return;
        }
        let position = (this.rptPage - 1) * this.rows;
        this.rpts = this.allRptsSinSeleccionados.slice(position, position + this.rows);
        this.botonDisabled = !this.rpts.some((item) => item.check);
    }
    getPaginacionRptsSeleccionados() {
        let position = (this.rptSeleccionadosPage - 1) * this.rows;
        this.rptSeleccionadosPaginados = this.rptSeleccionados.slice(position, position + this.rows);
    }
    cambiaSeleccionaTodo() {
        this.seleccionaTodos = !this.seleccionaTodos;
        this.rpts.forEach((rpt) => (rpt.check = this.seleccionaTodos));
        this.botonDisabled = !this.rpts.some((item) => item.check);
    }
    async onSaveSeleccionados() {
        await this.getDatos();
        this.buscando = true;
        this.actualizaTablaSeleccionados = true;
        this.rptSeleccionados = this.rpts.filter((rpt) => rpt.check === true);
        this.rptSeleccionadosPage = 1;
        this.getPaginacionRptsSeleccionados();
        this.updateRpts();
        this.rptPage = 1;
        this.updateRpts();
        this.guardarRpts();
        this.seleccionaTodos = false;
        this.changeDetectorRef.detectChanges();
        this.buscando = false;
        this.actualizaTablaSeleccionados = false;
    }

    async onSeleccionarPagina(event: any, seleccionados: boolean = false) {
        if (!event.target.id.includes('selector')) return;
        if (seleccionados) {
            this.actualizaTablaSeleccionados = true;
            this.getPaginacionRptsSeleccionados();
            this.changeDetectorRef.detectChanges();
            this.actualizaTablaSeleccionados = false;
        } else {
            if (!this.allRptsSinSeleccionados.length) return; // Si no genera un bucle infinito
            this.buscando = true;
            this.seleccionaTodos = false;
            this.allRptsSinSeleccionados.forEach((rpt) => (rpt.check = false));
            this.getPaginacionRpts();
            this.changeDetectorRef.detectChanges();
            this.buscando = false;
        }
    }
    async getAllRPTs(): Promise<Rpt[]> {
        if (this.tipoRPTSeleccionado === 'RPTs de mi equipo') {
            this.loading = true;
            const resultado = await this.rptService.getRPTsDynamo();
            let totalRpts = resultado.filter((elemento) => !this.adhesion?.RPTs?.includes(elemento));
            this.loading = false;
            return totalRpts;
        } else {
            this.loading = true;
            const resultado = await this.rptService.getRPTsDynamoAragon();
            let totalRpts = resultado.filter((elemento) => !this.adhesion?.RPTs?.includes(elemento));
            this.loading = false;
            return totalRpts;
        }
    }
    async solicitaAdhesion() {
        this.flagTooltip = false;
        this.autoButtonState = 'is-loading';
        this.autoButtonClass = 'c-button-loader--is-loading c-button-loader--primary ml-sm';
        this.autoButtonDisabled = true;
        this.autoButtonHint = 'Carga de 3 segundos...';

        let tipoDato: EditarAdhesion = {
            id: 615,
            name: 'name',
            label: 'Nombre',
            description: '',
            isCustom: false,
            type: 'TEXT_AREA',
            value: this.adhesion?.nombreCompleto,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let dsa: EditarAdhesion = {
            id: 611,
            name: 'source',
            label: 'fuente',
            description: '',
            isCustom: false,
            type: 'ENTITY_SEARCH',
            value: {
                ari: this.protocolo?.ari,
                state: this.protocolo.state,
                version: 0,
                metadata: {
                    link: `/entity/dsa/form/${this.protocolo.id}`,
                },
            },
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        const queryDestination: Query = { ...this.queryGetAri, q: this.jobCode, rows: 1 };
        const respDestination: any = await this.rptService.getRPTs(queryDestination);
        const ariUser: string = respDestination.results.BASIC[0].solrDocument.ARI;
        const state: string = respDestination.results.BASIC[0].solrDocument.STATE;
        const idObj: string = respDestination.results.BASIC[0].solrDocument.ID_OBJ;
        let destinatario: EditarAdhesion = {
            id: 612,
            name: 'destination',
            label: 'Destino',
            description: '',
            isCustom: false,
            type: 'ENTITY_SEARCH',
            value: {
                ari: ariUser,
                state: state,
                version: 0,
                metadata: {
                    link: `/entity/dsa/form/${idObj}`,
                },
            },
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let procedimiento: EditarAdhesion = {
            id: 613,
            name: 'procedimiento',
            label: 'Procedimiento',
            description: 'Procedimiento',
            isCustom: false,
            type: 'TEXT_AREA',
            value: this.adhesion?.nombreServicio,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        const rptsRemain = this.adhesion?.RPTs?.filter(
            (rptSeleccionado) => !this.rptSeleccionados.some((adhesionRPT) => adhesionRPT.rpt === rptSeleccionado.rpt)
        );
        const q = rptsRemain?.map((rpt) => (rpt.puesto!.length < 12 ? environment.entorno + rpt.puesto : rpt.puesto));
        const queryPuestos: Query = { ...this.queryGetAri, q: q?.join(' , ') || '', rows: rptsRemain?.length };
        const resp: any = await this.rptService.getRPTs(queryPuestos);
        if (this.adhesion) {
            this.adhesion.RPTAri = [];
        }
        resp.results.BASIC.map((item: any) => this.adhesion?.RPTAri?.push(item.solrDocument.ARI));

        let rpts: EditarAdhesion = {
            id: 659,
            name: 'job_list',
            label: 'Listado de puestos',
            description: 'Listado de puestos',
            isCustom: false,
            type: 'ARRAY_ENTITY',
            value: this.adhesion?.RPTAri,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let fechaLimite: EditarAdhesion = {
            id: 618,
            name: 'fecha_limite',
            label: 'Fecha Límite',
            description: 'Fecha limite de adhesión al protocolo',
            isCustom: false,
            type: 'INPUT_DATE',
            value: new Date(this.vigenciaAdhesion),
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let observaciones: EditarAdhesion = {
            id: 620,
            name: 'observaciones',
            label: 'Observaciones',
            description: 'Ingrese las observaciones que considere.',
            isCustom: false,
            type: 'TEXT_AREA',
            value: this.observaciones == '' ? null : this.observaciones,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let tipoNormativa: EditarAdhesion = {
            id: 625,
            name: 'tipo_normativa',
            label: 'Tipo Normativa',
            description: 'Tipo de normativa',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.adhesion?.baseLegal?.tipo,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let rangoAnyo: EditarAdhesion = {
            id: 626,
            name: 'rango_ano',
            label: 'Rango/Año',
            description: 'Rango/Año',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.adhesion?.baseLegal?.rango,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let dia: EditarAdhesion = {
            id: 627,
            name: 'dia',
            label: 'Día',
            description: 'Día',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.adhesion?.baseLegal?.dia,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let mes: EditarAdhesion = {
            id: 628,
            name: 'mes',
            label: 'Mes',
            description: 'Mes',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.adhesion?.baseLegal?.mes,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let tituloNormativa: EditarAdhesion = {
            id: 629,
            name: 'titulo_normativa',
            label: 'Título de la Normativa',
            description: 'Título de la Normativa',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.adhesion?.baseLegal?.titulo,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let enlaceNormativa: EditarAdhesion = {
            id: 630,
            name: 'enlace_normativa',
            label: 'Enlace de la Normativa',
            description: 'Enlace de la Normativa',
            isCustom: false,
            type: 'INPUT_TEXT',
            value: this.adhesion?.baseLegal?.enlace,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let PI: EditarAdhesion = {
            id: 632,
            name: 'pi',
            label: 'PI',
            description: 'PI',
            isCustom: false,
            type: 'INPUT_CHECKBOX',
            value: this.protocolo.PI!,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let SI: EditarAdhesion = {
            id: 644,
            name: 'si',
            label: 'SI',
            description: 'Información sensible',
            isCustom: false,
            type: 'INPUT_CHECKBOX',
            value: this.protocolo.SI ?? 'false',
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        const finishDate: EditarAdhesion = {
            id: 661,
            name: 'finish_date',
            label: 'Fecha de finalización',
            description: 'Fecha de finalización',
            isCustom: false,
            type: 'INPUT_DATE',
            value: null,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };
        let zona: EditarAdhesion = {
            id: 662,
            name: 'zone',
            label: 'Zona',
            description: '',
            isCustom: false,
            type: 'SELECT',
            value: environment.zone,
            labelSelect: '',
            acronym: '',
            alias: '',
            shortDescription: '',
        };

        let envioAdhesion: EditarAdhesion[] = [
            destinatario,
            dia,
            dsa,
            enlaceNormativa,
            fechaLimite,
            finishDate,
            mes,
            observaciones,
            PI,
            procedimiento,
            rangoAnyo,
            rpts,
            SI,
            tipoDato,
            tipoNormativa,
            tituloNormativa,
            zona,
        ];
        interface Peticion {
            entityAttributes: EditarAdhesion[];
        }
        let peticion: Peticion = {
            entityAttributes: envioAdhesion,
        };
        let idAdhesion: any = await this.editarAdhesionService.editarAdhesion(peticion, this.idAdhesion);
        if (idAdhesion.code == '0000' || idAdhesion.code === '99997' || idAdhesion.code === '99996') {
            let confirmacionAdhesion: ConfirmacionAdhesion = {
                // TODO: rol hardcodeado
                role: RolesEnum.JEFE_SERVICIO,
                requestReason: null,
            };
            let respuestaConfirmacion: any = await this.editarAdhesionService.confirmarAdhesion(
                confirmacionAdhesion,
                idAdhesion.data.id
            );
            this.autoButtonClass = 'c-button-loader--is-success c-button-loader--primary ml-sm';
            this.autoButtonDisabled = false;
            this.autoButtonHint = 'Reiniciando en 3 segundos...';
            this.autoButtonState = 'is-success';

            this.loading = true;
        } else {
            this.error = idAdhesion.code + ' ' + idAdhesion.description;
            this.openError();
            console.error('Ha habido un error ', idAdhesion.code, ' la causa es: ', idAdhesion.description);
            this.autoButtonState = 'is-success';
            this.autoButtonClass = 'c-button-loader--is-success c-button-loader--primary ml-sm';
            this.autoButtonHint = 'Reiniciando en 3 segundos...';
            this.errorAdhesion = true;
        }
    }
    openError() {
        throw new Error('Method not implemented.');
    }
}

function getRPTsDynamoAragon() {
    throw new Error('Function not implemented.');
}

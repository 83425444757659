<main id="mainContent" class="container mx-auto px-base">
    <div class="pb-lg"></div>
    <div class="pb-lg"></div>
    <div class="pb-lg"></div>
    <div class="p-base border border-neutral-base rounded flex flex-col items-center mb-64">
        <svg
            class="mt-sm"
            xmlns="http://www.w3.org/2000/svg"
            width="201.989"
            height="181.321"
            viewBox="0 0 201.989 181.321"
        >
            <g id="undraw_mail_sent_re_0ofv" transform="translate(0 0)">
                <path
                    id="Trazado_981"
                    data-name="Trazado 981"
                    d="M519.09,263.5a.353.353,0,0,1-.2-.064l-98-68.252a1.777,1.777,0,0,0-2.03,0l-97.3,68.248a.354.354,0,1,1-.407-.58l97.3-68.248a2.488,2.488,0,0,1,2.842,0l98,68.252a.354.354,0,0,1-.2.645Z"
                    transform="translate(-318.873 -194.163)"
                    fill="#3f3d56"
                />
                <path
                    id="Trazado_982"
                    data-name="Trazado 982"
                    d="M23.264,77.131,116.112,8.319l93.553,73.708L120.9,134.651,72.7,123.666Z"
                    transform="translate(-15.02 -5.371)"
                    fill="#e6e6e6"
                />
                <path
                    id="Trazado_983"
                    data-name="Trazado 983"
                    d="M401.78,642.9h-45.87a2.143,2.143,0,0,1,0-4.286h45.87a2.143,2.143,0,0,1,0,4.286Z"
                    transform="translate(-340.03 -481.112)"
                    fill="#fdcb33"
                />
                <path
                    id="Trazado_984"
                    data-name="Trazado 984"
                    d="M372.368,616.9H355.911a2.143,2.143,0,0,1,0-4.286h16.457a2.143,2.143,0,1,1,0,4.286Z"
                    transform="translate(-340.03 -464.326)"
                    fill="#fdcb33"
                />
                <path
                    id="Trazado_985"
                    data-name="Trazado 985"
                    d="M497.779,321.149a2.68,2.68,0,0,1-1.017-.2L439.5,297.19V222.818a2.483,2.483,0,0,1,2.481-2.481H551.834a2.483,2.483,0,0,1,2.481,2.481v74.424l-.108.046-55.381,23.651A2.7,2.7,0,0,1,497.779,321.149Z"
                    transform="translate(-395.381 -211.062)"
                    fill="#fff"
                />
                <path
                    id="Trazado_986"
                    data-name="Trazado 986"
                    d="M497.456,321a2.861,2.861,0,0,1-1.084-.212L439,296.986V222.495a2.661,2.661,0,0,1,2.658-2.658H551.512a2.661,2.661,0,0,1,2.658,2.658v74.541l-55.6,23.742A2.874,2.874,0,0,1,497.456,321Zm-57.748-24.491,56.934,23.624a2.168,2.168,0,0,0,1.654-.01l55.164-23.558V222.495a1.951,1.951,0,0,0-1.949-1.949H441.658a1.951,1.951,0,0,0-1.949,1.949Z"
                    transform="translate(-395.058 -210.739)"
                    fill="#3f3d56"
                />
                <path
                    id="Trazado_987"
                    data-name="Trazado 987"
                    d="M514.509,387.837h-.071l-40.681,17.371L418.1,428.976a1.8,1.8,0,0,1-1.375.007L359.3,405.159l-41.681-17.293-.064-.028h-.071A2.483,2.483,0,0,0,315,390.318V498.045a2.483,2.483,0,0,0,2.481,2.481H514.509a2.483,2.483,0,0,0,2.481-2.481V390.318A2.483,2.483,0,0,0,514.509,387.837Zm1.772,110.208a1.773,1.773,0,0,1-1.772,1.772H317.481a1.773,1.773,0,0,1-1.772-1.772V390.318a1.776,1.776,0,0,1,1.7-1.772L359.3,405.924l57.152,23.714a2.523,2.523,0,0,0,1.928-.011l55.38-23.65,40.823-17.431a1.778,1.778,0,0,1,1.7,1.772Z"
                    transform="translate(-315 -319.205)"
                    fill="#3f3d56"
                />
                <path
                    id="Trazado_988"
                    data-name="Trazado 988"
                    d="M544.238,315.115a9.745,9.745,0,0,1-5.863-1.948l-.1-.079-22.081-16.906a9.81,9.81,0,1,1,11.933-15.574l14.3,10.967,33.8-44.079a9.81,9.81,0,0,1,13.754-1.816l-.21.285.216-.281A9.821,9.821,0,0,1,591.8,259.44l-39.756,51.847A9.814,9.814,0,0,1,544.238,315.115Z"
                    transform="translate(-442.412 -226.117)"
                    fill="#fdcb33"
                />
            </g>
        </svg>
        <div class="pb-lg"></div>
        <h1 class="c-h1 bold">Adhesión enviada</h1>
        <p class="c-paragraph-lg text-neutral-base text-center">
            La adhesión será revisada por un supervisor para su aprobación, <br />
            recibirás un aviso cuando se apruebe o rechace.
        </p>
        <p class="c-paragraph-lg text-neutral-base text-center">
            Al tratarse de una solicitud de un servicio intermediado por la plataforma de <br />
            intermediación y requerir autorización externa, es probable que la solicitud requiera de unos días para su aprobación.
        </p>
        <p class="c-paragraph-lg text-neutral-base text-center">
            En caso de cualquier consulta, contacta con soporte del Gobierno de Aragón a través <br />
            del siguiente <a class="link" href="https://aplicaciones.aragon.es/saco_core/formConsulta.action?e=y" target="_blank">formulario</a>
        </p>
        <div class="pb-sm"></div>
        <desy-button (clickEvent)="goToDetalleAdhesion()" classes="c-button--primary">Ver Adhesión</desy-button>
    </div>
</main>

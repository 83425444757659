import { Component, OnInit } from '@angular/core';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { Query } from 'src/app/interfaces/query';
import { Usuario } from '../../interfaces/usuario';
import { LoginService } from '../../services/login/login.service';
import { WorkflowQuery } from '../../interfaces/workflow-query';
import { AdhesionService } from '../../services/adhesiones/adhesion.service';
import { ProtocolosService } from '../../services/protocolos/protocolos.service';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    constructor(
        private loginService: LoginService,
        private adhesionService: AdhesionService,
        private protocoloService: ProtocolosService
    ) {}

    role: string[];
    adhesionesEnviadas = {
        aprobadas: 0,
        rechazadas: 0,
        pendientes: 0,
        bloqueadas: 0,
        finalizadas: 0,
    };
    adhesionesRecibidas = {
        aprobadas: 0,
        rechazadas: 0,
        pendientes: 0,
        bloqueadas: 0,
        finalizadas: 0,
    };
    usuario: Usuario;
    buscando = false;
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;

    async ngOnInit() {
        this.buscando = true;
        this.role = this.loginService.getCurrentRole();
        this.usuario = this.loginService.getUsuarioActual()!;
        await this.getAdhesionesEnviadas();
        await this.getAdhesionesRecibidas();
        this.buscando = false;
    }

    async getAdhesionesEnviadas() {
        const adhesiones = await this.adhesionService.getAdhesionesEnviadasRaw({
            estados: [
                EstadosAdhesionEnum.APROBADA,
                EstadosAdhesionEnum.RECHAZADA,
                EstadosAdhesionEnum.PENDIENTE,
                EstadosAdhesionEnum.BLOQUEADA,
            ],
        });
        adhesiones.forEach((adhesion: any) => {
            if (adhesion.solrDocument.DESTINATION_TYPE !== 'APP') {
                switch (adhesion.solrDocument.STATE) {
                    case EstadosAdhesionEnum.APROBADA:
                        this.adhesionesEnviadas.aprobadas++;
                        break;
                    case EstadosAdhesionEnum.RECHAZADA:
                        this.adhesionesEnviadas.rechazadas++;
                        break;
                    case EstadosAdhesionEnum.PENDIENTE:
                        this.adhesionesEnviadas.pendientes++;
                        break;
                    case EstadosAdhesionEnum.BLOQUEADA:
                        if (adhesion.solrDocument.FINISH_DATE) {
                            this.adhesionesEnviadas.finalizadas++;
                        } else {
                            this.adhesionesEnviadas.bloqueadas++;
                        }
                        break;
                }
            }
        });
    }
    limpiarAdhesion() {
        localStorage.removeItem('protocolo');
        localStorage.removeItem('procedimientoAdhesion');
        localStorage.removeItem('baseAdhesion');
        localStorage.removeItem('rptsAdhesion');
    }

    async getData(query: WorkflowQuery) {
        let data: any = await this.adhesionService.getAllWorkflows(query);
        return data;
    }

    async getAdhesionesRecibidas() {
        const adhesiones = await this.adhesionService.getAdhesionesRecibidasRaw({
            estados: [EstadosAdhesionEnum.APROBADA, EstadosAdhesionEnum.RECHAZADA, EstadosAdhesionEnum.BLOQUEADA],
        });
        adhesiones.forEach((adhesion: any) => {
            if (adhesion.solrDocument.DESTINATION_TYPE !== 'APP') {
                switch (adhesion.solrDocument.STATE) {
                    case EstadosAdhesionEnum.APROBADA:
                        this.adhesionesRecibidas.aprobadas++;
                        break;
                    case EstadosAdhesionEnum.RECHAZADA:
                        this.adhesionesRecibidas.rechazadas++;
                        break;
                    case EstadosAdhesionEnum.BLOQUEADA:
                        if (adhesion.solrDocument.FINISH_DATE) {
                            this.adhesionesRecibidas.finalizadas++;
                        } else {
                            this.adhesionesRecibidas.bloqueadas++;
                        }
                        break;
                }
            }
        });
        const adhesionesRecibidasPendientes = await this.adhesionService.getAdhesionesRecibidasPendientesRaw();
        this.adhesionesRecibidas.pendientes = adhesionesRecibidasPendientes.length;
    }
}

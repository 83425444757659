import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProcedimientoAdminsitrativoService } from '../../../services/procedimiento-administrativo/procedimiento-adminsitrativo.service';
import { ProcedimientoAdministrativo } from 'src/app/interfaces/procedimiento-administrativo';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from '../../../../environments/environment';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';

@Component({
    selector: 'app-paso-2',
    templateUrl: './paso2.component.html',
    styleUrls: ['./paso2.component.css'],
})
export class Paso2Component implements OnInit {
    @ViewChild('paginator') el: ElementRef | undefined;
    rows: number = 10;
    search: string;
    buscando: boolean = false;
    nombreProtocolo: string | null;
    procedimientosAdministrativos: any[] = [];
    procedimientosPaginacion: any[] = [];
    selectedPageDefault = 1;
    verDetalles = environment.urlDetalles;

    constructor(
        private nuevaAdhesionService: NuevaAdhesionService,
        private loginService: LoginService,
        private procedimientoAdministrativoService: ProcedimientoAdminsitrativoService,
        private router: Router
    ) {}

    async ngOnInit() {
        if (!this.nuevaAdhesionService.getProtocoloActual()) {
            this.router.navigate(['nueva-adhesion/protocolos']);
            return;
        }
        this.buscando = true;
        this.nombreProtocolo = this.nuevaAdhesionService.getProtocoloActual()!.nombre;
        this.procedimientosAdministrativos = await this.procedimientoAdministrativoService.getProcedimientosDynamo();
        this.getPaginacion();
        this.buscando = false;
    }

    getPaginacion() {
        if (!this.procedimientosAdministrativos.length) {
            this.procedimientosPaginacion = [];
            return;
        }
        let position = (this.selectedPageDefault - 1) * this.rows;
        this.procedimientosPaginacion = this.procedimientosAdministrativos.slice(position, position + this.rows);
    }

    goToNuevaAdhesion() {
        this.router.navigate(['/nueva-adhesion/protocolos']);
    }

    onSeleccionar(procedimiento: ProcedimientoAdministrativo) {
        this.nuevaAdhesionService.setProcedimientoActual(procedimiento);
        this.router.navigate(['/nueva-adhesion/incorporacion-bases']);
    }

    async onSearch() {
        this.procedimientosAdministrativos = await this.procedimientoAdministrativoService.getProcedimientosDynamo();
        this.procedimientosAdministrativos = this.procedimientosAdministrativos.filter((procedimiento) => {
            return (
                procedimiento.nombre.toLowerCase().includes(this.search.toLowerCase()) ||
                procedimiento.codigo.toLowerCase().includes(this.search.toLowerCase()) ||
                procedimiento.nombreOrganismoProductor.toLowerCase().includes(this.search.toLowerCase())
            );
        });
        this.selectedPageDefault = 1;
        this.getPaginacion();
    }

    onButtonClick() {
        this.getPaginacion();
        window.scroll(0, 0);
    }

    onPageSelect(numPagina: number) {
        this.buscando = true;
        this.selectedPageDefault = numPagina;
        this.getPaginacion();
        this.buscando = false;
    }
}

<div class="modal-overlay" *ngIf="isOpen">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Detalles</h5>
            <span class="close" (click)="closeModal()">&times;</span>
        </div>
        <div class="modal-body">
            <!-- Tabla de datos -->
            <desy-table-advanced
                [classes]="'min-w-full mb-sm mt-sm'"
                *ngIf="modalChart && modalChart.length > 0"
                class="full-table"
            >
                <desy-table-advanced-header>
                    <desy-table-advanced-header-cell>
                        <desy-content>Periodo</desy-content>
                    </desy-table-advanced-header-cell>
                    <desy-table-advanced-header-cell>
                        <desy-content>Usuario</desy-content>
                    </desy-table-advanced-header-cell>
                    <desy-table-advanced-header-cell>
                        <desy-content>Consultas</desy-content>
                    </desy-table-advanced-header-cell>
                </desy-table-advanced-header>

                <desy-table-advanced-row *ngFor="let item of modalChart">
                    <desy-table-advanced-row-cell>{{ item.periodo }}</desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>
                        <div *ngFor="let valor of item.totales">
                            {{ valor.nombre }}
                        </div>
                    </desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>
                        <div *ngFor="let valor of item.totales">
                            {{ valor.valor }}
                        </div>
                    </desy-table-advanced-row-cell>
                </desy-table-advanced-row>
            </desy-table-advanced>
        </div>
        <div class="modal-footer">
            <desy-button (clickEvent)="closeModal()" classes="c-button--primary">Cerrar</desy-button>
        </div>
    </div>
</div>

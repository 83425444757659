import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'organizaciones',
})
export class OrganizacionesPipe implements PipeTransform {
    transform(value: string, ...args: unknown[]): string {
        switch (value) {
            case 'GA/Dpto_Deporte/SADC':
                return ' Departamento de Educación, Cultura y Deporte';
            case 'GA/Dpto_Ciudadania/IASS':
                return 'Instituto Aragonés de Servicios Sociales (IASS)';
            case 'GA/Dpto_Agricultura':
                return 'Servicio de Promoción y Calidad Agroalimentaria';
            case 'GA/Ser_OrdenacionSS':
                return 'Servicio Ordenación de Servicios Sociales';
            default:
                return 'No se ha encontrado el departamento';
        }
    }
}

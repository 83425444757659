import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { Router } from '@angular/router';
import { AdhesionService } from 'src/app/services/adhesiones/adhesion.service';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';

@Component({
    selector: 'app-enviadas',
    templateUrl: './enviadas.component.html',
    styleUrls: ['./enviadas.component.css'],
})
export class EnviadasComponent {
    @Input() adhesion: Adhesion;
    @Output() update = new EventEmitter();
    @Output() error = new EventEmitter<string>();

    masItems: any = [
        {
            text: 'Más',
            classes: 'mr-2 mas-button',
            sub: {
                items: [{ role: 'menuitem', text: 'Finalizar' }
                ],
            },
        },
    ];
    activeFinalizar: boolean = false;
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;

    constructor(private router: Router, private adhesionService: AdhesionService) {}

    onVerAdhesion() {
        this.router.navigate(['/detalle-adhesion-enviada', this.adhesion.idAdhesion]);
    }
    openDialog() {
        this.activeFinalizar = true;
    }
    closeDialog() {
        this.activeFinalizar = false;
    }
    async finalizarAdhesion() {
        const resp: any = await this.adhesionService.finalizarAdhesion(this.adhesion.idAdhesion!, this.adhesion.estado!);
        if (resp.code == '0000' && !resp.data?.error?.length) {
            this.closeDialog();
            this.update.emit();
        } else {
            this.closeDialog();
            this.error.emit(resp.data?.error?.length ? resp.data.error.join('\n') : resp.description);
        }
    }
}

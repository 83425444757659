import { Component, NgZone, OnInit, AfterViewInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Token } from '../../interfaces/token';
import { LoginService } from '../../services/login/login.service';
import { Router } from '@angular/router';
import { Usuario } from '../../interfaces/usuario';
import { environment } from 'src/environments/environment';
import { ScriptService } from '../../services/script/script.service';
import { AlertOptions, AlertService, NotificationOptions } from 'desy-angular';
import { ProcedimientoAdminsitrativoService } from 'src/app/services/procedimiento-administrativo/procedimiento-adminsitrativo.service';
import { RptService } from 'src/app/services/rpt/rpt.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewInit {
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    constructor(
        private loginService: LoginService,
        private zone: NgZone,
        private router: Router,
        private scriptService: ScriptService,
        private renderer: Renderer2,
        private alertService: AlertService,
        private procedimientoAdministrativoService: ProcedimientoAdminsitrativoService,
        private rptService: RptService,
    ) {
        (window as any).onIdentification = this.onIdentification.bind(this);
    }
    buscando: boolean;
    url_serv: string = environment.url_serv;
    async ngOnInit() {}
    token: Token;
    ngAfterViewInit() {
        this.showIframe();
    }

    async showIframe(){
        const urlMfeSSOSCript = `https://${this.url_serv}/js/sslssso.js`;
        const scriptElement = this.scriptService.loadJsScript(this.renderer, urlMfeSSOSCript);
        scriptElement.onload = () => {
            this.buscando = true;
            const urlRedirect = btoa(`${environment.home_url}`).replace(/\+/g, '-').replace(/\//g, '_');
            const urlMfeIframe = `https://${this.url_serv}/rest/identification/HAL/${urlRedirect}`;
            const iframe = document.getElementById('mfe_iframe') as HTMLIFrameElement;
            iframe.src = urlMfeIframe;
            iframe.scrolling = 'no';
            this.buscando = false;
        };
        scriptElement.onerror = () => {
            const alertOptions: AlertOptions = {
                id: 'alert',
                place: this.alertPlace,
                role: 'alert',
                ariaLive: 'assertLive',
                classes: 'mt-5',
            };
            const notification: NotificationOptions = {
                title: { text: 'Se ha producido un error al cargar la página' },
                description: { text: 'Por favor, inténtelo de nuevo más tarde' },
                type: 'alert',
            };
            this.alertService.openAlert(notification, alertOptions);
        };
    }

    async onIdentification(operation: any) {
        this.zone.run(async () => {
            this.buscando = true;
            // Token jwt de identificación
            this.token = {
                token: operation.jwt,
                tokenRefresh: operation.refreshJwt,
            };
            if (!this.token && !operation.organismName) {
                this.buscando = false;
                console.log('Error : ', this.token);
                return;
            }
            let body = {
                nif: operation?.eIdentifier,
                org: operation.organismCode,
                token: operation.jwt,
            }; 
            
            let userData: any = await this.loginService.validarToken(body);

            if(!userData?.eIdentifier){
                this.buscando = false;
                this.showIframe();
                const alertOptions: AlertOptions = {
                    id: 'alert',
                    place: this.alertPlace,
                    role: 'alert',
                    ariaLive: 'assertLive',
                    classes: 'mt-3 mb-5',
                };
                const notification: NotificationOptions = {
                    title: { text: 'Se ha producido un error' },
                    description: { text: 'El organismo seleccionado no es correcto' },
                    type: 'alert',
                };
                this.alertService.openAlert(notification, alertOptions);
                return;
            }
            this.loginService.setOperacion(operation);
            this.loginService.setCurrentToken(this.token);
            await this.crearUsuario(userData, body);
            let data = await this.loginService.getRoles();
            this.getRole(data);

            this.procedimientoAdministrativoService.getProcedimientosDynamo();
            this.rptService.getRPTsDynamoAragon();
            this.router.navigate(['/home']).then();
            this.buscando = false;
        });
    }

    async crearUsuario(userData: any, body: any) {
        const fullRPT = await this.rptService.getUserRpt(userData.roleCode);
        const usuarioLogeado: Usuario = {
            nombre: userData.givenName,
            apellido1: userData.surname1,
            apellido2: userData.surname2,
            orgCode: body.org,
            dni: userData?.eIdentifier,
            expiracion: userData.exp,
            metodoIdentificacion: userData.identificationMethod,
            unidadOrganizativa: userData.organismName,
            userName: userData.anjana_usr!,
            jobCode: environment.entorno+userData.roleCode,
            fullRPT: fullRPT + '',
            OUAnjana: [],
        };
        this.loginService.setTokenAnjana(userData.anjana_token);
        this.loginService.setUsuarioActual(usuarioLogeado);
    }

    getRole(data: any) {
        
        if (!data.data?.roleAndOU) return;
        let keys = Object.keys(data.data.roleAndOU);
        const organismo = this.loginService.getOperacion().organismCode
        const role = data.data.roleAndOU[environment.entorno+organismo]
        let OUAnjana = keys;
        let usuario = this.loginService.getUsuarioActual();
        usuario.OUAnjana = OUAnjana;
        this.loginService.setUsuarioActual(usuario);
        this.loginService.setCurrentRole(role);
    }
}

import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AlertOptions, AlertService, DialogOptions, DialogService, NotificationOptions } from 'desy-angular';
import { FormControl, FormGroup } from '@angular/forms';
import { OrganizacionService } from 'src/app/services/organizaciones/organizaciones.service';
import { ProtocolosService } from 'src/app/services/protocolos/protocolos.service';
import { Adhesion } from 'src/app/interfaces/adhesion';
import { State } from 'src/app/interfaces/state';
import { ActivatedRoute, Router } from '@angular/router';
import { AdhesionService } from 'src/app/services/adhesiones/adhesion.service';
import { Query } from 'src/app/interfaces/query';
import { Organizacion } from 'src/app/interfaces/organizacion';
import { FiltroOrganismosComponent } from '../../../shared/filtro-organismos/filtro-organismos.component';
import { LoginService } from '../../../services/login/login.service';
import { Usuario } from 'src/app/interfaces/usuario';
import { ListadoAdhesionesService } from 'src/app/services/listado-adhesiones/listado-adhesiones.service';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-listado',
    templateUrl: './listado.component.html',
    styleUrls: ['./listado.component.css'],
})
export class ListadoComponent implements OnInit {
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    // CLASES SEARCHBAR
    searchbar: string;
    tipoListado: string;
    idsAdhesiones: string[] = [];
    adhesionesTotales: Adhesion[] = [];
    adhesionesFiltradas: Adhesion[] = [];
    adhesionesPaginadas: Adhesion[] = [];
    role: string;
    user: Usuario;
    orderBy: string[] = ['Ultimo modificado', 'Más reciente', 'Más antiguo', 'Nombre'];
    ordenActual: string = 'Ultimo modificado';
    ordenItems: any = [
        {
            text: `Orden: ${this.ordenActual}`,
            classes: 'mb-base mr-base order-by',
            sub: {
                items: [
                    { role: 'menuitemcheckbox', text: 'Ultimo modificado', checked: true },
                    { role: 'menuitemcheckbox', text: 'Más reciente', checked: false },
                    { role: 'menuitemcheckbox', text: 'Más antiguo', checked: false },
                    { role: 'menuitemcheckbox', text: 'Nombre', checked: false },
                ],
            },
        },
    ];
    states: State[] = [
        {
            valor: EstadosAdhesionEnum.APROBADA,
            checked: false,
            texto: 'Aprobado',
        },
        {
            valor: EstadosAdhesionEnum.PENDIENTE,
            checked: false,
            texto: 'Pendiente',
        },
        {
            valor: EstadosAdhesionEnum.RECHAZADA,
            checked: false,
            texto: 'Rechazado',
        },
        {
            valor: EstadosAdhesionEnum.BLOQUEADA,
            checked: false,
            texto: 'Bloqueado',
        },
        {
            valor: EstadosAdhesionEnum.FINALIZADA,
            checked: false,
            texto: 'Finalizado',
        },
    ];
    statesSaved: State[] = [
        {
            valor: EstadosAdhesionEnum.APROBADA,
            checked: false,
            texto: 'Aprobado',
        },
        {
            valor: EstadosAdhesionEnum.PENDIENTE,
            checked: false,
            texto: 'Pendiente',
        },
        {
            valor: EstadosAdhesionEnum.RECHAZADA,
            checked: false,
            texto: 'Rechazado',
        },
        {
            valor: EstadosAdhesionEnum.BLOQUEADA,
            checked: false,
            texto: 'Bloqueado',
        },
        {
            valor: EstadosAdhesionEnum.FINALIZADA,
            checked: false,
            texto: 'Finalizado',
        },
    ];
    estadosSeleccionados: EstadosAdhesionEnum[] = [];
    rows: number = 10;
    buscando: boolean = false;
    selectedPageDefault = 1;
    search: string = '';
    query: Query = {
        q: '',
        fq: {
            OBJ_SUB_TYPE: ['ADHESION'],
            STATE: [
                EstadosAdhesionEnum.APROBADA,
                EstadosAdhesionEnum.PENDIENTE,
                EstadosAdhesionEnum.RECHAZADA,
                EstadosAdhesionEnum.BLOQUEADA,
                EstadosAdhesionEnum.FINALIZADA,
            ],
            ZONE: [environment.zone],
            // ID_OBJ: this.idsAdhesiones,
        },
        start: 0,
        orderBy: 'LAST_MODIFIED_DATE',
        sortingType: 'DESC',
    };
    organizaciones: Organizacion[] = [];
    organizacionesSeleccionadas: string[] = [];
    form = new FormGroup({
        fechaDesdeCreacion: new FormGroup({
            day: new FormControl(),
            month: new FormControl(),
            year: new FormControl(),
        }),
        fechaHastaCreacion: new FormGroup({
            day: new FormControl(),
            month: new FormControl(),
            year: new FormControl(),
        }),
        fechaDesdeModificacion: new FormGroup({
            day: new FormControl(),
            month: new FormControl(),
            year: new FormControl(),
        }),
        fechaHastaModificacion: new FormGroup({
            day: new FormControl(),
            month: new FormControl(),
            year: new FormControl(),
        }),
    });
    itemsFechas = [
        { labelText: 'Día', name: 'day', classes: 'w-14', maxlength: 2 },
        { labelText: 'Mes', name: 'month', classes: 'w-14', maxlength: 2 },
        { labelText: 'Año', name: 'year', classes: 'w-20', maxlength: 4 },
    ];
    filtroFechaCreacion: Boolean = false;
    filtroFechaModificacion: Boolean = false;
    errores: any = {
        rangoCreacion: false,
        rangoModificacion: false,
        creacionDesde: false,
        creacionHasta: false,
        modificacionDesde: false,
        modificacionHasta: false,
    };
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;
    adhesionesBloqueadasPorMi: number[] = [];
    protocolosPaginados: never[];
    resultados: never[];
    protocolos: never[];
    organismos: any;
    numResultados: any;
    organizacionQuery: Query = {
        q: '',
        fq: {
            OBJ_SUB_TYPE: ['DSA'],
            STATE: [EstadosAdhesionEnum.APROBADA],
            // ZONE: [environment.zone],
        },
        start: 0,
        orderBy: 'OBJ_NAME',
    };
    constructor(
        private adhesionService: AdhesionService,
        private alertService: AlertService,
        private dialogService: DialogService,
        private listadoAdhesionesService: ListadoAdhesionesService,
        private loginService: LoginService,
        private organizacionService: OrganizacionService,
        private protocolosService: ProtocolosService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit() {
        this.buscando = true;
        this.searchbar = 'flex-1';
        this.tipoListado = this.route.snapshot.url[1].path;
        this.role = this.loginService.getCurrentRole();
        this.user = this.loginService.getUsuarioActual();
        if (this.route.snapshot.queryParams.estado) {
            this.states.forEach((state) => {
                if (state.valor === this.route.snapshot.queryParams.estado) {
                    state.checked = true;
                    this.listadoAdhesionesService.setFiltroEstado([state.valor]);
                }
            });
        }
        const dataProtocolos: any = await this.protocolosService.getProtocolos(this.query);
        this.getFiltros();
        await this.getAdhesiones();
        await this.onGetOrganizaciones(this.organizacionQuery);
        this.getOrganizaciones();
        this.buscando = false;
    }

    getFiltros() {
        if (this.tipoListado !== this.listadoAdhesionesService.getTipoListado()) {
            this.listadoAdhesionesService.setTipoListado(this.tipoListado);
        }
        const orden = this.listadoAdhesionesService.getOrdenacion();
        const estados = this.listadoAdhesionesService.getFiltroEstado();
        const organizacionesSeleccionadas = this.listadoAdhesionesService.getFiltroOrganizacionesSeleccionadas();
        const fechasCreacion = this.listadoAdhesionesService.getFiltroCreacion();
        const fechasModificacion = this.listadoAdhesionesService.getFiltroModificacion();
        const search = this.listadoAdhesionesService.getSearch();
        if (estados) {
            this.states.map((state) => (state.checked = estados.includes(state.valor)));
            this.statesSaved.map((state) => (state.checked = estados.includes(state.valor!)));
            this.estadosSeleccionados = [];
            this.states.forEach((state) => {
                if (state.checked) this.estadosSeleccionados.push(state.valor!);
            });
            this.query.fq!.STATE = this.estadosSeleccionados.length
                ? this.estadosSeleccionados
                : [
                      EstadosAdhesionEnum.APROBADA,
                      EstadosAdhesionEnum.PENDIENTE,
                      EstadosAdhesionEnum.RECHAZADA,
                      EstadosAdhesionEnum.BLOQUEADA,
                      EstadosAdhesionEnum.FINALIZADA,
                  ];
        }
        if (organizacionesSeleccionadas) this.organizacionesSeleccionadas = organizacionesSeleccionadas;
        if (orden) this.onOrderBy({ text: orden });
        if (fechasCreacion) {
            const { desde, hasta } = fechasCreacion;
            this.filtroFechaCreacion = true;
            this.form.controls.fechaDesdeCreacion.setValue({
                day: desde.diaDesde,
                month: desde.mesDesde,
                year: desde.anyoDesde,
            });
            this.form.controls.fechaHastaCreacion.setValue({
                day: hasta.diaHasta,
                month: hasta.mesHasta,
                year: hasta.anyoHasta,
            });
            this.onFiltrosFecha('creacion');
        }
        if (fechasModificacion) {
            const { desde, hasta } = fechasModificacion;
            this.filtroFechaModificacion = true;
            this.form.controls.fechaDesdeModificacion.setValue({
                day: desde.diaDesde,
                month: desde.mesDesde,
                year: desde.anyoDesde,
            });
            this.form.controls.fechaHastaModificacion.setValue({
                day: hasta.diaHasta,
                month: hasta.mesHasta,
                year: hasta.anyoHasta,
            });
            this.onFiltrosFecha('modificacion');
        }
        if (search) {
            this.search = search;
            this.onSearch();
        }
    }

    async getAdhesiones() {
        this.tipoListado !== 'enviadas' ? await this.getAdhesionesRecibidas() : await this.getAdhesionesEnviadas();
        if (
            this.tipoListado !== 'enviadas' &&
            this.adhesionesTotales.find((adhesion) => adhesion.estado === EstadosAdhesionEnum.BLOQUEADA)
        ) {
            this.adhesionesBloqueadasPorMi = await this.adhesionService.getAdhesionesBloqueadasPorMi();
        }
        this.getAdhesionesFiltradas();
        this.getPaginacionAdhesiones();
    }
    async getAdhesionesFiltradas() {
        let tempAdhesiones = [...this.adhesionesTotales];
        if (this.organizacionesSeleccionadas.length) {
            tempAdhesiones = tempAdhesiones.filter((adhesion) =>
                this.organizacionesSeleccionadas.includes(adhesion.SOURCE_ORGANIZATIONAL_UNIT![0])
            );
        }

        if (this.search) {
            const searchString = this.search.toLowerCase().trim();
            tempAdhesiones = tempAdhesiones.filter((adhesion) => {
                return (
                    adhesion.idAdhesion?.toString().includes(searchString) ||
                    adhesion.nombreAdhesion?.toLowerCase().includes(searchString) ||
                    adhesion.nombreProtocolo?.toLowerCase().includes(searchString) ||
                    adhesion.nombreServicio?.toLowerCase().includes(searchString) ||
                    adhesion.RPTSolicitante?.cargo?.toLowerCase().includes(searchString)
                );
            });
        }
        this.adhesionesFiltradas = [...tempAdhesiones];
    }
    getPaginacionAdhesiones() {
        const position = (this.selectedPageDefault - 1) * this.rows;
        this.adhesionesPaginadas = this.adhesionesFiltradas.slice(position, position + this.rows);
    }
    onSearch() {
        this.listadoAdhesionesService.setSearch(this.search);
        this.getAdhesionesFiltradas();
        this.getPaginacionAdhesiones();
    }
    getOrganizaciones() {
        const sourceOrganizationalUnits = [...this.adhesionesTotales].map((x) => x.SOURCE_ORGANIZATIONAL_UNIT).flat();
        const resultArray = sourceOrganizationalUnits.map((unit: any) => ({
            name: unit,
            numResults: 1,
            checked: false,
        }));
        this.organizaciones = [];
        this.organizaciones = this.organizacionService.mapOrganizacionesActuales(resultArray);

        this.organizaciones.forEach((org) => {
            if (org.subOrganizacion) {
                org.subOrganizacion.forEach((subOrg) => {
                    if (this.organizacionesSeleccionadas.includes(org.nombre + '/' + subOrg.nombre))
                        subOrg.check = true;
                });
            } else {
                if (this.organizacionesSeleccionadas.includes(org.nombre)) org.check = true;
            }
        });
        if (this.organizaciones.length > 1) {
            this.organizaciones.shift();
        }
        this.organizacionService.setOrganizacionesActuales(this.organizaciones);
    }

    async onGetOrganizaciones(organizacionQuery: Query) {
        this.buscando = true;
        this.organizaciones = [];
        this.organizacionesSeleccionadas = [];
        this.organizaciones = await this.organizacionService.getOrganizaciones(organizacionQuery);
        this.buscando = false;
    }
    onSeleccionarPagina(event: any) {
        if (!event.target.id.includes('selector')) return;
        if (!this.adhesionesTotales.length) return;
        this.buscando = true;
        this.getPaginacionAdhesiones();
        this.buscando = false;
    }

    openDialog(): void {
        const dialogOptions: DialogOptions = {
            id: 'dialog',
            dismissOnBackdrop: true,
            focusOnClose: 'dialog-btn',
        };
        this.dialogService.openDialog(FiltroOrganismosComponent, dialogOptions).then(async (result) => {
            this.organizacionService.postDialog(result.dialog);
            this.dialogService.onCloseDialog(result.dialog).then(() => {
                this.organizacionesSeleccionadas = [];
                this.organizaciones.forEach((organizacion) => {
                    if (organizacion.subOrganizacion) {
                        organizacion.subOrganizacion.forEach((sub) => {
                            if (sub.check) {
                                const codigoOrganizacion = organizacion.code;
                                this.organizacionesSeleccionadas.push(codigoOrganizacion);
                            }
                        });
                    } else {
                        if (organizacion.check) {
                            const codigoOrganizacion = organizacion.code;
                            this.organizacionesSeleccionadas.push(codigoOrganizacion);
                        }
                    }
                });
                this.listadoAdhesionesService.setFiltroOrganizacionesSeleccionadas(this.organizacionesSeleccionadas);
                this.getAdhesionesFiltradas();
                this.getPaginacionAdhesiones();
            });
        });
    }
    normalizarCadena(cadena: any) {
        // Convertir a minúsculas y eliminar tildes
        return cadena
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
    }

    async filterAdhesiones(params: string) {
        this.buscando = true;
        this.query.q = params;

        if (params !== '') {
            await this.getAdhesiones();
        } else {
            this.onFiltroEstado();
        }
        this.buscando = false;
    }

    async onFiltroEstado() {
        this.buscando = true;
        this.statesSaved.map((state, index) => (state.checked = this.states[index].checked));
        this.estadosSeleccionados = [];
        this.organizacionesSeleccionadas = [];
        this.states.forEach((state) => {
            if (state.checked) this.estadosSeleccionados.push(state.valor!);
        });
        this.listadoAdhesionesService.setFiltroEstado(this.estadosSeleccionados);

        this.query.fq!.STATE = this.estadosSeleccionados.length
            ? this.estadosSeleccionados
            : [
                  EstadosAdhesionEnum.APROBADA,
                  EstadosAdhesionEnum.PENDIENTE,
                  EstadosAdhesionEnum.RECHAZADA,
                  EstadosAdhesionEnum.BLOQUEADA,
                  EstadosAdhesionEnum.FINALIZADA,
              ];
        await this.getAdhesiones();
        this.buscando = false;
    }

    daysInMonth(month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }

    onFiltrosFecha(tipo: string) {
        const esCreacion = tipo === 'creacion';
        //Reinicia los errores
        this.errores[esCreacion ? 'rangoCreacion' : 'rangoModificacion'] = false;
        this.errores[esCreacion ? 'creacionDesde' : 'modificacionDesde'] = false;
        this.errores[esCreacion ? 'creacionHasta' : 'modificacionHasta'] = false;
        const {
            day: diaDesde,
            month: mesDesde,
            year: anyoDesde,
        } = this.form.controls[esCreacion ? 'fechaDesdeCreacion' : 'fechaDesdeModificacion'].value;
        const {
            day: diaHasta,
            month: mesHasta,
            year: anyoHasta,
        } = this.form.controls[esCreacion ? 'fechaHastaCreacion' : 'fechaHastaModificacion'].value;
        if (esCreacion) {
            this.listadoAdhesionesService.setFiltroCreacion({
                desde: { diaDesde, mesDesde, anyoDesde },
                hasta: { diaHasta, mesHasta, anyoHasta },
            });
        } else {
            this.listadoAdhesionesService.setFiltroModificacion({
                desde: { diaDesde, mesDesde, anyoDesde },
                hasta: { diaHasta, mesHasta, anyoHasta },
            });
        }

        if (!diaDesde && !diaHasta && !mesDesde && !mesHasta && !anyoDesde && !anyoHasta) {
            esCreacion ? delete this.query.fq?.START_DATE : delete this.query.fq?.LAST_MODIFIED_DATE;
            this.getAdhesiones();
            esCreacion ? (this.filtroFechaCreacion = false) : (this.filtroFechaModificacion = false);
            return;
        }

        const diasMesFechaDesde = this.daysInMonth(mesDesde, anyoDesde);
        const diasMesFechaHasta = this.daysInMonth(mesHasta, anyoHasta);
        //Comprueba si los días y los meses son correctos
        if (
            !diaDesde ||
            !mesDesde ||
            !anyoDesde ||
            diaDesde > diasMesFechaDesde ||
            diaDesde < 0 ||
            mesDesde > 12 ||
            mesDesde < 1 ||
            anyoDesde.length < 4
        ) {
            this.errores[esCreacion ? 'creacionDesde' : 'modificacionDesde'] = true;
        }
        if (
            !diaHasta ||
            !mesHasta ||
            !anyoHasta ||
            diaHasta > diasMesFechaHasta ||
            diaHasta < 0 ||
            mesHasta > 12 ||
            mesHasta < 1 ||
            anyoHasta.length < 4
        ) {
            this.errores[esCreacion ? 'creacionHasta' : 'modificacionHasta'] = true;
        }
        //Si hay errores no comprueba más
        if (esCreacion) {
            if (this.errores.creacionDesde || this.errores.creacionHasta) return;
        } else {
            if (this.errores.modificacionDesde || this.errores.modificacionHasta) return;
        }

        //Crea las fechas y comprueba si el rango es correcto
        const fechaDesde = new Date(anyoDesde, mesDesde - 1, diaDesde);
        const fechaHasta = anyoHasta ? new Date(anyoHasta, mesHasta - 1, diaHasta) : new Date();
        fechaHasta.setHours(23, 59, 59);
        if (fechaDesde > fechaHasta) {
            this.errores[esCreacion ? 'rangoCreacion' : 'rangoModificacion'] = true;
            return;
        }
        const fechaDesdeString = fechaDesde.toISOString().split('.')[0] + 'Z';
        const fechaHastaString = fechaHasta.toISOString().split('.')[0] + 'Z';
        if (esCreacion) {
            this.query.fq!.START_DATE = [fechaDesdeString + ' TO ' + fechaHastaString];
        } else {
            this.query.fq!.LAST_MODIFIED_DATE = [fechaDesdeString + ' TO ' + fechaHastaString];
        }
        this.getAdhesiones();
        esCreacion ? (this.filtroFechaCreacion = true) : (this.filtroFechaModificacion = true);
    }
    onOrderBy($event: any) {
        const value = $event.text;
        this.ordenItems[0].sub.items.map((item: any) => (item.checked = item.text === value));
        this.listadoAdhesionesService.setOrdenacion(value);
        switch (value) {
            case 'Ultimo modificado':
                this.ordenActual = value;
                this.query.orderBy = 'LAST_MODIFIED_DATE';
                this.query.sortingType = 'DESC';
                break;
            case 'Más reciente':
                this.ordenActual = value;
                this.query.orderBy = 'START_DATE';
                this.query.sortingType = 'DESC';
                break;
            case 'Más antiguo':
                this.ordenActual = value;
                this.query.orderBy = 'START_DATE';
                this.query.sortingType = 'ASC';
                break;
            case 'Nombre':
                this.ordenActual = value;
                this.query.orderBy = 'ID_OBJ';
                this.query.sortingType = 'ASC';
                break;
            default:
                this.ordenActual = 'Más reciente';
                this.query.orderBy = 'START_DATE';
                this.query.sortingType = 'DESC';
                break;
        }
        this.ordenItems[0].text = `Orden: ${this.ordenActual}`;
        this.buscando = true;
        this.getAdhesiones();
        this.buscando = false;
    }

    onCancelarFechas(tipo: string) {
        const esCreacion = tipo === 'creacion';
        this.form.controls[esCreacion ? 'fechaDesdeCreacion' : 'fechaDesdeModificacion'].reset();
        this.form.controls[esCreacion ? 'fechaHastaCreacion' : 'fechaHastaModificacion'].reset();
        esCreacion ? (this.filtroFechaCreacion = false) : (this.filtroFechaModificacion = false);
    }
    openStates() {
        this.states.map((state, index) => (state.checked = this.statesSaved[index].checked));
    }

    openError($event: any) {
        const alertOptions: AlertOptions = {
            id: 'alert',
            place: this.alertPlace,
            role: 'alert',
            ariaLive: 'assertLive',
            classes: 'mt-5',
        };

        var notificationOptions: NotificationOptions = {
            title: 'Ha ocurrido un error: ' + $event,
            type: 'alert',
            classes: 'c-notification c-notification--alert',
            isDismissible: true,
        };
        this.alertService.openAlert(notificationOptions, alertOptions);
        window.scroll(0, 0);
    }

    async getAdhesionesEnviadas() {
        this.adhesionesTotales = await this.adhesionService.getAdhesionesEnviadas(this.query);
        this.getAdhesionesFiltradas();
        this.getPaginacionAdhesiones();
    }
    async getAdhesionesRecibidas() {
        let adhesionesRecibidasPendientes: Adhesion[] = [];
        if (this.estadosSeleccionados.includes(EstadosAdhesionEnum.PENDIENTE) || !this.estadosSeleccionados.length) {
            adhesionesRecibidasPendientes = await this.adhesionService.getAdhesionesRecibidasPendientes(this.query);
        }
        let adhesionesRecibidas: Adhesion[] = [];
        if (
            this.estadosSeleccionados.includes(EstadosAdhesionEnum.APROBADA) ||
            this.estadosSeleccionados.includes(EstadosAdhesionEnum.RECHAZADA) ||
            this.estadosSeleccionados.includes(EstadosAdhesionEnum.BLOQUEADA) ||
            this.estadosSeleccionados.includes(EstadosAdhesionEnum.FINALIZADA) ||
            !this.estadosSeleccionados.length
        ) {
            adhesionesRecibidas = await this.adhesionService.getAdhesionesRecibidas(this.query);
        }
        this.adhesionesTotales = [...adhesionesRecibidas, ...adhesionesRecibidasPendientes];
        this.getAdhesionesFiltradas();
        this.getPaginacionAdhesiones();
    }
}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RptService } from '../../../services/rpt/rpt.service';
import { Rpt } from '../../../interfaces/rpt';
import { OrderBy } from 'desy-angular';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';
import { environment } from '../../../../environments/environment';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';

@Component({
    selector: 'app-paso-4',
    templateUrl: './paso4.component.html',
    styleUrls: ['./paso4.component.scss'],
})
export class Paso4Component implements OnInit {
    numRPTs: number = 0;
    buscando: boolean = false; // quita la tabla y el seleccionar todo mientras carga
    actualizaTabla: boolean = false; // quita la tabla mientras carga
    actualizaTablaSeleccionados: boolean = false; // quita la tabla de seleccionados mientras carga
    tipoRPTSeleccionado: string = 'RPTs de mi equipo';
    orderBy: OrderBy = OrderBy.desc;
    rows: number = 10;
    search: string = '';
    rptPage = 1;
    rptSeleccionadosPage = 1;
    rpts: Rpt[] = []; // Rpts mostrados en la tabla ya paginados
    allRpts: Rpt[] = []; // Array con todos los rpts
    allRptsSinSeleccionados: Rpt[] = []; // Rpts filtrados. Desde este array se hace la paginación
    rptSeleccionados: Rpt[] = []; // Array con todos los rpts seleccionados. Desde este array se hace la paginación
    rptSeleccionadosPaginados: Rpt[] = []; // Rpts seleccionados mostrados en la tabla ya paginados
    seleccionaTodos: boolean = false;
    botonDisabled: boolean = true;
    
    constructor(
        private rptService: RptService,
        private router: Router,
        private loginService: LoginService,
        private changeDetectorRef: ChangeDetectorRef,
        private nuevaAdhesionService: NuevaAdhesionService,
    ) {}

    async ngOnInit() {
        if (!this.nuevaAdhesionService.getIncorporacionBases()){
            this.router.navigate(['nueva-adhesion/incorporacion-bases']);
            return;
        }
        window.scroll(0, 0);
        this.buscando = true;
        this.rpts = [];
        this.allRpts = [];
        this.rptSeleccionados = this.nuevaAdhesionService.getRPTsActuales() ?? [];
        this.getPaginacionRptsSeleccionados();
        this.setTipoRPT(this.tipoRPTSeleccionado);
        this.allRpts = await this.getAllRPTs();
        this.updateRpts();
        this.buscando = false;
    }
    async searchRpts() {
        this.buscando = true;
        this.setTipoRPT(this.tipoRPTSeleccionado);
        this.updateRpts();
        this.buscando = false;
    }

    goToIncorporacionBases() {
        this.router.navigate(['/nueva-adhesion/incorporacion-bases']);
    }

    async cambiaTipoRPT(tipo: string) {
        this.buscando = true;
        this.seleccionaTodos = false;
        this.setTipoRPT(tipo);
        this.allRpts = await this.getAllRPTs();
        this.rptPage = 1;
        this.updateRpts();
        this.buscando = false;
    }

    setTipoRPT(tipo: string) {
        this.tipoRPTSeleccionado = tipo;
    }

    guardarRpts() {
        this.nuevaAdhesionService.setRPTsActuales(this.rptSeleccionados);
    }
    goToResumen() {
        this.router.navigate(['nueva-adhesion/resumen']);
    }

    cambiaSeleccionaTodo() {
        this.seleccionaTodos = !this.seleccionaTodos;
        this.rpts.forEach((rpt) => rpt.check = this.seleccionaTodos);
        this.botonDisabled = !this.rpts.some((item) => item.check);
    }

    async getAllRPTs(): Promise<Rpt[]> {
        if (this.tipoRPTSeleccionado === 'RPTs de mi equipo') {
            return await this.rptService.getRPTsDynamo();
        } else {
            return await this.rptService.getRPTsDynamoAragon();
            
        }
        
    }

    getPaginacionRpts() {
        if (!this.allRptsSinSeleccionados.length) {
            this.rpts = [];
            this.botonDisabled = true;
            return;
        }
        let position = (this.rptPage - 1) * this.rows;
        this.rpts = this.allRptsSinSeleccionados.slice(position, position + this.rows);
        this.botonDisabled = !this.rpts.some((item) => item.check);
    }
    getPaginacionRptsSeleccionados() {
        let position = (this.rptSeleccionadosPage - 1) * this.rows;
        this.rptSeleccionadosPaginados = this.rptSeleccionados.slice(position, position + this.rows);
    }

    async onSeleccionarPagina(event: any, seleccionados: boolean = false) {
        if (!event.target.id.includes('selector')) return;
        if (seleccionados) {
            this.actualizaTablaSeleccionados = true;
            this.getPaginacionRptsSeleccionados();
            this.changeDetectorRef.detectChanges();
            this.actualizaTablaSeleccionados = false;
        } else {
            if (!this.allRptsSinSeleccionados.length) return; // Si no genera un bucle infinito
            this.buscando = true;
            this.seleccionaTodos = false;
            this.allRptsSinSeleccionados.forEach((rpt) => rpt.check = false);
            this.getPaginacionRpts();
            this.changeDetectorRef.detectChanges();
            this.buscando = false;
        }
    }

    onSaveSeleccionados() {
        this.buscando = true;
        this.actualizaTablaSeleccionados = true;
        const nuevosSeleccionados = this.rpts.filter((rpt) => rpt.check === true);
        this.rptSeleccionados = [...nuevosSeleccionados, ...this.rptSeleccionados];
        this.rptSeleccionadosPage = 1
        this.getPaginacionRptsSeleccionados();
        this.updateRpts();
        this.guardarRpts();
        this.seleccionaTodos = false;
        this.changeDetectorRef.detectChanges();
        this.buscando = false;
        this.actualizaTablaSeleccionados = false;
    }

    updateRpts() {
        // TODO
        const entorno = environment?.entorno
        const allRptsModificados = this.allRpts.map(item => ({
            ...item,
            puesto:  entorno + item.puesto
        }));
        this.allRptsSinSeleccionados = allRptsModificados.filter((item) => {
            return !this.rptSeleccionados.some((rpt) => rpt.cargo === item.cargo) && 
                (
                    this.search.length === 0 ||
                    item.cargo.toLowerCase().includes(this.search.toLowerCase().trim()) ||
                    item.nombre?.toLowerCase().includes(this.search.toLowerCase().trim()) ||
                    item.email?.toLowerCase().includes(this.search.toLowerCase().trim()) ||
                    item.organismo.toLowerCase().includes(this.search.toLowerCase().trim())
                )
        });
        this.actualizaTablaSeleccionados = true;
        this.getPaginacionRpts();
        this.changeDetectorRef.detectChanges();
        this.actualizaTablaSeleccionados = false;
    }
    onDeleteSelectedRpt(rpt: Rpt) {
        this.actualizaTabla = true;
        this.actualizaTablaSeleccionados = true;
        rpt.check = false;
        const index = this.rptSeleccionados.findIndex((item) => item === rpt);
        this.rptSeleccionados.splice(index, 1);
        this.getPaginacionRptsSeleccionados();
        this.updateRpts();
        this.guardarRpts();
        this.changeDetectorRef.detectChanges();
        this.actualizaTabla = false;
        this.actualizaTablaSeleccionados = false;
    }
    changeCheck(rpt: Rpt) {
        rpt.check = !rpt.check;
        this.botonDisabled = !this.rpts.some((item) => item.check);
    }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login/login.service';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
    constructor(private loginService: LoginService) {}
    tokenAnjana: string = '';
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.loginService.getTokenAnjana();
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        const reqClone = req.clone({ headers });

        return next.handle(reqClone);
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rptListado',
})
export class RptListadoPipe implements PipeTransform {
    transform(value: string): any {
        value = value.split('__')[0];
        return value;
    }
}

<main id="rptContent" class="container mx-auto px-base">
    <div class="pb-sm"></div>
    <a (click)="goToIncorporacionBases()" role="button" draggable="false" class="c-button c-button--transparent">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 140 140"
            class="self-center mr-2"
            aria-hidden="true"
            focusable="false"
            width="1em"
            height="1em"
        >
            <path
                d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                fill="currentColor"
            ></path>
        </svg>
        Volver
    </a>
    <div class="pb-sm"></div>
    <p class="c-paragraph-sm text-neutral-dark">Paso 4 de 5</p>
    <h1 class="c-h1">Nueva adhesión</h1>

    <h2 class="c-h2">4. Añadir RPTs</h2>
    <p class="c-paragraph-base text-neutral-dark">Añade las personas físicas o RPTs que necesiten adherirse.</p>

    <div class="flex pb-md" id="searchbar">
        <ng-template #tipoRPT>
            <ul>
                <li>
                    <a
                        (click)="cambiaTipoRPT('RPTs de mi equipo')"
                        class="cursor-pointer flex items-center px-base py-xs hover:bg-primary-base hover:text-white truncate focus:bg-warning-base focus:outline-none focus:shadow-outline-focus focus:text-black"
                        >RPTs de mi equipo</a
                    >
                </li>
                <li>
                    <a
                        (click)="cambiaTipoRPT('RPTs de gobierno')"
                        class="cursor-pointer flex items-center px-base py-xs hover:bg-primary-base hover:text-white truncate focus:bg-warning-base focus:outline-none focus:shadow-outline-focus focus:text-black"
                        >RPTs del Gobierno de Aragón</a
                    >
                </li>
            </ul>
        </ng-template>
        <div class="flex w-full" id="searchbar">
            <desy-dropdown text="{{ tipoRPTSeleccionado }}" [caller]="tipoRPT" classes="mr-2"></desy-dropdown>
            <desy-search-bar
                id="identificadorAdhesiones"
                placeholder="Buscar por código, nombre, departamento u organismo (ej. Asesor/a de gestión)."
                labelText="Búsqueda Procedimientos"
                name="filterDatasets"
                [(ngModel)]="search"
                (keyDown.enter)="searchRpts()"
                style="flex-grow: 1"
            >
                <desy-button
                    text="Buscar"
                    classes="c-button--primary ml-5 mr-0"
                    (click)="searchRpts()"
                ></desy-button>
            </desy-search-bar>
        </div>
    </div>

    <desy-spinner *ngIf="buscando" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
    <div *ngIf="!buscando" class="pb-md">
        <desy-label>
            <h1 class="block ng-start-inserted">
                <label for="">
                    <strong> {{ allRptsSinSeleccionados.length }} RPTs sugeridos</strong>
                </label>
            </h1>
        </desy-label>

        <div class="flex flex-wrap gap-sm w-full justify-between">
            <div class="mt-sm">
                <desy-checkboxes
                    idPrefix="example"
                    name="checkboxNgModel"
                    [items]="[{ value: 'seleccionaTodos', text: 'Seleccionar todo' }]"
                >
                    <desy-checkbox-item (change)="cambiaSeleccionaTodo()">
                        <p>Seleccionar todo</p>
                    </desy-checkbox-item>
                </desy-checkboxes>
            </div>
            <div class="mt-sm">
                <desy-button
                    text="Añadir a seleccionados"
                    classes="c-button--primary ml-sm"
                    (click)="onSaveSeleccionados()"
                    [disabled]="botonDisabled"
                ></desy-button>
            </div>
        </div>
        <desy-spinner *ngIf="actualizaTabla" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
        <desy-table-advanced
            [classes]="'min-w-full'"
            *ngIf="!actualizaTabla"
            class="table-rpt"
        >
            <desy-table-advanced-header>
                <desy-table-advanced-header-cell [orderBy]="orderBy">
                    <desy-content>Nombre</desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell>
                    <desy-content> Cargo </desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell
                    ><desy-content>Organismo</desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell
                    ><desy-content>Email</desy-content>
                </desy-table-advanced-header-cell>
            </desy-table-advanced-header>

            <desy-table-advanced-row *ngIf="!rpts.length">
                <desy-table-advanced-row-cell [colspan]="4">No hay resultados</desy-table-advanced-row-cell>
            </desy-table-advanced-row>

            <desy-table-advanced-row
                *ngFor="let rpt of rpts"
            >
                <desy-table-advanced-row-cell>
                    <desy-checkboxes class="table-rpt__checkboxes">
                        <desy-checkbox-item [checked]="rpt.check!" (change)="changeCheck(rpt)">
                            {{ rpt.nombre }}
                        </desy-checkbox-item>
                    </desy-checkboxes>
                </desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>
                    {{ rpt.cargo }}
                </desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>
                    {{ rpt.organismo }}
                </desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>
                    {{ rpt.email }}
                </desy-table-advanced-row-cell>
            </desy-table-advanced-row>
        </desy-table-advanced>
        <div class="pb-sm"></div>
        
        <desy-pagination
            *ngIf="allRptsSinSeleccionados.length"
            #paginator
            [itemsPerPage]="rows"
            [totalItems]="allRptsSinSeleccionados.length"
            [hasSelect]="true"
            [hasNext]="true"
            [hasPrevious]="true"
            [id]="'paginador'"
            [idPrefix]="'selector'"
            previousText="Anterior"
            nextText="Siguiente"
            [(currentPage)]="rptPage"
            (click)="onSeleccionarPagina($event)"
        >
        </desy-pagination>
    </div>

    <div *ngIf="rptSeleccionados.length">
        <desy-label>
            <h1 class="block ng-start-inserted py-4">
                <label for="">
                    <strong> {{ rptSeleccionados.length }} RPTs seleccionados</strong></label
                >
            </h1>
        </desy-label>

        <desy-spinner *ngIf="actualizaTablaSeleccionados" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
        
        <desy-table-advanced [classes]="'min-w-full mb-sm'" *ngIf="!actualizaTablaSeleccionados">
            <desy-table-advanced-header>
                <desy-table-advanced-header-cell>
                    <desy-content> Nombre </desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell>
                    <desy-content> Cargo </desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell>
                    <desy-content>Organismo</desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell>
                    <desy-content>Email</desy-content>
                </desy-table-advanced-header-cell>
            </desy-table-advanced-header>

            <desy-table-advanced-row *ngFor="let rpt of rptSeleccionadosPaginados">
                <desy-table-advanced-row-cell>{{ rpt.nombre }}</desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>{{ rpt.cargo }}</desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>{{ rpt.organismo }}</desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>
                    <div class="flex justify-between content-center">
                        {{ rpt.email }}
                        <button (click)="onDeleteSelectedRpt(rpt)">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00607A"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
                        </button>
                    </div>
                </desy-table-advanced-row-cell>
            </desy-table-advanced-row>
        </desy-table-advanced>
        <desy-pagination
            #paginator
            [itemsPerPage]="rows"
            [totalItems]="rptSeleccionados.length"
            [hasSelect]="true"
            [hasNext]="true"
            [hasPrevious]="true"
            [id]="'paginador'"
            [idPrefix]="'selector'"
            previousText="Anterior"
            nextText="Siguiente"
            [(currentPage)]="rptSeleccionadosPage"
            (click)="onSeleccionarPagina($event, true)"
        >
        </desy-pagination>
    </div>
    <div class="mt-lg">
        <desy-button
            text="Continuar"
            classes="c-button--primary ml-sm"
            (click)="goToResumen()"
            [disabled]="!rptSeleccionados.length"
        ></desy-button>
    </div>
</main>
<div class="pb-lg"></div>

<div class="modificar-rpt-component">
    <div class="header">
        <div class="header__content">
            <div class="flex gap-base">
                <div class="my-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" width="36.124" height="36.125" viewBox="0 0 36.124 36.125">
                        <g id="Grupo_1182" data-name="Grupo 1182" transform="translate(1.001)">
                            <g id="Grupo_1181" data-name="Grupo 1181" transform="translate(-1.001)">
                                <circle
                                    id="Elipse_40"
                                    data-name="Elipse 40"
                                    cx="7.096"
                                    cy="7.096"
                                    r="7.096"
                                    transform="translate(5.161)"
                                    fill="#5e616b"
                                />
                                <path
                                    id="Trazado_2929"
                                    data-name="Trazado 2929"
                                    d="M14.244,33.183a3.071,3.071,0,0,1,0-1.084l1.032-7.38a3.9,3.9,0,0,1,1.11-2.167l2.58-2.58a.568.568,0,0,0,.181-.516.593.593,0,0,0-.284-.49A11.612,11.612,0,0,0,13.7,17.107,12.257,12.257,0,0,0,0,29.415V31.35a2.58,2.58,0,0,0,2.58,2.58H13.6a.645.645,0,0,0,.49-.232.7.7,0,0,0,.155-.516Z"
                                    transform="translate(0.001 2.194)"
                                    fill="#5e616b"
                                />
                                <path
                                    id="Trazado_2930"
                                    data-name="Trazado 2930"
                                    d="M28.6,14.857a2.013,2.013,0,0,0-1.471.619l-9.5,9.47a2.58,2.58,0,0,0-.722,1.471l-.8,5.651a.645.645,0,0,0,.722.722l5.651-.8a2.58,2.58,0,0,0,1.471-.722l9.47-9.5a2.09,2.09,0,0,0,0-2.967L30.1,15.476A2.116,2.116,0,0,0,28.6,14.857Z"
                                    transform="translate(2.077 1.915)"
                                    fill="#5e616b"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="modificar-rpt-header">
                    <div class="flex flex-col justify-center">
                        <h1 class="title">Modificar RPTs</h1>
                        <div class="subtitle">Dicapacidad limitado Adhesión 1565</div>
                    </div>
                    <div class="modificar-rpt-header__button">
                        <desy-button classes="c-button--sm c-button--danger" (clickEvent)="cancel()">
                            Cancelar
                        </desy-button>
                        <desy-button classes="c-button--sm c-button--danger" (clickEvent)="guardarAdhesion()">
                            Guardar
                        </desy-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- TODO BOTONERA -->
    <div>
        <div class="block-button-rpts">
            <div class="flex gap-sm">
                <desy-button classes="c-button--sm c-button--primary" (clickEvent)="openAddRpts()">
                    Añadir RPTs
                </desy-button>
                <desy-button
                    [disabled]="botonDisabled"
                    classes="c-button--sm c-button--danger"
                    (clickEvent)="deleteRPTS()"
                >
                    Eliminar
                </desy-button>
            </div>
        </div>
        <div class="mt-sm"></div>
    </div>
    <!-- TODO TABLA -->
    <desy-spinner *ngIf="loading" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
    <desy-table-advanced [classes]="'min-w-full'" class="table-rpt" *ngIf="!loading">
        <desy-table-advanced-header>
            <desy-table-advanced-header-cell>
                <desy-content></desy-content>
            </desy-table-advanced-header-cell>
            <desy-table-advanced-header-cell [orderBy]="orderBy">
                <desy-content>Nombre</desy-content>
            </desy-table-advanced-header-cell>
            <desy-table-advanced-header-cell [orderBy]="orderBy">
                <desy-content> Cargo </desy-content>
            </desy-table-advanced-header-cell>
            <desy-table-advanced-header-cell [orderBy]="orderBy">
                <desy-content>Organismo</desy-content>
            </desy-table-advanced-header-cell>
            <desy-table-advanced-header-cell [orderBy]="orderBy">
                <desy-content>Email</desy-content>
            </desy-table-advanced-header-cell>
        </desy-table-advanced-header>

        <desy-table-advanced-row *ngIf="!adhesion?.RPTs?.length">
            <desy-table-advanced-row-cell [colspan]="4">No hay resultados</desy-table-advanced-row-cell>
        </desy-table-advanced-row>
        <desy-table-advanced-row *ngFor="let rpt of adhesion?.RPTs">
            <desy-table-advanced-row-cell>
                <desy-checkboxes class="table-rpt__checkboxes">
                    <desy-checkbox-item [checked]="rpt.check!" (change)="changeCheck(rpt)"> </desy-checkbox-item>
                </desy-checkboxes>
            </desy-table-advanced-row-cell>
            <desy-table-advanced-row-cell>
                {{ rpt.nombre }}
            </desy-table-advanced-row-cell>
            <desy-table-advanced-row-cell>
                {{ rpt.cargo }}
            </desy-table-advanced-row-cell>
            <desy-table-advanced-row-cell>
                {{ rpt.organismo }}
            </desy-table-advanced-row-cell>
            <desy-table-advanced-row-cell>
                {{ rpt.email }}
            </desy-table-advanced-row-cell>
        </desy-table-advanced-row>
    </desy-table-advanced>
    <!--TODO - Alert -->
    <div *ngIf="modalAlert" class="modificar-rpt-component__modal-cancel">
        <app-alert
            [idAdhesion]="idAdhesion"
            (datosAlPadreEvent)="responseDataSon($event)"
            (cerrarModalEvent)="closeModalSon($event)"
        >
        </app-alert>
    </div>

    <!--TODO MODAL-->
    <desy-dialog
        *ngIf="openModalAddRPTS === true"
        [(active)]="openModalAddRPTS"
        [id]="'modal'"
        [caller]="edit"
        classes="
    relative 
    max-h 
    lg:p-lg 
    border 
    border-neutral-base 
    rounded 
    bg-white
    modificar-rpt-component__modal
    "
    >
        <ng-template #edit>
            <div class="modificar-rpt-component__modal__title">Seleccione los RPTs que desee añadir.</div>
            <div class="modificar-rpt-component__modal__body">
                <p class="c-paragraph-base text-neutral-dark">
                    Añade las personas físicas o RPTs que necesiten adherirse.
                </p>

                <div class="flex pb-md modificar-rpt-component__modal__body__searchbar" id="searchbar">
                    <ng-template #tipoRPT>
                        <ul>
                            <li>
                                <a
                                    (click)="cambiaTipoRPT('RPTs de mi equipo')"
                                    class="cursor-pointer flex items-center px-base py-xs hover:bg-primary-base hover:text-white truncate focus:bg-warning-base focus:outline-none focus:shadow-outline-focus focus:text-black"
                                    >RPTs de mi equipo</a
                                >
                            </li>
                            <li>
                                <a
                                    (click)="cambiaTipoRPT('RPTs de gobierno')"
                                    class="cursor-pointer flex items-center px-base py-xs hover:bg-primary-base hover:text-white truncate focus:bg-warning-base focus:outline-none focus:shadow-outline-focus focus:text-black"
                                    >RPTs del Gobierno de Aragón</a
                                >
                            </li>
                        </ul>
                    </ng-template>
                    <div class="flex w-full" id="searchbar">
                        <desy-dropdown
                            text="{{ tipoRPTSeleccionado }}"
                            [caller]="tipoRPT"
                            classes="mr-2"
                        ></desy-dropdown>
                        <desy-search-bar
                            id="identificadorAdhesiones"
                            placeholder="Buscar por código, nombre, departamento u organismo (ej. Asesor/a de gestión)."
                            labelText="Búsqueda Procedimientos"
                            name="filterDatasets"
                            [(ngModel)]="search"
                            (keyDown.enter)="searchRpts()"
                            style="flex-grow: 1"
                        >
                            <desy-button
                                text="Buscar"
                                classes="c-button--primary ml-5 mr-0"
                                (click)="searchRpts()"
                            ></desy-button>
                        </desy-search-bar>
                    </div>
                </div>

                <desy-spinner *ngIf="loading" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
                <div
                    class="flex flex-wrap gap-sm w-full justify-between modificar-rpt-component__modal__body__container-table__buttombar"
                >
                    <div class="mt-sm">
                        <desy-checkboxes
                            idPrefix="example"
                            name="checkboxNgModel"
                            [items]="[{ value: 'seleccionaTodos', text: 'Seleccionar todo' }]"
                        >
                            <desy-checkbox-item (change)="cambiaSeleccionaTodo()">
                                <p>Seleccionar todo</p>
                            </desy-checkbox-item>
                        </desy-checkboxes>
                    </div>
                </div>
                <div *ngIf="!buscando" class="pb-md modificar-rpt-component__modal__body__container-table">
                    <desy-table-advanced
                        [classes]="'min-w-full modificar-rpt-component__modal__body__container-table__table'"
                        *ngIf="!loading"
                        class="table-rpt"
                    >
                        <desy-table-advanced-header>
                            <desy-table-advanced-header-cell [orderBy]="orderBy">
                                <desy-content>Nombre</desy-content>
                            </desy-table-advanced-header-cell>
                            <desy-table-advanced-header-cell>
                                <desy-content> Cargo </desy-content>
                            </desy-table-advanced-header-cell>
                            <desy-table-advanced-header-cell
                                ><desy-content>Organismo</desy-content>
                            </desy-table-advanced-header-cell>
                            <desy-table-advanced-header-cell
                                ><desy-content>Email</desy-content>
                            </desy-table-advanced-header-cell>
                        </desy-table-advanced-header>

                        <desy-table-advanced-row *ngIf="!rpts?.length">
                            <desy-table-advanced-row-cell [colspan]="4">No hay resultados</desy-table-advanced-row-cell>
                        </desy-table-advanced-row>

                        <desy-table-advanced-row *ngFor="let rpt of rpts">
                            <desy-table-advanced-row-cell>
                                <desy-checkboxes class="table-rpt__checkboxes">
                                    <desy-checkbox-item [checked]="rpt.check!" (change)="changeCheck(rpt)">
                                        {{ rpt.nombre }}
                                    </desy-checkbox-item>
                                </desy-checkboxes>
                            </desy-table-advanced-row-cell>
                            <desy-table-advanced-row-cell>
                                {{ rpt.cargo }}
                            </desy-table-advanced-row-cell>
                            <desy-table-advanced-row-cell>
                                {{ rpt.organismo }}
                            </desy-table-advanced-row-cell>
                            <desy-table-advanced-row-cell>
                                {{ rpt.email }}
                            </desy-table-advanced-row-cell>
                        </desy-table-advanced-row>
                    </desy-table-advanced>
                    <div class="pb-sm"></div>
                </div>
                <desy-pagination
                    *ngIf="allRptsSinSeleccionados.length"
                    #paginator
                    [itemsPerPage]="rows"
                    [totalItems]="allRptsSinSeleccionados.length"
                    [hasSelect]="true"
                    [hasNext]="true"
                    [hasPrevious]="true"
                    [id]="'paginador'"
                    [idPrefix]="'selector'"
                    previousText="Anterior"
                    nextText="Siguiente"
                    [(currentPage)]="rptPage"
                    (click)="onSeleccionarPagina($event)"
                    [classes]="'modificar-rpt-component__modal__body__container-table__paginator'"
                >
                </desy-pagination>
            </div>
            <div class="modificar-rpt-component__modal__buttons">
                <div class="modificar-rpt-component__modal__buttons__save">
                    <desy-button
                        [disabled]="botonDisabled"
                        classes="c-button--sm c-button--primary"
                        (clickEvent)="AddRpts()"
                    >
                        Guardar
                    </desy-button>
                </div>
                <div class="modificar-rpt-component__modal__buttons__close">
                    <desy-button classes="c-button--sm c-button--danger" (clickEvent)="closeAddRpts()">
                        Cerrar
                    </desy-button>
                </div>
            </div>
        </ng-template>
    </desy-dialog>
</div>

import { environment } from 'src/environments/environment';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ProtocolosService } from '../../../services/protocolos/protocolos.service';
import { Query } from '../../../interfaces/query';
import { Protocolo } from '../../../interfaces/protocolo';
import { AlertOptions, AlertService, DialogOptions, DialogService, NotificationOptions } from 'desy-angular';
import { OrganizacionService } from '../../../services/organizaciones/organizaciones.service';
import { FiltroOrganismosComponent } from '../../../shared/filtro-organismos/filtro-organismos.component';
import { Params, Router } from '@angular/router';
import { Organizacion } from '../../../interfaces/organizacion';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';
import { RptService } from 'src/app/services/rpt/rpt.service';
import { EstadosAdhesionEnum } from 'src/app/enum/estados-adhesion';
import organismosJson from './org-dep/org-dep.json'
import { Organismos } from 'src/app/interfaces/organismos';
@Component({
    selector: 'app-paso1',
    templateUrl: './paso1.component.html',
    styleUrls: ['./paso1.component.css'],
})
export class Paso1Component implements OnInit {
    @ViewChild('alertPlace', { read: ViewContainerRef }) alertPlace: ViewContainerRef;
    @ViewChild('paginator') el: ElementRef | undefined;
    rows: number = 10;
    organismos: Organismos[] = []
    protocolos: Protocolo[] = [];
    protocolosPaginados: Protocolo[] = [];
    protocoloQuery: Query = {
        q: '',
        fq: {
            OBJ_SUB_TYPE: ['DSA'],
            STATE: [EstadosAdhesionEnum.APROBADA],
        },
        start: 0,
        orderBy: 'OBJ_NAME',
        sortingType: 'ASC',
    };
    selectedPageDefault = 1;
    organizaciones: Organizacion[] = [];
    organizacionesSeleccionadas: string[] = [];
    organizacionQuery: Query = {
        q: '',
        fq: {
            OBJ_SUB_TYPE: ['DSA'],
            STATE: [EstadosAdhesionEnum.APROBADA],
        },
        start: 0,
        orderBy: 'OBJ_NAME',
    };
    numResultados: number = 0;
    buscando: boolean = false;
    organizacionesBusqueda: string[] = [];
    readonly EstadosAdhesionEnum = EstadosAdhesionEnum;

    resultadoDelMatch = [{
        codigo_organismo: '',
        nombre_organismo: '',
        nombre_departamento: ''
    }]

    dataProtocolos: any = null;
    Params: string = '';

    resultados: any[] = [];
    dataFilterWithEnviroment: any;

    constructor(
        private protocolosService: ProtocolosService,
        private nuevaAdhesionService: NuevaAdhesionService,
        private organizacionService: OrganizacionService,
        private dialogService: DialogService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private alertService: AlertService,
        private rptService: RptService,
    ) { }

    async ngOnInit() {
        this.buscando = true;
        //Precarga la informacion de los rpts de aragon
        this.rptService.getRPTsDynamoAragon();
        
        await this.onGetProtocolos(this.protocoloQuery);
        await this.onGetOrganizaciones(this.organizacionQuery);
        this.buscando = false;

    }

    async onButtonClick(event: any) {
        if (event.target?.id === 'selector-next') {
            await this.onNext();
        } else if (event.target?.id === 'selector-previous') {
            await this.onPrevious();
        } else if (event.target?.id.includes('selector')) {
            if (event.target?.id === 'selector') {
                this.onPageSelect(1);
            } else {
                let paginaInicial = +event.target?.id?.substring(9);
                await this.onPageSelect(paginaInicial + 1);
            }
        }
    }

    async onNext() {
        this.changePage();
    }

    async onPrevious() {
        this.changePage();
    }

    async onPageSelect(numPagina: number) {
        this.selectedPageDefault = numPagina;
        this.changePage();
    }

    async onGetProtocolos(query: Query) {
        this.buscando = true;
        this.protocolos = [];
        this.protocolosPaginados = [];
        if (this.dataProtocolos === null || this.dataProtocolos?.results?.BASIC.length === 1) {
            this.dataProtocolos = await this.protocolosService.getProtocolos(query);
        }
        if (this.dataProtocolos === null || this.dataProtocolos === undefined) {
            const alertOptions: AlertOptions = {
                id: 'alert',
                place: this.alertPlace,
                role: 'alert',
                ariaLive: 'assertLive',
                classes: 'mt-5',
            };
            const notification: NotificationOptions = {
                title: { text: 'Se ha producido un error al cargar la página' },
                description: { text: 'Por favor, inténtelo de nuevo más tarde' },
                type: 'alert',
            };
            this.alertService.openAlert(notification, alertOptions);
            return;
        }


        if (this.organizacionesBusqueda.length) {
            const dataJson = organismosJson
            // Iterar sobre cada objeto del array
            this.resultadoDelMatch = [];
            dataJson.forEach(objeto => {
                // Verificar si el nombre_departamento está en el array nombresDepartamentos
                if (this.organizacionesBusqueda.includes(objeto.nombre_departamento)) {
                    this.resultadoDelMatch.push(objeto);
                }
            });
        }


        const dataJson = organismosJson
        this.organismos = dataJson
        const codigoInThisEnvironment = this.organismos.map((x: any) => environment.entorno + x.codigo_organismo);

        this.dataFilterWithEnviroment = this.dataProtocolos.results.BASIC.filter((x: any) => {
            const unidadesOrganizacionales = x.solrDocument.ORGANIZATIONAL_UNIT;
            if (unidadesOrganizacionales) {
                return unidadesOrganizacionales.some((unit: any) => codigoInThisEnvironment.includes(unit));
            }
            return false;
        });

        const codigosOrganismo = new Set(this.resultadoDelMatch.map(match => match.codigo_organismo));
        const dataWithFilter = [];

        if (this.resultadoDelMatch.length > 1) {
            dataWithFilter.push(this.dataFilterWithEnviroment.filter((resultado: any) => {
                return codigosOrganismo.has(resultado.solrDocument.ORGANIZATIONAL_UNIT[0]);
            }))

            dataWithFilter.forEach((dato: any) => {
                dato.map((x: any) => {
                    let nombre = x.solrDocument.OBJ_NAME;

                    const ojborganismo = this.organismos.find(
                        y => (environment.entorno + y.codigo_organismo) === x.solrDocument.ORGANIZATIONAL_UNIT[0]
                    )
                    let nombreOrganismo = ojborganismo!.nombre_organismo
                    let nombreDepartamento = ojborganismo!.nombre_departamento

                    let descripcion = x.solrDocument.DESCRIPTION;
                    if (descripcion.charAt(descripcion.length - 1) !== '.') descripcion += '.';

                    let protocolo: Protocolo = {
                        nombre: nombre,
                        nombreOrganismo: nombreOrganismo,
                        nombreDepartamento: nombreDepartamento,
                        descripcion: descripcion,
                        id: x.solrDocument?.ID_OBJ,
                        ari: x.solrDocument.ARI,
                        condiciones: x.solrDocument.CONDICIONES ?? null,
                        PI: x.solrDocument.PI,
                        state: x.solrDocument.STATE
                    };
                    if (protocolo.nombreOrganismo === 'Dpto_Ciudadania') {
                        protocolo.organismoExtendido = ' Departamento de Ciudadania y Derechos Sociales';
                    } else {
                        protocolo.organismoExtendido = protocolo.nombreOrganismo;
                    }
                    this.protocolos.push(protocolo);
                })
            });
        } else {
            this.dataFilterWithEnviroment.forEach((dato: any) => {
                let nombre = dato.solrDocument.OBJ_NAME;

                const ojborganismo = this.organismos.find(
                    x => (environment.entorno + x.codigo_organismo) === dato.solrDocument.ORGANIZATIONAL_UNIT[0]
                )
                let nombreOrganismo = ojborganismo!.nombre_organismo
                let nombreDepartamento = ojborganismo!.nombre_departamento

                let descripcion = dato.solrDocument.DESCRIPTION;
                if (descripcion.charAt(descripcion.length - 1) !== '.') descripcion += '.';

                let protocolo: Protocolo = {
                    nombre: nombre,
                    nombreOrganismo: nombreOrganismo,
                    nombreDepartamento: nombreDepartamento,
                    descripcion: descripcion,
                    id: dato.solrDocument?.ID_OBJ,
                    ari: dato.solrDocument.ARI,
                    condiciones: dato.solrDocument.CONDICIONES ?? null,
                    PI: dato.solrDocument.PI,
                    state: dato.solrDocument.STATE
                };
                if (protocolo.nombreOrganismo === 'Dpto_Ciudadania') {
                    protocolo.organismoExtendido = ' Departamento de Ciudadania y Derechos Sociales';
                } else {
                    protocolo.organismoExtendido = protocolo.nombreOrganismo;
                }
                this.protocolos.push(protocolo);
            });
        }


        this.numResultados = this.protocolos.length;
        this.protocolosPaginados = this.protocolos.slice(
            (this.selectedPageDefault - 1) * this.rows,
            (this.selectedPageDefault - 1) * this.rows + this.rows
        );
        this.buscando = false;
    }
    normalizarCadena(cadena: any) {
        // Convertir a minúsculas y eliminar tildes
        return cadena?.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }

    filterProtocolos(params: string) {
        this.buscando = true;
        this.protocolosPaginados = [];
        this.resultados = []; // Limpiar los resultados anteriores
        this.protocolos = []; // Limpiar la lista de protocolos anterior
        const paramsNormalizado = this.normalizarCadena(params);

        const filteredResults = this.dataFilterWithEnviroment.filter((doc: any) => {
            const solrDoc = doc.solrDocument || {};
            const objName = solrDoc.OBJ_NAME || "";
            const description = solrDoc.DESCRIPTION || "";

            const objNameNormalizado = this.normalizarCadena(objName);
            const descriptionNormalizado = this.normalizarCadena(description);

            return objNameNormalizado.includes(paramsNormalizado) || descriptionNormalizado.includes(paramsNormalizado);
        });

        const protocolosFiltrados = filteredResults.map((x: any) => {
            const solrDoc = x.solrDocument || {};
            const nombre = solrDoc.OBJ_NAME;
            const ojborganismo = this.organismos?.find(
                y => (environment?.entorno + y?.codigo_organismo) === solrDoc.ORGANIZATIONAL_UNIT?.[0]
            );
            const nombreOrganismo = ojborganismo?.nombre_organismo || '';
            const nombreDepartamento = ojborganismo?.nombre_departamento || '';
            let descripcion = solrDoc.DESCRIPTION || '';
            if (descripcion && descripcion.charAt(descripcion.length - 1) !== '.') descripcion += '.';

            let protocolo: Protocolo = {
                nombre: nombre,
                nombreOrganismo: nombreOrganismo,
                nombreDepartamento: nombreDepartamento,
                descripcion: descripcion,
                id: solrDoc.ID_OBJ,
                ari: solrDoc.ARI,
                condiciones: solrDoc.CONDICIONES ?? null,
                PI: solrDoc.PI,
                state: solrDoc.STATE
            };

            if (protocolo.nombreOrganismo === 'Dpto_Ciudadania') {
                protocolo.organismoExtendido = 'Departamento de Ciudadania y Derechos Sociales';
            } else {
                protocolo.organismoExtendido = protocolo.nombreOrganismo;
            }

            return protocolo;
        });

        const protocolosCoincidentes = protocolosFiltrados.filter((protocolo: any) => {
            const nombreNormalizado = this.normalizarCadena(protocolo.nombre);
            const descripcionNormalizada = this.normalizarCadena(protocolo.descripcion);
            return nombreNormalizado.includes(paramsNormalizado) || descripcionNormalizada.includes(paramsNormalizado);
        });

        this.resultados = protocolosCoincidentes;
        this.numResultados = protocolosCoincidentes.length;
        this.protocolosPaginados = protocolosCoincidentes.slice(
            (this.selectedPageDefault - 1) * this.rows,
            (this.selectedPageDefault - 1) * this.rows + this.rows
        );

        if (!params) {
            this.onGetProtocolos(this.organizacionQuery);
        }

        this.buscando = false;
    }


    async onGetOrganizaciones(organizacionQuery: Query) {
        this.buscando = true;
        this.organizaciones = [];
        this.organizacionesSeleccionadas = [];
        this.organizaciones = await this.organizacionService.getOrganizaciones(organizacionQuery);
        this.buscando = false;
    }

    openDialog(): void {
        const dialogOptions: DialogOptions = {
            id: 'dialog',
            dismissOnBackdrop: true,
            focusOnClose: 'dialog-btn',
        };
        this.dialogService.openDialog(FiltroOrganismosComponent, dialogOptions).then(async (result) => {
            this.organizacionService.postDialog(result.dialog);
            this.dialogService.onCloseDialog(result.dialog).then(async () => {
                if (this.organizacionService.activaBusqueda) {
                    this.organizacionesBusqueda = this.organizacionService.getNombresQueryOrganizaciones();
                    this.buscando = true;
                    await this.onGetProtocolos(this.protocoloQuery);

                    this.buscando = false;
                }
            });
        });
    }

    async onEliminaFiltros() {
        this.buscando = true;
        this.organizacionesBusqueda = []
        this.organizaciones.map(x => x.check = false)
        this.resultadoDelMatch = [];
        await this.onGetProtocolos(this.protocoloQuery);
        this.buscando = false;
    }

    async goToServicio(protocolo: Protocolo) {
        const selectedProtocolo = await this.protocolosService.getDatosProtocolo(protocolo.id);
        
        const mappedProtocolo = this.protocolosService.mapProtocolo(selectedProtocolo, protocolo.id!, protocolo.ari, protocolo.state!);
        this.nuevaAdhesionService.setProtocoloActual(mappedProtocolo);
        this.router.navigate(['nueva-adhesion/seleccion-servicio']);
    }

    async goToDetalle(protocolo: Protocolo) {
        const selectedProtocolo = await this.protocolosService.getDatosProtocolo(protocolo.id);
        const mappedProtocolo = this.protocolosService.mapProtocolo(selectedProtocolo, protocolo.id!, protocolo.ari, protocolo.state!);
        this.nuevaAdhesionService.setProtocoloActual(mappedProtocolo);
        this.router.navigate(['/nueva-adhesion/protocolos', protocolo.id]);
    }

    async onSeleccionarPagina() {
        this.changePage();
    }
    async changePage() {
        this.buscando = true;
        this.protocolosPaginados = this.protocolos.slice(
            (this.selectedPageDefault - 1) * this.rows,
            (this.selectedPageDefault - 1) * this.rows + this.rows
        );
        this.changeDetectorRef.detectChanges();
        this.buscando = false;
        window.scroll(0, 0);
    }
}

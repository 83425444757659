import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-table',  // El selector correcto
  templateUrl: './modal-table.component.html',  // Asegúrate de que coincida con el nombre del archivo
  styleUrls: ['./modal-table.component.css']    // Asegúrate de que coincida con el nombre del archivo
})
export class ModalTableComponent {
  @Input() modalChart: any[];  // Recibe los datos desde el componente padre
  @Input() isOpen: boolean = false;  // Controla si el modal está abierto
  @Output() close = new EventEmitter<void>();  // Evento para cerrar el modal

  closeModal() {
    this.close.emit();  // Emite el evento de cierre al componente padre
  }
}

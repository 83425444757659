<ng-template #alertPlace></ng-template>
<h2 class="c-h2">Mis adhesiones {{ tipoListado }}</h2>

<div class="flex" id="searchbar">
    <desy-search-bar
        id="identificadorAdhesiones"
        placeholder="Buscar por nombre, departamento, organismo, descripción (Ej. Protocolo de discapacidad limitado)..."
        labelText="Búsqueda Nueva Adhesión"
        name="filterDatasets"
        [(ngModel)]="query.q"
        (keyDown.enter)="filterAdhesiones(query.q)"
        style="flex-grow: 1"
    >
        <desy-button
            text="Buscar"
            classes="c-button--primary ml-5 mr-0"
            (clickEvent)="filterAdhesiones(query.q)"
        ></desy-button>
    </desy-search-bar>
</div>

<desy-spinner *ngIf="buscando" classes="mb-2"></desy-spinner>
<desy-label classes="mb-1" *ngIf="!buscando">
    <h1 class="block ng-start-inserted mt-1 mb-1">
        <label for="">
            <strong> {{ adhesionesFiltradas.length }} resultados</strong>
        </label>
    </h1>
</desy-label>
<div *ngIf="!buscando">
    <div class="flex justify-start flex-wrap" style="column-gap: 1rem">
        <desy-button
            classes="c-paragraph-sm c-button--sm"
            text="Organismo responsable"
            [ngClass]="{ active: organizacionesSeleccionadas.length }"
            (clickEvent)="openDialog()"
            [id]="'botonOrganismos'"
        ></desy-button>
        <desy-dropdown
            classes="c-paragraph-sm c-dropdown--sm"
            [ngClass]="{ active: estadosSeleccionados.length }"
            [caller]="caller2"
            (clickEvent)="openStates()"
        >
            Estado
        </desy-dropdown>
        <desy-dropdown
            classes="c-paragraph-sm c-dropdown--sm"
            classesTooltip="w-full max-w-none p-5"
            [ngClass]="{ active: filtroFechaCreacion }"
            [caller]="callerFechaCreacion"
            >Fecha de creación</desy-dropdown
        >
        <desy-dropdown
            classes="c-paragraph-sm c-dropdown--sm"
            classesTooltip="w-full max-w-none p-5"
            [ngClass]="{ active: filtroFechaModificacion }"
            [caller]="callerFechaModificacion"
            >Fecha de modificación</desy-dropdown
        >
        <desy-button classes="c-paragraph-sm c-button--sm c-button--transparent" (click)="onFiltroEstado()"
            >Eliminar Filtros</desy-button
        >
        <desy-menubar
            [id]="'orderBy'"
            [idPrefix]="'orderBy'"
            classes="c-paragraph-sm c-dropdown--sm"
            class="order-by"
            [items]="ordenItems"
            (activeSubItemChange)="onOrderBy($event)"
        >
        </desy-menubar>
    </div>
    <div class="pb-sm"></div>

    <div *ngFor="let adhesion of adhesionesPaginadas">
        <app-enviadas
            *ngIf="tipoListado === 'enviadas'"
            [adhesion]="adhesion"
            (error)="openError($event)"
            (update)="getAdhesiones()"
        ></app-enviadas>
        <app-recibidas
            (error)="openError($event)"
            (update)="getAdhesiones()"
            *ngIf="tipoListado === 'recibidas'"
            [adhesion]="adhesion"
        ></app-recibidas>
        <!-- [bloqueadaPorMi]="adhesionesBloqueadasPorMi.includes(+adhesion.idAdhesion!)" -->
    </div>
</div>
<div class="py-xl" *ngIf="buscando">
    <desy-spinner></desy-spinner>
</div>
<desy-pagination
    *ngIf="adhesionesFiltradas.length"
    #paginator
    [itemsPerPage]="rows"
    [totalItems]="adhesionesFiltradas.length"
    [hasSelect]="true"
    [hasNext]="true"
    [hasPrevious]="true"
    [id]="'paginador'"
    [idPrefix]="'selector'"
    previousText="Anterior"
    nextText="Siguiente"
    [(currentPage)]="selectedPageDefault"
    (click)="onSeleccionarPagina($event)"
>
</desy-pagination>

<ng-template #caller2>
    <div class="flex flex-col items-start p-2">
        <desy-tree
            [name]="'exampleNameContent'"
            [type]="'checkbox'"
            [expandedFirstLevel]="false"
            [decoupleChildFromParent]="false"
            class="state-tree"
            classes="w-32"
        >
            <div *ngFor="let estado of states" class="flex flex-col justify-items-center" id="estado">
                <desy-tree-item
                    *ngIf="estado.valor != undefined && estado.checked != undefined"
                    [value]="estado.valor"
                    [(checked)]="estado.checked"
                >
                    <desy-label>{{ estado.texto }}</desy-label>
                </desy-tree-item>
            </div>
        </desy-tree>
        <div class="flex mx-auto">
            <desy-button text="Seleccionar" classes="c-button--primary" (clickEvent)="onFiltroEstado()"></desy-button>
        </div>
    </div>
</ng-template>

<ng-template #callerFechaCreacion class="big max-w-none">
    <div class="big">
        <desy-error-message *ngIf="errores.rangoCreacion"
            >El rango de fecha introducido no es valido</desy-error-message
        >
        <desy-title>Fecha de creación</desy-title>
        <div class="w-full flex justify-between content-center items-center">
            <form class="form" [formGroup]="form">
                <desy-date-input
                    formGroupName="fechaDesdeCreacion"
                    [items]="itemsFechas"
                    legendText="Desde"
                    hintText="Por ejemplo, 31 3 1980"
                    [errorMessageText]="errores.creacionDesde ? 'La fecha introducida no es válida' : ''"
                ></desy-date-input>
            </form>
            <form class="form" [formGroup]="form">
                <desy-date-input
                    formGroupName="fechaHastaCreacion"
                    [items]="itemsFechas"
                    legendText="Hasta"
                    hintText="Por ejemplo, 31 3 2025"
                    [errorMessageText]="errores.creacionHasta ? 'La fecha introducida no es válida' : ''"
                ></desy-date-input>
            </form>
        </div>
        <div class="flex justify-between">
            <desy-button classes="c-button--primary" (clickEvent)="onFiltrosFecha('creacion')">Aplicar</desy-button>
            <desy-button classes="mr-sm" (clickEvent)="onCancelarFechas('creacion')">Cancelar</desy-button>
        </div>
    </div>
</ng-template>
<ng-template #callerFechaModificacion class="big max-w-none">
    <div class="big">
        <desy-error-message *ngIf="errores.rangoModificacion"
            >El rango de fecha introducido no es válido</desy-error-message
        >
        <desy-title>Fecha de modificación</desy-title>
        <div class="w-full flex justify-between content-center items-center">
            <form class="form" [formGroup]="form">
                <desy-date-input
                    formGroupName="fechaDesdeModificacion"
                    [items]="itemsFechas"
                    legendText="Desde"
                    hintText="Por ejemplo, 31 3 1980"
                    [errorMessageText]="errores.modificacionDesde ? 'La fecha introducida no es válida' : ''"
                ></desy-date-input>
            </form>
            <form class="form" [formGroup]="form">
                <desy-date-input
                    formGroupName="fechaHastaModificacion"
                    [items]="itemsFechas"
                    legendText="Hasta"
                    hintText="Por ejemplo, 31 3 2025"
                    [errorMessageText]="errores.modificacionHasta ? 'La fecha introducida no es valida' : ''"
                ></desy-date-input>
            </form>
        </div>
        <div class="flex justify-between">
            <desy-button classes="c-button--primary" (clickEvent)="onFiltrosFecha('modificacion')">Aplicar</desy-button>
            <desy-button classes="mr-sm" (clickEvent)="onCancelarFechas('modificacion')">Cancelar</desy-button>
        </div>
    </div>
</ng-template>

<ng-template #callerOrderBy class="w-40">
    <ul *ngFor="let order of orderBy" class="w-40">
        <li>
            <a
                [routerLink]=""
                (click)="onOrderBy(order)"
                class="flex items-center w-36 pr-0 px-base py-xs hover:bg-primary-base hover:text-white truncate focus:bg-warning-base focus:outline-none focus:shadow-outline-focus focus:text-red"
                >{{ order }}</a
            >
        </li>
    </ul>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions, Router } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.guard';
import { HomeComponent } from './componentes/home/home.component';
import { LoginComponent } from './componentes/login/login.component';
import { IsloggedAuthGuard } from './guards/islogged-auth.guard';
import { ListadoAdhesionesComponent } from './componentes/listado-adhesiones/listado-adhesiones.component';
import { AdhesionEnviadaComponent } from './componentes/adhesion-enviada/adhesion-enviada.component';
import { VerAdhesionRecibidaComponent } from './componentes/ver-adhesion-recibida/ver-adhesion-recibida.component';
import { VerAdhesionEnviadaComponent } from './componentes/ver-adhesion-enviada/ver-adhesion-enviada.component';
import { DetalleProtocoloAdhesionComponent } from 'src/app/componentes/detalle-protocolo-adhesion/detalle-protocolo-adhesion.component';
import { NuevaAdhesionComponent } from './componentes/nueva-adhesion/nueva-adhesion.component';
import { Paso1Component } from './componentes/nueva-adhesion/paso1/paso1.component';
import { Paso2Component } from './componentes/nueva-adhesion/paso2/paso2.component';
import { Paso3Component } from './componentes/nueva-adhesion/paso3/paso3.component';
import { Paso4Component } from './componentes/nueva-adhesion/paso4/paso4.component';
import { Paso5Component } from './componentes/nueva-adhesion/paso5/paso5.component';
import { DetallesProtocoloComponent } from './componentes/nueva-adhesion/paso1/detalles-protocolo/detalles-protocolo.component';
import { ModificarRptComponent } from './componentes/modificar-rpt/modificar-rpt.component';
import { HistoricosComponent } from './componentes/historico/historico.component';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
    },
    { path: 'protocolo/detalle/:idProtocolo', component: DetalleProtocoloAdhesionComponent, canActivate: [AuthGuard] },
    {
        path: 'acceso',
        component: LoginComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [IsloggedAuthGuard],
    },
    {
        path: '',
        redirectTo: 'acceso',
        pathMatch: 'full',
    },
    {
        path: 'nueva-adhesion',
        component: NuevaAdhesionComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'protocolos',
                component: Paso1Component,
                canActivate: [AuthGuard],
            },
            { 
                path: 'protocolos/:idProtocolo', 
                component: DetallesProtocoloComponent, 
                canActivate: [AuthGuard] 
            },
            {
                path: 'seleccion-servicio',
                component: Paso2Component,
                canActivate: [AuthGuard],
            },
            {
                path: 'incorporacion-bases',
                component: Paso3Component,
                canActivate: [AuthGuard],
            },
            {
                path: 'RPTs',
                component: Paso4Component,
                canActivate: [AuthGuard],
            },
            {
                path: 'resumen',
                component: Paso5Component,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'listado-adhesiones/:tipoListado',
        component: ListadoAdhesionesComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
    },
    { path: 'adhesion-enviada/:idAdhesion', component: AdhesionEnviadaComponent, canActivate: [AuthGuard] },
    { path: 'detalle-adhesion-recibida/:idAdhesion', component: VerAdhesionRecibidaComponent, canActivate: [AuthGuard] },
    { path: 'detalle-adhesion-enviada/:idAdhesion', component: VerAdhesionEnviadaComponent, canActivate: [AuthGuard] },
    { path: 'modificar-rpts/:idAdhesion', component: ModificarRptComponent, canActivate: [AuthGuard] },

    { path: 'uso-datos/cedidos', component: HistoricosComponent, canActivate: [AuthGuard] },
    { path: 'uso-datos/adheridos', component: HistoricosComponent, canActivate: [AuthGuard] }

];
const routerOptions: ExtraOptions = {
    useHash: true,
    anchorScrolling: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}

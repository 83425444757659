import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Organismos } from 'src/app/interfaces/organismos';
import { OrganizacionService } from '../../services/organizaciones/organizaciones.service';
import { ProcedimientoAdministrativo } from 'src/app/interfaces/procedimiento-administrativo';
import { ProcedimientoAdminsitrativoService } from 'src/app/services/procedimiento-administrativo/procedimiento-adminsitrativo.service';
import { Protocolo } from '../../interfaces/protocolo';
import { ProtocolosService } from '../../services/protocolos/protocolos.service';
import { Query } from '../../interfaces/query';

@Component({
    selector: 'app-detalle-protocolo',
    templateUrl: './detalle-protocolo.component.html',
    styleUrls: ['./detalle-protocolo.component.css'],
})
export class DetalleProtocoloComponent implements OnInit {
    protocolo: Protocolo;
    procedimiento: ProcedimientoAdministrativo;
    datosProtocolo: any;
    waiting = false;
    idProtocolo: any;
    idAdhesion: Number;

    organismos: Organismos[] = [];
    isCollapsed: boolean = true;
    constructor(
        private protocoloService: ProtocolosService,
        private procedimientoService: ProcedimientoAdminsitrativoService,
        private router: Router,
        private route: ActivatedRoute,
        private organizacionService: OrganizacionService
    ) {}

    ngOnInit(): void {
        this.getDatos();
        this.idAdhesion = Number(this.route.snapshot.queryParamMap.get('idAdhesion'));
    }

    async getDatos() {
        this.waiting = true;
        this.idProtocolo = this.route.snapshot.paramMap.getAll('idProtocolo');
        await this.getProtocolo();
        this.procedimiento = this.procedimientoService.getProcedimientoActual();
        let res = await this.protocoloService.getDatosProtocolo(this.idProtocolo);
        let datosOrdenados = this.protocoloService.getDatosProtocoloOrdenados(res);
        this.datosProtocolo = this.getNivelesDatos(datosOrdenados);
        this.datosProtocolo = this.removeDuplicatesAndSort(this.datosProtocolo);
        this.waiting = false;
    }
    // Función para ordenar alfabéticamente por una propiedad específica
    removeDuplicatesAndSort(datos: any) {
        const sinDuplicados = [...new Set(datos)];
        return sinDuplicados.sort((a: any, b: any) => a.localeCompare(b));
    }

    async getProtocolo() {
        if (
            this.protocoloService.getProtocoloActual() != null &&
            this.protocoloService.getProtocoloActual() != undefined
        ) {
            this.protocolo = this.protocoloService.getProtocoloActual();
        } else {
            let query: Query = {
                q: '',
                fq: {
                    ID_OBJ: this.idProtocolo,
                },
                start: 0,
            };
            const data: any = await this.protocoloService.getProtocolos(query);
            this.createProtocolo(data);
        }
    }

    createProtocolo(data: any) {
        let dato = data.results.BASIC[0];
        let nombre = dato.solrDocument.OBJ_NAME;
        const dataJson = JSON.parse(localStorage.getItem('organismos')!);
        this.organismos = dataJson;
        const ojborganismo = this.organismos.find(
            (x) => environment.entorno + x.codigo_organismo === dato.solrDocument.ORGANIZATIONAL_UNIT[0]
        );
        let nombreOrganismo = ojborganismo!.nombre_organismo;
        let nombreDepartamento = ojborganismo!.nombre_departamento;
        let descripcion = dato.solrDocument.DESCRIPTION;
        let id = dato.solrDocument?.ID_OBJ;

        let protocolo: Protocolo = {
            nombre: nombre,
            nombreOrganismo: nombreOrganismo,
            nombreDepartamento: nombreDepartamento,
            descripcion: descripcion,
            id: dato.solrDocument?.ID_OBJ,
            ari: dato.solrDocument.ARI,
            condiciones: dato.solrDocument.CONDICIONES,
            PI: dato.solrDocument.PI,
            state: dato.solrDocument.STATE,
        };
        if (protocolo.nombreOrganismo === 'Dpto_Ciudadania') {
            protocolo.organismoExtendido = ' Departamento de Ciudadania y Derechos Sociales';
        } else {
            protocolo.organismoExtendido = protocolo.nombreOrganismo;
        }
        this.protocolo = protocolo;
    }

    getNivelesDatos(datos: string[]) {
        let datosADevolver: string[] = [];
        datos.forEach((dato: string) => {
            let partes = dato.split('-');
            let nuevoTexto = partes[0].trim();

            datosADevolver.push(nuevoTexto);
        });
        return datosADevolver;
    }
    toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    groupBy(list: any, prop: any) {
        return list.reduce((groupped: any, item: any) => {
            var key = item[prop];
            delete item[prop];
            if (groupped.hasOwnProperty(key)) {
                groupped[key].push(item);
            } else {
                groupped[key] = [item];
            }
            return groupped;
        }, {});
    }
    groupSubKeys(obj: any, properties: any, propIndex: any) {
        var grouppedObj = this.groupBy(obj, properties[propIndex]);
        Object.keys(grouppedObj).forEach((key) => {
            if (propIndex < properties.length - 2) {
                grouppedObj[key] = this.groupSubKeys(grouppedObj[key], properties, propIndex + 1);
            } else {
                grouppedObj[key] = grouppedObj[key].map((item: any) => item[properties[propIndex + 1]]);
            }
        });
        return grouppedObj;
    }

    groupByProperties(list: any, properties: any) {
        return this.groupSubKeys(list, properties, 0);
    }
}

<div class="border-b border-neutral-dark bg-white">
    <div class="container mx-auto mt-base -mb-px px-base">
        <div class="lg:flex lg:flex-wrap lg:w-full">
            <div class="lg:w-full mb-base lg:mb-lg">
                <h2 class="c-h2 mb-xs mt-12">Uso de datos</h2>
            </div>
            <div class="order-4 w-full">
                <desy-menu-horizontal
                    [ariaLabelledBy]="'cabecera-menu'"
                    [classes]="'c-menu-horizontal c-menu-horizontal--tabs'"
                >
                    <desy-menu-horizontal-item
                        [routerLink]="['/uso-datos/cedidos']"
                        routerLinkActive="active"
                        [active]="tab === 'cedidos'"
                        >Cedidos</desy-menu-horizontal-item
                    >
                    <desy-menu-horizontal-item
                        [routerLink]="['/uso-datos/adheridos']"
                        routerLinkActive="active"
                        [active]="tab === 'adheridos'"
                        >Adheridos</desy-menu-horizontal-item
                    >
                </desy-menu-horizontal>
            </div>
        </div>
    </div>
</div>
<main id="content" class="container mx-auto px-base my-12">
    <desy-spinner class="container__spinner" classes="spinner pt-36" *ngIf="buscando === true"></desy-spinner>
    <div class="container__title">
        <br />
        <h2 class="container__title__table-title2">Protocolos {{ peticion }}</h2>
        <p *ngIf="peticion == 'cedidos'">
            Estas son las estadisticas de uso de los protocolos que tienes cedidos para la consulta de información por
            parte de terceros.
        </p>
        <p *ngIf="peticion == 'adheridos'">
            Estas son las estadísticas de uso de los protocolos a los que estás adherido.
        </p>
        <br />
    </div>
    <div class="container__filtros" *ngIf="!buscandoFiltros">
        <!-- nuevas opciones de filtros -->
        <div class="container__filtros__dropdown">
            <desy-dropdown
                classes="c-paragraph-sm c-dropdown--sm"
                classesTooltip="w-full max-w-none p-5"
                [caller]="callerFechaSolicitudes"
                [ngClass]="{ activo: fechaFin !== '' || fechaInicio !== '' }"
            >
                <span *ngIf="fechaInicio === '' && fechaFin === ''">Rango de fechas</span>
                <span *ngIf="fechaInicio !== '' && fechaFin !== ''"> Desde {{ fechaInicio }} hasta {{ fechaFin }}</span>
            </desy-dropdown>
            <desy-dropdown
                classes="c-paragraph-sm c-dropdown--sm"
                [ngClass]="{ activo: valoresFiltros[0] !== null }"
                [disabled]="filtrosMap[0].valor.length == 0"
                classesContainer="w-86"
                [caller]="PROTOCOLOS"
            >
                {{ filtrosMap[0].nombre }}
            </desy-dropdown>
            <desy-dropdown
                classes="c-paragraph-sm c-dropdown--sm"
                [ngClass]="{ activo: valoresFiltros[1] !== null }"
                [disabled]="filtrosMap[1].valor.length == 0"
                classesContainer="w-86"
                [caller]="ORGANISMO"
            >
                {{ filtrosMap[1].nombre }}
            </desy-dropdown>
            <desy-dropdown
                classes="c-paragraph-sm c-dropdown--sm"
                [ngClass]="{ activo: valoresFiltros[2] !== null }"
                [disabled]="filtrosMap[2].valor.length == 0"
                classesContainer="w-86"
                [caller]="PROCEDIMIENTO"
            >
                {{ filtrosMap[2].nombre }}
            </desy-dropdown>
            <desy-dropdown
                classes="c-paragraph-sm c-dropdown--sm"
                [ngClass]="{ activo: valoresFiltros[3] !== null }"
                [disabled]="filtrosMap[3].valor.length == 0"
                classesContainer="w-86"
                [caller]="APLICACION"
            >
                {{ filtrosMap[3].nombre }}
            </desy-dropdown>
            <desy-dropdown
                classes="c-paragraph-sm c-dropdown--sm"
                [ngClass]="{ activo: valoresFiltros[4] !== null }"
                [disabled]="filtrosMap[4].valor.length == 0"
                classesContainer="w-86"
                [caller]="RPTs"
            >
                {{ filtrosMap[4].nombre }}
            </desy-dropdown>
            <desy-button classes="c-paragraph-sm c-button--sm c-button--transparent" (click)="limpiarFiltros()"
                >Eliminar Filtros</desy-button
            >
        </div>
    </div>
    <div>
        <h3 class="c-h3 mb-xs mt-12">Consultas totales</h3>
        <span>Total de consultas realizadas para todos los protocolos</span>
    </div>
    <br />
    <div class="container__grafico">
        <div class="container__grafico__totales-dropdown" *ngIf="!buscando">
            <desy-dropdown classes="c-paragraph-sm c-dropdown--sm" classesContainer="w-86" [caller]="VISTA">
                Tipo de vista - {{ vistaSeleccionada }}
            </desy-dropdown>
            <div>
                <div id="total" class="container__grafico__totales-dropdown__total">{{ totalesSumados }}</div>
                <p>Consultas a mis protocolos cedidos</p>
            </div>
        </div>
        <div [hidden]="!buscando">
            <img
                src="../../../assets/image/filtros-default.png"
                alt="grafico"
                class="container__grafico__chart-default"
            />
        </div>
        <div [hidden]="buscando">
            <canvas class="container__grafico__chart" #canvas></canvas>
        </div>
        <desy-button (clickEvent)="openModal()" classes="c-button--primary">Detalles</desy-button>

        <app-modal-table [modalChart]="modalChart" [isOpen]="isModalOpen" (close)="closeModal()"></app-modal-table>
    </div>
    <div class="container__table">
        <div class="container__table__title">
            <h1 class="container__table__title__table-title">Histórico de consultas</h1>
            <p>
                Cada consulta corresponde a cada vez que una persona ha consultado la informacion asociada a un
                protocolo.
            </p>
            <h1 class="container__table__title__table-title" *ngIf="solicitudesRealizadas == []">
                No se han encontrado datos para esta busqueda.
            </h1>
            <br />
        </div>
        <desy-table-advanced
            class="container__table__body"
            [classes]="'min-w-full mb-sm mt-sm'"
            *ngIf="solicitudesRealizadas && !buscandoPagina"
        >
            <desy-table-advanced-header>
                <desy-table-advanced-header-cell>
                    <desy-content>Solicitante</desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell>
                    <desy-content>Protocolo</desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell>
                    <desy-content> Aplicación solicitante </desy-content>
                </desy-table-advanced-header-cell>
                <desy-table-advanced-header-cell>
                    <desy-content>Fecha de la solicitud</desy-content>
                </desy-table-advanced-header-cell>
            </desy-table-advanced-header>
            <desy-table-advanced-row *ngFor="let solicitud of solicitudesRealizadas.data">
                <desy-table-advanced-row-cell *ngIf="solicitud.app_desc != 'Carpeta Ciudadana'">
                    <ul>
                        <li>{{ solicitud.sjc_desc }}</li>
                        <li>{{ solicitud.nombre_organismo }}</li>
                        <li *ngIf="solicitud.nombre_organismo != ''" class="info">
                            <span
                                class="material-symbols-rounded"
                                (mouseover)="openTooltip($event, solicitud)"
                                (mouseleave)="closeTooltip()"
                            >
                                info
                            </span>
                        </li>
                        <li *ngIf="solicitud.nombre_organismo == ''" class="sinOrganismo">-</li>
                    </ul>
                </desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell *ngIf="solicitud.app_desc == 'Carpeta Ciudadana'"
                    >Persona ciudadana</desy-table-advanced-row-cell
                >
                <desy-table-advanced-row-cell>
                    <ul>
                        <li *ngFor="let itemsDSA of solicitud.dsa" class="mb-1">{{ itemsDSA }}</li>
                    </ul>
                </desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>
                    <ul>
                        <li>{{ solicitud.app_desc }}</li>
                        <li>{{ solicitud.procedimiento }} - {{ solicitud.nombre_procedimiento }}</li>
                        <li>
                            {{ solicitud.atributos_solicitud }}
                        </li>
                    </ul>
                </desy-table-advanced-row-cell>
                <desy-table-advanced-row-cell>{{ solicitud.inicio_timestamp }}</desy-table-advanced-row-cell>
            </desy-table-advanced-row>
        </desy-table-advanced>
        <desy-spinner
            class="container__table__body__spinner"
            classes="spinner pt-36"
            *ngIf="buscandoPagina && !solicitudesRealizadas"
        ></desy-spinner>
        <desy-pagination
            *ngIf="totalPaginas && currentPage > 0"
            [itemsPerPage]="100"
            [totalItems]="totalRegistros"
            [hasSelect]="true"
            [showFirst]="true"
            [showLast]="true"
            [hasNext]="true"
            [hasPrevious]="true"
            [idPrefix]="'selector'"
            previousText="Anterior"
            nextText="Siguiente"
            (click)="onButtonClick($event)"
            [(currentPage)]="currentPage"
        >
        </desy-pagination>
        <br />
    </div>
    <!-- Caller de filtros -->
    <ng-template #PROTOCOLOS>
        <div class="flex flex-col items-start p-2">
            <desy-tree
                [name]="'exampleNameContent'"
                [type]="'checkbox'"
                [expandedFirstLevel]="false"
                [decoupleChildFromParent]="false"
                class="state-tree"
                classes="w-auto"
            >
                <div *ngFor="let item of filtrosMap[0].valor" class="flex flex-col justify-items-center" id="estado">
                    <desy-tree-item [value]="item.nombre" [(checked)]="item.check">
                        <desy-label>{{ item.nombre }}</desy-label>
                    </desy-tree-item>
                </div>
            </desy-tree>
            <div class="flex mx-auto justify-between">
                <desy-button
                    text="Seleccionar"
                    classes="c-button--primary"
                    (clickEvent)="concatenarSeleccion(filtrosMap[0].nombre)"
                ></desy-button>
                <desy-button
                    text="Cancelar"
                    classes="ml-sm"
                    (clickEvent)="onCancelarSeleccion(filtrosMap[0].nombre, filtrosMap[0].valor)"
                ></desy-button>
            </div>
        </div>
    </ng-template>
    <ng-template #ORGANISMO>
        <div class="flex flex-col items-start p-2">
            <desy-tree
                [name]="'exampleNameContent'"
                [type]="'checkbox'"
                [expandedFirstLevel]="false"
                [decoupleChildFromParent]="false"
                class="state-tree"
                classes="w-auto"
            >
                <div *ngFor="let item of filtrosMap[1].valor" class="flex flex-col justify-items-center" id="estado">
                    <desy-tree-item [value]="item.nombre" [(checked)]="item.check">
                        <desy-label>{{ item.nombre }}</desy-label>
                    </desy-tree-item>
                </div>
            </desy-tree>
            <div class="flex mx-auto justify-between">
                <desy-button
                    text="Seleccionar"
                    classes="c-button--primary"
                    (clickEvent)="concatenarSeleccion(filtrosMap[1].nombre)"
                ></desy-button>
                <desy-button
                    text="Cancelar"
                    classes="ml-sm"
                    (clickEvent)="onCancelarSeleccion(filtrosMap[1].nombre, filtrosMap[1].valor)"
                ></desy-button>
            </div>
        </div>
    </ng-template>
    <ng-template #PROCEDIMIENTO>
        <div class="flex flex-col items-start p-2">
            <desy-tree
                [name]="'exampleNameContent'"
                [type]="'checkbox'"
                [expandedFirstLevel]="false"
                [decoupleChildFromParent]="false"
                class="state-tree"
                classes="w-auto"
            >
                <div *ngFor="let item of filtrosMap[2].valor" class="flex flex-col justify-items-center" id="estado">
                    <desy-tree-item [value]="item.codigo" [(checked)]="item.check">
                        <desy-label>{{ item.nombre }}</desy-label>
                    </desy-tree-item>
                </div>
            </desy-tree>
            <div class="flex mx-auto justify-between">
                <desy-button
                    text="Seleccionar"
                    classes="c-button--primary"
                    (clickEvent)="concatenarSeleccion(filtrosMap[2].nombre)"
                ></desy-button>
                <desy-button
                    text="Cancelar"
                    classes="ml-sm"
                    (clickEvent)="onCancelarSeleccion(filtrosMap[2].nombre, filtrosMap[2].valor)"
                ></desy-button>
            </div>
        </div>
    </ng-template>
    <ng-template #APLICACION>
        <div class="flex flex-col items-start p-2">
            <desy-tree
                [name]="'exampleNameContent'"
                [type]="'checkbox'"
                [expandedFirstLevel]="false"
                [decoupleChildFromParent]="false"
                class="state-tree"
                classes="w-auto"
            >
                <div *ngFor="let item of filtrosMap[3].valor" class="flex flex-col justify-items-center" id="estado">
                    <desy-tree-item [value]="item.codigo" [(checked)]="item.check">
                        <desy-label>{{ item.nombre }}</desy-label>
                    </desy-tree-item>
                </div>
            </desy-tree>
            <div class="flex mx-auto justify-between">
                <desy-button
                    text="Seleccionar"
                    classes="c-button--primary"
                    (clickEvent)="concatenarSeleccion(filtrosMap[3].nombre)"
                ></desy-button>
                <desy-button
                    text="Cancelar"
                    classes="ml-sm"
                    (clickEvent)="onCancelarSeleccion(filtrosMap[3].nombre, filtrosMap[3].valor)"
                ></desy-button>
            </div>
        </div>
    </ng-template>
    <ng-template #RPTs>
        <div class="flex flex-col items-start p-2">
            <desy-tree
                [name]="'exampleNameContent'"
                [type]="'checkbox'"
                [expandedFirstLevel]="false"
                [decoupleChildFromParent]="false"
                class="state-tree"
                classes="w-auto"
            >
                <div *ngFor="let item of filtrosMap[4].valor" class="flex flex-col justify-items-center" id="estado">
                    <desy-tree-item [value]="item.codigo" [(checked)]="item.check">
                        <desy-label>
                            <span *ngIf="item.codigo_rpt !== null && item.nombre !== null"
                                >{{ item.codigo_rpt }} - {{ item.nombre }}</span
                            >
                            <span *ngIf="item.codigo_rpt == null && item.nombre == null"
                                >Consultas realizadas sin RPT</span
                            >
                        </desy-label>
                    </desy-tree-item>
                </div>
            </desy-tree>
            <div class="flex mx-auto justify-between">
                <desy-button
                    text="Seleccionar"
                    classes="c-button--primary"
                    (clickEvent)="concatenarSeleccion(filtrosMap[4].nombre)"
                ></desy-button>
                <desy-button
                    text="Cancelar"
                    classes="ml-sm"
                    (clickEvent)="onCancelarSeleccion(filtrosMap[4].nombre, filtrosMap[4].valor)"
                ></desy-button>
            </div>
        </div>
    </ng-template>
    <!-- fechas -->
    <ng-template #callerFechaSolicitudes class="big max-w-none">
        <div class="big">
            <desy-title>Rango de fecha</desy-title>
            <p class="errores" *ngIf="anioNoValido !== ''">{{ anioNoValido }}</p>
            <p class="errores" *ngIf="fechaNoValida !== ''">{{ fechaNoValida }}</p>
            <p class="errores" *ngIf="mesNoValido !== ''">{{ mesNoValido }}</p>
            <p class="errores" *ngIf="diaNoValido !== ''">{{ diaNoValido }}</p>
            <div class="w-full flex justify-between content-center items-center">
                <form class="form" [formGroup]="form">
                    <desy-date-input
                        formGroupName="fechaDesde"
                        [items]="itemsFechas"
                        legendText="Desde"
                        hintText="Por ejemplo, 31 3 1980"
                    ></desy-date-input>
                </form>
                <form class="form" [formGroup]="form">
                    <desy-date-input
                        formGroupName="fechaHasta"
                        [items]="itemsFechas"
                        legendText="Hasta"
                        hintText="Por ejemplo, 31 3 2025"
                    ></desy-date-input>
                </form>
            </div>
            <div class="flex justify-between">
                <desy-button classes="c-button--primary" (clickEvent)="onFiltrosFecha(itemsFechas)"
                    >Seleccionar</desy-button
                >
                <desy-button classes="mr-sm" (clickEvent)="onCancelarFechas(itemsFechas)">Cancelar</desy-button>
            </div>
        </div>
    </ng-template>
    <!-- fin de caller -->
    <!-- Caller de tipo de vista -->
    <ng-template #VISTA>
        <div class="tipo-vista">
            <button (click)="seleccionarVista('DIARIO')">Vista diario</button>
            <button (click)="seleccionarVista('SEMANAL')">Vista semanal</button>
            <button (click)="seleccionarVista('MENSUAL')">Vista mensual</button>
            <button (click)="seleccionarVista('ANUAL')">Vista anual</button>
        </div>
    </ng-template>
    <!-- Fin de caller de tipo de vista -->

    <!-- Tooltips -->
    <div
        class="container__tooltip"
        [ngStyle]="{ display: show ? 'block' : 'none', top: tooltipPosition.top, left: tooltipPosition.left }"
    >
        <div class="container__tooltip__content">
            <span (click)="closeTooltip()" class="container__tooltip__content__close">&times;</span>
            <div class="container__tooltip__content__info">
                <div class="container__tooltip__content__info__avatar">
                    <span class="container__tooltip__content__info__avatar__icon material-symbols-outlined">
                        person
                    </span>
                </div>
                <div class="container__tooltip__content__info__body">
                    <p class="container__tooltip__content__info__body__title">{{ tooltipData?.sjc_desc[0] }}</p>
                    <p class="container__tooltip__content__info__body__name">
                        {{ tooltipData?.nombre_sol[0] }} {{ tooltipData?.primer_apellido_sol[0] }}
                        {{ tooltipData?.segundo_apellido_sol[0] }}
                    </p>
                    <p class="container__tooltip__content__info__body__organismo">
                        {{ tooltipData?.nombre_organismo[0] }}
                    </p>
                    <p class="container__tooltip__content__info__body__departamento">
                        {{ tooltipData?.nombre_departamento[0] }}
                    </p>
                    <br />
                    <p class="container__tooltip__content__info__body__mail">
                        <span class="material-symbols-outlined"> stacked_email </span>
                        <a
                            class="container__tooltip__content__info__body__mail__text"
                            [href]="'mailto:' + (tooltipData?.correo_electronico_sol[0] || '')"
                        >
                            {{ tooltipData?.correo_electronico_sol[0] }}
                        </a>
                    </p>
                    <p class="container__tooltip__content__info__body__nif">
                        <span class="material-symbols-outlined"> clinical_notes </span>
                        NIF: {{ tooltipData?.nif_sol[0] }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- fin de Tooltips -->
</main>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'estado',
})
export class EstadoPipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'APPROVED':
                return 'APROBADA';
            case 'PENDING':
                return 'PENDIENTE';
            case 'REJECTED':
                return 'RECHAZADA';
            case 'WAITING':
                return 'PENDIENTE';
            case 'CANCELLED':
                return 'RECHAZADA';
            case 'DISABLED':
                return 'BLOQUEADA';
            case 'FINALIZADA':
                return 'FINALIZADA/CANCELADA';
            default:
                return 'No encontrado';
        }
    }
}

<main class="container mx-auto px-base">
    <div *ngIf="!adhesion">La adhesión solicitada no existe</div>
    <div *ngIf="!buscando && adhesion">
        <ng-template #alertPlace></ng-template>
        <div class="pb-sm"></div>
        <a (click)="goToListadoAdhesion()" role="button" draggable="false" class="c-button c-button--transparent">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 140 140"
                class="self-center mr-2"
                aria-hidden="true"
                focusable="false"
                width="1em"
                height="1em"
            >
                <path
                    d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                    fill="currentColor"
                ></path>
            </svg>
            Volver
        </a>

        <div class="pb-sm"></div>
        <div class="title flex justify-between mb-4 w-full">
            <div class="w-full">
                <div class="flex mt-1 c- paragraph-base mb-2 w-full">
                    <h1 class="c-h3 mr-4 title-h1" *ngIf="adhesion.nombreAdhesion != null">
                        {{ adhesion.nombreAdhesion }}
                    </h1>
                    <h2 class="font-semibold subtitle-h2">{{ adhesion.nombreProtocolo }}</h2>
                    <div class="buttons">
                        <desy-button
                            *ngIf="adhesion.estado == EstadosAdhesionEnum.APROBADA && isDSAdhesion"
                            classes="c-button--secondary mr-3"
                            (clickEvent)="onBloquearAdhesion()"
                            >Bloquear uso adhesión</desy-button
                        >
                        <desy-button
                            *ngIf="adhesion.estado == EstadosAdhesionEnum.BLOQUEADA && isEnabled"
                            [disabled]="!isEnabled"
                            classes="c-button--secondary mr-3"
                            (clickEvent)="onDesbloquearAdhesion()"
                            >Activar uso de la adhesión</desy-button
                        >
                        <desy-button
                            *ngIf="
                                adhesion.estado == EstadosAdhesionEnum.APROBADA ||
                                adhesion.estado == EstadosAdhesionEnum.BLOQUEADA
                            "
                            classes="c-button--secondary mr-3"
                            (clickEvent)="onFinalizarAdhesion()"
                            >Finalizar uso de la adhesión</desy-button
                        >
                        <!-- <desy-button
                            classes="c-button--primary"
                            (clickEvent)="modificarRpts()"
                            >Modificar RPTs</desy-button
                        > -->
                        <div
                            *ngIf="
                                (datosDpd.estado === EstadosAdhesionEnum.PENDIENTE && isDpd) ||
                                (datosJefeSupervisor.estado === EstadosAdhesionEnum.PENDIENTE && isDSFuncional)
                            "
                        >
                            <desy-button classes="mr-5" (clickEvent)="openDialogRechazar()">Rechazar</desy-button>
                            <desy-button classes="c-button--primary" (clickEvent)="openDialogAprobar()"
                                >Aprobar</desy-button
                            >
                        </div>
                    </div>
                </div>
                <div class="flex mt-1 c- paragraph-base mb-2 w-full">
                    <div class="flex mb-5">
                        <div class="cuadrado pendiente mr-2" ngClass="{{ adhesion.estado }}"></div>
                        <p class="mr-10 c- paragraph-base mb-2 semi-bold" *ngIf="adhesion.estado">
                            {{ adhesion.estado | estado }}
                        </p>
                        <p class="mr-10">
                            Creada el {{ adhesion.fechaCreacion | date : 'd' }} de
                            {{ adhesion.fechaCreacion | date : 'MMMM' }} de {{ adhesion.fechaCreacion | date : 'YYYY' }}
                        </p>

                        <p *ngIf="adhesion.RPTSolicitante" class="c- paragraph-base">
                            Solicitada por {{ adhesion.RPTSolicitante.nombre }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-12">
            <p class="c-h3 bold black" *ngIf="adhesion.estado === EstadosAdhesionEnum.FINALIZADA; else otroEstado">
                Esta adhesión está finalizada
            </p>
            <ng-template #otroEstado>
                <p class="c-h3 bold black" *ngIf="protocolo.SI == 'true'">
                    {{
                        datosDpd.estado === EstadosAdhesionEnum.APROBADA &&
                        datosJefeSupervisor.estado === EstadosAdhesionEnum.APROBADA
                            ? 'Adhesión aprobada por 2 personas'
                            : 'Esta adhesión necesita la aprobación de dos personas'
                    }}
                </p>
                <p class="c- paragraph-base mb-2 text-neutral-base" *ngIf="protocolo.SI == 'true'">
                    El/la jefe/a de servicio supervisor y el/la delegado/a de protección de datos
                    {{
                        datosDpd.estado === EstadosAdhesionEnum.APROBADA &&
                        datosJefeSupervisor.estado === EstadosAdhesionEnum.APROBADA
                            ? 'han aprobado'
                            : 'tienen que aprobar'
                    }}
                    la solicitud.
                </p>
                <p class="c-h3 bold black" *ngIf="protocolo.SI == 'false'">
                    Esta adhesión necesita la aprobación de una persona
                </p>
                <p class="c- paragraph-base mb-2 text-neutral-base" *ngIf="protocolo.SI == 'false'">
                    El/la jefe/a de servicio supervisor tiene que aprobar la solicitud.
                </p>
                <desy-status-item>
                    <desy-title>
                        Jefe/a de servicio supervisor<br />
                        <div *ngIf="datosJefeSupervisor.estado === EstadosAdhesionEnum.RECHAZADA">
                            {{ datosJefeSupervisor.estado | estado }} por {{ datosJefeSupervisor.nombre }} el
                            {{ datosJefeSupervisor.fecha | date }}
                        </div>
                        <div *ngIf="datosJefeSupervisor.estado === EstadosAdhesionEnum.RECHAZADA">
                            Motivo: {{ datosJefeSupervisor.motivo }}
                        </div>
                    </desy-title>
                    <desy-status
                        *ngIf="
                            datosJefeSupervisor.estado == EstadosAdhesionEnum.WAITING ||
                            datosJefeSupervisor.estado == EstadosAdhesionEnum.PENDIENTE
                        "
                        [text]="datosJefeSupervisor.estado | estado"
                        [icon]="{ type: 'alert' }"
                        id="pending"
                    ></desy-status>
                    <desy-status
                        *ngIf="
                            datosJefeSupervisor.estado == EstadosAdhesionEnum.RECHAZADA ||
                            datosJefeSupervisor.estado == 'CANCELLED'
                        "
                        [text]="datosJefeSupervisor.estado | estado"
                        [icon]="{ type: 'alert' }"
                    ></desy-status>
                    <desy-status
                        *ngIf="datosJefeSupervisor.estado == EstadosAdhesionEnum.APROBADA"
                        [text]="datosJefeSupervisor.estado | estado"
                        [icon]="{ type: 'success' }"
                        classes="warning-base"
                    ></desy-status>
                    <desy-status
                        *ngIf="datosJefeSupervisor.estado == EstadosAdhesionEnum.BLOQUEADA"
                        [text]="datosJefeSupervisor.estado | estado"
                        [icon]="iconBloqueo"
                    ></desy-status>
                </desy-status-item>
                <desy-status-item *ngIf="protocolo.SI == 'true'">
                    <desy-title>
                        Delegado/a de protección de datos
                        <div *ngIf="datosDpd.estado === EstadosAdhesionEnum.RECHAZADA">
                            {{ datosDpd.estado | estado }} por {{ datosDpd.nombre }} el {{ datosDpd.fecha | date }}
                        </div>
                        <div *ngIf="datosDpd.estado === EstadosAdhesionEnum.RECHAZADA">
                            Motivo: {{ datosDpd.motivo }}
                        </div>
                    </desy-title>
                    <desy-status
                        *ngIf="
                            datosDpd.estado == EstadosAdhesionEnum.WAITING ||
                            datosDpd.estado == EstadosAdhesionEnum.PENDIENTE
                        "
                        [text]="datosDpd.estado | estado"
                        [icon]="{ type: 'alert' }"
                        id="pending"
                    ></desy-status>
                    <desy-status
                        *ngIf="datosDpd.estado == EstadosAdhesionEnum.RECHAZADA"
                        [text]="datosDpd.estado | estado"
                        [icon]="{ type: 'alert' }"
                    ></desy-status>
                    <desy-status
                        *ngIf="datosDpd.estado == EstadosAdhesionEnum.APROBADA"
                        [text]="datosDpd.estado | estado"
                        [icon]="{ type: 'success' }"
                        classes="warning-base"
                    ></desy-status>
                    <desy-status
                        *ngIf="datosDpd.estado == EstadosAdhesionEnum.BLOQUEADA"
                        [text]="datosDpd.estado | estado"
                        [icon]="iconBloqueo"
                    ></desy-status>
                </desy-status-item>
            </ng-template>
        </div>
        <div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Protocolo de consulta</strong></h3>
                <p class="c- paragraph-base mb-2 text-neutral-dark mb-2">
                    {{ protocolo.nombre }}
                </p>
                <a
                    id="menu-item"
                    [routerLink]="['/protocolo/detalle/', protocolo.id]"
                    [queryParams]="{ idAdhesion: adhesion.idAdhesion }"
                    class="c-menu-horizontal__link relative hover:text-primary-base underline truncate focus:outline-none"
                >
                    <span class="c-link text-mb"> Ver detalles </span>
                </a>
            </div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Servicio</strong></h3>

                <p *ngIf="procedimiento.nombre != undefined" class="c- paragraph-base mb-2 text-neutral-dark">
                    {{ procedimiento.nombre }}
                </p>
            </div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Base Legal</strong></h3>
                <p class="c- paragraph-base mb-2 text-neutral-dark" *ngIf="adhesion.baseLegal != undefined">
                    {{ adhesion.baseLegal.tipo }} {{ adhesion.baseLegal.rango }}, del {{ adhesion.baseLegal.dia }} de
                    {{ adhesion.baseLegal.mes }}, {{ adhesion.baseLegal.titulo }}
                </p>
                <a
                    id="menu-item"
                    target="_blank"
                    href="{{ adhesion.baseLegal?.enlace }}"
                    class="c-menu-horizontal__link relative hover:text-primary-base underline truncate focus:outline-none"
                    style="cursor: pointer"
                >
                    <span class="c-link text-mb flex">
                        Ver detalles
                        <svg
                            class="iconoDetalles"
                            xmlns="http://www.w3.org/2000/svg"
                            height="20px"
                            viewBox="0 0 24 24"
                            width="20px"
                            fill="currentColor"
                        >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                            />
                        </svg>
                    </span>
                </a>
            </div>
            <div class="mb-12">
                <h3 class="c-h3"><strong>Tiempo de vigencia</strong></h3>
                <p
                    class="c- paragraph-base mb-2 text-neutral-dark"
                    *ngIf="adhesion.fechaVigencia != '2999-12-31' && adhesion.fechaVigencia != '2999-12-30'"
                >
                    {{ adhesion.fechaVigencia | date : 'medium' }}
                </p>
                <p
                    class="c- paragraph-base mb-2 text-neutral-dark"
                    *ngIf="adhesion.fechaVigencia === '2999-12-31' || adhesion.fechaVigencia === '2999-12-30'"
                >
                    Indefinido
                </p>
                <p class="c- paragraph-base mb-2 text-neutral-dark" *ngIf="adhesion.fechaVigencia == null">
                    Indefinido
                </p>
            </div>
        </div>

        <div class="mb-2 font-bold">{{ rpts.length }} RPTs</div>
        <div class="mb-12">
            <desy-table-advanced [classes]="'min-w-full'" [idPrefix]="'table-with-all'">
                <desy-table-advanced-header>
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Nombre</desy-content></desy-table-advanced-header-cell
                    >
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Cargo</desy-content></desy-table-advanced-header-cell
                    >
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Organismo</desy-content></desy-table-advanced-header-cell
                    >
                    <desy-table-advanced-header-cell [orderBy]="orderBy"
                        ><desy-content>Email</desy-content></desy-table-advanced-header-cell
                    >
                </desy-table-advanced-header>

                <desy-table-advanced-row *ngFor="let rpt of rpts">
                    <desy-table-advanced-row-cell>{{ rpt.nombre }}</desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>{{ rpt.cargo }}</desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>{{ rpt.organismo }}</desy-table-advanced-row-cell>
                    <desy-table-advanced-row-cell>{{ rpt.email }}</desy-table-advanced-row-cell>
                </desy-table-advanced-row>
            </desy-table-advanced>
        </div>
        <div class="mb-36" *ngIf="adhesion.observaciones != undefined && adhesion.observaciones.length > 0">
            <h3 class="c-h3">Observaciones</h3>
            <p>{{ adhesion.observaciones }}</p>
        </div>
        <div class="mb-36" *ngIf="adhesion.observaciones == undefined">
            <h3 class="c-h3">Observaciones</h3>
            <p class="c-paragraph-base text-neutral-dark italic">No se han dejado observaciones para esta adhesión</p>
        </div>

        <desy-dialog
            [(active)]="activeAprobar"
            [id]="'dialog'"
            [caller]="aprobar"
            focusOnClose="dialog-btn"
            [dismissOnBackdrop]="true"
        >
            <ng-template #aprobar>
                <desy-modal
                    [isDismissible]="true"
                    (closeModal)="closeDialog()"
                    classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
                >
                    <desy-icon
                        ><svg
                            id="Grupo_1141"
                            data-name="Grupo 1141"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                        >
                            <path
                                id="Trazado_992"
                                data-name="Trazado 992"
                                d="M40.8,7.2h-3V3a3,3,0,0,0-6,0V7.2H16.2V3a3,3,0,0,0-6,0V7.2h-3A7.2,7.2,0,0,0,0,14.4V40.8A7.2,7.2,0,0,0,7.2,48H40.8A7.2,7.2,0,0,0,48,40.8V14.4A7.2,7.2,0,0,0,40.8,7.2ZM34.522,24.922l-12,12a3,3,0,0,1-4.243,0l-4.8-4.8a3,3,0,0,1,4.243-4.243L20.4,30.557l9.878-9.878a3,3,0,1,1,4.243,4.243Z"
                                fill="#d6eaf0"
                            />
                        </svg>
                    </desy-icon>
                    <desy-title>¿Quieres aprobar la {{ adhesion.nombreAdhesion }}? </desy-title>
                    <desy-modal-button-secondary
                        [classes]="'c-button--primary c-button--sm'"
                        (clickEvent)="aprobarAdhesion()"
                        >Sí, aprobar adhesión</desy-modal-button-secondary
                    >
                    <desy-modal-button-primary
                        [classes]="'c-button--secondary c-button--sm'"
                        (clickEvent)="closeDialog()"
                        >Cancelar</desy-modal-button-primary
                    >
                </desy-modal>
            </ng-template>
        </desy-dialog>
        <desy-dialog
            [(active)]="activeConfirmarRechazar"
            [id]="'dialog'"
            [caller]="confirmarRechazar"
            focusOnClose="dialog-btn"
            [dismissOnBackdrop]="true"
            class="z-50 absolute"
        >
            <ng-template #confirmarRechazar>
                <desy-modal
                    [isDismissible]="true"
                    (closeModal)="closeDialogConfirmar()"
                    classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white mt-2xl"
                >
                    <desy-icon
                        ><svg
                            id="Grupo_1141"
                            data-name="Grupo 1141"
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            viewBox="0 0 48 48"
                        >
                            <path
                                id="Trazado_992"
                                data-name="Trazado 992"
                                d="M40.8,7.2h-3V3a3,3,0,0,0-6,0V7.2H16.2V3a3,3,0,0,0-6,0V7.2h-3A7.2,7.2,0,0,0,0,14.4V40.8A7.2,7.2,0,0,0,7.2,48H40.8A7.2,7.2,0,0,0,48,40.8V14.4A7.2,7.2,0,0,0,40.8,7.2ZM34.522,24.922l-12,12a3,3,0,0,1-4.243,0l-4.8-4.8a3,3,0,0,1,4.243-4.243L20.4,30.557l9.878-9.878a3,3,0,1,1,4.243,4.243Z"
                                fill="#d6eaf0"
                            />
                        </svg>
                    </desy-icon>
                    <desy-title>¿Quieres rechazar la {{ adhesion.nombreAdhesion }}? </desy-title>
                    <desy-modal-button-primary
                        [classes]="'c-button--secondary c-button--sm'"
                        (clickEvent)="closeDialogConfirmar()"
                        >Cancelar</desy-modal-button-primary
                    >
                    <desy-modal-button-secondary
                        [classes]="'c-button--primary c-button--sm'"
                        (clickEvent)="rechazarAdhesion()"
                        >Sí, rechazar adhesión</desy-modal-button-secondary
                    >
                </desy-modal>
            </ng-template>
        </desy-dialog>
        <desy-dialog
            [active]="activeRechazar"
            [id]="'dialog-'"
            [caller]="rechazar"
            focusOnClose="dialog-btn"
            [dismissOnBackdrop]="false"
            classes="mx-0 w-full h-full"
            class="z-40 absolute"
        >
            <ng-template #rechazar class="w-full h-full m-0">
                <div class="relative w-full mx-auto h-full m-0 p-0 border border-neutral-base bg-white">
                    <div class="border-custom">
                        <div class="rechazar-container">
                            <div class="icon">
                                <svg
                                    id="Grupo_1148"
                                    data-name="Grupo 1148"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="35.5"
                                    height="35.5"
                                    viewBox="0 0 35.5 35.5"
                                >
                                    <path
                                        id="Trazado_1020"
                                        data-name="Trazado 1020"
                                        d="M30.175,5.325H27.956V2.219a2.219,2.219,0,0,0-4.437,0V5.325H11.981V2.219a2.219,2.219,0,1,0-4.437,0V5.325H5.325A5.325,5.325,0,0,0,0,10.65V30.175A5.325,5.325,0,0,0,5.325,35.5h24.85A5.325,5.325,0,0,0,35.5,30.175V10.65A5.325,5.325,0,0,0,30.175,5.325ZM24.644,25.056a2.219,2.219,0,0,1-3.138,3.138L17.75,24.438l-3.756,3.756a2.219,2.219,0,0,1-3.138-3.138L14.612,21.3l-3.756-3.756a2.219,2.219,0,1,1,3.138-3.138l3.756,3.756,3.756-3.756a2.219,2.219,0,1,1,3.138,3.138L20.888,21.3Z"
                                        fill="#5e616b"
                                    />
                                </svg>
                            </div>
                            <div>
                                <div class="c-h3">{{ 'Rechazar adhesión' | uppercase }}</div>
                                <div class="flex">
                                    <p class="c-paragraph-sm font-bold text-neutral-base mr-4">
                                        {{ procedimiento.nombre }}
                                    </p>
                                    <p class="c-paragraph-sm font-bold text-neutral-base">
                                        {{ adhesion.nombreAdhesion }}
                                    </p>
                                </div>
                            </div>
                            <div class="buttons">
                                <desy-button classes="c-button--sm c-button--secondary" (clickEvent)="closeDialog()"
                                    >Cancelar</desy-button
                                >

                                <desy-button
                                    classes="ml-4 c-button--sm c-button--primary"
                                    (clickEvent)="openDialogConfirmarRechazar()"
                                    >Rechazar</desy-button
                                >
                            </div>
                        </div>
                    </div>
                    <div class="container mx-auto px-base my-12">
                        <h2 class="c-h2">Indica el motivo del rechazo</h2>
                        <p class="c-paragraph-base black font-semibold">Motivo del rechazo</p>
                        <desy-textarea
                            required="true"
                            classes="w-3/5 h-80"
                            [(ngModel)]="motivoRechazo"
                            placeholder="Indica el motivo del rechazo de la adhesión (ej. Lorem ipsum)"
                        >
                            <desy-error-message
                                [text]="'Debes introducir un motivo para rechazar la adhesión'"
                                *ngIf="controlaErrores"
                            ></desy-error-message>
                        </desy-textarea>
                    </div>
                </div>
            </ng-template>
        </desy-dialog>
        <desy-dialog
            *ngIf="activeBloquear"
            [(active)]="activeBloquear"
            [id]="'dialog'"
            [caller]="bloquear"
            focusOnClose="dialog-btn"
            [dismissOnBackdrop]="true"
        >
            <ng-template #bloquear>
                <desy-modal
                    [isDismissible]="true"
                    (closeModal)="closeDialog()"
                    classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
                >
                    <desy-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 491.858 491.858">
                            <path
                                d="M245.929,0C110.106,0,0,110.106,0,245.929s110.106,245.929,245.929,245.929c135.822,0,245.929-110.106,245.929-245.929S381.751,0,245.929,0z M43.721,245.929c0-111.677,90.531-202.208,202.208-202.208c46.144,0,88.668,15.467,122.696,41.485L85.204,368.625C59.187,334.597,43.721,292.072,43.721,245.929z M245.929,448.137c-45.828,0-88.087-15.255-122-40.95L407.186,123.93c25.694,33.911,40.949,76.171,40.949,121.999C448.136,357.605,357.605,448.137,245.929,448.137z"
                                fill="#D6EAF0"
                            />
                        </svg>
                    </desy-icon>
                    <desy-title>
                        ¿Quieres bloquear el uso de la adhesión?
                        <div class="c-paragraph-sm font-normal mt-base mb-10">
                            Los RPTs vinculados ya no podrán acceder a la información proporcionada en esta adhesión.
                        </div>
                    </desy-title>
                    <desy-modal-button-secondary
                        [classes]="'c-button--primary c-button--sm'"
                        (clickEvent)="bloquearAdhesion()"
                        >Sí, bloquear adhesión</desy-modal-button-secondary
                    >
                    <desy-modal-button-primary
                        [classes]="'c-button--secondary c-button--sm'"
                        (clickEvent)="closeDialog()"
                        >Cancelar</desy-modal-button-primary
                    >
                </desy-modal>
            </ng-template>
        </desy-dialog>
        <desy-dialog
            *ngIf="activeDesbloquear"
            [(active)]="activeDesbloquear"
            [id]="'dialog'"
            [caller]="desbloquear"
            focusOnClose="dialog-btn"
            [dismissOnBackdrop]="true"
        >
            <ng-template #desbloquear>
                <desy-modal
                    [isDismissible]="true"
                    (closeModal)="closeDialog()"
                    classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
                >
                    <desy-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect id="Rectángulo_38" data-name="Rectángulo 38" width="24" height="24" fill="none" />
                            <path
                                id="Trazado_182"
                                data-name="Trazado 182"
                                d="M5.141,18.2a2.48,2.48,0,0,1-1.994-1.109L.33,13.546a1.529,1.529,0,0,1,.152-1.957,1.2,1.2,0,0,1,1.775.082L4.9,14.993A.3.3,0,0,0,5.368,15l10.4-11.992a1.2,1.2,0,0,1,1.816-.051,1.529,1.529,0,0,1,.046,2.009L7.1,17.234a2.4,2.4,0,0,1-1.959.963Z"
                                transform="translate(2.954 1.429)"
                                fill="#D6EAF0"
                            />
                        </svg>
                    </desy-icon>
                    <desy-title>
                        ¿Quieres activar el uso de la adhesión?
                        <div class="c-paragraph-sm font-normal mt-base mb-10">
                            Los RPTs vinculados podrán acceder a la información proporcionada en esta adhesión.
                        </div>
                    </desy-title>
                    <desy-modal-button-secondary
                        [classes]="'c-button--primary c-button--sm'"
                        (clickEvent)="desbloquearAdhesion()"
                        >Sí, activar la adhesión</desy-modal-button-secondary
                    >
                    <desy-modal-button-primary
                        [classes]="'c-button--secondary c-button--sm'"
                        (clickEvent)="closeDialog()"
                        >Cancelar</desy-modal-button-primary
                    >
                </desy-modal>
            </ng-template>
        </desy-dialog>
        <desy-dialog
            *ngIf="activeFinalizar"
            [(active)]="activeFinalizar"
            [id]="'dialog'"
            [caller]="finalizar"
            focusOnClose="dialog-btn"
            [dismissOnBackdrop]="true"
        >
            <ng-template #finalizar>
                <desy-modal
                    [isDismissible]="true"
                    (closeModal)="closeDialog()"
                    classes="relative max-w-lg mx-auto lg:p-lg border border-neutral-base rounded bg-white"
                >
                    <desy-title>
                        Finalizar uso de la adhesion
                        <div class="text-base font-normal mt-base">
                            Al finalizar la adhesión, el organismo consumidor no podrá realizar consultas sobre los
                            datos proporcionados en esta adhesión. Este proceso no es reversible. ¿Estás seguro?
                        </div>
                    </desy-title>
                    <desy-modal-button-primary
                        [classes]="'c-button--secondary c-button--sm button-cancelar'"
                        (clickEvent)="closeDialog()"
                        >Cancelar</desy-modal-button-primary
                    >
                    <desy-modal-button-secondary
                        [classes]="'c-button--primary c-button--sm button-finalizar'"
                        (clickEvent)="finalizarAdhesion()"
                        >Sí, finalizar</desy-modal-button-secondary
                    >
                </desy-modal>
            </ng-template>
        </desy-dialog>
    </div>
    <desy-spinner *ngIf="buscando" classes="my-10 mb-12 p-base"></desy-spinner>
</main>

<main id="adhesionContent" class="container mx-auto px-base">
    <ng-template #alertPlace></ng-template>
    <div class="pb-sm"></div>

    <a routerLink="/nueva-adhesion/RPTs" role="button" draggable="false" class="c-button c-button--transparent">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" class="self-center mr-2" aria-hidden="true"
            focusable="false" width="1em" height="1em">
            <path
                d="M37.93 27.93l-35 35a10 10 0 000 14.14l35 35a10 10 0 1014.14-14.14L38.41 84.27A2.5 2.5 0 0140.18 80H130a10 10 0 000-20H40.18a2.5 2.5 0 01-1.77-4.27l13.66-13.66a10 10 0 00-14.14-14.14z"
                fill="currentColor"></path>
        </svg>
        Volver
    </a>
    <p class="c-paragraph-sm pt-4 text-neutral-dark">Paso 5 de 5</p>
    <h1 class="c-h1 mb-12">Nueva adhesión</h1>
    <desy-spinner *ngIf="buscando" id="spinner" text="Cargando..." classes="text-2xl"></desy-spinner>
    <div *ngIf="!buscando">
        <h2 class="c-h2">5. Resumen y comentarios</h2>
        <p class="c-paragraph-base text-neutral-dark">
            Revisa el contenido del protocolo y su adhesión e incluye al final las observaciones que consideres.
        </p>
        <div class="pb-sm"></div>

        <h2 class="c-h2">Protocolo de consulta</h2>
        <p class="c-paragraph-base text-neutral-dark">{{ protocolo.nombre | filtroOrganismo }}</p>
        <div class="pb-sm"></div>
        <div>
            <div class="mx-auto">
                <div class="datos-disponibles">
                    <h3 class="c-h3 "><strong>Datos disponibles para la consulta</strong> </h3>
                    <div class="boton-mostrar">
                        <p class="cursor-pointer px-4 py-2 underline" (click)="toggleCollapse()" *ngIf="isCollapsed">
                            Mostrar todo
                        </p>
                        <p class="cursor-pointer px-4 py-2 underline" (click)="toggleCollapse()" *ngIf="!isCollapsed">
                            Ocultar todo
                        </p>
                    </div>
                </div>

                <div [ngClass]="{'hidden': isCollapsed}" class="px-4 py-2">
                    <div class="grid grid-cols-3 gap-4">
                        <ul>
                            <li *ngFor="let dato of datosProtocolo">{{ dato }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-sm"></div>
        <div class="pb-sm"></div>
        <h3 class="c-h3"><strong>Organismo responsable</strong></h3>
        <p class="c-paragraph-base text-neutral-dark" *ngIf="protocolo.organismoExtendido != null && protocolo">
            {{ protocolo.organismoExtendido | filtroOrganismo }}
        </p>
        <h3 class="c-h3"><strong>Departamento</strong></h3>
        <p class="c-paragraph-base text-neutral-dark">
            {{ protocolo.nombreDepartamento }}
        </p>
        <h3 class="c-h3"><strong>Condiciones</strong></h3>
        <p class="c-paragraph-base text-neutral-dark" *ngIf="
                protocolo.condiciones != undefined &&
                protocolo.condiciones.length > 0 &&
                protocolo.condiciones != 'Condiciones'
            ">
            {{ protocolo.condiciones }}
        </p>
        <p class="c-paragraph-base text-neutral-dark italic"
            *ngIf="protocolo.condiciones == undefined || protocolo.condiciones === 'Condiciones'">
            No hay condiciones especiales en la adhesión
        </p>
        <div class="mb-xl pb-sm border-b group"></div>
        <div class="pb-md"></div>
        <h2 class="c-h2">Condiciones para la adhesión</h2>
        <div class="pb-sm"></div>
        <h3 class="c-h3"><strong>Protocolo de consulta</strong></h3>
        <p class="c-paragraph-base text-neutral-dark">
            {{ protocolo.nombre }}
        </p>
        <a id="menu-item" routerLink="/nueva-adhesion/protocolos"
            class="c-menu-horizontal__link relative hover:text-primary-base underline truncate focus:outline-none">
            <span class="c-link text-mb"> Modificar </span>
        </a>
        <div class="pb-md"></div>
        <h3 class="c-h3"><strong>Servicio</strong></h3>
        <p class="c-paragraph-base text-neutral-dark">
            {{ procedimiento.nombre }}
        </p>
        <a id="menu-item" routerLink="/nueva-adhesion/seleccion-servicio"
            class="c-menu-horizontal__link relative hover:text-primary-base underline truncate focus:outline-none">
            <span class="c-link text-mb"> Modificar </span>
        </a>
        <div class="pb-md"></div>
        <h3 class="c-h3"><strong>Base Legal</strong></h3>
        <p class="c-paragraph-base text-neutral-dark">
            {{ baseLegal.tipo }} {{ baseLegal.rango }}, del {{ baseLegal.dia }} de {{ baseMes }}, {{ baseLegal.titulo }}
        </p>
        <div class="flex">
            <a id="menu-item" routerLink="/nueva-adhesion/incorporacion-bases"
                class="c-menu-horizontal__link relative mr-6 hover:text-primary-base underline truncate focus:outline-none">
                <span class="c-link text-mb"> Modificar </span>
            </a>
            <a id="menu-item" [href]="baseLegal.enlace" target="_blank"
                class="c-menu-horizontal__link relative flex hover:text-primary-base underline truncate focus:outline-none"
                style="cursor: pointer">
                <span class="c-link text-mb mr-2.5"> Ver detalles </span>

                <svg class="iconoDetalles" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24"
                    width="20px" fill="#00607A">
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                        d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
                </svg>
            </a>
        </div>

        <div class="pb-md"></div>
        <h3 class="c-h3"><strong>Tiempo de vigencia</strong></h3>
        <p *ngIf="baseLegal.tiempoVigencia != '31/12/2999'" class="c-paragraph-base text-neutral-dark">
            {{ baseLegal.tiempoVigencia }}
        </p>
        <p *ngIf="baseLegal.tiempoVigencia === '31/12/2999'" class="c-paragraph-base text-neutral-dark">Indefinido</p>

        <a id="menu-item" routerLink="/nueva-adhesion/incorporacion-bases"
            class="c-menu-horizontal__link relative hover:text-primary-base underline truncate focus:outline-none">
            <span class="c-link text-mb"> Modificar </span>
        </a>

        <div class="pb-md"></div>
        <h3 class="c-h3"><strong>RPTs</strong></h3>
        <p class="c-paragraph-base text-neutral-dark">
            <desy-tooltip *ngIf="rpts != undefined && flagTooltip" [id]="'tooltipRPT'"
                [text]="rpts.length + ' seleccionados'" [icon]="{ type: 'info' }"><desy-tooltip-content>
                    <div *ngFor="let rpt of rpts; index as i">{{ rpt.cargo }} - {{ rpt.nombre }}</div>
                </desy-tooltip-content>
            </desy-tooltip>
            <span *ngIf="!flagTooltip">{{rpts.length + ' seleccionados'}}</span>
        </p>
        <a id="menu-item" routerLink="/nueva-adhesion/RPTs"
            class="c-menu-horizontal__link relative hover:text-primary-base underline mb-14 truncate focus:outline-none">
            <span class="c-link text-mb"> Modificar </span>
        </a>
        <div class="mb-xl pb-sm mt-14 border-b group"></div>
        <div class="pb-md"></div>
        <h2 class="c-h2">Observaciones</h2>
        <p class="c-paragraph-base text-neutral-dark">
            Indica aquí si deseas trasladar algún comentario adicional al responsable de la aprobación de la adhesión al
            protocolo de consulta.
        </p>
        <desy-textarea id="identificador" [(ngModel)]="observaciones" classes="w-full" class="w-full"></desy-textarea>
        <div class="pb-sm"></div>

        <div x-data="{isOpen: true}" x-show="isOpen" x-transition:enter="transition ease-out duration-100 transform"
            x-transition:enter-start="opacity-0 scale-y-95" x-transition:enter-end="opacity-100 scale-y-100"
            x-transition:leave="transition ease-in duration-75 transform"
            x-transition:leave-start="opacity-100 scale-y-100" x-transition:leave-end="opacity-0 scale-y-95"
            id="default-with-html" class="c-notification c-notification--primary">
            <div class="h-full mr-base">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" width="1em" height="1em"
                    class="w-5 h-5 text-primary-base" aria-label="Información" focusable="false" role="img">
                    <path
                        d="M70 0a70 70 0 1070 70A70.08 70.08 0 0070 0zm7.5 105a7.5 7.5 0 01-15 0V70a7.5 7.5 0 0115 0zM70 50a10 10 0 1110-10 10 10 0 01-10 10z"
                        fill="currentColor"></path>
                </svg>
            </div>
            <div class="lg:flex flex-1 self-center">
                <div class="lg:flex-1 lg:self-center">
                    <p id="default-with-html-title" class="font-bold pr-base focus:outline-none focus:underline"
                        tabindex="-1">
                        La adhesión debe ser aprobada por la persona responsable funcionalmente del conjunto de datos de
                        cesión. Si el conjunto de datos tuviera datos sensibles, previamente será revisada por el/la
                        delegado/a de protección de datos.
                    </p>
                </div>
                <!-- <div class="lg:flex-1 lg:self-center">
                    <p
                        id="default-with-html-title"
                        class="font-bold pr-base focus:outline-none focus:underline"
                        tabindex="-1"
                    >
                        La adhesión será revisada por un supervisor para su aprobación, recibirás un aviso cuando se
                        apruebe o rechace.
                    </p>
                </div> -->
            </div>
        </div>

        <div class="pb-md"></div>

        <p class="c-paragraph-base text-neutral-dark">
            Al hacer click en “Solicitar adhesión” aceptas los términos y condiciones que acabas de revisar en esta
            página.
        </p>
        <div class="pb-sm"></div>
        <desy-error-message *ngIf="errorAdhesion">Ha habido un error al enviar la adhesión.</desy-error-message>
        <desy-button-loader [classes]="autoButtonClass" [state]="autoButtonState" [disabled]="autoButtonDisabled"
            text="Solicitar adhesión" (clickEvent)="solicitaAdhesion()"></desy-button-loader>
    </div>
</main>
<div class="pb-lg"></div>
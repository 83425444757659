import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ErrorMessageData, HintData, ItemDateInputData, SelectItemData } from 'desy-angular';
import { NuevaAdhesionService } from 'src/app/services/nueva-adhesion/nueva-adhesion.service';
import { ProcedimientoAdminsitrativoService } from 'src/app/services/procedimiento-administrativo/procedimiento-adminsitrativo.service';
import { Bases } from '../../../interfaces/bases';

@Component({
    selector: 'app-paso-3',
    templateUrl: './paso3.component.html',
    styleUrls: ['./paso3.component.css'],
})
export class Paso3Component implements OnInit {
    constructor(
        private router: Router,
        private nuevaAdhesionService: NuevaAdhesionService,
        private procedimientosService: ProcedimientoAdminsitrativoService
    ) {}
    //Atributos necesarios para que el formulario este ok
    valorTipo: string = '';
    valorRango: string = '';
    valorDiaNorma: string = '';
    valorMesNorma: string = '';
    valorTituloNorma: string = '';
    valorEnlaceNorma: string = '';
    valorVigencia: string = '';
    diaVigencia: string = '';
    mesVigencia: string = '';
    anioVigencia: string = '';
    fechaVigencia: any;
    controlaErrores: boolean = false;
    controlaFechaNormativa: boolean = false;
    controlaFechaVigencia: boolean = false;

    items: SelectItemData[] = [
        {
            text: 'Selecciona una opción...',
            value: '',
        },

        {
            text: 'Constitución Española',
            value: 'Constitución Española',
        },
        {
            text: 'Ley Orgánica',
            value: 'ley-organica',
        },
        {
            text: 'Ley',
            value: 'Ley',
        },
        {
            text: 'Ley de Bases',
            value: 'Ley de Bases',
        },
        {
            text: 'Real Decreto Legislativo',
            value: 'Real Decreto Legislativo',
        },
        {
            text: 'Real Decreto-ley',
            value: 'Real Decreto-ley',
        },
        {
            text: 'Real Decreto',
            value: 'Real Decreto',
        },
        {
            text: 'Decreto Legislativo',
            value: 'Decreto Legislativo',
        },
        {
            text: 'Decreto-ley',
            value: 'Decreto-ley',
        },
        {
            text: 'Decreto',
            value: 'Decreto',
        },
        {
            text: 'Orden Ministerial',
            value: 'Orden Ministerial',
        },
        {
            text: 'Orden',
            value: 'Orden',
        },
        {
            text: 'Directiva',
            value: 'Directiva',
        },
        {
            text: 'Reglamento',
            value: 'Reglamento',
        },
        {
            text: 'Decisión',
            value: 'Decisión',
        },
        {
            text: 'Recomendación',
            value: 'Recomendación',
        },
        {
            text: 'Dictamen',
            value: 'Dictamen',
        },
        {
            text: 'Tratado Internacional',
            value: 'Tratado Internacional',
        },
        {
            text: 'Acuerdo',
            value: 'Acuerdo',
        },
        {
            text: 'Instrucción',
            value: 'Instrucción',
        },
        {
            text: 'Resolución',
            value: 'Resolución',
        },
        {
            text: 'Circular',
            value: 'Circular',
        },
        {
            text: 'Otro',
            value: 'Otro',
        },
    ];
    meses: SelectItemData[] = [
        {
            text: 'Selecciona una opción...',
            value: '',
        },
        { text: 'Enero', value: '1' },
        { text: 'Febrero', value: '2' },
        { text: 'Marzo', value: '3' },
        { text: 'Abril', value: '4' },
        { text: 'Mayo', value: '5' },
        { text: 'Junio', value: '6' },
        { text: 'Julio', value: '7' },
        { text: 'Agosto', value: '8' },
        { text: 'Septiembre', value: '9' },
        { text: 'Octubre', value: '10' },
        { text: 'Noviembre', value: '11' },
        { text: 'Diciembre', value: '12' },
    ];

    fechaDia: ItemDateInputData = {
        name: 'dia',
        labelText: 'Día',
        classes: 'w-14',
        maxlength: 2,
    };

    fechaMes: ItemDateInputData = {
        name: 'mes',
        labelText: 'Mes',
        classes: 'w-14',
        maxlength: 2,
    };

    fechaAnyo: ItemDateInputData = {
        name: 'anyo',
        labelText: 'Año',
        classes: 'w-20',
        maxlength: 4,
        labelData: {},
    };
    itemsFecha: ItemDateInputData[] = [this.fechaDia, this.fechaMes, this.fechaAnyo];

    form = new FormGroup({
        formFecha: new FormGroup({
            dia: new FormControl<any>(undefined, Validators.required),
            mes: new FormControl<any>(undefined, Validators.required),
            anyo: new FormControl<any>(undefined, Validators.required),
        }),
    });
    value: any;
    formGroupClasses: string;
    role: string;
    ariaLabel: string;
    ariaDescribedBy: string;
    ariaLabelledBy: string;
    ariaHidden: string;
    ariaDisabled: string;
    ariaControls: string;
    ariaCurrent: string;
    ariaLive: string;
    ariaExpanded: string;
    ariaErrorMessage: string;
    ariaHasPopup: string;
    tabindex: string;
    classes: string;
    namePrefix = 'prefix';
    errorMessageData: ErrorMessageData = {};
    hintData: HintData = {
        text: 'Fecha límite',
    };

    ngOnInit(): void {
        if (!this.nuevaAdhesionService.getProcedimientoActual()){
            this.router.navigate(['nueva-adhesion/seleccion-servicio']);
            return;
        }
        window.scroll(0, 0);
        this.compruebaGetBaseLegal();
    }

    compruebaGetBaseLegal() {
        let baseActual = this.nuevaAdhesionService.getIncorporacionBases();
        if (baseActual != null) {
            this.valorTipo = baseActual.tipo;
            this.valorRango = baseActual.rango;
            this.valorDiaNorma = baseActual.dia;
            this.valorMesNorma = baseActual.mes;
            this.valorTituloNorma = baseActual.titulo;
            this.valorEnlaceNorma = baseActual.enlace;
            if (baseActual.tiempoVigencia === '31/12/2999') {
                this.valorVigencia = 'indefinido';
            } else {
                this.valorVigencia = 'fechaDefinida';
                if(baseActual.tiempoVigencia){
                    this.form.get('formFecha')!.get('dia')!.setValue(baseActual.tiempoVigencia.substring(0, 2));
                    this.form.get('formFecha')!.get('mes')!.setValue(baseActual.tiempoVigencia.substring(3, 5));
                    this.form.get('formFecha')!.get('anyo')!.setValue(baseActual.tiempoVigencia.substring(6, 10));
                }
            }
        }
    }

    goToSeleccionServicio() {
        this.router.navigate(['/nueva-adhesion/seleccion-servicio']);
    }
    onSubmit() {
        this.controlaFechaVigencia = false;
        this.controlaFechaNormativa = false;
        this.controlaErrores = false;

        if (!this.valorTipo || !this.valorTituloNorma || !this.valorEnlaceNorma || !this.valorVigencia) {
            this.controlaErrores = true;
        }

        if (!this.valorMesNorma || !this.valorRango || !this.valorDiaNorma) {
            this.controlaErrores = true;
        } else {
            const anyoNormativa = +this.valorRango.substring(this.valorRango.length - 4);
            const diasMesNormativa = this.daysInMonth(+this.valorMesNorma, +anyoNormativa);
            if (
                isNaN(+this.valorDiaNorma) ||
                +this.valorDiaNorma > diasMesNormativa ||
                +this.valorDiaNorma < 1 ||
                isNaN(anyoNormativa)
            ) {
                this.controlaFechaNormativa = true;
            }
        }
        if (this.valorVigencia === 'indefinido') {
            this.fechaVigencia = '31/12/2999';
        } else {
            const { dia, mes, anyo } = this.form.controls.formFecha.value;
            if (!dia || !mes || !anyo) {
                this.controlaFechaVigencia = true;
            } else {
                const diasMesfechaVigencia = this.daysInMonth(mes, anyo);
                if (dia > diasMesfechaVigencia || dia < 1 || mes > 12 || mes < 1 || anyo.length < 4) {
                    this.controlaFechaVigencia = true;
                }
            }
            if (!this.controlaFechaVigencia) {
                this.fechaVigencia = `${dia.length === 1 ? '0' + dia : dia}/${
                    mes.length === 1 ? '0' + mes : mes
                }/${anyo}`;
            }
        }
        if (this.controlaErrores || this.controlaFechaNormativa || this.controlaFechaVigencia) return;

        let bases: Bases = {
            tipo: this.valorTipo,
            rango: this.valorRango,
            dia: this.valorDiaNorma,
            mes: this.valorMesNorma,
            titulo: this.valorTituloNorma,
            enlace: this.valorEnlaceNorma.startsWith('http') ? this.valorEnlaceNorma : 'https://' + this.valorEnlaceNorma,
            tiempoVigencia: this.fechaVigencia,
        };

        this.nuevaAdhesionService.setIncorporacionBases(bases);
        this.router.navigate(['nueva-adhesion/RPTs']);
    }

    daysInMonth(month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }
}

import { Injectable } from '@angular/core';
import { Bases } from 'src/app/interfaces/bases';
import { ProcedimientoAdministrativo } from 'src/app/interfaces/procedimiento-administrativo';
import { Protocolo } from 'src/app/interfaces/protocolo';
import { Rpt } from 'src/app/interfaces/rpt';

@Injectable({
    providedIn: 'root',
})
export class NuevaAdhesionService {
    protocolo: Protocolo | null;
    bases: Bases | null;
    procedimientoActual: ProcedimientoAdministrativo | null;
    rptsActuales: Rpt[] | null;

    setProtocoloActual(protocolo: Protocolo | null) {
        this.protocolo = protocolo;
    }

    getProtocoloActual(): Protocolo | null {
        return this.protocolo;
    }
    setIncorporacionBases(bases: Bases) {
        this.bases = bases;
    }

    getIncorporacionBases(): Bases | null {
        return this.bases;
    }
    setProcedimientoActual(procedimiento: ProcedimientoAdministrativo) {
        this.procedimientoActual = procedimiento;
    }

    getProcedimientoActual(): ProcedimientoAdministrativo | null {
        return this.procedimientoActual;
    }
    setRPTsActuales(rpts: Rpt[]) {
        this.rptsActuales = rpts;
    }

    getRPTsActuales(): Rpt[] | null {
        return this.rptsActuales;
    }

    clear() {
        this.protocolo = null;
        this.bases = null;
        this.procedimientoActual = null;
        this.rptsActuales = null;
    }
}

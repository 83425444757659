import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login/login.service';

@Injectable({
    providedIn: 'root',
})
export class IsloggedAuthGuard  {
    constructor(public auth: LoginService, public router: Router) {}
    canActivate() {
        if (this.auth.isAuthenticated()) {
            this.router.navigate(['home']);
            return false;
        }
        return true;
    }
}
